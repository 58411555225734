import { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'redux'

import { editSegmentGroupMountLogElementHistoryDetailFilter } from '@/api/element-history-detail-filter'
import * as ApplicationActions from '@/store/application/main/actions'
import { updateElement } from '@/store/elements/actions'
import { getElementMapsObject } from '@/store/elements/logic'
import FilterHandler from '@/three/logic/FilterHandler'
import type { DefaultState } from '@/types/state'
import type { Translation } from '@/types/translation'
import { Identifiable } from '@/Util/decorators/Identifiable'

import BaseDialog from './BaseDialog'
import HistoryDetailDialog from './HistoryDetailDialog'
import Button from '../components/Button'
import InputComponent from '../specific/Input'
import { Spacer } from './project/OpenProjectDialog/Styles'
import { Form, Text } from '../visualization/dashboard/Dialogs/DialogStyles'

const connector = connect((state: DefaultState) => ({
  elementHistoryDetailsInfo: state.application.main.elementHistoryDetailsInfo,
  ...getElementMapsObject(state),
  term: state.filter.term,
}), {
  closeDialog: ApplicationActions.closeDialog,
  openDialog: ApplicationActions.openDialog,
  setActiveDetailDialogFilter: ApplicationActions.setActiveDetailDialogFilter,
  updateElement,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  t: Translation
}

type State = {
  filter: string
  loading: boolean
  noElementFoundWithFilter: boolean
  typeNotSpecified: boolean
  invalidRealDataUUIDFilter: boolean
}

class EditSegmentGroupDetailFilterDialog extends Component<Props, State> {
  @Identifiable('EditSegmentGroupDetailFilterDialog') public static readonly NAME: string

  public override state: State = {
    filter: '',
    loading: true,
    noElementFoundWithFilter: false,
    typeNotSpecified: false,
    invalidRealDataUUIDFilter: false,
  }

  public override componentDidMount () {
    const { elementHistoryDetailsInfo } = this.props
    const elementMaps = getElementMapsObject(this.props)

    if (!elementHistoryDetailsInfo) {
      return
    }

    const { filter = '' } = elementHistoryDetailsInfo

    const filteredElements = Object.keys(FilterHandler.getFilteredElements(elementMaps, filter, false) ?? {})

    this.setState({
      filter,
      loading: false,
      noElementFoundWithFilter: filteredElements.length === 0,
    })
  }

  private readonly handleFilterInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.handleFilterChange(e.target.value ?? '')
  }

  private readonly handleFilterChange = (newFilter = '') => {
    const elementMaps = getElementMapsObject(this.props)
    const [ type, restFilter ] = newFilter.split('#')
    const invalidRealDataUUIDFilter = newFilter.length > 0 &&
    (!restFilter?.toLowerCase().startsWith('realdatauuid=') || restFilter.length !== 49)

    const filteredElements = Object.keys(FilterHandler.getFilteredElements(elementMaps, newFilter, false) ?? {})

    if (filteredElements.length === 0 && newFilter.length) {
      this.setState({
        noElementFoundWithFilter: true,
        filter: newFilter,
        typeNotSpecified: !type || !newFilter.includes('#'),
        invalidRealDataUUIDFilter,
      })

      return
    }

    this.setState({
      filter: newFilter,
      noElementFoundWithFilter: false,
      typeNotSpecified: false,
      invalidRealDataUUIDFilter,
    })
  }

  private readonly handleClose = () => {
    this.props.closeDialog(EditSegmentGroupDetailFilterDialog.NAME)
  }

  private readonly handleUseCurrentTerm = () => {
    const { term } = this.props

    this.handleFilterChange(term)
  }

  private readonly handleSubmit = () => {
    const {
      elementHistoryDetailsInfo,
      openDialog,
      closeDialog, 
      setActiveDetailDialogFilter,
      updateElement,
    } = this.props
    const { filter } = this.state

    if (!elementHistoryDetailsInfo) {
      return
    }

    const { segmentGroupMountLogId, filter: pastFilter } = elementHistoryDetailsInfo

    try {
      const res = editSegmentGroupMountLogElementHistoryDetailFilter(segmentGroupMountLogId, filter)

      if (!res) {
        return
      }

      updateElement('SegmentGroupMountLog', segmentGroupMountLogId, { historyDetailDialogFilter: filter })

      setActiveDetailDialogFilter(filter, segmentGroupMountLogId)

      if (!pastFilter.length && filter.length) {  
        openDialog(HistoryDetailDialog.NAME)
      }
      else if (pastFilter.length && !filter.length) {
        closeDialog(HistoryDetailDialog.NAME)
      }

      this.handleClose()
    }
    catch (error: any) {
      // eslint-disable-next-line no-console
      console.error(error)
    }
  }

  public override render () {
    const { elementHistoryDetailsInfo, term } = this.props
    const { filter, loading, noElementFoundWithFilter, typeNotSpecified, invalidRealDataUUIDFilter } = this.state

    if (!elementHistoryDetailsInfo) {
      return null
    }

    return (
      <BaseDialog
        title='title'
        icon='pe-7s-server'
        header='History Detail Dialog'
        onClose={this.handleClose}
        headerWidth='390px'
        medium
        contentMinHeight={100}
      >
        <Form $minHeight={100}>
          <InputComponent
            type='text'
            name='editFilter'
            placeholder='Edit Filter'
            label='Edit Filter'
            onChange={this.handleFilterInputChange}
            value={filter}
          />
          {
            noElementFoundWithFilter && (
              <>
                <Spacer $h={15} $br />
                <Text style={{ color: 'yellow' }}>
                  No element found with the filter
                </Text>
              </>
            )
          }
          {
            typeNotSpecified && (
              <>
                <Spacer $h={15} $br />
                <Text style={{ color: 'yellow' }}>
                  Type not specified
                </Text>
              </>
            )
          }
          {
            invalidRealDataUUIDFilter && (
              <>
                <Spacer $h={15} $br />
                <Text style={{ color: 'yellow' }}>
                  Invalid RealDataUUID filter (valid format: type#realDataUUID=...)
                </Text>
              </>
            )
          }
          <div>
            <Button
              title='Use current filter'
              type='warning'
              disabled={!term.length}
              onClick={this.handleUseCurrentTerm}
              loading={loading}
              half
            >
              Use current filter
            </Button>
            <Button
              title='Submit'
              type='primary'
              disabled={noElementFoundWithFilter}
              onClick={this.handleSubmit}
              loading={loading}
              half
            >
              Submit
            </Button>
          </div>
        </Form>
      </BaseDialog>
    )
  }
}

export default compose<any>(connector)(EditSegmentGroupDetailFilterDialog)
