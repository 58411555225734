import React from 'react'

import Input from '@/react/specific/Input'

import { IconButton, InputWrapper, Label } from './styles'

const hasResetButton = (props: any) => {
  const {
    onUsePreset: handleUsePreset,
    currentSteelGrade,
    assignments,
    inputValues,
    fieldIndex,
    inputIndex,
    index,
    i,
  } = props

  if (Object.keys(currentSteelGrade ?? {}).length < 1) {
    return null
  }

  const currentAssignment = ((assignments ?? [])[i] ?? [])[index] ?? {}
  const currentField = currentAssignment.val ?? []
  const currentPresetValue = currentField[inputIndex] === currentAssignment.key
    ? currentField[inputIndex]
    : currentSteelGrade[currentAssignment.key]

  return (
    <div>
      {
        currentPresetValue && inputValues[i][fieldIndex] !== currentPresetValue
          ? (
            <IconButton
              className='pe-7s-prev'
              onClick={() => handleUsePreset(i, fieldIndex, currentPresetValue)}
              title={currentPresetValue}
            />
          )
          : (
            <IconButton
              className='pe-7s-prev'
              title={currentPresetValue}
              $gray
            />
          )
      }
    </div>
  )
}

export default function FormBuilder (props: any) {
  const {
    parameterStructure,
    inputValues,
    onInput: handleInput,
    disabled,
  } = props

  return (
    <div>
      {
        parameterStructure && parameterStructure.map((val: any, i: number) => {
          if (!val) {
            return null
          }

          const countArray = new Array(val.count).fill(val.types)
          let fieldId = 0

          return countArray.map((inputTypes, index) => (
            <InputWrapper key={index}>
              <Label title={val.parameterName}>
                {val.parameterName}
              </Label>
              <div>
                {
                  inputTypes.map((inputType: string, inputIndex: number) => {
                    const fieldIndex = fieldId

                    fieldId++

                    return (
                      <React.Fragment key={`${i}:${fieldIndex}`}>
                        <Input
                          autoFocus={!props.tileId && Boolean(index < 1 && inputIndex < 1)}
                          name={`${i}:${fieldIndex}`}
                          value={inputValues[i][fieldIndex]}
                          placeholder={val.parameterName[inputIndex]}
                          type='text'
                          onChange={handleInput}
                          disabled={disabled}
                          half
                          data-tile
                          className={`${disabled ? 'disabled' : ''} ${inputType === 'number' ? 'largeText' : ''}`}
                        />
                        {!disabled && hasResetButton({ ...props, fieldIndex, inputIndex, index, i })}
                      </React.Fragment>
                    )
                  })
                }
              </div>
            </InputWrapper>
          ))
        })
      }
    </div>
  )
}
