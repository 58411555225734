import { DialogID } from '../DriverID'
import { DriverManager, DriverStep } from '../DriverManager'

const CONTEXT: Record<string, boolean> = {}

export default [
  {
    element: DialogID.OpenProject.ID,
    popover: {
      title: 'Open Project Dialog',
      description: 'tbd',
      showButtons: [ 'next', 'close' ],
    },
  },
  {
    element: DialogID.OpenProject.ProjectSelector,
    popover: {
      title: 'Open Project Selector',
      description: 'tbd',
    },
  },
  {
    element: '.MuiPopover-paper',
    popover: {
      title: 'Select "Create New Project"',
      description: 'tbd',
    },
  },
  {
    element: DialogID.CreateProject.ID,
    popover: {
      title: 'Create Project Dialog',
      description: 'tbd',
      showButtons: [ 'next', 'close' ],
    },
  },
  {
    element: DialogID.CreateProject.NameInput,
    popover: {
      title: 'Name',
      description: 'tbd',
      showButtons: [ 'next', 'close' ],
    },
  },
  {
    element: DialogID.CreateProject.DescriptionInput,
    popover: {
      title: 'Description',
      description: 'tbd',
      showButtons: [ 'next', 'close' ],
    },
  },
  {
    element: DialogID.CreateProject.CreateButton,
    popover: {
      title: 'Create',
      description: 'tbd',
    },
    onDeselected: () => {
      if (CONTEXT[DialogID.CreateProject.CreateButton]) {
        return
      }

      CONTEXT[DialogID.CreateProject.CreateButton] = true

      let remainingRetries = 20

      // console.log('onDeselected')

      const waitFunc = async () => {
        const dialogOpen = document.querySelector(DialogID.ProjectData.ID)

        if (dialogOpen || remainingRetries-- <= 0) {
          // console.log('dialogOpen', dialogOpen, remainingRetries)

          DriverManager.current?.moveNext()

          delete CONTEXT[DialogID.CreateProject.CreateButton]

          return
        }

        await new Promise((resolve) => setTimeout(resolve, 100))

        requestAnimationFrame(waitFunc)
      }

      requestAnimationFrame(waitFunc)
    },
  },
  {
    popover: {
      title: 'Create',
      description: 'tbd',
    },
  },
  {
    element: DialogID.ProjectData.ID,
    popover: {
      title: 'Project Data Dialog',
      description: 'tbd',
      showButtons: [ 'next', 'close' ],
    },
  },
  {
    element: DialogID.ProjectData.UploadButton,
    popover: {
      title: 'Upload',
      description: 'tbd',
    },
  },
  {
    element: DialogID.ProjectData.OpenCasterButton,
    popover: {
      title: 'Load Caster',
      description: 'this could take a while to load',
    },
  },
] as DriverStep[]
