// window.usageTimeInfo
import { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'redux'
import styled from 'styled-components'

import { UsageTimeExceededDialog } from '@/react/dialogs/UsageTimeExceededDialog'
import * as ApplicationActions from '@/store/application/main/actions'
import type { DefaultState } from '@/types/state'
import { displayTime } from '@/Util/time'

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
`

// TODO: use theme colors
export const TimeDisplay = styled.div`
  position: relative;
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
  color: #fff;

  .icon {
    position: absolute;
    left: -13px;
    top: -1px;
    font-size: 14px;
  }

  .icon-linux {
    font-size: 10px;
  }

  span:not(.icon) {
    margin-left: 5px;
    vertical-align: middle;
  }
`

const connector = connect((state: DefaultState) => ({
  openAppDialogs: state.application.main.openAppDialogs,
  authenticationData: state.application.main.authenticationData,
}), {
  openDialog: ApplicationActions.openDialog,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  t(key: string, params?: Record<string, unknown>): string
}

type State = {
  remainingSeconds: number | null
}

class ApplicationUsageTime extends Component<Props, State> {
  private intervalId: number | undefined

  public override state: State = {
    remainingSeconds: null,
  }

  public override componentDidMount (): void {
    this.intervalId = window.setInterval(() => {
      this.handleInterval()
    }, 1000)
  }

  public override componentWillUnmount (): void {
    if (this.intervalId) {
      window.clearInterval(this.intervalId)
    }
  }

  private readonly handleInterval = () => {
    if (!window.usageTimeInfo) {
      return
    }

    const { usageTime, usageTimeLimit, intervalStartedAt, usageTimeExceeded } = window.usageTimeInfo ?? {}

    if (usageTimeExceeded) {
      this.setState({ remainingSeconds: -1 })

      return
    }

    const remainingSeconds = Math.floor((usageTimeLimit - usageTime) * 60 - (Date.now() - intervalStartedAt) / 1000)

    const { openAppDialogs, openDialog } = this.props

    if (usageTimeExceeded && !openAppDialogs.includes(UsageTimeExceededDialog.NAME)) {
      openDialog(UsageTimeExceededDialog.NAME)
    }

    this.setState({ remainingSeconds })
  }
  
  public override render () {
    const { remainingSeconds } = this.state
    const { authenticationData, t } = this.props
    const { usageTimeLimit } = window.usageTimeInfo ?? {}

    if (usageTimeLimit === null || remainingSeconds === null || authenticationData?.userType !== 'local') {
      return null
    }

    const title = `${t('usageTimeLimit')}: ${displayTime(usageTimeLimit * 60, true)}`

    const isWindows = (navigator.platform ?? navigator.userAgent).toLowerCase().includes('win')

    if (remainingSeconds <= 0) {
      return (
        <Wrapper>
          <TimeDisplay title={title}>
            <span className={isWindows ? 'icon' : 'icon-linux'}>⚠️</span>
            <span>{t('usageTimeExceeded')}</span>
          </TimeDisplay>
        </Wrapper>
      )
    }

    return (
      <Wrapper>
        <TimeDisplay title={title}>
          {t('usageTimeRemaining')}: {displayTime(remainingSeconds)}
        </TimeDisplay>
      </Wrapper>
    )
  }
}

export default compose<any>(withTranslation('titlebar'), connector)(ApplicationUsageTime)
