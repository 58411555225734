import Util from '@/three/logic/Util'
import type { SensorPointParent } from '@/types/state'

import FeatureFlags, { Permissions } from '.'

export default class FeatureFlagsUtil {
  public static getSensorPointLocations (paths: string[]) {
    const locations: Record<SensorPointParent, boolean> = {
      RollerBody: false,
      Roller: false,
      Segment: false,
    }

    for (const parentType in locations) {
      for (const path of paths) {
        const { type } = Util.getParentInfo(path)

        if (type === parentType) {
          locations[parentType as SensorPointParent] = true

          break
        }
      }
    }

    return locations
  }

  public static getSensorPointEditPermissions = (featureFlags: Permissions) => ({
    MoldFace: FeatureFlags.canEditSensorPointInMoldFace(featureFlags),
    RollerBody: FeatureFlags.canEditSensorPointInRollerBody(featureFlags),
    RollerBearing: FeatureFlags.canEditSensorPointInRollerBearing(featureFlags),
    Roller: FeatureFlags.canEditSensorPointInRoller(featureFlags),
    Segment: FeatureFlags.canEditSensorPointInSegment(featureFlags),
    Nozzle: FeatureFlags.canEditSensorPointInNozzle(featureFlags),
  })
}
