import hoistStatics from 'hoist-non-react-statics'
import hotkeys from 'hotkeys-js'
import cloneDeep from 'lodash/cloneDeep'
import { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'redux'

import { checkProjectNameAvailability, updateProject } from '@/api/project'
import { useConfig } from '@/config'
import Util from '@/logic/Util'
import Button from '@/react/components/Button'
import FeatureFlags from '@/react/FeatureFlags'
import Input from '@/react/specific/Input'
import { Form } from '@/react/visualization/dashboard/Dialogs/DialogStyles'
import ApiClient from '@/store/apiClient'
import * as ApplicationActions from '@/store/application/main/actions'
import type { DefaultState } from '@/types/state'
import { Identifiable } from '@/Util/decorators/Identifiable'

import { OpenProjectDialog } from './OpenProjectDialog'
import BaseDialog from '../BaseDialog'

const T = 'editProjectDialog'

const connector = connect((state: DefaultState) => ({
  editProjectId: state.application.main.editProjectId,
  currentProject: state.application.main.currentProject,
  featureFlags: FeatureFlags.getRealFeatureFlags(state),
}), {
  openDialog: ApplicationActions.openDialog,
  closeDialog: ApplicationActions.closeDialog,
  setEditProjectId: ApplicationActions.setEditProjectId,
  setCurrentProject: ApplicationActions.setCurrentProject,
})

type PropsFromRedux = ConnectedProps<typeof connector>

export interface Props extends PropsFromRedux {
  t(key: string, params?: Record<string, unknown>): string
}

type State = {
  [key: string]: boolean | string
  name: string
  initialName: string
  nameAvailable: boolean
  description: string
  error: string
  loading: boolean
}

export class EditProjectDialog extends Component<Props, State> {
  @Identifiable('EditProjectDialog') public static readonly NAME: string

  private didInit = false

  private checkProjectNameTimeout?: NodeJS.Timeout

  public override state: State = {
    name: '',
    initialName: '',
    nameAvailable: true,
    description: '',
    error: '',
    loading: false,
  }
  
  public override componentDidMount () {
    this.handleInit()
    hotkeys('Escape', this.handleClose)
  }
  
  public override componentDidUpdate () {
    this.handleInit()
  }
  
  public override componentWillUnmount () {
    hotkeys.deleteScope('other')
    hotkeys.unbind('Escape', this.handleClose)
  }

  private readonly handleInit = () => {
    const { editProjectId } = this.props

    if (!this.didInit && editProjectId) {
      this.didInit = true

      ApiClient
        .get(`${useConfig().apiBaseURL}/projects/${editProjectId}`)
        .then(({ name, description }) => {
          this.setState({
            name,
            initialName: name,
            description,
          })
        })
        .catch((response) => {
          const { error } = response

          this.setState({
            error: error ? error.status : 'unknown',
            loading: false,
          })
        })
    }
  }

  private readonly handleClose = () => {
    const { closeDialog, openDialog, setEditProjectId } = this.props

    closeDialog(EditProjectDialog.NAME)
    openDialog(OpenProjectDialog.NAME)
    setEditProjectId()
  }

  private readonly handleInput = (event: any) => {
    const { name, value } = event.target

    this.setState({
      [name]: value,
    })

    if (name === 'name') {
      Util.checkNameAvailability(
        value,
        this.state.initialName,
        (state) => this.setState(state),
        this.checkProjectNameTimeout,
        (timeout) => {
          this.checkProjectNameTimeout = timeout
        },
        checkProjectNameAvailability,
      )
    }
  }

  private readonly handleKeyDown = async (event: any) => {
    if (event.keyCode === 13) {
      await this.handleSubmit()
    }
  }

  private readonly handleSubmit = async () => {
    const { name, description } = this.state
    const { editProjectId, currentProject, setCurrentProject } = this.props

    if (!name || !editProjectId) {
      return
    }

    this.setState({
      loading: true,
    })

    await updateProject(editProjectId, { name, description })

    if (currentProject && currentProject.id === editProjectId) {
      const project = cloneDeep(currentProject)

      project.name = name
      project.description = description

      setCurrentProject(project)
    }

    this.handleClose()
  }
  
  public override render () {
    const { name, description, error, loading, nameAvailable } = this.state
    const { t, featureFlags } = this.props
    const submitDisabled = !(name.length > 0 && description.length > 0) || !nameAvailable

    return (
      <BaseDialog
        title={t(`${T}.title`)}
        icon='pe-7s-folder'
        header={t(`${T}.header`)}
        onClose={this.handleClose}
        small
      >
        <Form>
          <Input
            label={t(`${T}.name.label`)}
            title={t(`${T}.name.label`)}
            placeholder={t(`${T}.name.placeholder`)}
            name='name'
            type='text'
            value={name}
            onChange={this.handleInput}
            onKeyDown={this.handleKeyDown}
            disabled={!FeatureFlags.canRenameProject(featureFlags)}
            error={!nameAvailable}
            helperText={nameAvailable ? '' : t(`${T}.name.notAvailable`)}
          />
          <Input
            label={t(`${T}.description.label`)}
            title={t(`${T}.description.label`)}
            placeholder={t(`${T}.description.placeholder`)}
            name='description'
            type='text'
            rows={4}
            value={description}
            onChange={this.handleInput}
            disabled={!FeatureFlags.canEditProjectDescription(featureFlags)}
          />
          <Button
            type='primary'
            title={submitDisabled ? t(`${T}.save.disabled`) : ''}
            disabled={submitDisabled}
            onClick={this.handleSubmit}
            error={error}
            loading={loading}
            isRef
          >
            {t(`${T}.save.label`)}
          </Button>
          <Button
            value=''
            onClick={this.handleClose}
            title={t(`${T}.cancel`)}
          >
            {t(`${T}.cancel`)}
          </Button>
        </Form>
      </BaseDialog>
    )
  }
}

const composedComponent = compose<any>(withTranslation('application'), connector)(EditProjectDialog)

export default hoistStatics(composedComponent, EditProjectDialog)
