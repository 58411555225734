import { filterActions } from 'redux-ignore'

import { initialState, UtilAction, utilActionList, UtilActionsEnum } from './consts'
import { DataActionsEnum } from '../data/consts'

// eslint-disable-next-line @typescript-eslint/ban-types
const actionHandlers: Record<string, Function | undefined> = {
  [UtilActionsEnum.ACTION_SET_USER_SETTINGS]: (state: UtilState, { settings }: UtilAction) => ({
    ...state,
    ...settings,
  }),

  [UtilActionsEnum.ACTION_SET_SAVE_PATH]: (state: UtilState, { savePath }: UtilAction) => ({
    ...state,
    savePath,
  }),

  [UtilActionsEnum.ACTION_SET_ROLLER_VISIBLE]: (state: UtilState, { rollerChildren }: UtilAction) => ({
    ...state,
    rollerChildren,
  }),

  [UtilActionsEnum.ACTION_SET_IS_CONSISTENCY_CHECK_CASE]: (state: UtilState) => ({
    ...state,
    isConsistencyCheck: true,
  }),

  [DataActionsEnum.ACTION_RESET_ALL]: (state: UtilState) => ({
    ...state,
    rollerChildren: initialState.rollerChildren,
  }),
}

function utilReducers (state = initialState, action: any) {
  const handler = (actionHandlers as any)[action.type]

  return handler ? handler(state, action) : state
}

export default filterActions(utilReducers, [
  ...utilActionList,
  DataActionsEnum.ACTION_RESET_ALL,
]) as typeof utilReducers
