// TODO: add actions and reducers

// export enum tileWarningsActions {
//     ACTION_SET_TILE_WARNINGS = 'TileWarningsActions/ACTION_SET_TILE_WARNINGS',
//     DELETE = 'TileWarningsActions/DELETE',
//   }

// export function setTileWarnings (warnings: any) {
//   return {
//     type: tileWarningsActions.ACTION_SET_TILE_WARNINGS,
//     tileWarnings: warnings,
//   }
// }

const initialState = {
  reference: null, // null means current caster, do not use new Date() here as it would not change to the current date
}

export function getReferenceDate (state: TimestampsState): Date {
  // add a day to the current date in order to always be ahead of the server time
  return state['reference'] ?? new Date(Date.now() + 86400000) // 86400000 is 24 hours in milliseconds
}

export default function (): TimestampsState {
  return initialState
}
