import ThreeUtil from '@/three/logic/Util'
import { StrandSides } from '@/types/elements/enum'
import type { SlotMapKey } from '@/types/state'
import { ElementMapsUtil } from '@/Util/ElementMapsUtil'
import { Mapping } from '@/Util/mapping/Mapping'

import SectionView from '.'

export default class CalculationUtil {
  public static getButtonCoordinates (x: number | null, y: number | null, name: string, buttons: any) {
    const buttonCoordinates: any = {}

    buttonCoordinates.x = x ?? buttons[name]?.position?.x ?? 0
    buttonCoordinates.y = y ?? buttons[name]?.position?.y ?? 0

    return buttonCoordinates
  }

  public static calcSectionPlane (
    base: number,
    large1: number,
    wide1: number,
    large2: number,
    wide2: number,
    margin: number,
  ) {
    return Math.max(
      base + large1 * 2,
      wide1 * 2,
      base + large2 * 2,
      wide2 * 2,
    ) + margin
  }

  public static calcCameraZoom (height: number, width: number, min: Coord, max: Coord) {
    return Math.min(width / (max.x - min.x), height / (max.y + 0.5 - min.y)) / 50 * 0.85
  }

  public static calcMousePosition (mouseValueOnCanvas: number, axisValue: number, dimension: number, isY = false) {
    const valueToAdd = isY ? 1 : -1
    let firstExpression = (mouseValueOnCanvas - axisValue) / dimension

    if (isY) {
      firstExpression = -firstExpression
    }

    return (firstExpression * 2 + valueToAdd)
  }

  public static getActualSegmentGroup (view: SectionView, currentPasslnPosition: number) {
    if (!currentPasslnPosition || view.jumpOver === null) {
      const id = Mapping.mountLogIdByTypeAndNumericId.SegmentGroup[0]
      const firstSGName = view.elementMaps.SegmentGroupMountLog[id ?? '']?.name ?? ''

      return {
        id: 0,
        name: firstSGName,
      }
    }

    if (view.jumpOver === 'DataPoint') {
      const id = this.getActualSegmentGroupDataPointCase(view, currentPasslnPosition)
      const name = view.elementMaps.SegmentGroupMountLog[id ?? '']?.name ?? ''

      return {
        id: Mapping.numericIdByMountLogId[id ?? ''] ?? 0,
        name,
      }
    }

    const elementTypeMountLogMap = view.elementMaps[`${view.jumpOver}MountLog`]
    const mountLogs = Object.values(elementTypeMountLogMap ?? {}) as BaseMountLog[]

    if (mountLogs.length === 0) {
      return {
        id: 0,
        name: '',
      }
    }

    const currentPsslnPositionMountLog = mountLogs.find(mountLog => {
      const slot = view.elementMaps[`${view.jumpOver}Slot` as SlotMapKey][mountLog.slotId ?? '']

      return slot?.passlineCoord === currentPasslnPosition
    })
    // eslint-disable-next-line max-len
    const currentPath = Mapping.elementPathByMountLogId[currentPsslnPositionMountLog?.id ?? '']

    if (!currentPath) {
      return {
        id: 0,
        name: '',
      }
    }

    const { path: segmentPath } = ThreeUtil.getParentInfo(currentPath)
    const { id: segmentGroupId, path: segmentGroupPath } = ThreeUtil.getParentInfo(segmentPath)
    const segmentGroup = ElementMapsUtil.getFullCasterElementByPath(segmentGroupPath, view.elementMaps)

    if (!segmentGroup || segmentGroupId === undefined) {
      return {
        id: 0,
        name: '',
      }
    }

    const segmentGroupName = segmentGroup.name ?? ''

    return {
      id: segmentGroupId,
      name: segmentGroupName,
    }
  }

  public static getCurrentFixedSideRollerNumber (view: SectionView, currentPasslinePosition: number) {
    const { RollerMountLog, RollerSlot } = view.elementMaps

    const fixedSideMountLog = Object.values(RollerMountLog).find(rollerMountLog =>
      RollerSlot[rollerMountLog?.slotId ?? '']?.side === StrandSides.Fixed &&
      RollerSlot[rollerMountLog?.slotId ?? '']?.passlineCoord === currentPasslinePosition
    )

    if (!fixedSideMountLog) {
      return SectionView.currentFixedSideRollerNumber
    }

    const { id } = fixedSideMountLog

    return Mapping.numericIdByMountLogId[id] ?? SectionView.currentFixedSideRollerNumber
  }

  // calculate get actual segment group when jumping over data points

  private static getActualSegmentGroupDataPointCase (view: SectionView, currentPasslnPosition: number): string | null {
    // search for the nearest nozzle or roller to the current passln position
    const nozzleSlots = Object.values(view.elementMaps.NozzleSlot)
    const rollerSlots = Object.values(view.elementMaps.RollerSlot)
    const segmentGroupSlots = Object.values(view.elementMaps.SegmentGroupSlot)

    const { element: nozzleSlot, difference: d1 } = CalculationUtil.findNearestSlotToPassLn(
      nozzleSlots,
      currentPasslnPosition,
    )
    const { element: rollerSlot, difference: d2 } = CalculationUtil.findNearestSlotToPassLn(
      rollerSlots,
      currentPasslnPosition,
    )
    const { element: segmentGroupSlot, difference: d3 } = CalculationUtil.findNearestSlotToPassLn(
      segmentGroupSlots,
      currentPasslnPosition,
    )

    if (!nozzleSlot && !rollerSlot && !segmentGroupSlot) {
      return null
    }

    const segmentGroupMountLogs = Object.values(view.elementMaps.SegmentGroupMountLog)
    const segmentGroupMountLog = segmentGroupMountLogs.find(segmentGroupMountLog =>
      segmentGroupMountLog.slotId === segmentGroupSlot?.id
    )

    if (d3 < d2 && d3 < d1 && segmentGroupMountLog) {
      return segmentGroupMountLog.id
    }

    const nozzleMountLog = Object.values(view.elementMaps.NozzleMountLog).find(nozzleMountLog =>
      nozzleMountLog.slotId === nozzleSlot?.id
    )

    const segmentMountLogs = Object.values(view.elementMaps.SegmentMountLog)

    if (d1 < d2 && nozzleMountLog) {
      const segmentMountLog = segmentMountLogs.find(segmentMountLog =>
        segmentMountLog.id === nozzleMountLog.segmentMountLogId
      )

      const segmentGroupMountLog = segmentGroupMountLogs.find(segmentGroupMountLog =>
        segmentGroupMountLog.id === segmentMountLog?.segmentGroupMountLogId
      )

      return segmentGroupMountLog?.id ?? null
    }

    const rollerMountLog = Object.values(view.elementMaps.RollerMountLog).find(rollerMountLog =>
      rollerMountLog.slotId === rollerSlot?.id
    )

    if (rollerMountLog) {
      const segmentMountLog = segmentMountLogs.find(segmentMountLog =>
        segmentMountLog.id === rollerMountLog.segmentMountLogId
      )

      const segmentGroupMountLog = segmentGroupMountLogs.find(segmentGroupMountLog =>
        segmentGroupMountLog.id === segmentMountLog?.segmentGroupMountLogId
      )

      return segmentGroupMountLog?.id ?? null
    }

    return null
  }

  private static findNearestSlotToPassLn<Slot extends BaseSlot> (slots: Slot[], currentPasslnPosition: number) {
    let minDistance = Infinity
    let index = 0
    const elementCount = slots.length

    for (let i = 0; i < elementCount; i++) {
      const element = slots[i]
      const distance = Math.abs(currentPasslnPosition - (element?.passlineCoord ?? 0))

      if (distance < minDistance) {
        minDistance = distance
        index = i
      }
    }

    return { element: slots[index] ?? null, difference: minDistance }
  }
}
