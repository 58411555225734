import { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { compose } from 'redux'

import type { Translation } from '@/types/translation'

import { Selector } from '../InputStyles'

type Props = {
  title: string
  name: string
  massValue: string
  isDisabled: boolean
  selectors: Array<any>
  style?: any
  onChange: (e: any) => void
  t: Translation
}

class SelectorField extends Component<Props> {
  public override render () {
    const { isDisabled, title, selectors, onChange, name, massValue, t, style } = this.props
    const displayValues = selectors.map(option => {
      if (typeof option === 'string') {
        return option
      }

      if (option === 0) {
        return t('selectorField.disabled')
      }

      return t('selectorField.enabled')
    })

    return (
      <Selector
        onChange={onChange}
        disabled={isDisabled}
        title={title}
        value={massValue ?? ''}
        name={name}
        style={style}
      >
        {
          massValue === 'multiple Values' &&
            <option value='multiple Values' disabled>{t('selectorField.multiValue')}</option>
        }
        {
          selectors.map((option, index) => (
            <option key={option} value={option}>
              {displayValues[index]}
            </option>
          ))
        }
      </Selector>
    )
  }
}

export default compose<any>(withTranslation('caster'))(SelectorField)
