import { Camera } from 'three'

export default class LodUtil {
  public static readonly LOD_ROLLER = 'LoDRoller'

  public static readonly LOD_ROLLER_BODY = 'LoDRollerBody'

  public static readonly LOD_ROLLER_BEARING = 'LoDRollerBearing'

  public static readonly LOD_TYPES = [ LodUtil.LOD_ROLLER, LodUtil.LOD_ROLLER_BODY, LodUtil.LOD_ROLLER_BEARING ]

  public static handleLoDs (camera: Camera, elementList: any, phantomElementList: any) {
    LodUtil.LOD_TYPES.forEach(key => {
      const type = key.substring(3)

      // handle lod
      if (elementList[type]) {
        Object.values(elementList[type]).forEach((element: any) => {
          element.objects[key].update(camera)
        })
      }

      // handle lod phantom
      if (phantomElementList[type]) {
        Object.values(phantomElementList[type]).forEach((element: any) => {
          element.objects[key].update(camera)
        })
      }
    })
  }
}
