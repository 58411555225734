import * as THREE from 'three'

export default class TextureUtil {
  private static textureLoader: THREE.TextureLoader | null = null

  private static textureRegistry: Record<string, THREE.Texture> = {}

  public static init (): void {
    TextureUtil.textureLoader = new THREE.TextureLoader()
    TextureUtil.textureRegistry = {}
  }

  public static load (path: string, force = false): THREE.Texture {
    const { textureRegistry, textureLoader } = TextureUtil

    if (textureLoader && (force || !textureRegistry[path])) {
      const texture = textureLoader.load(
        path,
        undefined,
        undefined,
        (error) => {
          // eslint-disable-next-line no-console
          console.error('TextureUtil.load', error)
        },
      )

      texture.anisotropy = 16
      texture.colorSpace = THREE.SRGBColorSpace

      textureRegistry[path] = texture
    }

    return textureRegistry[path] ?? new THREE.Texture()
  }
}
