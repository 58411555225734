/* eslint-env browser */

import cloneDeep from 'lodash/cloneDeep'

import { updateDataLineCriticalStrainCurve } from '@/api/element'
import { ViewLogic } from '@/react/visualization/dashboard/ViewLogic'
import type { ElementMaps } from '@/types/state'
import type { PlotConfig } from '@/types/visualization'
import { ElementMapsUtil } from '@/Util/ElementMapsUtil'

import SVG from './SVG'

export type EffectStyle = 'divide2' | 'divide4' | 'linear' | 'no-divide'

type EditData = {
  effectPointsToLeft: number
  effectPointsToRight: number
  effectStyleLeft: EffectStyle
  effectStyleRight: EffectStyle
}

type AdditionalData = {
  xDomain: Domain
  yDomain: Domain
  flipAxes: boolean
  traceCount: number
  editData: EditData
  caseId: string
  casterId: string
}

export default abstract class EditableBase {
  public static readonly TRACE_MISSING = 'TRACE_MISSING'

  public constructor (
    traceClassName: string,
    traceGroupClassName: string,
    traceContainerClassName: string,
    subElementName?: string | undefined,
  ) {
    this.previousValues = []

    this.traceClassName = traceClassName // 'js-line' | 'js-fill' | 'point'
    this.traceGroupClassName = traceGroupClassName // 'lines' | 'fills' | 'points'
    this.traceContainerClassName = traceContainerClassName // 'trace'
    this.subElementName = subElementName ?? '' // 'path' | ''
  }

  protected plot: any

  protected data: Array<any> = []

  protected previousValues: Array<number[]>

  protected additionalData?: AdditionalData | undefined

  protected plotConfig?: PlotConfig | undefined

  protected elementMaps?: ElementMaps | undefined

  protected updateStoreElement?: (type: keyof ElementMaps, id: string, data: any) => any | undefined

  protected dataLinePath?: string | undefined

  protected traceClassName: string

  protected traceGroupClassName: string

  protected traceContainerClassName: string

  protected subElementName: string

  protected xMin?: number | undefined

  protected yMin?: number | undefined

  protected xRange?: number | undefined

  protected yRange?: number | undefined

  protected xScale?: number | undefined

  protected yScale?: number | undefined

  protected xOffset?: number | undefined

  protected yOffset?: number | undefined

  protected calcNeededValues (plot: any, data: any) {
    if (!this.additionalData) {
      return
    }

    const { xDomain, yDomain } = this.additionalData

    const [ xMin, xMax ] = xDomain
    const [ yMin, yMax ] = yDomain

    this.xMin = xMin
    this.yMin = yMin

    this.xRange = xMax - xMin
    this.yRange = yMax - yMin

    const xAxis: string = (data.xaxis ?? 'x').replace(/([xy])(\d*)/, '$1axis$2')
    const yAxis: string = (data.yaxis ?? 'y').replace(/([xy])(\d*)/, '$1axis$2')
    const [ xMinL, xMaxL ] = plot.layout[xAxis].range
    const [ yMinL, yMaxL ] = plot.layout[yAxis].range

    this.xScale = this.xRange / (xMaxL - xMinL)
    this.yScale = this.yRange / (yMaxL - yMinL)

    this.xOffset = xMinL - xMin
    this.yOffset = yMinL - yMin
  }

  protected calcX (x: number, width: number) {
    const { xMin = 0, xRange = 0, xScale = 0, xOffset = 0 } = this
    const denominator = xRange / width

    // instead of returning infinity return 0
    if (!denominator) {
      return 0
    }

    return ((x - xOffset - xMin) / denominator) * xScale
  }

  protected calcY (y: number, height: number) {
    const { yMin = 0, yRange = 0, yScale = 0, yOffset = 0 } = this
    const denominator = yRange / height

    // instead of returning infinity return 0
    if (!denominator) {
      return 0
    }

    return height - (((y - yOffset - yMin) / denominator) * yScale)
  }

  protected buildPathD (_x: number[], _y: number[], _width: number, _height: number): string {
    throw new Error('EditableBase _buildPathD is abstract!')
  }

  protected createPathPart (
    container: Element,
    x: number[],
    y: number[],
    style: string,
    width: number,
    height: number,
    traceIndex: number,
    partIndex: number,
  ): void {
    const path = SVG.getPath()

    path.setAttribute('id', `editable-update-${this.traceClassName}-${traceIndex}-${partIndex}`)
    path.setAttribute('class', `${this.traceClassName} editable-update`)
    path.setAttribute('style', style)

    path.setAttributeNS(null, 'd', this.buildPathD(x, y, width, height))

    container.appendChild(path)
  }

  protected getStyles (data: Array<any>, plot: HTMLElement): Array<string> {
    const traces = Array.from(plot.querySelectorAll(`.${this.traceContainerClassName} ${this.subElementName}`))

    const lines = traces
      .filter(trace =>
        data.find(d => Array.from(trace.classList ?? []).includes(`${this.traceContainerClassName}${d.uid}`))
      )
      .map(trace => trace.querySelector(`.${this.traceClassName}:not(.editable-update) ${this.subElementName}`))
      .filter(Boolean)

    return lines.map(line => line!.getAttribute('style')) as string[]
  }

  protected getMeta (plot: HTMLElement): {
    width: number
    height: number
  } {
    const plotElement = plot.getElementsByClassName('bglayer')

    const { width, height } = plotElement[0]?.getBoundingClientRect() ?? { width: 0, height: 0 }

    return {
      width,
      height,
    }
  }

  protected placeEditMarkers (
    container: Element,
    x: number[],
    y: number[],
    width: number,
    height: number,
  ) {
    for (let i = 0; i < y.length; i++) {
      // Filter duplicate x values to avoid multiple edit circles on top of each other
      if (container.querySelector(`[data-x="${x[i]}"]`) || x[i] === undefined || y[i] === undefined) {
        continue
      }

      const xValue = this.calcX(x[i]!, width)
      const yValue = this.calcY(y[i]!, height)
      const circle = SVG.getCircle()

      circle.setAttribute('id', `edit_circle_${i}`)
      circle.setAttribute('class', 'edit-circle')
      circle.setAttribute('cx', xValue.toFixed(2))
      circle.setAttribute('cy', yValue.toFixed(2))
      circle.setAttribute('r', '5')
      circle.setAttribute('data-x', x[i]!.toString())
      circle.setAttribute('data-y', y[i]!.toString())

      // move up/down
      circle.addEventListener('mousedown', (event: MouseEvent) => {
        container.setAttribute('data-current-x', x[i]!.toString())
        container.setAttribute('data-start-mouse-y', event.clientY.toString())
      })

      container.appendChild(circle)
    }
  }

  protected handleMouseUp () {
    const uiGroup = this.plot?.querySelector('.draglayer .ui-group')

    if (!uiGroup) {
      return
    }

    const hadData = uiGroup.hasAttribute('data-current-x')

    uiGroup.removeAttribute('data-current-x')
    uiGroup.removeAttribute('data-start-mouse-y')

    if (hadData) {
      this.persistData() // TODO: this gets triggered multiple times
    }
  }

  protected updatePoint (
    rangeStart: number,
    rangeEnd: number,
    height: number,
    yValueOffset: number,
    currentXIndex: number,
    currentX: string,
    data: Array<any>,
  ) {
    const currentDot = this.plot.querySelector(`circle[data-x="${currentX}"]`)

    const currentY = Number(currentDot.getAttribute('data-y'))
    let newY = currentY + yValueOffset

    if (newY < rangeStart) {
      newY = rangeStart
    }
    else if (newY > rangeEnd) {
      newY = rangeEnd
    }

    currentDot.setAttribute('cy', this.calcY(newY, height).toFixed(2))
    currentDot.setAttribute('data-y', newY.toString())

    data[0].y[currentXIndex] = newY
  }

  protected calcNewYValue (yValueOffset: number, i: number, extend: number, variant: EffectStyle) {
    switch (variant) {
      case 'divide2':
        return yValueOffset / Math.pow(2, i)

      case 'linear':
        return yValueOffset * (1 / extend) * (extend - i)

      case 'divide4':
        return yValueOffset - yValueOffset / Math.pow(4, extend - i)

      case 'no-divide':
        return yValueOffset

      default:
        return yValueOffset
    }
  }

  protected handleMouseMove (event: MouseEvent) {
    const uiGroup = this.plot?.querySelector('.draglayer .ui-group')

    if (!uiGroup) {
      return
    }

    const foregroundContainer = this.plot.querySelector('.draglayer')
    const dragRect = foregroundContainer?.querySelector('.xy .drag:first-child')

    const { height } = dragRect?.getBoundingClientRect() ?? { height: 0 }

    const [ rangeStart, rangeEnd ] = this.plot.layout?.yaxis?.range ?? [ 0, 1 ]

    const currentX = uiGroup.getAttribute('data-current-x')
    const currentDot = uiGroup.querySelector(`circle[data-x="${currentX}"]`)

    if (!currentX || !currentDot) {
      return
    }

    const startMouseY = Number(uiGroup.getAttribute('data-start-mouse-y') ?? '0')
    const yPixelOffset = event.clientY - startMouseY
    const yValueOffset = (rangeEnd - rangeStart) * (yPixelOffset / height) * -1
    const data = cloneDeep(this.data)

    this.data = data

    const currentXIndex = this.data[0].x.findIndex((x: number) => x === Number(currentX))

    this.updatePoint(rangeStart, rangeEnd, height, yValueOffset, currentXIndex, currentX, data)

    const { editData } = this.additionalData!

    const extendLeft = editData.effectPointsToLeft
    const extendRight = editData.effectPointsToRight

    for (let i = 1; i <= extendLeft; i++) {
      const leftX = this.data[0].x[currentXIndex - i]

      if (currentXIndex - i >= 0) {
        const newYValueOffset = this.calcNewYValue(yValueOffset, i, extendLeft, editData.effectStyleLeft)

        this.updatePoint(rangeStart, rangeEnd, height, newYValueOffset, currentXIndex - i, leftX, data)
      }
    }

    for (let i = 1; i <= extendRight; i++) {
      const rightX = this.data[0].x[currentXIndex + i]

      if (currentXIndex + i < data[0].x.length) {
        const newYValueOffset = this.calcNewYValue(yValueOffset, i, extendRight, editData.effectStyleRight)

        this.updatePoint(rangeStart, rangeEnd, height, newYValueOffset, currentXIndex + i, rightX, data)
      }
    }

    uiGroup.setAttribute('data-start-mouse-y', event.clientY.toString())

    this.update(this.plot, data)
  }

  protected getUIGroup (plot: HTMLElement) {
    const foregroundContainer = plot.querySelector('.draglayer')

    foregroundContainer?.querySelector('.ui-group')?.remove()

    const dragRect = foregroundContainer?.querySelector('.xy .drag:first-child')

    const uiGroup = SVG.getG()

    uiGroup.setAttribute('class', 'ui-group')
    uiGroup.setAttribute('transform', `translate(${dragRect?.getAttribute('x')}, ${dragRect?.getAttribute('y')})`)

    window.removeEventListener('mouseup', this.handleMouseUp.bind(this))
    window.addEventListener('mouseup', this.handleMouseUp.bind(this))

    window.removeEventListener('mousemove', this.handleMouseMove.bind(this))
    window.addEventListener('mousemove', this.handleMouseMove.bind(this))

    foregroundContainer?.appendChild(uiGroup)

    return uiGroup
  }

  protected createTrace (plot: HTMLElement, linesContainer: Element, data: any, style: string, traceIndex: number) {
    const { x, y } = data
    const { width, height } = this.getMeta(plot)
    const length: number = y.length
    const step = Math.ceil(length / 3)

    this.calcNeededValues(plot, data)

    let prevEnd = 0

    const uiGroup = this.getUIGroup(plot)

    for (let i = 0; i < length; i += step) {
      const len = i + step < length ? i + step : length
      const partIndex = Math.floor(i / step)

      this.createPathPart(
        linesContainer,
        x.slice(prevEnd, len),
        y.slice(prevEnd, len),
        style,
        width,
        height,
        traceIndex,
        partIndex,
      )

      this.placeEditMarkers(
        uiGroup,
        x.slice(prevEnd, len),
        y.slice(prevEnd, len),
        width,
        height,
      )

      prevEnd += i === 0 ? step - 1 : step
    }
  }

  protected getSortedData (data: Array<any>, plot: HTMLElement): Array<any> {
    const traces = plot.querySelectorAll(`.${this.traceContainerClassName} ${this.subElementName}`)

    return Array
      .prototype
      .map
      .call(
        traces ?? [],
        (trace: HTMLElement) =>
          data.find(d =>
            Array.prototype.includes.call(trace.classList ?? [], `${this.traceContainerClassName}${d.uid}`)
          ),
      )
      .filter(Boolean)
  }

  protected create (plot: HTMLElement, data: Array<any>) {
    const linesContainer = plot.getElementsByClassName(this.traceGroupClassName)
    const styles = this.getStyles(data, plot)
    const sortedData: Array<any> = this.getSortedData(data, plot)

    for (let i = 0; i < sortedData.length; i++) {
      if (!linesContainer[i]) {
        continue
      }

      this.createTrace(
        plot,
        linesContainer[i]!,
        sortedData[i],
        styles[i]?.replace(/visibility: ?hidden;?/, '') ?? '',
        i,
      )
    }

    this.previousValues = sortedData.map(({ y }) => y)
  }

  protected hasChanges (start: number, end: number, previousValues: number[], data: any) {
    if (previousValues) {
      const axisToCompare: number[] = this.additionalData?.flipAxes ? data.x : data.y

      for (let j = start; j < end; j++) {
        if (axisToCompare[j] !== previousValues[j]) {
          return true
        }
      }
    }

    return false
  }

  protected updateTrace (plot: HTMLElement, data: any, previousValues: number[], traceIndex: number) {
    const { x, y } = data
    const { width, height } = this.getMeta(plot)
    const length: number = y.length
    const step = Math.ceil(length / 3)

    this.calcNeededValues(plot, data)

    let prevEnd = 0

    for (let i = 0; i < length; i += step) {
      const end = i + step < length ? i + step : length

      if (this.hasChanges(prevEnd, end, previousValues, data)) {
        const partIndex = Math.floor(i / step)
        const line = plot
          .querySelector(`#editable-update-${this.traceClassName}-${traceIndex}-${partIndex} ${this.subElementName}`)

        if (!line) {
          return
        }

        line.setAttributeNS(
          null,
          'd',
          this.buildPathD(x.slice(prevEnd, end), y.slice(prevEnd, end), width, height),
        )
      }

      prevEnd += i === 0 ? step - 1 : step
    }
  }

  protected update (plot: HTMLElement, data: Array<any>) {
    const sortedData: Array<any> = this.getSortedData(data, plot)

    for (let i = 0; i < sortedData.length; i++) {
      this.updateTrace(plot, sortedData[i], this.previousValues[i] ?? [], i)
    }

    this.previousValues = sortedData.map(({ y }) => y)
  }

  private getCriticalStrainCurve (config: PlotConfig) {
    if (config.isMergedDynamicDataSource) {
      return config.configs.find((config: PlotConfig) =>
        (
          config.filter ?? ''
        )
          .length &&
        config.filter?.toLowerCase()?.includes('dataline') &&
        config.selectedY?.toLowerCase()?.includes('critical')
      )
    }

    return null
  }

  private getCriticalStrainConfig () {
    if (!this.plotConfig) {
      return null
    }

    return this.getCriticalStrainCurve(this.plotConfig) ?? null
  }

  private getTypeXY (config: any) {
    if (!config) {
      return null
    }

    const { selectedX, selectedY } = config
    const type = 'DataLine'
    const attrX = selectedX.substring(selectedX.indexOf('|') + 1)
    const attrY = `${selectedY.substring(selectedY.indexOf('|') + 1)}ManualMod`

    return {
      type,
      attrX,
      attrY,
    }
  }

  private getData (data: Array<any>): Array<any> | null {
    const criticalStrainConfig = this.getCriticalStrainConfig()
    const typeXY = this.getTypeXY(criticalStrainConfig)

    if (!this.elementMaps || !criticalStrainConfig || !typeXY) {
      return null
    }

    const { type, attrX, attrY } = typeXY

    const elements = ViewLogic.getDynamicElementsFromConfig(
      this.elementMaps,
      criticalStrainConfig,
      {}, // no filteredElementCache needed
      type,
    )

    this.dataLinePath = elements[0] // TODO: ensure this gets the correct path, there should only be one in this array

    const dynamicData: any = ViewLogic.getDynamicDataFromDataLines<DataLineSlot, DataLineMountLog>(
      elements,
      this.elementMaps,
      'DataLine',
      attrX,
      attrY,
      false,
      true,
    )

    const newData = cloneDeep(data)

    if (!dynamicData.length) {
      // eslint-disable-next-line no-console
      console.log('No dynamic data found for critical strain curve, falling back to original data')

      return newData
    }

    newData[0].y = dynamicData.map(({ y }: { y: number }) => y)

    return newData
  }

  private persistData () {
    if (!this.data || !this.dataLinePath || !this.elementMaps) {
      return
    }

    const dataLineMountLog = cloneDeep(ElementMapsUtil.getMountLogByPath<DataLineMountLog>(
      this.dataLinePath,
      this.elementMaps,
    ))
    const criticalStrainConfig = this.getCriticalStrainConfig()
    const typeXY = this.getTypeXY(criticalStrainConfig)

    if (!dataLineMountLog || !criticalStrainConfig || !typeXY) {
      return
    }

    const yValuesString = this.data[0].y.map((y: number) => Number(y).toFixed(4)).join(' ')
    const { attrY } = typeXY

    if (dataLineMountLog.additionalData[attrY] === yValuesString) {
      // console.log('No changes to persist')

      return
    }

    dataLineMountLog.additionalData[attrY] = yValuesString

    updateDataLineCriticalStrainCurve(
      dataLineMountLog,
      this.additionalData?.caseId ?? '',
    )
      .then(() => {
        // eslint-disable-next-line no-console
        console.log('DataLine updated')

        if (this.updateStoreElement) {
          this.updateStoreElement('DataLineMountLog', dataLineMountLog.id, dataLineMountLog)
        }
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('Failed to update DataLine', error)
      })

    // TODO: update DataLine element in elementMaps (store)
  }

  public draw (
    plot: HTMLElement,
    data: Array<any>,
    xDomain: Domain,
    yDomain: Domain,
    flipAxes: boolean,
    editData: EditData,
    caseId: string,
    casterId: string,
  ) {
    this.plot = plot

    const manualModData = this.getData(data)

    if (!manualModData) {
      // TODO: show error on plot

      return
    }

    this.data = manualModData

    this.additionalData = {
      xDomain,
      yDomain,
      flipAxes,
      traceCount: data.length,
      editData,
      caseId,
      casterId,
    }

    const rawLines = plot.querySelectorAll(`.${this.traceClassName}.editable-update`)
    const lines = Array.from(rawLines)

    if (lines.length === 0) {
      return this.create(plot, this.data)
    }

    this.update(plot, data)
  }

  public setEditData (editData: EditData) {
    if (!this.additionalData) {
      return
    }

    this.additionalData.editData = editData
  }

  public setData (
    plotConfig: PlotConfig,
    elementMaps: ElementMaps,
    updateElement: (type: keyof ElementMaps, id: string, data: any) => any,
  ) {
    this.plotConfig = plotConfig
    this.elementMaps = elementMaps
    this.updateStoreElement = updateElement
  }
}
