import hotkeys from 'hotkeys-js'
import { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'redux'

import { setVisualizationConfig } from '@/api/visualization-config'
import * as VisualizationActions from '@/store/visualization/actions'
import type { DefaultState } from '@/types/state'
import type { Translation } from '@/types/translation'
import type { PlotConfig, TileConfig } from '@/types/visualization'

import { Bottom, Header, I, Tab, TabsWrapper, Title } from './Dialogs/DialogStyles'
import PlotConfigComponent from './PlotConfigurator/PlotConfig'

const connector = connect(({ visualization, application }: DefaultState) => ({
  appState: application.main.appState,
  ...visualization, // TODO: connect the single values don't just use spread!
}), {
  showConfigDialog: VisualizationActions.showConfigDialog,
  setConfig: VisualizationActions.setConfig,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  setEditIdToNull: () => void
  fullscreen: boolean
  currentTileId?: string
  editDialogConfigId: string
  plotConfigs: Record<string, PlotConfig>
  tileConfigs: Record<string, TileConfig>
  visualization: any
  viewsObject: any
  visualizationMetaInformation: any
  allowDeletion?: boolean
  t: Translation
}

type State = {
  activeTab: number
}

class ConfigDialogContent extends Component<Props, State> {
  public override state: State = {
    activeTab: 0,
  }
  
  public override componentDidMount () {
    hotkeys('Escape', this.handleCloseConfigDialog)
  }
  
  public override componentWillUnmount () {
    hotkeys.deleteScope('other')
    hotkeys.unbind('Escape', this.handleCloseConfigDialog)
  }

  private readonly handleCloseConfigDialog = () => {
    const { showConfigDialog } = this.props

    showConfigDialog()

    const {
      appState,
      visualizationMetaInformation,
      setConfig,
      plotConfigs,
      tileConfigs,
      viewsObject,
    } = this.props

    const data = { viewsObject, plotConfigs, tileConfigs }

    setVisualizationConfig(visualizationMetaInformation?.[appState]?.config, data)
      .then(() => {
        setConfig({ data } as VisualizationConfig, false)
      })
  }

  private readonly handleTabClick = (event: any) => {
    this.setState({
      activeTab: Number(event.target.id),
    })
  }
  
  public override render () {
    const {
      editDialogConfigId,
      fullscreen,
      plotConfigs,
      tileConfigs,
      currentTileId,
      t,
      allowDeletion,
    } = this.props
    const { activeTab } = this.state
    const { configIds, name } = plotConfigs[editDialogConfigId] ?? {}
    const currentPlot = (
      currentTileId
        ? tileConfigs?.[currentTileId]
        : plotConfigs[editDialogConfigId]
    ) ?? {} as Partial<PlotConfig | TileConfig>
    const plotConfigId = currentTileId ? tileConfigs?.[currentTileId]?.configId : editDialogConfigId
    const { type } = currentPlot

    if (!type) {
      return null
    }

    let tabs = type !== 'text' || fullscreen
      ? [
        { title: 'extended', icon: 'pe-7s-edit' },
      ]
      : []

    if (currentTileId && !fullscreen) {
      tabs.unshift({ title: 'general', icon: 'pe-7s-graph2' })

      if (type === 'line' || type === 'bar' || type === 'area') {
        tabs.push({ title: 'vertical', icon: 'pe-7s-tools' })
      }

      if (
        type !== 'text' &&
        type !== 'contour' &&
        type !== 'gage'
      ) {
        tabs.push({ title: 'show', icon: 'pe-7s-drop' })
      }

      if (type === 'text' || type === 'gage') {
        tabs.push({ title: 'edit', icon: 'pe-7s-display2' })
      }

      if (/^(line|area)$/.test(type) && configIds) {
        tabs.push({ title: 'additional', icon: 'pe-7s-display1' })
      }
    }

    if (/merged/.test(editDialogConfigId)) {
      tabs.push({ title: 'merged', icon: 'pe-7s-menu' })
    }

    if (type === 'edit_box') {
      tabs = [ { title: 'editable_files', icon: 'pe-7s-note2' } ]
    }

    if (type === 'command') {
      tabs = [ { title: 'edit_command', icon: 'pe-7s-note2' } ]
    }

    let displayName = name

    if (currentTileId) {
      displayName = tileConfigs?.[currentTileId]?.name ?? name
    }

    if (!plotConfigs[plotConfigId ?? '']) {
      tabs = tabs.filter((tab) => tab.title === 'general')
    }

    if (type === 'table') {
      const hiddenTabsForTables = [ 'show', 'extended' ]

      tabs = tabs.filter((tab) => !hiddenTabsForTables.includes(tab.title))
      // icon for table
      tabs.push({ title: 'table_preferences', icon: 'pe-7s-note2' })
    }

    return (
      <div>
        {
          !fullscreen &&
          (
            <Header title={t('configDialogContent.title', { name: displayName ?? '' })}>
              <Title>{t('configDialogContent.label', { name: displayName ?? '' })}</Title>
              <I
                title={t('configDialogContent.close')}
                className='pe-7s-close'
                onClick={this.handleCloseConfigDialog}
              />
            </Header>
          )
        }
        <PlotConfigComponent
          activeTab={activeTab}
          fullscreen={fullscreen}
          tabs={tabs}
          allowDeletion={allowDeletion}
          setEditIdToNull={this.props.setEditIdToNull}
        />
        <Bottom>
          <TabsWrapper>
            {
              tabs.map((tab, i) => (
                <Tab
                  key={i}
                  title={t(`configDialogContent.tabs.${tab.title}`)}
                  $activeTab={activeTab === i}
                  onClick={this.handleTabClick}
                  id={i.toString()}
                >
                  <i className={tab.icon} />
                </Tab>
              ))
            }
          </TabsWrapper>
        </Bottom>
      </div>
    )
  }
}

export default compose<any>(withTranslation('visualization'), connector)(ConfigDialogContent)
