import { request } from '@/api'

export async function updateDataLineCriticalStrainCurve (
  dataLineMountLog: DataLineMountLog,
  caseId: string,
): Promise<void> {
  const message = 'Failed to update DataLine'

  await request('patch', 'data-lines/critical-strain-curve', message, {
    params: { caseId },
    data: { dataLineMountLog },
  })
}
