import cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'
import { PureComponent } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'redux'

import { useConfig } from '@/config'
import FeatureFlags from '@/react/FeatureFlags'
import ApiClient from '@/store/apiClient'
import { getElementTypeURL } from '@/store/data/logic'
import { getElementMapsObject } from '@/store/elements/logic'
import { getReferenceDate } from '@/store/timestamps'
import * as VisualizationActions from '@/store/visualization/actions'
import ThreeUtil from '@/three/logic/Util'
import type { DefaultState } from '@/types/state'
import type { CompareCasterInformation } from '@/types/visualization'
import { ElementMapsUtil } from '@/Util/ElementMapsUtil'
import { Mapping } from '@/Util/mapping/Mapping'

import AllInOne from '../AllInOne'
import { SectionContainer, SectionContent } from '../AllInOne/styles'
import Section from '../Section'

const connector = connect((state: DefaultState) => ({
  ...getElementMapsObject(state),
  featureFlags: FeatureFlags.getRealFeatureFlags(state),
  timestamps: state.timestamps,
  selectedComparisonCaseIds: state.visualization.selectedComparisonCaseIds,
  compareCasterInformation: state.visualization.compareCasterInformation,
  Caster: state.Caster,
  currentSimulationCase: state.application.main.currentSimulationCase,
}), {
  // no actions yet
  setCompareCasterInformation: VisualizationActions.setCompareCasterInformation,
})

type PropsFromRedux = ConnectedProps<typeof connector>

export interface Props extends PropsFromRedux {
  paths: string[]
}

type State = void

export class SegmentGroup extends PureComponent<Props, State> {
  private readonly supportPointNameOrder = [ 'INL', 'INR', 'OUTL', 'OUTR' ]

  public override componentDidMount (): void {
    const { paths, selectedComparisonCaseIds } = this.props

    if ((paths.length > 0) && (selectedComparisonCaseIds.length > 0)) {
      this.updateSupportPointsCompareInformation()
    }
  }

  public override componentDidUpdate (prevProps: Readonly<Props>): void {
    const { paths, selectedComparisonCaseIds } = this.props

    if (
      (paths.length > 0) &&
      (selectedComparisonCaseIds.length > 0) &&
      (!isEqual(prevProps.paths, paths) || !isEqual(prevProps.selectedComparisonCaseIds, selectedComparisonCaseIds))
    ) {
      this.updateSupportPointsCompareInformation()
    }
  }

  private readonly updateSupportPointsCompareInformation = async () => {
    const {
      compareCasterInformation,
      paths,
      selectedComparisonCaseIds,
      timestamps,
      setCompareCasterInformation,
      currentSimulationCase,
      Caster,
    } = this.props

    if (!paths[0]) {
      return
    }

    const elementMaps = getElementMapsObject(this.props)
    const supportPointPaths = this.getSupportPointPaths().filter((path) => path)
    const supportPointNames = supportPointPaths
      .map((path) => ElementMapsUtil.getFullCasterElementByPath(path, elementMaps)?.name)
      .filter(Boolean) as string[]
    const segmentGroupElement = ElementMapsUtil.getFullCasterElementByPath(paths[0], elementMaps)

    if ((supportPointNames.length === 0) || !segmentGroupElement) {
      return
    }

    const { passlineCoord } = segmentGroupElement
    const elementsToBeRequestedPerCase: Record<string, string[]> = {}

    for (const caseId of selectedComparisonCaseIds) {
      for (const name of supportPointNames) {
        const key = `${name}_${passlineCoord}`

        const element = compareCasterInformation[caseId]?.['SupportPoint']?.[key]

        if (!element) {
          elementsToBeRequestedPerCase[caseId] = elementsToBeRequestedPerCase[caseId] ?? []
          elementsToBeRequestedPerCase[caseId].push(key)
        }
      }
    }

    if (Object.keys(elementsToBeRequestedPerCase).length > 0) {
      const caseId = currentSimulationCase?.id
      const casterId = Caster?.id
      const data = await ApiClient
        .post(`${useConfig().apiBaseURL}/${getElementTypeURL('SupportPoint')}/compare`, {
          params: { caseId, casterId },
          data: {
            requestedData: elementsToBeRequestedPerCase,
            date: getReferenceDate(timestamps),
          },
        })

      if (!data || (Object.keys(data).length === 0)) {
        return
      }

      const newCompareCasterInformation = cloneDeep(compareCasterInformation)
      const caseIds = Object.keys(data)

      for (const caseId of caseIds) {
        const infoByCase =
          newCompareCasterInformation[caseId] = newCompareCasterInformation[caseId] ?? {} as CompareCasterInformation

        if (!infoByCase['SupportPoint']) {
          infoByCase['SupportPoint'] = {}
        }

        infoByCase['SupportPoint'] = {
          ...infoByCase['SupportPoint'],
          ...data[caseId],
        }
      }

      setCompareCasterInformation(newCompareCasterInformation)
    }
  }

  private readonly getSegmentGroupMountLog = () => {
    const { paths } = this.props
    const elementMaps = getElementMapsObject(this.props)
    const path = paths?.[0] ?? ''
    const { id } = ThreeUtil.getElementInfo(path)
    const segmentGroupMountLogUUID = Mapping.mountLogIdByTypeAndNumericId.SegmentGroup[id]

    return elementMaps.SegmentGroupMountLog[segmentGroupMountLogUUID ?? '']
  }

  private readonly getSupportPointPaths = () => {
    const { paths } = this.props
    const elementMaps = getElementMapsObject(this.props)

    // only use the first path
    const path = paths?.[0] ?? ''
    const segmentGroupMountLog = this.getSegmentGroupMountLog()

    // FIXME: this filter should not be necessary, why does the SegmentGroupMountLog have old supportPointMountLogs?
    const filteredSupportPointMountLogs = segmentGroupMountLog
      ?.supportPointMountLogs
      ?.filter((id) => elementMaps.SupportPointMountLog[id]) ?? []

    filteredSupportPointMountLogs.sort((idA, idB) => {
      const supportPointMountLogA = elementMaps.SupportPointMountLog[idA]
      const supportPointMountLogB = elementMaps.SupportPointMountLog[idB]

      return (
        this.supportPointNameOrder.indexOf(supportPointMountLogA?.name ?? '') -
        this.supportPointNameOrder.indexOf(supportPointMountLogB?.name ?? '')
      )
    })

    return filteredSupportPointMountLogs?.map((supportPointMountLogUUID) => {
      const supportPointId = Mapping.numericIdByMountLogId[supportPointMountLogUUID]

      return `${path}/SupportPoint:${supportPointId}`
    }) ?? []
  }

  private readonly renderSegmentGroup = () => {
    const { paths } = this.props

    return (
      <>
        <AllInOne
          paths={paths}
          type='SegmentGroup'
        />
      </>
    )
  }

  public override render () {
    const { paths } = this.props

    if (paths?.length === 0 || paths?.length > 1) {
      return (
        <div>
          {this.renderSegmentGroup()}
        </div>
      )
    }

    const path = paths?.[0] ?? ''
    const supportPointPaths = this.getSupportPointPaths()
    const segmentGroupMountLog = this.getSegmentGroupMountLog()
    const name = segmentGroupMountLog?.name ?? path

    return (
      <div>
        {this.renderSegmentGroup()}
        <SectionContainer>
          {/* TODO: translate */}
          <Section name='SupportPoints'>
            <SectionContent>
              {
                (supportPointPaths.length > 0)
                  ? `${supportPointPaths.length} SupportPoints in ${name} selected`
                  : `No SupportPoints in ${name}`
              }
            </SectionContent>
          </Section>
        </SectionContainer>
        {
          supportPointPaths.map((supportPointPath: any, index: number) => (
            <div key={supportPointPath}>
              <AllInOne
                paths={[ supportPointPath ]}
                allPaths={supportPointPaths}
                type='SegmentGroupSupportPoints'
                hideActions={index !== supportPointPaths.length - 1}
              />
            </div>
          ))
        }
      </div>
    )
  }
}

export default compose<any>(connector)(SegmentGroup)
