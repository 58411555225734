import { Vector2 } from 'three'

export default class ConditionUtil {
  public static mouseNotOnCanvasOrIgnoredIntersect (event: any, mouseOnCanvas: any) {
    return (!mouseOnCanvas ||
      (!/canvas/i.test(event.target.tagName) && !event.target.getAttribute('data-ignore-intersect')))
  }

  public static mouseOutsideOfView (width: number, height: number, x: number, y: number, mouseOnCanvas: Vector2) {
    return (mouseOnCanvas.x > width + x || mouseOnCanvas.y > height + y)
  }

  public static differentMouseDownAndUpPositions (downMousePosition: Vector2, mouseOnCanvas: Vector2) {
    return (
      downMousePosition.x !== mouseOnCanvas.x ||
      downMousePosition.y !== mouseOnCanvas.y
    )
  }
}
