import { request } from '@/api'

export type ExternalDataSourceResponse = {
  id: string
  name: string
  type: string
  data: Record<string, unknown>
}

const PATH = 'external-data-sources'

export function createExternalDataSource (
  name: string,
  type: string,
  data: Record<string, unknown>,
  configId: string,
): Promise<ExternalDataSourceResponse | null> {
  // TODO: translate message
  return request('post', PATH, 'Failed to create external data source', {
    data: { name, type, data, visualizationConfigId: configId },
  })
}

export function updateExternalDataSource (
  id: string,
  name: string,
  type: string,
  data: Record<string, unknown>,
): Promise<Record<string, string> | null> {
  // TODO: translate message
  return request('patch', `${PATH}/${id}`, 'Failed to update external data source', {
    data: { name, type, data },
  })
}

export function deleteExternalDataSource (id: string): Promise<ExternalDataSourceResponse | null> {
  // TODO: translate message
  return request('del', `${PATH}/${id}`, 'Failed to delete external data source')
}
