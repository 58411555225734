export default class Executables {
  public static getAllowedExecutables (
    executableDefinitions: ExecutableDefinition[],
    currentSimulationCase: SimulationCase,
  ) {
    const isRealData = Boolean(currentSimulationCase.blueprintId)

    return executableDefinitions?.filter((definition) => Boolean(definition.realData) === isRealData) ?? []
  }
}
