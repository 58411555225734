// TODO: we should try to use environment variables here!

interface KeycloakData {
  keycloakURL: string
  keycloakClientID: string
  mySMSReferrer: string
}

const devKeycloak: KeycloakData = {
  keycloakURL: 'https://keycloak.dev.sms-digital.cloud/realms/smsdigital/protocol/openid-connect/auth',
  keycloakClientID: 'castersuite',
  mySMSReferrer: 'there is no dev portal yet',
} as const

const prodKeycloak: KeycloakData = {
  keycloakURL: 'https://auth.prd.sms-digital.cloud/realms/smsdigital/protocol/openid-connect/auth',
  keycloakClientID: 'castersuite-demo',
  mySMSReferrer: 'portal.prd.sms-digital.cloud',
} as const

const prefixMap = {
  apiBaseURL: 'api',
  mySMSRedirectURL: 'app',
} as const

type DomainDataKey = keyof typeof prefixMap

type DomainData = Record<DomainDataKey, string>

const domain = 'casterappsuite'
const prodSystems = [ 'demo', 'demo2', 'tkse' ] as const

function getURLMap (sub?: string, secure = true, tld = 'com'): DomainData {
  return Object.keys(prefixMap).reduce((acc, key) => ({
    ...acc,
    [key]: `http${secure ? 's' : ''}://${prefixMap[key as DomainDataKey]}${sub ? `.${sub}` : ''}.${domain}.${tld}`,
  }), {} as DomainData)
}

export function useConfig (): DomainData & KeycloakData {
  // dev system - workspace
  if (window.location.hostname.endsWith('.local')) {
    return {
      ...getURLMap('', false, 'local'),
      ...devKeycloak,
    }
  }

  // dev system - standalone (electron)
  if (window.meta?.DEV || window.location.hostname === 'localhost') {
    return {
      apiBaseURL: 'https://127.0.0.1:3000',
      mySMSRedirectURL: 'http://127.0.0.1:8072',
      ...devKeycloak,
    }
  }

  for (const prodSystem of prodSystems) {
    if (window.location.hostname.includes(`.${prodSystem}.`)) {
      return { ...getURLMap(prodSystem), ...prodKeycloak }
    }
  }

  if (window.location.hostname.includes('.tmp-qa.')) {
    return { ...getURLMap('tmp-qa'), ...devKeycloak }
  }

  if (window.location.hostname.includes('.qa.')) {
    return { ...getURLMap('qa'), ...devKeycloak }
  }

  // QA system (without prefix)
  return { ...getURLMap(), ...devKeycloak }
}
