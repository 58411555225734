import isEqual from 'lodash/isEqual'
import * as THREE from 'three'
import { Group, Mesh, Vector2 } from 'three'

import { Info, RecentlyUsedInfo } from '@/logic/Info'
import type { PassedData } from '@/react/Caster'
import Util from '@/three/logic/Util'
import PasslineCurve from '@/three/objects/PasslineCurve'
import { Views } from '@/three/ThreeBase'
import ThreeManager from '@/three/ThreeManager'
import { Debounce } from '@/Util/decorators/Debounce'
import { isTarget } from '@/Util/ElementUtil'

import CameraHandlers from './CameraHandlers'
import ConditionUtil from './ConditionUtil'
import DrawHandlers from './DrawHandlers'
import EventHandlers from './EventHandlers'
import Getters from './Getters'
import SectionHandlers from './SectionHandlers'
import SectionUtil from './SectionUtil'
import MainView from '../MainView'
import CalculationUtil from '../MainView/CalculationUtil'

export default class SectionView extends MainView {
  public static readonly planeMaterial = new THREE.MeshBasicMaterial({
    color: '#22282e',
    transparent: true,
    opacity: 0.85,
  })

  public static readonly planeHeaderMaterial = new THREE.MeshBasicMaterial({ color: '#212529' })

  public static readonly headerPartingMaterial = new THREE.MeshBasicMaterial({ color: '#000000' })

  public static readonly strandMaterial = new THREE.MeshBasicMaterial({ color: '#8c7200' }) // #FFD931

  public static minArrowColor = '#f5f603'

  public static maxArrowColor = '#f5b903'

  public static readonly maxStrandMaterial = new THREE.MeshBasicMaterial({ color: '#463900' })

  public static readonly minStrandLineMaterial = new THREE.LineBasicMaterial({
    color: this.minArrowColor,
    linewidth: 2,
  })

  public static readonly maxStrandLineMaterial = new THREE.LineBasicMaterial({
    color: this.maxArrowColor,
    linewidth: 2,
  })

  public static readonly labelLineMaterial = new THREE.LineBasicMaterial({ color: '#FFFFFF' })

  public static readonly rollerBodyMaterial = new THREE.MeshBasicMaterial({
    color: '#3a6ce0',
    transparent: true,
    opacity: 0.5,
  })

  public static readonly rollerBearingMaterial = new THREE.MeshBasicMaterial({
    color: '#383838',
    transparent: true,
    opacity: 0.5,
  })

  public static readonly rollerLineMaterial = new THREE.LineBasicMaterial({ color: '#4ffaff' })

  public static readonly rollerBearingLineMaterial = new THREE.LineBasicMaterial({ color: '#6a4dff' })

  public static currentSegmentGroup = { id: 1, name: '1' }

  public static currentFixedSideRollerNumber = 0

  public static currentSegmentGroupNameChanged = false

  public static debug = false // TODO: set as command line argument

  public updatePlane

  public viewMode

  public buttons: any

  public side: Record<StrandSide, boolean>

  public rollerGroup: any

  public statusPrevNext

  public additionalLayerType: 'Nozzle' | 'Roller'

  public jumpOver: JumpOverOptions | null

  public sectionPlaneHeader?: THREE.Mesh

  public sectionPartingLineHeader?: any

  public jumpUp?: boolean

  public jumpDown?: boolean

  private setTooltip?: (tooltip: Tooltip | null) => void

  private tooltip: Tooltip | null = null

  public override sectionViewExpanded: boolean

  private targetHeight?: number

  public lastDrawnScrollValue?: number

  public sectionPlaneWidth?: number

  public sectionPlaneHeight?: number

  public passLineCoordinates?: any

  public strand?: Mesh

  public minStrand?: Group

  public maxStrand?: Group

  public buttonBackground?: Mesh

  public buttonBackgroundLine?: Mesh & { name: string }

  public override perspectiveCamera: THREE.PerspectiveCamera | null = null

  public nozzleGroup?: any

  public minPasslineCoord = 0

  public maxPasslineCoord = 0

  private heightPos = 0

  public constructor (renderer: THREE.WebGLRenderer, views: Views, splitMode = 0) {
    super(renderer, views, splitMode, true)

    SectionView.staticClassName = 'SectionView'
    this.className = 'SectionView'

    // noinspection JSUnusedGlobalSymbols
    this.sectionDetail = true
    this.updatePlane = true
    this.sectionPlane.visible = false
    this.additionalLayerType = 'Nozzle'

    this.viewMode = false
    this.buttons = {}
    this.side = {
      fixed: true,
      loose: true,
      left: true,
      right: true,
    }

    this.rollerGroup = {}

    // noinspection JSUnusedGlobalSymbols
    this.spacer = 0.5
    this.largestNozzle = 0
    this.widestNozzle = 0
    this.largestNarrowNozzle = 0
    this.widestNarrowNozzle = 0
    this.largestRoller = 0
    this.widestRoller = 0
    this.largestNarrowRoller = 0
    this.widestNarrowRoller = 0
    // false = next/ true = previous
    this.statusPrevNext = false
    this.additionalLayerType = 'Nozzle'
    this.sectionViewExpanded = true

    this.jumpOver = 'Roller'

    const near = 0.1
    const far = 0.101

    SectionHandlers.setupRaycaster(this, near, far)

    SectionHandlers.setupCamera(this, near, far)

    SectionHandlers.setupControls(this)

    if (this.gridHelper) {
      this.scene.remove(this.gridHelper)
    }

    // section plane folded is the box where everything is displayed
    SectionHandlers.setupSectionPlaneFolded(this)

    Util.addOrReplace(this.scene, this.sectionPlaneFolded)
  }

  public override updateRoller () {
    // empty
  }

  public override setData (data: PassedData) {
    // TODO: improve this, we could just send a key in the data object
    //     const hasNewData = !isEqual(data.rootData ?? {}, this.data ?? {})
    // FIXME: is this correct?
    const hasNewData = !isEqual(this.elementMaps ?? {}, data.elementMaps ?? {})
    const termChanged = this.term !== data.term

    super.setData(data)

    this.setTooltip = data.setTooltip
    this.tooltip = data.tooltip
    this.isNewCaster = data.isNewCaster

    if (hasNewData) {
      this.elementMaps = data.elementMaps
      this.updatePlane = true
    }

    const casterData = this.elementMaps.Caster

    if (hasNewData || termChanged) {
      this.updateMinAndMaxPasslineCoordinates()
    }

    if (casterData && this.largestNarrowNozzle) {
      this.updateTransform(this.redraw)
    }

    if (this.isNewCaster) {
      this.sectionViewExpanded = false
      SectionHandlers.hideAllButtons(this.buttons)
    }

    if (casterData && (this.updatePlane || hasNewData || this.dirtyList?.length)) {
      this.emptyPrevOrNextElements()
      SectionHandlers.handleUpdatePlane(this)
    }
  }

  private emptyPrevOrNextElements () {
    const prevOrNextElementsGroup = this.scene.getObjectByName('PrevOrNextElements')

    if (prevOrNextElementsGroup) {
      for (let i = prevOrNextElementsGroup.children.length - 1; i >= 0; i--) {
        const obj = prevOrNextElementsGroup.children[i]

        if (obj) {
          prevOrNextElementsGroup.remove(obj)
        }
      }
    }
  }

  public override applyInfo (info: RecentlyUsedInfo): void {
    if (info.sectionViewSliderJumpOver) {
      this.jumpOver = info.sectionViewSliderJumpOver
    }

    if (info.sectionViewSliderTargetHeight) {
      this.targetHeight = info.sectionViewSliderTargetHeight
      this.setView(info.sectionViewSliderTargetHeight, true)
    }
  }

  @Debounce(100)
  private updateState (targetHeight: number) {
    Info.setRecentlyUsedInfo({ sectionViewSliderTargetHeight: targetHeight })
  }

  public override setView (targetHeight = Infinity, forceUpdate = false) {
    const jump = Boolean(this.jumpUp) || Boolean(this.jumpDown)

    if (
      !forceUpdate &&
      (
        ConditionUtil.noListOrNozzleOrRoller(this.elementList) ||
        (targetHeight === this.targetHeight && !jump)
      )
    ) {
      return
    }

    if (forceUpdate) {
      targetHeight = this.targetHeight ?? 0
    }

    const jumpDirection = SectionUtil.getJumpDirection(this.jumpUp, this.jumpDown)

    if (jumpDirection !== 0) {
      this.jumpUp = false
      this.jumpDown = false
    }

    this.targetHeight = targetHeight

    this.updateState(targetHeight)

    // TODO: is this needed?
    // this.handleVisibility()

    if (!this.elementList.Nozzle && !this.elementList.Roller) {
      return
    }

    const yCoordinates = Getters.getCurrentElementListYPositionsInOrderDesc(this)

    if (yCoordinates.length === 0) {
      return
    }

    const { position, center, heightPos } = Getters.getCenterAndPosition(
      yCoordinates,
      jumpDirection,
      targetHeight,
      jump,
      this.center2d ?? { x: 0, y: 0 },
      this.heightPos,
    )

    SectionView.debug
      ? this.setCameraPosition(new THREE.Vector3(7, position.y + 10, 20), center, this.camera, this.controls)
      : this.setCameraPosition(position, center, this.camera, this.controls)

    const { position: pos, angleX } = PasslineCurve.getInfoAtPlCoord(this.plHeight - heightPos)

    Util.flipXZ(pos)
    pos.multiply(new THREE.Vector3(-1, 1, 1))

    if (this.views && this.views.mainView && this.views.uiView) {
      this.views.mainView.sectionPlane.position.copy(pos)
      this.views.mainView.sectionPlane.rotation.y = angleX

      this.sectionPlaneFolded?.position?.set(0, heightPos - 0.0001, 0)
      this.views.uiView.scrollValue = (heightPos - this.plHeight) / -this.plHeight
      this.views.uiView.setButtonPos()

      // rerender if there are changes
      // if (this.heightPos !== heightPos) {
      //   ThreeManager.base.renderScene()
      // }

      this.heightPos = heightPos
    }
  }

  // TODO: rework if needed because this ignores the filter...
  // handleVisibility () {
  //   const hideType = !this.jumpOverNozzles ? 'Nozzle' : 'Roller'
  //   const showType = this.jumpOverNozzles ? 'Nozzle' : 'Roller'

  //   if (!this.elementList[hideType] || !this.elementList[showType]) {
  //     return
  //   }

  //   Object.values(this.elementList[hideType]).forEach(({ path }: any) => {
  //     if (this.elementList[hideType] && this.elementList[hideType][path]) {
  //       this.elementList[hideType][path].hide()
  //     }
  //   })

  //   Object.values(this.elementList[showType]).forEach(({ path }: any) => {
  //     if (this.elementList[showType] && this.elementList[showType][path]) {
  //       this.elementList[showType][path].show()
  //     }
  //   })
  // }

  public handleMouseLeave () {
    if (this.tooltip) {
      this.setTooltip?.(null)
    }
  }

  public override handleMouseMove (event: MouseEvent, mouseOnCanvas: Vector2) {
    // do not handle mouse move if any mouse button is pressed, or if the section is not active, or if a dialog is open
    const isSectionViewTarget = isTarget(event, ThreeManager.base?.container)

    if (
      !this.tooltip &&
      (event.buttons !== 0 || !this.views?.uiView?.isSectionActive || !isSectionViewTarget)
    ) {
      return
    }

    const { x, y, width, height } = this.viewport

    const mouse = Getters.getMouse(mouseOnCanvas, x, y, width, height)

    if (this.camera) {
      this.raycaster.setFromCamera(mouse, this.camera)
    }

    // eslint-disable-next-line max-len
    // TODO: this call throws: THREE.BufferGeometry.computeBoundingSphere(): Computed radius is NaN. The "position" attribute is likely to have NaN values.
    if (ConditionUtil.mouseIsOutsideOfSectionView(this.raycaster, this.sectionPlaneFolded, this.sectionPlaneHeader)) {
      if (this.tooltip && this.setTooltip) {
        this.setTooltip(null)
      }

      return
    }

    // TODO: laggy
    // TODO: are the snaps broken? maybe they are always hit when they have not been positioned yet?
    const { tooltips, snaps } = Getters.getIntersectedTooltipsAndSnaps(this.tooltipObjects, this.raycaster)

    if ((snaps.length > 0) && (tooltips.length === 0) && this.camera) {
      CalculationUtil.orderSnapsByDistanceToMouse(this.camera, mouse, snaps)

      SectionHandlers.showClosestSnapsTooltip(snaps[0], tooltips)
    }

    if (tooltips.length) {
      if (!isEqual(this.tooltip, tooltips[0]) && this.setTooltip) {
        // TODO: this is laggy as hell - it cases the store to update every time this is called ...
        this.setTooltip(tooltips[0] ?? null)
      }

      return
    }

    if (this.tooltip) {
      this.setTooltip?.(null)
    }
  }

  public override handleMouseUp (event: any, mouseOnCanvas: Vector2): any {
    const intersects = super.handleMouseUp(event, mouseOnCanvas)

    if (intersects.length) {
      EventHandlers.handleActions(intersects, this)

      return false
    }

    if ((this.selection.length > 0) && this.views?.mainView?.jumpToFilter) {
      this.views.mainView.jumpToFilter(this.selection.join(' '))
    }

    return intersects
  }

  public override handleKeyDown () {}

  public override render (): void {
    if (!this.sectionPlaneFolded?.visible) {
      return
    }

    super.render()
  }

  public override resize (width: number, height: number) {
    if (this.splitMode === 0) {
      super.resize(0, 0)

      return
    }

    super.resize(width, height)

    CameraHandlers.setZoom(this)

    if (this.camera) {
      CameraHandlers.updateCamera(this.viewport, this.camera as THREE.OrthographicCamera)
    }
  }

  public override handleKeyUp (_event: any) {}

  public override animate () {
    if (this.sectionPlaneFolded?.visible) {
      const scrollValue = this.views?.uiView?.scrollValue ?? 0

      this.setView(this.plHeight - this.plHeight * (scrollValue > 0 ? scrollValue : 1))
      DrawHandlers.drawPassLineCoordinates(this)
    }
  }

  public showSection (width: number, height: number) {
    if (this.sectionPlaneFolded) {
      this.sectionPlaneFolded.visible = true
    }

    this.splitMode = 1
    this.resize(width, height)
  }

  public hideSection () {
    if (this.sectionPlaneFolded) {
      this.sectionPlaneFolded.visible = false
    }

    this.splitMode = 0
    this.resize(0, 0)
  }

  public updateTransform (force = false) {
    if (!force && !this.isNewCaster) {
      return
    }

    this.lastDrawnScrollValue = 0

    DrawHandlers.drawPassLineCoordinates(this)

    DrawHandlers.drawAllSectionViewSwitches(this)
  }

  private getMinAndMaxPasslineCoordinates (): { min: number, max: number } {
    if (!this.elementList.Nozzle && !this.elementList.Roller) {
      return { min: 0, max: 0 }
    }

    const passlnCoordinates = Getters.getCurrentElementListPasslineCoordinates(this)

    if (passlnCoordinates.length === 0) {
      return { min: 0, max: 0 }
    }

    return { min: Math.min(...passlnCoordinates), max: Math.max(...passlnCoordinates) }
  }

  public updateMinAndMaxPasslineCoordinates () {
    const { min, max } = this.getMinAndMaxPasslineCoordinates()

    this.minPasslineCoord = min
    this.maxPasslineCoord = max
  }
}
