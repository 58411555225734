import { request } from '@/api'

const PATH = 'projects'

export function updateProject (projectId: string, data: Partial<Project>): Promise<void> {
  const errorText = 'Failed to update project' // TODO: translate message

  return request('patch', `${PATH}/${projectId}`, errorText, { data }) as any
}

export function getProject (projectId: string): Promise<Project> {
  const errorText = 'Failed to get project' // TODO: translate message

  return request('get', `${PATH}/${projectId}`, errorText) as any
}

export function getProjectsWithCases (): Promise<Project[] | null> {
  const errorText = 'Failed to get projects with cases' // TODO: translate message

  return request<Project[]>('get', PATH, errorText, { params: { withCases: true } })
}

export function updateProjectAdditionalData (projectId: string, data: any) {
  const errorText = 'Failed to update project additional data' // TODO: translate message

  return request('patch', `${PATH}/${projectId}/additional-data`, errorText, { data }) as any
}

export async function checkProjectNameAvailability (name: string): Promise<boolean> {
  const errorText = 'Failed to check project name availability' // TODO: translate message

  const res = await request('get', `${PATH}/check-name`, errorText, { params: { name } }) as { available: boolean }

  return res.available
}
