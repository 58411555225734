import { GridColumnVisibilityModel, GridSortItem } from '@mui/x-data-grid'
import isEqual from 'lodash/isEqual'

import type { PlotConfig, TileConfig } from '@/types/visualization'

import TableComponent from './Table'

type Props = {
  tileId: string
  configId: string
  viewId?: string
  tileConfig: TileConfig
  plotConfig: PlotConfig
  temporalData: TemporalDataState
  onVisualizationConfigUpdate: (tileConfig: TileConfig) => void
  saveTileConfig: (tileConfig: Partial<TileConfig>) => void
}

const TablePlot = (props: Props) => {
  const { plotConfig, temporalData, tileConfig, saveTileConfig, onVisualizationConfigUpdate } = props

  if (!plotConfig) {
    return
  }

  const { filter } = plotConfig
  const [ , realDataUUID ] = filter.split('#realDataUUID=')

  if (!realDataUUID) {
    return null
  }

  const handleColumnVisibilityChange = (visibilityModel: GridColumnVisibilityModel) => {
    // transform the visibility model an object with the column name as key and isHidden as value
    const keyIsHiddenMap: Record<string, boolean> = {}

    Object.entries(visibilityModel).forEach(([ key, isShown ]) => {
      if (isShown) {
        return 
      }

      keyIsHiddenMap[key] = !isShown
    })

    const updatedTileConfigState = {
      ...tileConfig,
      hiddenColumns: {
        ...tileConfig.hiddenColumns,
        ...keyIsHiddenMap,
      },
    }

    saveTileConfig(updatedTileConfigState)
    onVisualizationConfigUpdate?.(updatedTileConfigState)
  }

  const handleSortModelChange = (sortingInfoArray: GridSortItem[]) => {
    if (isEqual(sortingInfoArray, tileConfig.sortingOfColumns)) {
      return
    }

    const updatedTileConfigState = {
      ...tileConfig,
      sortingOfColumns: sortingInfoArray,
    }

    saveTileConfig(updatedTileConfigState)
    onVisualizationConfigUpdate?.(updatedTileConfigState)
  }

  return (
    <TableComponent
      realDataUUID={realDataUUID}
      temporalData={temporalData}
      tileConfig={tileConfig}
      onColumnVisibilityChange={handleColumnVisibilityChange}
      onSortModelChange={handleSortModelChange}
    />
  )
}

export default TablePlot
