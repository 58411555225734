import type { DebounceSettings } from 'lodash'
import debounce from 'lodash/debounce'

export function Debounce (waitMs: number, options: DebounceSettings = {}) {
  return function (_target: any, _propertyKey: string, descriptor: PropertyDescriptor) {
    const originalMethod = descriptor.value

    descriptor.value = debounce(originalMethod, waitMs, options)

    return descriptor
  }
}
