import FeatureFlags, { type Permissions } from '@/react/FeatureFlags'
import type { CasterDialogElementType } from '@/types/filter'

export class FormBuilderUtil {
  public static canViewAttributes (
    type: CasterDialogElementType,
    paths: string[],
    featureFlags: Permissions,
  ): boolean {
    // TODO: feature flags for CC element
    if (type === 'CCElement') {
      return true
    }

    if (type === 'SensorPoint') {
      return FeatureFlags.canViewSensorPointAttributesInCurrentLocations(featureFlags, paths)
    }

    return FeatureFlags.canViewTypesAttributes(type, featureFlags)
  }
}
