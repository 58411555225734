import moment from 'moment'

export default class TimeUtil {
  public static getDisplayDate (date: Date | number | string) {
    return moment(date).format(moment.HTML5_FMT.DATE)
  }

  public static getDisplayDateTime (date: Date | number | string) {
    return moment(date).format(`${moment.HTML5_FMT.DATE} ${moment.HTML5_FMT.TIME}`)
  }
}
