import { faAngleDown, faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Component } from 'react'
import type { ReactNode } from 'react'
import styled from 'styled-components'

const Toggler = styled.button`
  background-color: #333a44;
  padding:    5px;
  border:     none;
  color:      inherit;
  width:      100%;
  text-align: left;
  user-select: none;
  cursor: pointer;
  
  &:focus {
    outline: 0;
  }
`

const ToggleIcon = styled.div`
  display:        inline-block;
  position:       absolute;
  right:          23px;
  pointer-events: none;
`

const Separator = styled.div`
  border-bottom: 1px solid #333a44;
  margin: 16px 0;
`

const Spacer = styled.div`
  margin: 18px 0;
`

type Props = {
  children: ReactNode
  name: string
  title?: string
  hideSeparator?: boolean
  closed?: boolean
  spaceForComparisonCasterTitle?: boolean
}

type State = {
  isExpanded: boolean
}

export default class Section extends Component<Props, State> {
  public override state: State = {
    isExpanded: true,
  }
  
  public override componentDidMount () {
    const { closed } = this.props

    if (closed) {
      this.setState({
        isExpanded: false,
      })
    }
  }

  private readonly handleToggle = () => {
    this.setState({
      isExpanded: !this.state.isExpanded,
    })
  }
  
  public override render () {
    const { isExpanded } = this.state
    const { children, name, title, hideSeparator, spaceForComparisonCasterTitle } = this.props

    return (
      <div>
        <Toggler onClick={this.handleToggle} title={title ?? name}>
          {name}
          <ToggleIcon>
            {isExpanded ? <FontAwesomeIcon icon={faAngleDown} /> : <FontAwesomeIcon icon={faAngleRight} />}
          </ToggleIcon>
        </Toggler>
        {spaceForComparisonCasterTitle && title !== 'Selection' && <Spacer />}
        {isExpanded && children}
        {!hideSeparator && <Separator />}
      </div>
    )
  }
}
