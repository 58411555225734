/* eslint-env browser */

import EditableBase from './EditableBase'

export default class Line extends EditableBase {
  public constructor () {
    super('js-line', 'lines', 'trace')
  }

  protected override buildPathD (x: number[], y: number[], width: number, height: number): string {
    let d = ''

    for (let i = 0; i < y.length; i++) {
      if (x[i] === undefined || y[i] === undefined) {
        continue
      }

      const xValue = this.calcX(x[i]!, width)
      const yValue = this.calcY(y[i]!, height)

      // https://jsperf.com/string-concat-vs-array-join-10000/15
      d = d.concat('L', xValue.toFixed(2), ',', yValue.toFixed(2))
    }

    return `M${d.slice(1)}`
  }
}
