import { NetworkStatus } from '@/api/network-event'

export enum ApplicationMainActionsEnum {
  ACTION_SET_OPEN_DIALOGS = 'application/ACTION_SET_OPEN_DIALOGS,',
  ACTION_SET_LOADING_STATUS = 'application/ACTION_SET_LOADING_STATUS',
  ACTION_SET_TOOLTIP = 'application/ACTION_SET_TOOLTIP',
  ACTION_OPEN_DIALOG = 'application/ACTION_OPEN_DIALOG',
  ACTION_CLOSE_DIALOG = 'application/ACTION_CLOSE_DIALOG',
  ACTION_CLEAR_DIALOGS = 'application/ACTION_CLEAR_DIALOGS',
  ACTION_SET_REQUIRED_COMMAND_FILES = 'application/ACTION_SET_REQUIRED_COMMAND_FILES',
  ACTION_SET_CURRENT_PROJECT = 'application/ACTION_SET_CURRENT_PROJECT',
  ACTION_UPDATE_CURRENT_PROJECT_ADDITIONAL_DATA = 'application/ACTION_UPDATE_CURRENT_PROJECT_ADDITIONAL_DATA',
  ACTION_SET_CURRENT_PROJECT_CASES_METADATA = 'application/ACTION_SET_CURRENT_PROJECT_CASES_METADATA',
  ACTION_RESET_CURRENT_PROJECT_CASES_METADATA = 'application/ACTION_RESET_CURRENT_PROJECT_CASES_METADATA',
  ACTION_DELETE_ID_FROM_CURRENT_PROJECT_CASE_IDS = 'application/ACTION_DELETE_ID_FROM_CURRENT_PROJECT_CASE_IDS',
  ACTION_ADD_ID_TO_CURRENT_PROJECT_CASE_IDS = 'application/ACTION_ADD_ID_TO_CURRENT_PROJECT_CASE_IDS',
  ACTION_SET_CURRENT_SIMULATION_CASE = 'application/ACTION_SET_CURRENT_SIMULATION_CASE',
  ACTION_UPDATE_CURRENT_PROJECT_SCHEMA = 'application/ACTION_UPDATE_CURRENT_PROJECT_SCHEMA',
  ACTION_SET_AUTHENTICATION_DATA = 'application/ACTION_SET_AUTHENTICATION_DATA',
  ACTION_SET_THEME = 'application/ACTION_SET_THEME',
  ACTION_SET_FULL_PROJECT_PROCESSING = 'application/ACTION_SET_FULL_PROJECT_PROCESSING',
  ACTION_SET_EDIT_PROJECT_ID = 'application/ACTION_SET_EDIT_PROJECT_ID',
  ACTION_SET_EDIT_SIMULATION_CASE_ID = 'application/ACTION_SET_EDIT_SIMULATION_CASE_ID',
  ACTION_SET_EDIT_CONFIG_ID = 'application/ACTION_SET_EDIT_CONFIG_ID',
  ACTION_SET_APP_STATE = 'application/ACTION_SET_APP_STATE',
  ACTION_SET_DEFAULT_CASTER_DASHBOARD_CONFIG = 'application/ACTION_SET_DEFAULT_CASTER_DASHBOARD_CONFIG',
  ACTION_SET_LAST_LOADED_CASTER_CATALOG_ID = 'application/ACTION_SET_LAST_LOADED_CASTER_CATALOG_ID',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  openDashboard = 'application/ACTION_SET_APP_STATE',
  ACTION_SET_CASE_LOCKS = 'application/ACTION_SET_CASE_LOCKS',
  ACTION_SET_CASE_VERIFICATION = 'application/ACTION_SET_CASE_VERIFICATION',
  ACTION_ADD_CASE_VERIFICATION = 'application/ACTION_ADD_CASE_VERIFICATION',
  ACTION_STORE_NETWORK_STATUS = 'application/ACTION_STORE_NETWORK_STATUS',
  selectDataSource = 'visualization/ACTION_VISUALIZATION_OPEN_SELECT_SOURCE_DIALOG',
  exportPlots = 'visualization/ACTION_VISUALIZATION_OPEN_PLOT_EXPORT_DIALOG',
  configurePlots = 'visualization/ACTION_VISUALIZATION_SHOW_PLOT_LIST',
  mapCommands = 'visualization/ACTION_VISUALIZATION_SHOW_COMMAND_MAPPING',
  editMode = 'visualization/ACTION_VISUALIZATION_SET_EDIT_MODE',
  ACTION_RESET_STORE = 'ACTION_RESET_STORE',
  ACTION_UPDATE_CASE_CURRENT_CASTER_ID = 'application/ACTION_UPDATE_CASE_CURRENT_CASTER_ID',
  ACTION_SET_ACTIVE_DETAIL_DIALOG_FILTER = 'application/ACTION_SET_ACTIVE_DETAIL_DIALOG_FILTER',
  ACTION_RESET_ACTIVE_DETAIL_DIALOG_FILTER = 'application/ACTION_RESET_ACTIVE_DETAIL_DIALOG_FILTER',
}

export const actionList = Object.values(ApplicationMainActionsEnum)

export enum AppState {
  Caster = 'caster',
  ParamDashboard = 'param-dashboard',
  ResultDashboard = 'result-dashboard',
}

export enum VerificationType {
  Catalog = 'catalog',
  Caster = 'caster',
  Parameters = 'parameters',
}

export const initialState: ApplicationMainState = {
  openDialogs: [ 'CasterTree' ],
  loadingStatus: false,
  openAppDialogs: [],
  params: null,
  nonexistentCommandFiles: {},
  nonexistentCommandFileMapping: [],
  latestUploadedVisualizationConfigId: '',
  currentProject: {} as Project,
  currentProjectCasesMetadata: [],
  currentSimulationCase: {} as SimulationCase,
  editProjectId: null,
  editSimulationCaseId: null,
  editConfigId: null,
  darkTheme: true,
  authenticationData: {} as AuthData,
  appState: AppState.Caster,
  caseLocks: [],
  caseVerifications: [],
  networkStatus: NetworkStatus.Disconnected,
  elementHistoryDetailsInfo: null,
}
