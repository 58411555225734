import ReactLoading from 'react-loading'
import styled, { css } from 'styled-components'

export const Empty = styled.div`${({ theme }) =>
  css`
  color: ${theme['mainFontColor']};
  text-align: center;
  margin: 62px 0;
`}`

export const InfoMessage = styled.div`${({ theme }) =>
  css`
  position: absolute;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -50%);
  color: ${theme['primary'].font};
  text-align: center;
`}`

export const Loading = styled(ReactLoading)`${({ theme }) =>
  css`
  color: ${theme['primary'].font}
  max-width: 50px;
  max-height: 50px;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
`}`

export const Label = styled.div`${({ theme }) =>
  css`
  color: ${theme['mainFontColor']};
  font-size: 14px;
`}`

export const IdLabel = styled.div`${({ theme }) =>
  css`
  line-height: 21px;
  margin-left: 5px;
  color: ${theme['mainFontColor']};
  font-size: 12px;
  
  .id {
    user-select: text;
  }

  svg {
    cursor: pointer;

    &:hover {
      color: ${theme['primary'].font};
    }
  }
`}`

export const Description = styled.div`${({ theme }) =>
  css`
  color: ${theme['mainFontColor']};
  font-size: 14px;
  height: ${2 * 38 + 2 * 17 + 6}px;
  overflow: auto;
  border: 1px solid ${theme['colors'].swatch6};
  padding: 9px;
  border-radius: 5px;
  user-select: none;
  white-space: pre-wrap;
`}`

export const Spacer = styled.div<{ $h?: number, $w?: number, $br?: boolean }>`${({ $h, $w, $br }) =>
  css`
  display: ${$br ? 'block' : 'inline-block'};
  height: ${$h ?? 0}px;
  width: ${$w ?? 0}px;
`}`
