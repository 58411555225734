import { Component } from 'react'
import styled from 'styled-components'

import ball from '../../icon/waiting-1.svg'
import innerCircle from '../../icon/waiting-2.svg'
import middleCircle from '../../icon/waiting-3.svg'
import outerCircle from '../../icon/waiting-4.svg'

const Logo = styled.div`
  flex:           1;
  padding:        6px 0;
  line-height:    32px;
  min-width:      20px;
  max-width:      20px;
  height:         32px;
  margin-left:    5px;
  vertical-align: middle;
  position:       relative;
  user-select:    none;
  -webkit-app-region: drag;
`

const Image = styled.img`
  position:       absolute;
  user-select:    none;
  pointer-events: none;
`

class TitleBarLogo extends Component {
  public override render () {
    return (
      <Logo>
        <Image src={ball} alt='Logo' />
        <Image src={innerCircle} alt='Logo' />
        <Image src={middleCircle} alt='Logo' />
        <Image src={outerCircle} alt='Logo' />
      </Logo>
    )
  }
}

export default TitleBarLogo
