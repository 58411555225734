import { faInfoCircle, faUserEdit } from '@fortawesome/free-solid-svg-icons'
import isEqual from 'lodash/isEqual'

import Logic from '.'
import { Props, State } from '../.'

export default class Builder {
  public static buildColumns = (prevProps?: Props) => {
    const { currentProject, setColumns, t } = Logic.dialogInstance.props
    const { matrixColumns, matrixColumnOrder } = currentProject ?? {}

    if (
      prevProps &&
      isEqual(prevProps.currentProject.matrixColumns, matrixColumns) &&
      isEqual(prevProps.currentProject.matrixColumnOrder, matrixColumnOrder)
    ) {
      return
    }

    setColumns([
      {
        label: { fixedWidth: true, icon: faInfoCircle, title: t(`${Logic.T}.status.info`) },
        labelType: 'icon',
        className: 'status-cell full-sticky',
      },
      {
        label: { fixedWidth: true, icon: faUserEdit, title: t(`${Logic.T}.label.author`) },
        labelType: 'icon',
        className: 'author-cell full-sticky',
      },
      { label: t(`${Logic.T}.label.simulationCase`), className: 'simulation-case-cell full-sticky' },
      ...(matrixColumnOrder ?? [])
        .map((columnKey: string) => {
          const [ key, derivedIndex ] = columnKey.split('_derived_')

          if (!key || !matrixColumns || !matrixColumns[key]) {
            return null
          }

          let label = matrixColumns[key]?.name ?? ''

          if (derivedIndex !== undefined) {
            if (matrixColumns[key]?.xValues && matrixColumns[key]?.xValues.length) {
              label += ` x = ${((matrixColumns[key].xValues as any)[derivedIndex] ?? 0).toFixed(2)}`
            }
            else {
              label += ` index = ${derivedIndex}`
            }
          }

          return {
            key: columnKey,
            label,
            title: label,
            ...matrixColumns[key],
          }
        })
        .filter((cell: any) => cell !== null),
    ])
  }

  public static buildGrid = (prevProps?: Props, prevState?: State) => {
    const { updatedCells } = Logic.dialogInstance.state
    const {
      selections,
      resultData,
      currentProject,
      setSelections,
      setGrid,
      updateResultData,
      t,
    } = Logic.dialogInstance.props
    const { matrixColumns, matrixColumnOrder } = currentProject ?? {}

    if (
      prevProps &&
      isEqual(prevProps.currentProject, currentProject) &&
      isEqual(prevProps.resultData, resultData) &&
      prevState &&
      isEqual(prevState.updatedCells, updatedCells)
    ) {
      return
    }

    const missingData: ResultData = {}

    const commandDoesNotExistTitle = t(`${Logic.T}.message.commandDoesNotExist`)

    const grid = []

    for (const [ rowPos, simulationCase ] of currentProject.simulationCases.entries()) {
      const {
        name,
        user,
        id,
        commandFiles,
        visualizationDataList,
        simulationStartedAt,
        simulationDataReceivedAt,
        simulationStoppedManually,
      } = simulationCase
      const { commands } = commandFiles[0] ?? {}
      const { dataId } = visualizationDataList?.slice(-1)[0] ?? {}
      const upperAuthorName = user?.name?.toUpperCase() ?? ''

      const status = Logic.getStatus(simulationCase)?.title
      const row: Array<{
        value: string
        title?: string
        valueType?: string
        readOnly?: boolean
        className: string
        key?: string
        data?: any
        editType?: string
        name?: string
        titleList?: boolean
      }> = [
        { // colPos = 0
          value: status ?? '',
          title: status ?? '',
          valueType: 'icon',
          readOnly: true,
          className: `status-cell ${simulationDataReceivedAt && simulationStoppedManually ? 'manually' : ''}`,
        },
        { // colPos = 1
          value: upperAuthorName,
          title: upperAuthorName,
          readOnly: true,
          key: 'author',
          className: 'author-cell',
        },
        { // colPos = 2
          value: name,
          title: name,
          key: 'simulation_case',
          data: { id, simulationStartedAt },
          editType: 'simulationCaseName',
          className: `simulation-case-cell ${(updatedCells ?? {} as any)[`${rowPos}_2`] ?? ''}`,
        },
      ]

      const initialRowLength = row.length

      if (dataId && (resultData[dataId] === undefined || resultData[dataId] === null)) {
        missingData[dataId] = {}
      }

      const existingMatrixColumns = matrixColumnOrder?.filter((columnKeyRaw: string) => {
        const [ columnKey ] = columnKeyRaw.split('_derived_')

        return Boolean(matrixColumns?.[columnKey ?? ''])
      }) ?? []

      for (const [ colIndex, columnKeyRaw ] of existingMatrixColumns.entries()) {
        const [ columnKey, derivedIndexRaw ] = columnKeyRaw.split('_derived_')
        const derivedIndex = derivedIndexRaw ? Number(derivedIndexRaw) : undefined
        const { editType, id, ...restColumnData } = matrixColumns?.[columnKey ?? ''] ?? {}

        if (editType === 'command') {
          const colPos = initialRowLength + colIndex
          const { parameter, command, shortName } = commands
            ?.find((command: any) => command.command === id) ?? {} as any

          let value = ''
          let tooltip = ''

          value = parameter?.flat()?.join(';') ?? ''

          if (command === 'init_material') {
            tooltip = value
            value = (shortName ?? '').replace('default_', 'D').replace('custom_', 'C').replace('custom', 'Custom')
          }

          const commandExists = parameter && parameter.length !== undefined
          const additionalClass = (updatedCells ?? {} as any)[`${rowPos}_${colPos}`]

          row.push({
            value,
            title: tooltip ?? value ?? (!commandExists && commandDoesNotExistTitle) ?? '',
            name: id,
            readOnly: !commandExists || !id || Boolean(simulationStartedAt),
            editType,
            className: `cell-type-command ${additionalClass}`,
          })
        }
        else if (editType === 'resultKey') {
          const { xValues } = restColumnData ?? {}
          const [ group, key ] = id.split('🥳')
          let data = (resultData[dataId]?.[group] ?? {})[key] ?? []
          const isDerived = derivedIndex !== undefined

          if (data.length && typeof data[0] === 'number') {
            data = data.map((val: number) => Math.abs(val) - Math.floor(Math.abs(val)) > 0 ? val.toFixed(4) : val)
          }

          if (isDerived && data[derivedIndex] === undefined) {
            data = []
          }
          else if (isDerived && data[derivedIndex] !== undefined) {
            data = [ data[derivedIndex] ]
          }

          const value = data.length ? data.join(', ') : ''

          row.push({
            value,
            titleList: data && (xValues && xValues.length
              ? data.map((val: number, i: number) => [
                `x: ${
                  xValues[isDerived ? derivedIndex : i] !== undefined
                    ? xValues[isDerived ? derivedIndex : i].toFixed(2)
                    : ''
                }`,
                `y: ${val}`,
              ])
              : data),
            readOnly: true,
            className: 'cell-type-result',
          })
        }
      }

      grid.push(row)
    }

    updateResultData(missingData)

    Logic.getMissingData(missingData)

    const newSelections = currentProject
      .simulationCases
      .reduce((newSelectionsObject: any, simulationCase: SimulationCase) => ({
        ...newSelectionsObject,
        [simulationCase.id]: selections[simulationCase.id] ?? false,
      }), {})

    setSelections(newSelections)
    setGrid(grid)
  }
}
