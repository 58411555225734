import { deleteNozzleCatalog, getNozzleCatalogContents } from '@/api/nozzle-catalog'
import ProjectDataDialogLogic from '@/react/dialogs/project/ProjectDataDialog/Logic'
import FeatureFlags, { Permissions } from '@/react/FeatureFlags'
import ErrorActions from '@/store/application/error/consts'
import { DataActionsEnum } from '@/store/data/consts'

type SetCatalogList = (catalogList: CaseFile[]) => {
  type: DataActionsEnum
  catalogList: CaseFile[]
}

type SetCurrentCatalogId = (currentCatalogId?: string) => {
  type: DataActionsEnum
  currentCatalogId: string | undefined
}

type SetError = (actionName: string, error?: string) => {
  type: ErrorActions
  actionName: string
  error: string | undefined
}

export const loadCatalog = async (params: {
  selectedCatalogId: string
  setCurrentCatalogId: SetCurrentCatalogId
  catalogList: { id: string }[]
  currentSimulationCase: SimulationCase
  saveCatalog: (data: any) => void
  setLastLoadedCasterCatalogId: (id: string) => void
  closeDialogCallBack?: () => void
}) => {
  const {
    selectedCatalogId,
    saveCatalog,
    setLastLoadedCasterCatalogId,
    setCurrentCatalogId,
    currentSimulationCase,
    closeDialogCallBack,
  } = params

  if (!selectedCatalogId || selectedCatalogId === 'default' || selectedCatalogId === 'add') {
    return
  }

  const projectId = currentSimulationCase.projectId
  const caseId = currentSimulationCase.id

  const csvString = await getNozzleCatalogContents(projectId, caseId, selectedCatalogId)

  if (!csvString) {
    return
  }

  const catalogData = await ProjectDataDialogLogic.getParsedCsv(csvString)

  saveCatalog(catalogData)
  setLastLoadedCasterCatalogId(selectedCatalogId)
  setCurrentCatalogId(selectedCatalogId)

  closeDialogCallBack?.()
}

export const deleteCatalog = async (
  key: string,
  params: {
    featureFlags: Permissions
    currentSimulationCase: SimulationCase
    catalogList: CaseFile[]
    setCatalogList: SetCatalogList
    currentCatalogId?: string | undefined
    setCurrentCatalogId: SetCurrentCatalogId
  },
) => {
  const {
    featureFlags,
    currentSimulationCase: { id, projectId },
    catalogList,
    setCatalogList,
    currentCatalogId,
    setCurrentCatalogId,
  } = params

  if (!FeatureFlags.canDeleteNozzleCatalog(featureFlags)) {
    return
  }

  await deleteNozzleCatalog(projectId, id, key)

  setCatalogList(catalogList.filter(val => val.id !== key))

  if (currentCatalogId === key) {
    setCurrentCatalogId()
  }
}

export const addCatalog = (
  catalog: CaseFile | 'default',
  params: {
    catalogList: CaseFile[]
    setCatalogList: SetCatalogList
    setSelectedCatalogId: (id: string) => void
  },
) => {
  const { setCatalogList, setSelectedCatalogId, catalogList } = params

  const newCatalogList = [ ...catalogList ]

  if (catalog !== 'default') {
    newCatalogList.unshift(catalog)
  }

  setSelectedCatalogId(catalog === 'default' ? 'default' : catalog.id)
  setCatalogList(newCatalogList)
}

export const changeCatalog = (event: any, params: {
  currentSimulationCase: SimulationCase
  setSelectedCatalogId: (id: string) => void
  setCatalogList: SetCatalogList
  setError: SetError
  catalogList: CaseFile[]
}) => {
  const { setSelectedCatalogId, setError } = params
  const { name, value } = event.target

  setError(name)

  if (value === 'add') {
    return ProjectDataDialogLogic.handleUploadCasterCatalog(
      (catalog: CaseFile | 'default') => addCatalog(catalog, params),
      setError,
      params,
    )
  }

  setSelectedCatalogId(value?.length ? value : 'default')
}
