export default class SVG {
  private static readonly NAMESPACE_URI = 'http://www.w3.org/2000/svg'

  private static getElement (type: string) {
    return document.createElementNS(SVG.NAMESPACE_URI, type)
  }

  public static getSVG () {
    return SVG.getElement('svg')
  }

  public static getG () {
    return SVG.getElement('g')
  }

  public static getPath () {
    return SVG.getElement('path')
  }

  public static getCircle () {
    return SVG.getElement('circle')
  }
}
