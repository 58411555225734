import { filterActions } from 'redux-ignore'

import type { FilterState } from '@/types/filter'

export enum TemporalDataActionsEnum {
  ACTION_SET_TEMPORAL_DATA = 'temporalData/ACTION_SET_TEMPORAL_DATA',
}

const { ACTION_SET_TEMPORAL_DATA } = TemporalDataActionsEnum
// eslint-disable-next-line @typescript-eslint/ban-types
const actionHandlers: Record<string, Function | undefined> = {
  [ACTION_SET_TEMPORAL_DATA]: (state: FilterState, action: { data: any }) => ({
    ...state,
    ...action.data,
  }),
}

function temporalDataReducers (state = {}, action: { type: string }) {
  const handler = actionHandlers[action.type]

  return handler ? handler(state, action) : state
}

export default filterActions(temporalDataReducers, [
  ACTION_SET_TEMPORAL_DATA,
]) as typeof temporalDataReducers
