import { request } from '@/api'

const PATH = 'cases'

export async function getCasesByProjectId (projectId: string, withCurrentCaster = false): Promise<SimulationCase[]> {
  const errorText = 'Failed to get cases' // TODO: translate message

  const response = await request<{ cases: SimulationCase[] }>('get', PATH, errorText, {
    params: { projectId, withCurrentCaster },
  })

  return response?.cases ?? []
}

export async function getDefaultCase (): Promise<SimulationCase | null> {
  // TODO: translate message
  const response = await request<{ cases: SimulationCase[] }>('get', PATH, 'Failed to get default case', {
    params: { isDefault: true, byUser: true },
  })

  return response?.cases?.[0] ?? null
}

export function setDefaultDashboard (caseId: string, configId?: string): Promise<void> {
  const errorText = 'Failed to set default dashboard' // TODO: translate message

  return request('patch', `${PATH}/${caseId}/default-dashboard`, errorText, { data: { configId } }) as Promise<void>
}

export function setLoadedNozzleCatalog (caseId: string, catalogId?: string): Promise<void> {
  const errorText = 'Failed to set loaded nozzle catalog' // TODO: translate message

  return request('patch', `${PATH}/${caseId}/loaded-nozzle-catalog`, errorText, { data: { catalogId } }) as any
}

export function updateCase (caseId: string, data: Partial<SimulationCase>): Promise<void> {
  const errorText = 'Failed to update case' // TODO: translate message

  return request('patch', `${PATH}/${caseId}`, errorText, { data }) as any
}

export async function checkCaseNameAvailability (name: string): Promise<boolean> {
  const errorText = 'Failed to check project name availability' // TODO: translate message

  const res = await request('get', `${PATH}/check-name`, errorText, { params: { name } }) as { available: boolean }

  return res.available
}
