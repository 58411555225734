import { request } from '@/api'

const PATH = 'verifications'

export function createCaseVerification (
  projectId: string,
  caseId: string,
  type: VerificationType,
  isVerified: boolean,
  payload?: string,
): Promise<CaseVerification | null> {
  const errorText = 'Failed to create case verification' // TODO: translate message
  const options = { data: { type, isVerified, payload } }

  return request<CaseVerification>('post', `${PATH}/${projectId}/${caseId}`, errorText, options)
}

export function getCaseVerifications (caseId: string, params?: any): Promise<CaseVerification[]> {
  const errorText = 'Failed to get case verifications' // TODO: translate message

  return (
    request('get', `${PATH}/${caseId}`, errorText, { params }) ?? Promise.resolve([])
  ) as Promise<CaseVerification[]>
}

export function getAllCaseVerifications (projectId: string, params?: any): Promise<CaseVerification[]> {
  const errorText = 'Failed to get all case verifications' // TODO: translate message

  return (
    request('get', `${PATH}/${projectId}/all`, errorText, { params }) ?? Promise.resolve([])
  ) as Promise<CaseVerification[]>
}
