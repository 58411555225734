import { useConfig } from '@/config'
import VisUtil from '@/react/visualization/VisUtil'
import ApiClient from '@/store/apiClient'
import type { Translation } from '@/types/translation'

export type Props = {
  tileId: string
  /**
   * PlotConfig ID but actually it's a file path
   */
  configId: string
  currentSimulationCase: SimulationCase
  onChildState: any // TODO: remove
  t: Translation
}

export type State = {
  content: string
  editContent: string
  change: any
  isEditing: boolean
  savePending: boolean
  loading: boolean
}

export const T = 'editBoxWrapper'

export default class Logic {
  public static getContent (filePath: string, simulationCaseId: string): Promise<any> {
    return ApiClient
      .get(`${useConfig().apiBaseURL}/editable-files/${simulationCaseId}`, { params: { filePath } })
  }

  public static patchContent (filePath: string, simulationCaseId: string, content: string): Promise<any> {
    return ApiClient
      .patch(`${useConfig().apiBaseURL}/editable-files/${simulationCaseId}`, { data: { content, filePath } })
  }

  public static isFrozen (simulationCase: any): boolean {
    return simulationCase && simulationCase.simulationStartedAt
  }

  public static isOnlyViewable (simulationCase: any, configId: string, catalogList: CaseFile[]): boolean {
    return VisUtil
      .getViewableFileSelectors(simulationCase, catalogList)
      .some(selector => selector.key === configId)
  }
}
