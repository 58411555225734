import { DriverManager, DriverStep } from '../DriverManager'

const CONTEXT: {
  SliderTest?: boolean
} = {}

export default [
  {
    element: 'body',
    popover: {
      position: 'top',
      title: 'Slider Test No Overlay',
      description: 'Slider Test No Overlay Description',
    },
    onDeselected: async () => {
      if (CONTEXT.SliderTest) {
        return
      }

      CONTEXT.SliderTest = true

      let remainingRetries = 20

      const canvas = document.querySelector('canvas')
      const { style } = DriverManager.getOrCreateElement('slider-test', canvas?.parentNode as HTMLElement)

      const { width, top, bottom } = (await import('@/three/views/UIView')).default.sliderDimensions

      style.position = 'absolute'
      style.top = `${top - 60}px` // TODO: 60 is a magic number
      style.bottom = `${bottom}px`
      style.right = `${bottom - width * 1.5}px`
      style.width = `${width * 4}px`
      style.backgroundColor = 'transparent'
      style.zIndex = '9999'
      style.pointerEvents = 'none'

      const waitFunc = async () => {
        const dialogOpen = document.querySelector('#slider-test')

        if (dialogOpen || remainingRetries-- <= 0) {
          // console.log('dialogOpen', dialogOpen, remainingRetries)

          DriverManager.current?.moveNext()

          delete CONTEXT.SliderTest

          return
        }

        await new Promise((resolve) => setTimeout(resolve, 100))

        requestAnimationFrame(waitFunc)
      }

      requestAnimationFrame(waitFunc)
    },
  },
  {
    popover: {
      title: 'Slider Test No Overlay',
      description: 'Slider Test No Overlay Description',
    },
    showButtons: [ 'next', 'close' ],
  },
  {
    element: '#slider-test',
    popover: {
      title: 'Slider Test',
      description: 'Slider Test Description',
    },
  },
] as DriverStep[]
