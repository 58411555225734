import { enqueueSnackbar } from 'notistack'
import * as THREE from 'three'

import { getState } from '@/api/state'

export type RecentlyUsedInfo = {
  projectId?: string
  simulationCaseId?: string
  casterDashboardConfigId?: string
  casterDashboardTabIndex?: number
  casterCameraPosition?: THREE.Vector3
  casterCameraLookAt?: THREE.Vector3
  filterTerm?: string
  isSectionActive?: boolean
  sectionViewSliderJumpOver?: JumpOverOptions | null
  sectionViewSliderTargetHeight?: number
}

export class Info {
  private static readonly recentlyUsedInfoKey = 'recentlyUsedInfo'

  private static locked = true

  public static async initFromStateLink () {
    const params = new URLSearchParams(window.location.search)
    const stateId = params.get('state-share')

    if (!stateId) {
      return
    }

    const state = await getState(stateId)

    if (!state?.data) {
      enqueueSnackbar(
        'Failed to get shared state data, maybe the link is invalid or expired.',
        { variant: 'error' },
      )

      return
    }

    localStorage.setItem(this.recentlyUsedInfoKey, JSON.stringify(state.data as RecentlyUsedInfo))
  }

  public static getRecentlyUsedInfo (): RecentlyUsedInfo {
    return JSON.parse(localStorage.getItem(this.recentlyUsedInfoKey) ?? '{}')
  }

  public static unlock () {
    Info.locked = false
  }

  public static setRecentlyUsedInfo (info: Partial<RecentlyUsedInfo>) {
    if (Info.locked) {
      return
    }

    localStorage.setItem(this.recentlyUsedInfoKey, JSON.stringify({ ...this.getRecentlyUsedInfo(), ...info }))
  }

  public static removeRecentlyUsedInfo () {
    if (Info.locked) {
      return
    }

    localStorage.removeItem(this.recentlyUsedInfoKey)
  }
}
