import cloneDeep from 'lodash/cloneDeep'

import type { PlotConfig, TileConfig, VisualizationState } from '@/types/visualization'

import { VisualizationActionsEnum as VisActionsEnum } from '../consts'
import Util from '../util/Util'

const plotReducers = {
  [VisActionsEnum.ACTION_VISUALIZATION_SHOW_PLOT_LIST]: (state: VisualizationState) => ({
    ...state,
    isPlotListOpened: !state.isPlotListOpened,
  }),

  [VisActionsEnum.ACTION_VISUALIZATION_SAVE_PLOT_CONFIG]: (
    state: VisualizationState,
    { plotConfig }: { plotConfig: PlotConfig },
  ) => ({
    ...state,
    plotConfigs: {
      ...state.plotConfigs,
      [plotConfig.id]: {
        ...state.plotConfigs[plotConfig.id],
        ...plotConfig,
      },
    },
  }),

  [VisActionsEnum.ACTION_VISUALIZATION_REMOVE_PLOT_CONFIG]: (
    state: VisualizationState,
    { plotId }: { plotId: string },
  ) => {
    const newPlots = { ...state.plotConfigs }

    delete newPlots[plotId]

    return {
      ...state,
      plotConfigs: newPlots,
    }
  },

  [VisActionsEnum.ACTION_VISUALIZATION_DERIVE_PLOT]: (state: VisualizationState, { configId, x, viewId, xIndex }: {
    configId: string
    x: number
    viewId: string
    xIndex: number
  }) => {
    const origConfig = { ...state.plotConfigs[configId] }

    origConfig.xRangeMin = xIndex
    origConfig.xRangeMax = xIndex + 1
    origConfig.type = 'text'
    origConfig.group = 'derived'

    if (!origConfig.configIds) {
      Util.handleNoConfigIds(origConfig)
    }

    return {
      ...state,
      plotConfigs: {
        ...state.plotConfigs,
        [`${configId}_derived_${String(x).replace(/\./g, '🍓')}`]: origConfig,
      },
      viewId,
      openDerivePlotDialog: true,
    }
  },

  [VisActionsEnum.ACTION_VISUALIZATION_MERGE_PLOTS]: (state: VisualizationState, { configIds, name, plotType }: {
    configIds: string[]
    name: string
    plotType: string
  }) => {
    const id = `config_merged_${Util.getConfigIdsHash(configIds)}`
    const configs = Object.values(state.plotConfigs).filter(config => configIds.includes(config.id))

    const { vRangeMin, vRangeMax } = Util.getVRange(plotType)

    if (configs[0]?.group === 'dynamicDataSource' || configs[0]?.group === 'externalDataSource') {
      return {
        ...state,
        plotConfigs: {
          ...state.plotConfigs,
          [id]: {
            ...Util.defaultPlotConfig, // TODO: calc values from configs
            id,
            key: id, // TODO: check if correct
            type: plotType ?? 'line',
            vRangeMin,
            vRangeMax,
            name,
            configIds,
            configs,
            isMergedDynamicDataSource: true,
            group: 'merged',
            // xValues: configs.reduce((_, config) => ([ ...config.xValues ]), []),
            // yValueRange: [
            //   Math.min(...configs.map(config => config.yValueRange[0])),
            //   Math.max(...configs.map(config => config.yValueRange[1])),
            // ],
            // xRangeMax: configs.map(pCC => pCC.length)[0],
          },
        },
      }
    }

    return {
      ...state,
      plotConfigs: {
        ...state.plotConfigs,
        [id]: {
          ...Util.defaultPlotConfig, // TODO: calc values from configs
          id,
          type: plotType ?? 'line',
          vRangeMin,
          vRangeMax,
          name,
          configIds,
          xValues: configs.reduce((_: any, config) => [ ...config.xValues ], []),
          yValueRange: [
            Math.min(...configs.map(config => config.yValueRange[0]).filter(n => n !== undefined)),
            Math.max(...configs.map(config => config.yValueRange[1]).filter(n => n !== undefined)),
          ],
          xRangeMax: configs.map(pCC => (pCC as any).length)[0], // TODO: this is wrong, plotConfigs dont have length
        },
      },
    }
  },

  [VisActionsEnum.ACTION_ADD_PLOT_TILE]: (state: VisualizationState, { viewId, dashboardId, tileId, plotParams }: {
    viewId: string
    dashboardId: string
    tileId: string
    plotParams: any
  }) => ({
    ...state,
    viewsObject: {
      ...state.viewsObject,
      [viewId]: {
        ...state.viewsObject[viewId],
        dashboards: {
          ...state.viewsObject[viewId]?.dashboards,
          [dashboardId]: {
            ...state.viewsObject[viewId]?.dashboards[dashboardId],
            tileIds: [
              ...(state.viewsObject[viewId]?.dashboards[dashboardId]?.tileIds ?? []),
              tileId,
            ],
          },
        },
      },
    },
    tileConfigs: {
      ...state.tileConfigs,
      [tileId]: {
        // id: tileId,
        ...state.tileConfigs?.[tileId],
        ...plotParams,
      },
    },
  }),

  [VisActionsEnum.ACTION_DELETE_PLOT_TILE]: (state: VisualizationState, { tileId }: { tileId: string }) => {
    const newViewsObject = cloneDeep(state.viewsObject)
    const newTileConfigs = cloneDeep(state.tileConfigs)

    Util.deleteTile(newViewsObject, tileId)

    delete newTileConfigs[tileId]

    return ({
      ...state,
      viewsObject: newViewsObject,
      tileConfigs: newTileConfigs,
    })
  },
  [VisActionsEnum.ACTION_VISUALIZATION_SET_CURRENT_TILE_ID]: (
    state: VisualizationState,
    { currentTileId }: { currentTileId: string },
  ) => ({
    ...state,
    currentTileId,
  }),

  [VisActionsEnum.ACTION_SAVE_TILE_CONFIG]: (
    state: VisualizationState,
    { tileConfig }: { tileConfig: TileConfig },
  ) => ({
    ...state,
    tileConfigs: {
      ...state.tileConfigs,
      [tileConfig.id]: {
        ...state.tileConfigs[tileConfig.id],
        ...tileConfig,
      },
    },
  }),

  [VisActionsEnum.ACTION_UPDATE_COMMAND_TILE]: (state: VisualizationState, { commandId }: { commandId: string }) => {
    const updatableCommandTile = [ ...state.updatableCommandTile ]

    if (updatableCommandTile.includes(commandId)) {
      const commandIndex = updatableCommandTile.indexOf(commandId)

      updatableCommandTile.splice(commandIndex, 1)
    }
    else {
      updatableCommandTile.push(commandId)
    }

    return ({
      ...state,
      updatableCommandTile,
    })
  },
}

export default plotReducers
