import type { Props } from '@/react/context/form/FormBuilder/FormBuilder'

export class DefinitionHelper {
  public static isRealDataCase ({ currentSimulationCase }: Props) {
    return Boolean(currentSimulationCase?.blueprintId)
  }

  public static isBlueprintCase ({ currentSimulationCase }: Props) {
    return !currentSimulationCase?.blueprintId
  }
}
