import isEqual from 'lodash/isEqual'
import { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'redux'
import styled, { css } from 'styled-components'

import type { CasterDialogElementType } from '@/types/filter'
import type { DefaultState } from '@/types/state'

import TypeBuilder from './context/type/TypeBuilder'
import PartsWarehouse from './PartsWarehouse'
import ScrollBar from './specific/ScrollBar'

const CasterDialogView = styled.div<{
  $dialogWidth: number
  $isOpen: boolean
}>`${({
  $isOpen,
  $dialogWidth,
}: any) =>
  css`
  display:    inline-block;
  position:   absolute;
  top:        0;
  right:      ${$isOpen ? '0' : '-335px'};
  bottom:     0;
  width:      ${$dialogWidth}px;
  min-width:  335px;
  background: #22282e;
  color:      #FFFFFF;
  font-size:  14px;
  overflow:   hidden;
`}`

const connector = connect((state: DefaultState) => ({
  selectedPaths: state.data.selectedPaths,
  openDialogs: state.application.main.openDialogs,
  currentCasterDialogWidth: state.visualization.currentCasterDialogWidth,
}))

type Props = ConnectedProps<typeof connector>

type SelectedPathsByType = {
  [elementType: string]: string[]
  General: string[]
  SegmentGroup: string[],
  Segment: string[],
  Nozzle: string[]
  Roller: string[]
  RollerBody: string[]
  RollerBearing: string[]
  SensorPoint: string[]
}

type State = {
  showElements: boolean
  dragging: boolean
  currentPanelWidth: number
  mousePosition: number
}

export class CasterDialog extends Component<Props> {
  public override state: State = {
    showElements: false,
    dragging: false,
    currentPanelWidth: 335,
    mousePosition: 0,
  }

  public override componentDidUpdate (prevProps: Props) {
    const { selectedPaths } = this.props

    if (!isEqual(selectedPaths, prevProps.selectedPaths)) {
      this.setState({
        showElements: true,
      })
    }
  }

  private readonly toggleElements = () => {
    this.setState({
      showElements: !this.state.showElements,
    })
  }

  public override render () {
    const {
      selectedPaths,
      openDialogs,
      currentCasterDialogWidth,
    } = this.props
    const openCasterDialog = openDialogs.includes('CasterDialog')
    const openPartsWarehouse = openDialogs.includes('PartsWarehouse')
    const isOpen = openCasterDialog || openPartsWarehouse
    const { showElements } = this.state

    const selectedPathsByType: SelectedPathsByType = {
      General: [],
      Elements: [],
      SegmentGroup: [],
      Segment: [],
      SupportPoint: [],
      Nozzle: [],
      Roller: [],
      RollerBody: [],
      RollerBearing: [],
      SensorPoint: [],
      DataPoint: [],
      DataLine: [],
      Modules: [],
    }

    // FIXME: using Sets in Redux is not working properly, we need to find a solution that stores Sets outside of Redux
    // FIXME: maybe we can find a different storage solution for this, or make a custom Set that is serializable!
    // FIXME: https://redux.js.org/style-guide/#do-not-put-non-serializable-values-in-state-or-actions
    const workaroundSelectedPaths = selectedPaths instanceof Set ? selectedPaths : new Set<string>()

    for (const path of workaroundSelectedPaths) {
      const type = path.substring(path.lastIndexOf('/') + 1, path.lastIndexOf(':'))

      selectedPathsByType[type]?.push(path)
    }

    let types = Object.keys(selectedPathsByType) as CasterDialogElementType[]

    if (!showElements) {
      types = types.filter(type => [ 'Modules', 'General', 'ConsistencyCheck', 'Elements' ].includes(type))
    }

    return (
      <CasterDialogView
        $isOpen={isOpen}
        $dialogWidth={currentCasterDialogWidth}
      >
        <ScrollBar hidden={!isOpen}>
          {
            openPartsWarehouse
              ? <PartsWarehouse />
              : (
                types.map((type: CasterDialogElementType) => (
                  <TypeBuilder
                    key={type}
                    type={type}
                    paths={selectedPathsByType[type]}
                    areElementsShown={showElements}
                    toggleElements={this.toggleElements}
                  />
                ))
              )
          }
        </ScrollBar>
      </CasterDialogView>
    )
  }
}

export default compose<any>(connector)(CasterDialog)
