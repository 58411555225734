import hoistStatics from 'hoist-non-react-statics'
import hotkeys from 'hotkeys-js'
import { enqueueSnackbar } from 'notistack'
import { Component, ReactNode } from 'react'
import { withTranslation } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'redux'

import { checkCaseNameAvailability } from '@/api/case'
import { useConfig } from '@/config'
import IpcManager from '@/IpcManager'
import TimeUtil from '@/logic/TimeUtil'
import Util from '@/logic/Util'
import Button from '@/react/components/Button'
import FeatureFlags from '@/react/FeatureFlags'
import Input from '@/react/specific/Input'
import { Form } from '@/react/visualization/dashboard/Dialogs/DialogStyles'
import ApiClient from '@/store/apiClient'
import * as ApplicationActions from '@/store/application/main/actions'
import { AppState } from '@/store/application/main/consts'
import DataActions from '@/store/data/actions'
import * as ElementsActions from '@/store/elements/actions'
import * as VisualizationActions from '@/store/visualization/actions'
import type { DefaultState } from '@/types/state'
import { Identifiable } from '@/Util/decorators/Identifiable'

import { OpenProjectDialog } from './OpenProjectDialog'
import BaseDialog from '../BaseDialog'

const T = 'newSimulationCaseDialog'

const connector = connect((state: DefaultState) => ({
  currentProject: state.application.main.currentProject,
  currentSimulationCase: state.application.main.currentSimulationCase,
  featureFlags: FeatureFlags.getRealFeatureFlags(state),
}), {
  closeDialog: ApplicationActions.closeDialog,
  setCurrentProject: ApplicationActions.setCurrentProject,
  setCurrentSimulationCase: ApplicationActions.setCurrentSimulationCase,
  resetAllElements: ElementsActions.resetAllElements,
  setVisualizationMetaInformation: VisualizationActions.setVisualizationMetaInformation,
  setCurrentCatalogId: DataActions.setCurrentCatalogId,
  resetReducer: DataActions.resetReducer,
  setAppState: ApplicationActions.setAppState,
  addMetadataToCurrentProjectCasesMetadata: ApplicationActions.addMetadataToCurrentProjectCasesMetadata,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  t(key: string, params?: Record<string, unknown>): string
}

type State = {
  name: string
  nameAvailable: boolean
  description: string
  fromSimulationCaseId: string | null | undefined
  error: string
  loading: boolean
  cases: SimulationCase[]
}

export class NewSimulationCaseDialog extends Component<Props, State> {
  @Identifiable('NewSimulationCaseDialog') public static readonly NAME: string

  private checkCaseNameTimeout?: NodeJS.Timeout

  public override state: State = {
    cases: [] as SimulationCase[],
    name: '',
    nameAvailable: true,
    description: '',
    fromSimulationCaseId: null,
    error: '',
    loading: true,
  }
  
  public override async componentDidMount () {
    hotkeys('Escape', this.handleClose)

    try {
      const { currentProject } = this.props
      const { cases } = await ApiClient
        .get(`${useConfig().apiBaseURL}/cases`, { params: { projectId: currentProject.id } })

      if (!cases || !cases.length) {
        this.setState({ loading: false })

        return
      }

      this.setState({ cases, loading: false })
    }
    catch ({ status }: any) {
      this.setState({
        loading: false,
        error: status as string,
      })
    }
  }
  
  public override componentWillUnmount () {
    hotkeys.deleteScope('other')
    hotkeys.unbind('Escape', this.handleClose)
  }

  private readonly handleClose = () => {
    const { closeDialog } = this.props

    closeDialog(NewSimulationCaseDialog.NAME)
  }

  private readonly handleInput = (event: any) => {
    const { name, value } = event.target

    this.setState({
      [name]: value,
    } as State)

    if (name === 'name') {
      Util.checkNameAvailability(
        value,
        this.state.name,
        (state) => this.setState(state),
        this.checkCaseNameTimeout,
        (timeout) => {
          this.checkCaseNameTimeout = timeout
        },
        checkCaseNameAvailability,
      )
    }
  }

  private readonly handleKeyDown = (event: any) => {
    if (event.keyCode === 13) {
      this.handleSubmit()
    }
  }

  private readonly handleSubmit = async () => {
    const { name, description, fromSimulationCaseId } = this.state
    const {
      currentProject,
      setCurrentSimulationCase,
      resetAllElements,
      resetReducer,
      setVisualizationMetaInformation,
      setCurrentCatalogId,
      t,
      featureFlags,
      addMetadataToCurrentProjectCasesMetadata,
      closeDialog,
    } = this.props

    if (!name) {
      return
    }

    this.setState({
      loading: true,
    })

    try {
      const case_ = await ApiClient
        .post(
          `${useConfig().apiBaseURL}/cases`,
          {
            data: {
              projectId: currentProject.id,
              name,
              description,
              fromCaseId: fromSimulationCaseId,
            },
          },
        )

      delete case_.project

      const isSlimVersion = FeatureFlags.usesSlimVersion(featureFlags)

      closeDialog(OpenProjectDialog.NAME)
      resetReducer()
      resetAllElements()
      setVisualizationMetaInformation('config', '', AppState.ParamDashboard)
      setVisualizationMetaInformation('data', '', AppState.ResultDashboard)
      setVisualizationMetaInformation('config', '', AppState.ResultDashboard)
      setVisualizationMetaInformation('config', '', AppState.Caster)
      setCurrentCatalogId()
      setCurrentSimulationCase(case_, isSlimVersion)
      addMetadataToCurrentProjectCasesMetadata({ id: case_.id, createdAt: new Date(case_.createdAt) })

      this.handleClose()

      if (case_.currentCasterId) {
        IpcManager.send('loadCurrentCaster', { simulationCase: case_, caseId: null })
      }

      enqueueSnackbar(t(`${T}.create.success`), { autoHideDuration: 3000, variant: 'success' })
    }
    catch (error: any) {
      this.setState({
        error: error ? error?.status : 'unknown',
        loading: false,
      })
      enqueueSnackbar(t(`${T}.create.error`), { autoHideDuration: 4000, variant: 'error' })
    }
  }

  private readonly handleChangeSimulationCase = (event: any) => {
    this.setState({
      fromSimulationCaseId: event.target.value,
    })
  }
  
  public override render () {
    const { name, description, fromSimulationCaseId, error, loading, cases, nameAvailable } = this.state
    const { t, currentSimulationCase, featureFlags } = this.props
    const submitDisabled = !(name.length > 0 && description.length > 0) || !nameAvailable

    const simulationCaseSelectors: {
      value: ReactNode | string
      key: string
      notRemovable?: boolean
    }[] = cases.map(({ name, user, hideUser, createdAt, id, blueprintId }, index) => ({
      value: (
        <span>
          {`C${index + 1}${currentSimulationCase.id === id ? '(R)' : ''} - ${name}`}
          {
            FeatureFlags.canViewRealDataBadges(featureFlags)
              ? (
                blueprintId
                  ? <span className='badge real-data'>{t('label.realData')}</span>
                  : <span className='badge blueprint'>{t('label.blueprint')}</span>
              )
              : <span>&nbsp;</span>
          }
          {`${hideUser ? 'System' : user?.name} (${TimeUtil.getDisplayDate(createdAt)})`}
        </span>
      ),
      key: id,
    }))

    simulationCaseSelectors.unshift({ key: 'default', value: t(`${T}.default`), notRemovable: true })

    return (
      <BaseDialog
        title={t(`${T}.title`)}
        icon='pe-7s-folder'
        header={t(`${T}.header`)}
        onClose={this.handleClose}
        small
      >
        <Form>
          <Input
            name='fromSimulationCaseId'
            type='select'
            label={t(`${T}.simulationCase.label`)}
            title={t(`${T}.simulationCase.title`)}
            value={fromSimulationCaseId ?? simulationCaseSelectors[0]?.key}
            selectors={simulationCaseSelectors}
            onChange={this.handleChangeSimulationCase}
          />
          <Input
            label={t(`${T}.name.label`)}
            title={t(`${T}.name.label`)}
            placeholder={t(`${T}.name.placeholder`)}
            name='name'
            type='text'
            value={name}
            onChange={this.handleInput}
            onKeyDown={this.handleKeyDown}
            error={!nameAvailable}
            helperText={!nameAvailable ? t(`${T}.name.notAvailable`) : ''}
          />
          <Input
            label={t(`${T}.description.label`)}
            title={t(`${T}.description.label`)}
            placeholder={t(`${T}.description.placeholder`)}
            name='description'
            type='text'
            rows={4}
            value={description}
            onChange={this.handleInput}
            onKeyDown={this.handleKeyDown}
          />
          <Button
            type='primary'
            title={submitDisabled ? t(`${T}.create.disabled`) : ''}
            disabled={submitDisabled}
            onClick={this.handleSubmit}
            error={error}
            loading={loading}
            icon='pe-7s-plus'
            isRef
          >
            {t(`${T}.create.label`)}
          </Button>
          <Button
            value=''
            onClick={this.handleClose}
            title={t(`${T}.cancel`)}
          >
            {t(`${T}.cancel`)}
          </Button>
        </Form>
      </BaseDialog>
    )
  }
}

const composedComponent = compose<any>(withTranslation('application'), connector)(NewSimulationCaseDialog)

export default hoistStatics(composedComponent, NewSimulationCaseDialog)
