/* eslint-disable camelcase */
/* eslint-disable key-spacing */
export const ConsistencyCheck = {
  categories: {
    1: 'general',
  },
  fields: {
    uuid: { type: 'string', category: 1, defaultValue: '' },
    oldValue: { type: 'string', category: 1, defaultValue: 0 },
    newValue: { type: 'string', category: 1, defaultValue: 0 },
    comment: { type: 'string', category: 1, defaultValue: '' },
  },
}
/* eslint-enable key-spacing */
