import type { ElementMaps } from '@/types/state'

export const casterElementNamesArray: readonly (keyof ElementMaps)[] = [
  'Caster',
  'PasslineSlot',
  'PasslineMountLog',
  'PasslineSectionSlot',
  'PasslineSectionMountLog',
  'AirLoopSlot',
  'AirLoopMountLog',
  'CoolingLoopSlot',
  'CoolingLoopMountLog',
  'CoolingZoneSlot',
  'CoolingZoneMountLog',
  'LoopAssignmentSlot',
  'LoopAssignmentMountLog',
  'SegmentGroupSlot',
  'SegmentGroupMountLog',
  'SegmentSlot',
  'SegmentMountLog',
  'SupportPointSlot',
  'SupportPointMountLog',
  'RollerBodySlot',
  'RollerBodyMountLog',
  'RollerBearingSlot',
  'RollerBearingMountLog',
  'NozzleSlot',
  'NozzleMountLog',
  'RollerSlot',
  'RollerMountLog',
  'DataLineSlot',
  'DataLineMountLog',
  'StrandGuideSlot',
  'StrandGuideMountLog',
  'MoldSlot',
  'MoldMountLog',
  'MoldFaceSlot',
  'MoldFaceMountLog',
  'MoldBCAreaSlot',
  'MoldBCAreaMountLog',
  'RollerDataPointSlot',
  'RollerDataPointMountLog',
  'RollerBodyDataPointSlot',
  'RollerBodyDataPointMountLog',
  'StrandDataPointSlot',
  'StrandDataPointMountLog',
  'MoldFaceDataPointSlot',
  'MoldFaceDataPointMountLog',
  'SegmentDataPointSlot',
  'SegmentDataPointMountLog',
  'SegmentSensorPointSlot',
  'SegmentSensorPointMountLog',
  'RollerSensorPointSlot',
  'RollerSensorPointMountLog',
  'RollerBodySensorPointSlot',
  'RollerBodySensorPointMountLog',
]

export function resetAllElements () {
  return (dispatch: any) => {
    casterElementNamesArray.forEach((type) => {
      dispatch({
        type: `${type}/RESET`,
      })
    })
  }
}

export function updateElementMaps (elementMaps: ElementMaps) {
  return (dispatch: any) => {
    // initialize data because if it is an empty object if the new caster doesn't have one type of element previous
    // elements won't be deleted
    const data = casterElementNamesArray.reduce((acc, type) => {
      acc[type] = {
        ...elementMaps[type],
      } as any

      return acc
    }, {} as ElementMaps)

    // TODO: update all for now, later we can add a store for element changes and only update what is needed

    dispatch({
      type: 'UPDATE_WHOLE_STATE',
      data,
    })
  }

  // TODO: optimize, only update changed elements if they are not many
}

export function updateElement (type: keyof ElementMaps, id: string, data: any) {
  return {
    type: `${type}/UPDATE`,
    id,
    data,
  }
}
