import isEqual from 'lodash/isEqual'
import { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'redux'

import Input from '@/react/specific/Input'
import { FormWrapper, InputWrapper } from '@/react/visualization/dashboard/Dialogs/DialogStyles'
import VisUtil from '@/react/visualization/VisUtil'
import * as VisualizationActions from '@/store/visualization/actions'
import type { DefaultState } from '@/types/state'
import type { Translation } from '@/types/translation'
import type { TileConfig } from '@/types/visualization'

const connector = connect(({ visualization }: DefaultState) => ({
  plotConfigs: visualization.plotConfigs,
  editDialogConfigId: visualization.editDialogConfigId,
  currentTileId: visualization.currentTileId,
  tileConfigs: visualization.tileConfigs,
  data: visualization.data,
}), {
  saveTileConfig: VisualizationActions.saveTileConfig,
  showConfigDialog: VisualizationActions.showConfigDialog,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  fullscreen: boolean
  t: Translation
}

type State = {
  typeSelectors: Array<{ key: string, value: string }>
  allConfigIds: any[]
  config: any
  tileConfig: TileConfig
}

const typesWithoutDomain = [ 'text', 'gage', 'contour', 'table' ]

class GeneralTab extends Component<Props, State> {
  private timeoutRef?: number

  public constructor (props: Props) {
    super(props)

    const { editDialogConfigId, plotConfigs, currentTileId, tileConfigs, data } = props
    const { configId, type } = tileConfigs[currentTileId ?? ''] ?? { configId: '', type: '' }

    const group = /_derived_/.test(configId)
      ? 'derived'
      : (!/_merged_/.test(configId)
        ? plotConfigs[configId]?.group
        : '')

    const typeSelectors = type === 'table'
      ? [ { key: 'table', value: 'Table' } ]
      : VisUtil.getTypeSelectors(group)
    const allConfigIds = VisUtil.getConfigSelectors(data, plotConfigs)

    const config = allConfigIds.find((config) => config.key === (configId ?? editDialogConfigId))

    this.state = {
      typeSelectors,
      config,
      allConfigIds,
      tileConfig: (currentTileId ? { ...tileConfigs[currentTileId] } : {}) as TileConfig,
    }
  }

  public override componentDidUpdate = (_pervProps: Props, prevState: State) => {
    const { saveTileConfig } = this.props
    const { tileConfig } = this.state

    if (!isEqual(prevState.tileConfig, tileConfig ?? {})) {
      clearTimeout(this.timeoutRef)

      this.timeoutRef = window.setTimeout(() => {
        saveTileConfig(tileConfig)
        delete this.timeoutRef
      }, 100)
    }
  }

  public override componentWillUnmount () {
    const { saveTileConfig } = this.props
    const { tileConfig } = this.state

    if (this.timeoutRef) {
      clearTimeout(this.timeoutRef)
      saveTileConfig(tileConfig)
    }
  }

  private readonly handleInputDataTile = (event: any) => {
    const { tileConfig, typeSelectors } = this.state
    const { plotConfigs, editDialogConfigId, saveTileConfig, showConfigDialog } = this.props
    const { name, value } = event.target
    const val: any = value

    let editTileConfig = {
      ...tileConfig,
      [name]: val,
    } as any

    let plotType: string | typeof typeSelectors = [ ...typeSelectors ]

    if (name === 'configId') {
      if (!val) {
        return
      }

      delete editTileConfig.isDynamicData
      delete editTileConfig.isMergedDynamicData
      editTileConfig.legendOptions = {}
      // TODO: keep some settings from the previous configId, like colors and stuff

      // because if it is not a merged plot, it cant have an additional axis, but if the info is still on the object
      // the plot will render smaller because of the space previously used by the extra axis info
      if (editTileConfig.additionalYAxes) {
        editTileConfig.additionalYAxes = []
      }

      if (plotConfigs[val]?.isMergedDynamicDataSource) {
        editTileConfig.isMergedDynamicData = true
        editTileConfig.isDynamicData = false
      }

      if (plotConfigs[val]?.group === 'dynamicDataSource') {
        editTileConfig.isDynamicData = true
      }

      const group = /_derived_/.test(val)
        ? 'derived'
        : (!/_merged_/.test(val)
          ? plotConfigs[val]?.group
          : '')

      const typeSelectors = VisUtil.getTypeSelectors(group) ?? []
      const type = editTileConfig.type ?? plotConfigs[editDialogConfigId]?.type

      plotType = typeSelectors.map(type => type.key).includes(type) ? type : typeSelectors[0]?.key

      editTileConfig = {
        ...editTileConfig,
        type: plotType,
      }

      this.setState({ typeSelectors })
      saveTileConfig(editTileConfig)

      // change the editDialogConfigId to the new configId
      showConfigDialog(val, true)
    }

    if (name === 'type') {
      plotType = typeSelectors.map(type => type.key).includes(val) ? val : typeSelectors[0]?.key

      editTileConfig = {
        ...editTileConfig,
        type: plotType,
      }

      saveTileConfig(editTileConfig)
    }

    this.setState({
      tileConfig: editTileConfig,
    })
  }
  
  public override render () {
    const {
      editDialogConfigId,
      plotConfigs,
      fullscreen,
      t,
    } = this.props

    const { tileConfig, typeSelectors, config, allConfigIds } = this.state

    const alphabeticallySortedConfigIds = allConfigIds
      .sort((a, b) => a.value.toLowerCase().localeCompare(b.value.toLowerCase()))

    const { decimals: plotConfigDecimals } = plotConfigs[editDialogConfigId] ?? {}

    const {
      type,
      name,
      configId,
      xDomainMin,
      xDomainMax,
      yDomainMin,
      yDomainMax,
      decimals,
      followPasslnCoord,
    } = tileConfig

    const plotName = allConfigIds
      .find(selector => selector.key === (configId ?? editDialogConfigId))
      ?.value ?? 'Unnamed Plot'

    return (
      <FormWrapper $fullscreen={fullscreen}>
        <InputWrapper $fullscreen={fullscreen}>
          <Input
            label={t('plotConfig.name.name')}
            name='name'
            type='text'
            title={name ?? ''}
            value={name ?? ''}
            placeholder={plotName}
            onChange={this.handleInputDataTile}
          />
          <Input
            label={t('plotConfig.type.label')}
            name='type'
            type='select'
            title={type ?? typeSelectors[0]?.value}
            value={type ?? typeSelectors[0]?.key ?? ''}
            selectors={typeSelectors}
            onChange={this.handleInputDataTile}
            disabled={type === 'table'}
          />
        </InputWrapper>
        <InputWrapper $fullscreen={fullscreen}>
          <Input
            label={t('plotConfig.configId.label')}
            name='configId'
            type='select'
            title={config && config.title}
            value={configId ?? editDialogConfigId}
            selectors={alphabeticallySortedConfigIds}
            onChange={this.handleInputDataTile}
            disabled={type === 'table'}
          />
        </InputWrapper>
        {
          !typesWithoutDomain.includes(type) && (
            <InputWrapper $fullscreen={fullscreen}>
              <Input
                label={t('plotConfig.yDomain.label')}
                // eslint-disable-next-line max-len
                title={t('plotConfig.yDomain.title')}
                name='yDomain'
                type='range'
                min={yDomainMin as any}
                max={yDomainMax as any}
                onChange={this.handleInputDataTile}
              />
              <Input
                label={t('plotConfig.xDomain.label')}
                // eslint-disable-next-line max-len
                title={
                  `${t('plotConfig.xDomain.title')} ${
                    followPasslnCoord && '- Disabled because followPasslnCoord is active'
                  }`
                }
                name='xDomain'
                type='range'
                min={xDomainMin as any}
                max={xDomainMax as any}
                unused={followPasslnCoord ?? false}
                onChange={this.handleInputDataTile}
              />
            </InputWrapper>
          )
        }
        {
          type === 'gage' &&
          (
            <InputWrapper $fullscreen={fullscreen}>
              <Input
                label={t('plotConfig.decimals.label')}
                title={t('plotConfig.decimals.title')}
                name='decimals'
                type='select'
                value={Boolean(decimals) || decimals === 0 ? decimals : plotConfigDecimals}
                selectors={Array(10).fill(0).map((_v, i) => i)} // TODO: test this, it used to be fill()
                onChange={this.handleInputDataTile}
              />
            </InputWrapper>
          )
        }
      </FormWrapper>
    )
  }
}

export default compose<any>(withTranslation('visualization'), connector)(GeneralTab)
