export enum UtilActionsEnum {
  ACTION_SET_USER_SETTINGS = 'util/ACTION_SET_USER_SETTINGS',
  ACTION_SET_SAVE_PATH = 'util/ACTION_SET_SAVE_PATH',
  ACTION_SET_ROLLER_VISIBLE = 'util/ACTION_SET_ROLLER_VISIBLE',
  ACTION_SET_HAS_DATA = 'util/ACTION_SET_HAS_DATA',
  ACTION_SET_IS_CONSISTENCY_CHECK_CASE = 'util/ACTION_SET_IS_CONSISTENCY_CHECK_CASE',
}

export const utilActionList = Object.values(UtilActionsEnum)

export const initialState = {
  savePath: null,
  rollerChildren: 2,
  isConsistencyCheck: false,
}

export type UtilAction = {
  type: string
  settings: any
  savePath: string
  rollerChildren: number
}
