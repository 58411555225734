import { Group } from 'three'

import ThreeDataLine from '@/three/objects/DataLine'
import ThreeDataPoint from '@/three/objects/DataPoint'
import ThreeNozzle from '@/three/objects/Nozzle'
import ThreeRoller from '@/three/objects/Roller'
import ThreeRollerBearing from '@/three/objects/RollerBearing'
import ThreeRollerBody from '@/three/objects/RollerBody'
import ThreeSegment from '@/three/objects/Segment'
import ThreeSegmentGroup from '@/three/objects/SegmentGroup'
import ThreeSensorPoint from '@/three/objects/SensorPoint'
import ThreeSupportPoint from '@/three/objects/SupportPoint'

export default class DrawUtil {
  public static getNewElementByType ( // TODO: improve typing
    type: string,
    parent: any,
    container: Group,
    clickableObjects: any,
    tooltipObjects: any,
    sectionDetail: any,
    phantomGroup?: Group,
    featureFlags?: Record<string, boolean>,
  ) {
    switch (type) {
      case 'SegmentGroup':
        return new ThreeSegmentGroup(container, parent)
      case 'Segment':
        return new ThreeSegment(container, parent, clickableObjects, sectionDetail, featureFlags ?? {})
      case 'SupportPoint':
        return new ThreeSupportPoint(container, parent, clickableObjects)
      case 'Nozzle':
        return new ThreeNozzle(container, parent, clickableObjects, tooltipObjects)
      case 'Roller':
        return new ThreeRoller(container, parent, clickableObjects, phantomGroup)
      case 'RollerBody':
        return new ThreeRollerBody(container, parent, clickableObjects, phantomGroup)
      case 'RollerBearing':
        return new ThreeRollerBearing(container, parent, clickableObjects, phantomGroup)
      case 'SensorPoint':
        return new ThreeSensorPoint(container, parent, clickableObjects, tooltipObjects)
      case 'DataPoint':
        return new ThreeDataPoint(container, parent, clickableObjects, tooltipObjects)
      case 'DataLine':
        return new ThreeDataLine(container, parent, clickableObjects, tooltipObjects)
      default:
        return null
    }
  }

  public static isDrawable (type: string) {
    switch (type) {
      case 'SegmentGroup':
      case 'Segment':
      case 'SupportPoint':
      case 'Nozzle':
      case 'Roller':
      case 'RollerBearing':
      case 'RollerBody':
      case 'SensorPoint':
      case 'DataPoint':
      case 'DataLine':
        return true
      default:
        return false
    }
  }
}
