import { BoxGeometry, Mesh, MeshBasicMaterial } from 'three'

import TextureUtil from '@/three/logic/TextureUtil'
import Util from '@/three/logic/Util'

export default class CubeHandlers {
  public static initCube () {
    const textures = []
    const materials = []

    textures.push(TextureUtil.load('textures/view_cube/fixed.png'))
    textures.push(TextureUtil.load('textures/view_cube/loose.png'))
    textures.push(TextureUtil.load('textures/view_cube/top.png'))
    textures.push(TextureUtil.load('textures/view_cube/bottom.png'))
    textures.push(TextureUtil.load('textures/view_cube/left.png'))
    textures.push(TextureUtil.load('textures/view_cube/right.png'))

    // using ! as we just pushed the textures so we know they are there
    textures[2]!.center.set(0.5, 0.5)
    textures[3]!.center.set(0.5, 0.5)
    textures[2]!.rotation = Util.RAD90
    textures[3]!.rotation = -Util.RAD90

    // order to add materials: x+,x-,y+,y-,z+,z-
    materials.push(new MeshBasicMaterial({ map: textures[0] }))
    materials.push(new MeshBasicMaterial({ map: textures[1] }))
    materials.push(new MeshBasicMaterial({ map: textures[2] }))
    materials.push(new MeshBasicMaterial({ map: textures[3] }))
    materials.push(new MeshBasicMaterial({ map: textures[4] }))
    materials.push(new MeshBasicMaterial({ map: textures[5] }))

    const geometry = new BoxGeometry(1, 1, 1)

    return new Mesh(geometry, materials)
  }
}
