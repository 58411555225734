import cloneDeep from 'lodash/cloneDeep'

import { useConfig } from '@/config'
import ApiClient from '@/store/apiClient'

export default class MatrixUtil {
  public static updateSimulationCaseName (
    currentProject: Project,
    setCurrentProject: (project: Project) => void,
    name: string,
    simulationCaseId: string,
    selectedCellData: any,
    successCallback: () => void,
    errorCallback: (error: any) => void,
  ): void {
    const project = cloneDeep(currentProject)
    const simulationCase = project.simulationCases.find(simulationCase => simulationCase.id === simulationCaseId)

    ApiClient
      .patch(
        `${useConfig().apiBaseURL}/cases/${simulationCaseId ?? ''}`,
        { data: { name, description: simulationCase?.description ?? '', selectedCellData } },
      )
      .then(() => {
        if (selectedCellData && selectedCellData.length) {
          // eslint-disable-next-line react/prop-types
          const ids = selectedCellData.map((data: any) => data.simulationCaseId)

          project.simulationCases.forEach(simulationCase => {
            if (ids.includes(simulationCase.id)) {
              simulationCase.name = name
            }
          })
        }
        else {
          if (simulationCase) {
            simulationCase.name = name
          }
        }

        setCurrentProject(project)

        successCallback()
      })
      .catch((response) => {
        const { error } = response

        errorCallback(error)
      })
  }

  public static async updateSimulationCaseNames (
    simulationCaseData: Array<any>,
    currentProject: Project,
    currentSimulationCase: SimulationCase,
    setCurrentProject: (project: Project) => void,
    setCurrentSimulationCase: (simCase: SimulationCase) => void,
  ): Promise<any> {
    const project = cloneDeep(currentProject)
    const currentSimulationCaseClone = cloneDeep(currentSimulationCase)

    await ApiClient.patch(`${'Network.URI(deprecated)'}/simulation_case/names`, { data: { simulationCaseData } })

    for (const { simulationCaseId, name } of simulationCaseData) {
      const simulationCase = project.simulationCases.find(simulationCase => simulationCase.id === simulationCaseId)

      if (simulationCase) {
        simulationCase.name = name
      }

      if (currentSimulationCaseClone.id === simulationCaseId) {
        currentSimulationCaseClone.name = name
      }
    }

    setCurrentProject(project)

    if (currentSimulationCaseClone.name !== currentSimulationCase.name) {
      setCurrentSimulationCase(currentSimulationCaseClone)
    }

    return project
  }
}
