import { faPencilAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GridColumnVisibilityModel } from '@mui/x-data-grid'
import cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'
import { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'redux'
import styled from 'styled-components'

import { updateProjectAdditionalData } from '@/api/project'
import BaseDialog from '@/react/dialogs/BaseDialog'
import * as ApplicationActions from '@/store/application/main/actions'
import { getElementMapsObject } from '@/store/elements/logic'
import * as TemporalDataActions from '@/store/temporalData/actions'
import FilterHandler from '@/three/logic/FilterHandler'
import type { DefaultState, TagName } from '@/types/state'
import type { Translation } from '@/types/translation'
import { Identifiable } from '@/Util/decorators/Identifiable'
import { ElementMapsUtil } from '@/Util/ElementMapsUtil'

import EditSegmentGroupDetailFilterDialog from './EditSegmentGroupDetailFilterDialog'
import { PARENT } from '../context/AllInOne/consts'
import {
  Text,
} from '../visualization/dashboard/Dialogs/DialogStyles'
import TableComponent from '../visualization/dashboard/Plots/TablePlot/Table'
import TemporalDataHandler from '../visualization/dashboard/SimpleDashboard/temporalDataHandler'
import { Spinner } from '../visualization/PlotWrapper/EditBoxWrapper/styles'

const connector = connect((state: DefaultState) => ({
  elementHistoryDetailsInfo: state.application.main.elementHistoryDetailsInfo,
  temporalData: state.temporalData,
  currentProject: state.application.main.currentProject,
  ...getElementMapsObject(state),
}), {
  resetActiveDetailDialogFilter: ApplicationActions.resetActiveDetailDialogFilter,
  updateCurrentProjectAdditionalData: ApplicationActions.updateCurrentProjectAdditionalData,
  setTemporalData: TemporalDataActions.setTemporalData,
  closeDialog: ApplicationActions.closeDialog,
  openDialog: ApplicationActions.openDialog,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  t: Translation
}

type State = {
  loading: boolean
}

const StyledIcon = styled(FontAwesomeIcon)`
  margin-left: 5px;

  &:hover {
    cursor: pointer;
    color: white;
  }
`

class HistoryDetailDialog extends Component<Props, State> {
  @Identifiable('HistoryDetailDialog') public static readonly NAME: string

  public override state: State = {
    loading: true,
  }

  public override componentDidMount () {
    this.handleFetchData()
  }

  private readonly handleFetchData = async () => {
    const { elementHistoryDetailsInfo, setTemporalData, currentProject } = this.props

    if (!elementHistoryDetailsInfo) {
      this.setState({ loading: false })

      return
    }

    await TemporalDataHandler
      .getTemporalDataForSpecificFilter(setTemporalData, elementHistoryDetailsInfo.filter ?? '', currentProject.id)

    this.setState({ loading: false })
  }

  private readonly handleEditFilter = () => {
    this.props.openDialog(EditSegmentGroupDetailFilterDialog.NAME)
  }

  private readonly handleClose = () => {
    // this.props.resetActiveDetailDialogFilter()
    this.props.closeDialog(HistoryDetailDialog.NAME)
  }

  private readonly handleColumnVisibilityChange = (visibilityModel: GridColumnVisibilityModel) => {
    const { currentProject, elementHistoryDetailsInfo, updateCurrentProjectAdditionalData } = this.props
    const [ type ] = elementHistoryDetailsInfo?.filter?.split('#realDataUUID=') ?? []

    const elementType = FilterHandler.getFilterElementTypes(type ?? '')[0]?.toLowerCase()

    if (!elementType) {
      return
    }

    const currentElementTypeHiddenColumns = currentProject.additionalData[elementType]?.hiddenColumns ?? {}
    const keyIsHiddenMap: Record<string, boolean> = { ...currentElementTypeHiddenColumns }

    Object.entries(visibilityModel).forEach(([ key, isShown ]) => {
      keyIsHiddenMap[key] = !isShown
    })

    const updatedAdditionalData = {
      ...currentProject.additionalData,
      [elementType]: {
        ...currentProject.additionalData[elementType],
        hiddenColumns: { ...keyIsHiddenMap },
      },
    }

    updateProjectAdditionalData(currentProject.id, updatedAdditionalData)
    updateCurrentProjectAdditionalData(updatedAdditionalData)
  }

  private readonly handleSortModelChange = (sortingInfoArray: any[]) => {
    const { currentProject, elementHistoryDetailsInfo, updateCurrentProjectAdditionalData } = this.props
    const [ type ] = elementHistoryDetailsInfo?.filter?.split('#realDataUUID=') ?? []

    const elementType = FilterHandler.getFilterElementTypes(type ?? '')[0]?.toLowerCase()

    if (!elementType || isEqual(sortingInfoArray, currentProject.additionalData[elementType]?.sortingOfColumns)) {
      return
    }

    const updatedAdditionalData = {
      ...currentProject.additionalData,
      [elementType]: {
        ...currentProject.additionalData[elementType],
        sortingOfColumns: sortingInfoArray,
      },
    }

    updateProjectAdditionalData(currentProject.id, updatedAdditionalData)
    updateCurrentProjectAdditionalData(updatedAdditionalData)
  }

  public override render () {
    const { elementHistoryDetailsInfo, temporalData } = this.props
    const { loading } = this.state

    if (loading) {
      return (
        <BaseDialog
          title='title'
          icon='pe-7s-server'
          header='History Detail Dialog'
          headerWidth='500px'
          onClose={this.handleClose}
          contentMinHeight={400}
        >
          <Spinner disableShrink />
        </BaseDialog>
      )
    }

    const [ type, realDataUUID ] =
      (elementHistoryDetailsInfo?.filter?.split('#realDataUUID=') ?? []) as [TagName, string]
    
    if (!type || !realDataUUID) {
      return null
    }

    const elementType = (FilterHandler.getFilterElementTypes(type)[0] ?? '').toLowerCase()

    const elementMaps = getElementMapsObject(this.props)
    const temporalDataClone = cloneDeep(temporalData)

    for (const key in temporalDataClone) {
      if (!temporalDataClone[key]) {
        continue
      }

      for (let i = 0; i < temporalDataClone[key].length; i++) {
        if (!temporalDataClone[key][i]) {
          continue
        }

        const mountLog = temporalDataClone[key][i]
        const elementName = ElementMapsUtil.getElementName(type, PARENT[type] as TagName)

        temporalDataClone[key][i] = ElementMapsUtil.getFullCasterElementByMountLog(elementName, mountLog, elementMaps)

        if (!temporalDataClone[key][i]) {
          // eslint-disable-next-line no-console
          console.warn('Could not find element for mount log', mountLog)
          
          temporalDataClone[key][i] = mountLog

          continue
        }

        temporalDataClone[key][i].id = temporalDataClone[key][i].mountLogId
      }
    }

    return (
      <BaseDialog
        title='title'
        icon='pe-7s-server'
        header='History Detail Dialog'
        headerWidth='500px'
        onClose={this.handleClose}
        contentMinHeight={400}
        fullWidth
      >
        <Text $margin={20}>
          Filter: {elementHistoryDetailsInfo?.filter}
          <StyledIcon icon={faPencilAlt} onClick={this.handleEditFilter} />
        </Text>
        <div style={{ height: '700px' }}>
          <TableComponent
            realDataUUID={realDataUUID}
            temporalData={temporalDataClone}
            onColumnVisibilityChange={this.handleColumnVisibilityChange}
            onSortModelChange={this.handleSortModelChange}
            project={this.props.currentProject}
            elementType={elementType}
          />
        </div>
      </BaseDialog>
    )
  }
}

export default compose<any>(connector)(HistoryDetailDialog)
