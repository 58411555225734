import { type ChangeEventHandler, useMemo } from 'react'
import { ConnectedProps, connect } from 'react-redux'
import { compose } from 'redux'
import styled, { css } from 'styled-components'

import Util from '@/logic/Util'
import { TreeViewID } from '@/react/driver/DriverID'
import FeatureFlags from '@/react/FeatureFlags'
import DataActions from '@/store/data/actions'
import { getElementMapsObject } from '@/store/elements/logic'
import ThreeUtil from '@/three/logic/Util'
import type { CasterElementNames } from '@/types/data'
import type { DefaultState } from '@/types/state'
import { SetUtil } from '@/Util/SetUtil'

const ChildrenSelectorWrapper = styled.div<{ $checked: boolean }>`${({ $checked }) => css`
  display: ${$checked ? 'inline-flex' : 'none'};
  width: 25px;
  text-align: center;
  place-content: center;
`}`

const connector = connect(
  (state: DefaultState) => ({
    selectedPaths: state.data.selectedPaths,
    allPaths: state.data.allPaths,
    allChildrenSelectedPaths: state.data.allChildrenSelectedPaths,
    featureFlags: FeatureFlags.getRealFeatureFlags(state),
    ...getElementMapsObject(state),
  }),
  {
    setSelectedElementPaths: DataActions.setSelectedElementPaths,
  },
)

type Props = ConnectedProps<typeof connector> & {
  path: string
  selectType?: CasterElementNames
}

const ChildrenSelector: React.FC<Props> = (props: Props) => {
  const {
    path,
    selectType,
    allChildrenSelectedPaths,
    allPaths,
    selectedPaths,
    featureFlags,
    setSelectedElementPaths, 
  } = props
  const { type } = ThreeUtil.getElementInfo(path)
  const canViewAndUseSelectionForRealData = FeatureFlags.canViewAndUseSelectionForRealData(featureFlags)

  if (!canViewAndUseSelectionForRealData || type === 'SensorPoint' || selectType === 'SensorPoint') {
    return null
  }

  const selfAndChildPaths = useMemo(
    () => {
      const term = path + (selectType ? `/${selectType}:` : '/')
      const set = SetUtil.filter(allPaths, (path) => path.startsWith(term))

      if (!selectType) {
        set.add(path)
      }

      return set
    },
    [ allPaths, path, selectType ],
  )

  const pathsToSelect = useMemo(
    () => SetUtil.filter(selfAndChildPaths, (path) => !selectedPaths.has(path)),
    [ selfAndChildPaths, selectedPaths ],
  )

  const isChecked = allChildrenSelectedPaths.has(path + (selectType ? `/${selectType}-Selector` : ''))

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    event.stopPropagation()

    const paths = !isChecked ? pathsToSelect : selfAndChildPaths
    const elementMaps = getElementMapsObject(props)
    const { type, id } = ThreeUtil.getElementInfo(path)

    if (type === 'SegmentGroup' && !selectedPaths.has(path)) {
      const currentSegmentGroupStartPasslnCoord = Util.getCurrentSegmentGroupPasslnCoord(elementMaps, id)
      
      window.dispatchEvent(
        new CustomEvent(
          'CurrentSegmentGroupStartPasslnCoordChanged',
          { detail: { currentSegmentGroupStartPasslnCoord } },
        ),
      )
      ;(window as any).currentSegmentGroupStartPasslnCoord = currentSegmentGroupStartPasslnCoord
    }

    setSelectedElementPaths(Array.from(paths), true)
  }

  return (
    <ChildrenSelectorWrapper className={TreeViewID.ChildrenSelector} $checked={isChecked}>
      <input type='checkbox' onChange={handleChange} checked={isChecked} />
    </ChildrenSelectorWrapper>
  )
}

export default compose<any>(connector)(ChildrenSelector)
