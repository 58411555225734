import type { Translation } from '@/types/translation'

type SteelGradeSelector = {
  group?: string
  key: string
  value: string
}

export { default as UpdateCurrentSimulationCase } from './UpdateCurrentSimulationCase'

export function getSteelGradesSelectors (
  defaultSteelGradeList: any[],
  customSteelGradeList: any[],
  T: string,
  t: Translation,
): {
  defaultSteelGradesSelector: SteelGradeSelector[]
  customSteelGradesSelector: SteelGradeSelector[]
} {
  const defaultSteelGradesSelector = defaultSteelGradeList.map((preset, index) => ({
    group: t(`${T}.steelGrade.defaultGroup`),
    key: `default_${index}`,
    value: `D${index} | ${preset['Steel Grade Group']} | ${preset.Group} | C ${preset.C}`,
  }))

  const customSteelGradesSelector = customSteelGradeList.map((preset, index) => ({
    group: t(`${T}.steelGrade.customGroup`),
    key: `custom_${index}`,
    value: `C${index} | ${preset.Group} | C ${preset.C}`,
  }))

  return { defaultSteelGradesSelector, customSteelGradesSelector }
}

export function populateSteelGrades (defaultSteelGradeList: any[], customSteelGradeList: any[]) {
  const steelGrades: any = {}

  defaultSteelGradeList.forEach((stealGrade, i) => {
    steelGrades[`default_${i}`] = stealGrade
  })

  customSteelGradeList.forEach((stealGrade, i) => {
    steelGrades[`custom_${i}`] = stealGrade
  })

  return steelGrades
}
