import { Checkbox, FormControl, FormControlLabel, TextField, Slider } from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { Component } from 'react'
import NumericInput from 'react-numeric-input'
import styled, { css } from 'styled-components'

import { getPercentage } from '../components/Button/styles'
import DropDown, { Action } from '../visualization/dashboard/SelectPlot'

type InputType =
  | 'axesPadding'
  | 'checkbox'
  | 'datetime-local'
  | 'defaultRange'
  | 'number'
  | 'password'
  | 'range'
  | 'select'
  | 'selectRange'
  | 'slider'
  | 'text'

type InputWrapperProps = {
  $half?: boolean
  $oneThird?: boolean
  $twoThirds?: boolean
  $noMarginTop?: boolean
}

export const InputWrapper = styled.div<InputWrapperProps>`${({ theme, $half, $oneThird, $twoThirds, $noMarginTop }) =>
  css`
    display: inline-block;
    margin-top: ${$noMarginTop ? 0 : '17px'};
    white-space: nowrap;
    flex: 1;
    width: ${
  !($half || $oneThird || $twoThirds)
    ? '100%'
    : `calc(${getPercentage($half, $oneThird, $twoThirds)}% - 7px)!important`
};
    
    ${($half || $oneThird || $twoThirds) ? 'margin-right: 15px !important;' : ''}

    &:not(:last-of-type) {
      margin-right: 20px;
    }

    textarea,
    input {
      caret-color: ${theme['input'].color};
    }
  `}`

const CheckboxWrapper = styled.div`${({ theme }) =>
  css`
  display:      flex;
  align-items:  center;
  width:        100%;
  padding-top:  16px;
  
  label span {
    color: ${theme['input'].color};
  }
  
  &:not(:last-of-type) {
    margin-right: 20px;
  }
`}`

export const Label = styled.label`${({ theme }) =>
  css`
  display:          block;
  text-align:       left;
  color:            ${theme['input'].color};
  font-size:        14px;
  align-self:       center;
  flex:             1;
  overflow:         hidden;
  user-select:      none;
  margin-bottom:    10px;
`}`

type InputRangeProps = { $half?: boolean, $readOnly?: boolean, $unused?: boolean }

const InputRange = styled(TextField)<InputRangeProps>`${({ disabled, $half, $readOnly, $unused }) =>
  css`
  width: ${!$half ? '100%' : 'calc(50% - 7px)!important'};
  ${disabled || $readOnly ? 'cursor: not-allowed' : ''}

  input {
    text-align: right;
    ${$unused && 'color: #767a7d;'}
  }
`}`

const sliderTheme = createTheme({
  components: {
    MuiSlider: {
      styleOverrides: {
        root: {
          // Adjust the padding to accommodate a larger thumb
          padding: '20px 0',
        },
        track: {
          // Set the track color and make it thicker
          height: 8,
          backgroundColor: '#2a80b9',
        },
        rail: {
          // Set the rail color and make it thicker
          height: 8,
          backgroundColor: '#2a80b9',
        },
        thumb: {
          // Increase the thumb size and set its color
          height: 24,
          width: 24,
          backgroundColor: '#2a80b9',
        },
        mark: {
          // Set the color and size of the slider marks
          backgroundColor: 'white',
          height: 8,
          width: 2,
          // marginTop: -3,
        },
        markLabel: {
          // Adjust the position of the mark label
          top: 28,
        },
      },
    },
  },
})

// #2a80b9

type InputSliderProps = { $readOnly?: boolean | undefined, $unused?: boolean | undefined }

const InputSlider = styled(Slider)<InputSliderProps>`${({ disabled, $readOnly }) =>
  css`
  ${disabled || $readOnly ? 'cursor: not-allowed' : ''}
  margin-right: 20px;
`}`

const StyledSliderNumericInput = styled(NumericInput)`${({ theme }) =>
  css`
    width: 100px;
    background: ${theme['input'].background};
    color: ${theme['input'].color} !important;
    border-radius: 5px !important;
    text-shadow: none !important;
    padding-right: 5px;
    margin-left: 10px;
  `}`

const StyledNumericInput = styled(NumericInput)`${({ theme }) =>
  css`
    background: ${theme['input'].background};
    color: ${theme['input'].color} !important;
    border-radius: 5px !important;
    width: calc(100% - 9px);
    text-shadow: none !important;
  `}`

const StyledTextField = styled(TextField)<{ $half?: boolean, $readOnly?: boolean }>`${(
  { disabled, $half, $readOnly },
) =>
  css`
  width: ${!$half ? '100%' : 'calc(50% - 7px)!important'};
  ${disabled || $readOnly ? 'cursor: not-allowed' : ''}
`}`

const numericInputStyle = {
  wrap: {
    width: '100%',
  },
  'input:not(.form-control)': {
    border: 'none',
    font: 'inherit',
    height: '100%',
    margin: 0,
    padding: '10px 0 10px 10px',
    letterSpacing: 'inherit',
    boxSizing: 'content-box',
  },
  'input:focus': { outline: 'none' },
  btn: {
    border: 'none',
    outline: 'none',
    background: 'none',
    boxShadow: 'none',
  },
  'btn:hover': {
    background: 'none',
    border: 'none',
    cursor: 'pointer',
  },
  arrowDown: {
    borderColor: '#a2a6a9 transparent transparent',
  },
  arrowUp: {
    borderColor: 'transparent transparent #a2a6a9',
  },
}

// clone numericInputStyle but delete the wrap property
const numericInputStyleNoWrap = { ...numericInputStyle } as any

delete numericInputStyleNoWrap.wrap

const InWrapper = styled.span`
  position:  relative;
  line-height: 38px;
  
  :first-of-type {
    margin-right: 14px;
  }
`

const InLabel = styled.span`${({ theme }) =>
  css`
  position:    absolute;
  left:        10px;
  top:         50%;
  transform:   translate(0, -50%);
  color:       ${theme['input'].placeholder};
  user-select: none;
`}`

type OptionalProps = {
  secondName?: string
  value?: string[] | boolean | number | string
  min?: number
  max?: number
  step?: number
  precision?: number
  rows?: number
  label?: string
  placeholder?: string
  secondPlaceholder?: string
  title?: string
  titleMin?: string
  titleMax?: string
  half?: boolean
  oneThird?: boolean
  twoThirds?: boolean
  noMarginTop?: boolean
  selectors?: (Selector | boolean | number | string)[]
  onChange?: ((e: any) => void)
  onSliderChange?: ((name: string, value: any) => void)
  onLabelRightClick?: ((e: any) => void)
  inputRef?: any
  error?: any
  helperText?: string
  onDelete?: any
  onSetDefault?: ((key: string) => void)
  onDownload?: any
  onEdit?: any
  onKeyDown?: any
  disabled?: boolean
  noDeleteCurrent?: boolean
  noDeleteGiven?: any[]
  autoFocus?: any
  style?: any
  unused?: boolean
  id?: string
  readonly?: boolean
  className?: string
  multiple?: boolean
  marks?: boolean
  checkbox?: boolean
  selectedIds?: string[]
  actions?: Action[]
  onActionClick?: ((key: Action['key'], id: string) => void)
  optionStyles?: any
  spaceBetween?: boolean
  MenuProps?: any
  xAxisPadding?: number
  yAxisPadding?: number
  onCheckboxClick?: ((e: any, key: any) => void)
  renderValue?: (values: []) => string
}

// this type allows all optional Props to be undefined
type UndefinedProps = {
  [K in keyof OptionalProps]: OptionalProps[K] | undefined;
}

type Props = UndefinedProps & {
  name: string
  type: InputType
}

export default class InputComponent extends Component<Props> {
  private readonly getMinAndMax = () => {
    const { min, max } = this.props

    if (min !== undefined && max !== undefined) {
      return ` (${min} to ${max})`
    }
    else if (min !== undefined) {
      return ` (min: ${min})`
    }
    else if (max !== undefined) {
      return ` (max: ${max})`
    }

    return ''
  }

  public override render () {
    const {
      id,
      step,
      min,
      max,
      precision,
      rows,
      value,
      type,
      onChange,
      onSliderChange,
      onLabelRightClick,
      selectors,
      label,
      placeholder,
      name,
      secondName,
      secondPlaceholder,
      title,
      titleMin,
      titleMax,
      half,
      oneThird,
      twoThirds,
      noMarginTop,
      xAxisPadding,
      yAxisPadding,
      actions,
      onActionClick,
      unused,
      marks,
      ...restProps
    } = this.props as OptionalProps & { name: string, type: InputType }

    // const restProps = { ...rawRestProps } as Omit<Props, 'className'> & { className?: string }

    // if (!restProps.className) {
    //   delete restProps.className
    // }

    switch (type) {
      case 'select':
        return (
          <InputWrapper
            $noMarginTop={noMarginTop ?? false}
            title={title ?? ''}
            $half={half ?? false}
            $oneThird={oneThird ?? false}
            $twoThirds={twoThirds ?? false}
          >
            {label && <Label onContextMenu={onLabelRightClick}>{label}</Label>}
            <DropDown
              id={id}
              name={name ?? label ?? ''}
              value={value}
              selectors={selectors ?? []}
              onChange={onChange}
              actions={actions}
              onActionClick={onActionClick}
              {...restProps}
            />
          </InputWrapper>
        )
      case 'slider':
        return (
          <InputWrapper title={title ?? ''}>
            {label && <Label>{label}</Label>}
            <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
              <ThemeProvider theme={sliderTheme}>
                <InputSlider
                  name={name}
                  value={value as number}
                  min={1}
                  max={10}
                  step={0.5}
                  marks={marks ?? false}
                  onChange={(_e: any, value: any) => onSliderChange?.(name, value)}
                  {...restProps}
                />
              </ThemeProvider>
              <StyledSliderNumericInput
                value={value as any}
                min={1}
                max={10}
                step={0.5}
                name={name}
                style={numericInputStyleNoWrap}
                precision={precision}
                disabled={this.props.disabled}
                onChange={(value: any) => onChange?.({ target: { name, value } })}
                {...restProps}
              />
            </div>
          </InputWrapper>
        )

      case 'range':
        return (
          <InputWrapper title={title ?? ''}>
            {label && <Label>{label}</Label>}
            <div>
              <InWrapper>
                <InputRange
                  $half
                  name={`${name}Min`}
                  value={min}
                  title={titleMin}
                  placeholder='min'
                  onChange={onChange}
                  $unused={unused ?? false}
                  {...restProps}
                />
                <InLabel>min</InLabel>
              </InWrapper>
              <InWrapper>
                <InputRange
                  $half
                  name={`${name}Max`}
                  value={max}
                  title={titleMax}
                  placeholder='max'
                  $unused={unused ?? false}
                  onChange={onChange}
                  {...restProps}
                />
                <InLabel>max</InLabel>
              </InWrapper>
            </div>
          </InputWrapper>
        )
      case 'axesPadding':
        return (
          <InputWrapper title={title ?? ''}>
            {label && <Label>{label}</Label>}
            <div>
              <InWrapper>
                <InputRange
                  $half
                  name='xAxisPadding'
                  value={xAxisPadding}
                  title='X Axis Padding'
                  placeholder='X Axis Padding'
                  onChange={onChange}
                  {...restProps}
                />
                <InLabel>X Axis</InLabel>
              </InWrapper>
              <InWrapper>
                <InputRange
                  $half
                  name='yAxisPadding'
                  value={yAxisPadding}
                  title='Y Axis Padding'
                  placeholder='Y Axis Padding'
                  onChange={onChange}
                  {...restProps}
                />
                <InLabel>Y Axis</InLabel>
              </InWrapper>
            </div>
          </InputWrapper>
        )
      case 'selectRange':
        return (
          <InputWrapper title={title ?? ''}>
            {label && <Label>{label}</Label>}
            <div>
              <InWrapper>
                <DropDown
                  half
                  name={`${name}Min`}
                  title={titleMin}
                  value={min}
                  placeholder='min'
                  selectors={selectors ?? []}
                  onChange={onChange}
                  {...restProps}
                />
              </InWrapper>
              <InWrapper>
                <DropDown
                  half
                  name={`${name}Max`}
                  title={titleMax}
                  value={max}
                  placeholder='max'
                  onChange={onChange}
                  selectors={selectors ?? []}
                  {...restProps}
                />
              </InWrapper>
            </div>
          </InputWrapper>
        )
      case 'defaultRange':
        return (
          <InputWrapper title={title ?? ''}>
            {label && <Label>{label}</Label>}
            <div>
              <InWrapper>
                <InputRange
                  $half
                  name={`${name.charAt(0).toUpperCase()}${name.slice(1)}`}
                  title={titleMin}
                  value={min}
                  placeholder={placeholder ?? ''}
                  onChange={onChange}
                  {...restProps}
                />
                <InLabel>{`${name.charAt(0).toUpperCase()}${name.slice(1)}`}</InLabel>
              </InWrapper>
              <InWrapper>
                <InputRange
                  $half
                  name={`${(secondName ?? '').charAt(0).toUpperCase()}${(secondName ?? '').slice(1)}`}
                  title={titleMax}
                  value={max}
                  placeholder={secondPlaceholder ?? ''}
                  onChange={onChange}
                  {...restProps}
                />
                <InLabel>{`${(secondName ?? '').charAt(0).toUpperCase()}${(secondName ?? '').slice(1)}`}</InLabel>
              </InWrapper>
            </div>
          </InputWrapper>
        )
      case 'checkbox':
        return (
          <CheckboxWrapper>
            <FormControlLabel
              control={
                <Checkbox
                  id={id}
                  onChange={onChange}
                  color='default'
                  checked={typeof value === 'string' ? value === 'true' : Boolean(value)}
                  name={name ?? label ?? ''}
                />
              }
              label={title}
              {...restProps}
            />
          </CheckboxWrapper>
        )
      case 'number':
        return (
          <InputWrapper title={title ?? ''} $noMarginTop={noMarginTop ?? false}>
            {label && <Label>{label}{this.getMinAndMax()}</Label>}
            <FormControl style={{ justifyContent: 'center', padding: '0 1px 0 0' }}>
              <StyledNumericInput
                value={value as any}
                min={min}
                max={max}
                step={step}
                name={name}
                style={numericInputStyle}
                precision={precision}
                disabled={this.props.disabled}
                onChange={(value: any) => onChange ? onChange({ target: { name, value } }) : undefined}
                {...restProps}
              />
            </FormControl>
          </InputWrapper>
        )
      default:
        return (
          <InputWrapper title={title ?? ''} $half={half ?? false}>
            {label && <Label>{label}</Label>}
            <StyledTextField
              id={id ?? ''}
              type={type}
              name={name ?? label ?? ''}
              value={value ?? ''}
              placeholder={placeholder ?? label ?? ''}
              onChange={onChange}
              multiline={Boolean(rows)}
              minRows={rows ?? 0}
              maxRows={rows ?? 0}
              inputProps={
                {
                  min,
                  max,
                  step,
                }
              }
              {...restProps}
            />
          </InputWrapper>
        )
    }
  }
}
