/**
 * Memoization decorator (experimental, use with caution)
 */
export function Memoize () {
  const cache: Record<string, unknown> = {}

  return function (_target: any, _propertyKey: string, descriptor: PropertyDescriptor) {
    const originalMethod = descriptor.value

    descriptor.value = function (...args: any[]) {
      const key = JSON.stringify(args)

      if (!cache[key]) {
        cache[key] = originalMethod.apply(this, args)
      }

      return cache[key]
    }
  }
}
