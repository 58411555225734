import * as THREE from 'three'

import SectionView from '.'
import CalculationUtil from './CalculationUtil'

export default class CameraHandlers {
  public static setZoom (view: SectionView) {
    // sets zoom on section, when expanded
    if (!view.sectionPlaneFolded || !view.camera) {
      return
    }

    view.sectionPlaneFolded.geometry.computeBoundingBox()

    if (!view.sectionPlaneFolded.geometry.boundingBox) {
      // eslint-disable-next-line no-console
      console.warn('No bounding box for section plane')

      return
    }

    const { width, height } = view.viewport
    const { max, min } = view.sectionPlaneFolded.geometry.boundingBox

    view.camera.zoom = CalculationUtil.calcCameraZoom(height, width, min, max)
  }

  public static updateCamera (viewport: ViewPort, camera: THREE.OrthographicCamera) {
    const scale = 0.01 / (viewport.width / viewport.height)

    camera.left = viewport.width / -2 * scale
    camera.right = viewport.width / 2 * scale
    camera.top = viewport.height / 2 * scale
    camera.bottom = viewport.height / -2 * scale
    camera.updateProjectionMatrix()
  }
}
