import { DataGrid, GridColumnVisibilityModel, GridSortItem } from '@mui/x-data-grid'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import type { TileConfig } from '@/types/visualization'

import { getAllKeysFromMountLogs } from './TablePlotUtil'

type Props = {
  temporalData: TemporalDataState
  realDataUUID: string
  tileConfig?: TileConfig
  onColumnVisibilityChange?: (params: GridColumnVisibilityModel) => void
  onSortModelChange?: (sortingInfoArray: GridSortItem[], details?: any) => void
  project?: Project
  elementType?: string
}

const StyledNoDataContainer = styled.div`${({ theme }) =>
  `
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    color: ${theme['mainFontColor']};
    font-size: 1.5rem;
    font-weight: 500;
  `}`

const TableComponent = ({
  temporalData,
  realDataUUID,
  tileConfig,
  onColumnVisibilityChange,
  onSortModelChange,
  project,
  elementType,
}: Props) => {
  const { t } = useTranslation('visualization')
  const data = temporalData[realDataUUID]

  if (!data?.length) {
    return (
      <StyledNoDataContainer>
        {t('table.noData')}
      </StyledNoDataContainer>
    )
  }

  const getInfo = () => {
    if (project && elementType) {
      return project.additionalData[elementType] ?? {} as TileConfig
    }

    return tileConfig ?? {} as TileConfig
  }

  const { hiddenColumns = {}, sortingOfColumns = [] } = getInfo()
  const keys = getAllKeysFromMountLogs(data)

  const columns = keys.map(key => ({
    field: key,
    headerName: key,
    width: 150,
    hide: Boolean(hiddenColumns[key]),
    valueFormatter: (value: unknown) => {
      if (typeof value === 'number') {
        return value.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 3 })
      }
      else if (typeof value === 'object') {
        return JSON.stringify(value)
      }

      return value
    },
  }))

  const rows = data.map((row, index) => {
    const mountLogInfo = {
      ...row,
      ...row?.additionalData,
    }

    delete mountLogInfo.additionalData

    return {
      id: index,
      ...mountLogInfo,
    }
  })

  const columnVisibilityModel: Record<string, boolean> = Object.fromEntries(
    columns.map(column => [ column.field, !column.hide ]),
  )

  return (
    <div style={{ height: '100%' }}>
      <DataGrid
        sx={
          {
            '.MuiDataGrid-columnHeaders': {
              div: {
                background: 'none !important',
              },
            },
            '& .MuiTablePagination-root': {
              width: '100%',
            },

            '& .MuiTablePagination-input': {
              width: '100px',
            },
          }
        }
        rows={rows}
        columns={columns}
        // disableColumnSelector
        disableRowSelectionOnClick
        // pageSize={10}
        // checkboxSelection
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={onColumnVisibilityChange ?? (() => {})}
        sortModel={sortingOfColumns}
        onSortModelChange={onSortModelChange ?? (() => {})}
      />
    </div>
  )
}

export default TableComponent
