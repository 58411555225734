import { Component } from 'react'

import Button from '@/react/components/Button'
import type { ArrayOfTranslations, Translation } from '@/types/translation'

import { submitButtonColor, submitButtonColorHover } from './index'
import ParameterComponent from './Parameter'

interface Props {
  stepIndex: number
  step: Step
  caseValues: Record<string, any>
  dynamicOptions: Record<string, Option[]>
  featureFlags: Record<string, boolean>
  handleInputChange: (event: Event & { target: HTMLInputElement }, stepNumber?: number) => void
  loading: boolean
  t: ArrayOfTranslations & Translation
  handleUploadFile: (id: string, stepId?: string) => void
  expanded: boolean
  handleExpandStep?: (index: number) => void
  handleRunStep: (stepId: string) => void
  valid: boolean
  disabled: boolean
  lastStep: boolean
  getParameterByRef: (ref: string) => any
  stepDefinitionId: string
  executionSteps: ExecutionStepEntity[] | null
  executionStepId?: string | undefined
  uploadingFileParameterId: string
}

class StepComponent extends Component<Props> {
  private readonly handleToggleStep = () => {
    const { handleExpandStep, stepIndex } = this.props

    if (handleExpandStep) {
      handleExpandStep(stepIndex)
    }
  }

  private readonly onInputChange = (e: any) => {
    const { handleInputChange, stepIndex } = this.props

    handleInputChange(e, stepIndex)
  }

  private readonly onFileUpload = (id: string) => {
    const { handleUploadFile, stepDefinitionId } = this.props

    handleUploadFile(id, stepDefinitionId)
  }

  private readonly getParameterComponentByRef = (ref: string) => {
    const { 
      getParameterByRef,
      caseValues,
      featureFlags,
      loading,
      t,
      dynamicOptions,
      executionSteps,
      executionStepId, 
      uploadingFileParameterId,
    } = this.props
    const parameter = getParameterByRef(ref)

    if (!parameter) {
      return null
    }

    return (
      <ParameterComponent
        caseValues={caseValues}
        dynamicOptions={dynamicOptions}
        featureFlags={featureFlags ?? {}}
        loading={loading}
        parameter={parameter}
        t={t}
        handleInputChange={this.onInputChange}
        handleUploadFile={this.onFileUpload}
        disabled
        executionSteps={executionSteps}
        executionStepId={executionStepId}
        uploadingFileParameterId={uploadingFileParameterId}
      />
    )
  }

  public override render () {
    const {
      step,
      stepIndex,
      dynamicOptions,
      caseValues,
      loading,
      t,
      featureFlags,
      expanded,
      valid,
      handleRunStep,
      handleExpandStep,
      disabled,
      lastStep,
      uploadingFileParameterId,
    } = this.props
    const { parameters = [], submitButtonLabel } = step
    const submitLabel = submitButtonLabel ?? `Run Step ${stepIndex}`

    return (
      <div>
        <div
          onClick={this.handleToggleStep}
          style={
            {
              color: '#a2a6a9',
              fontSize: '20px',
              marginTop: '20px',
              cursor: handleExpandStep ? 'pointer' : 'default',
            }
          }
        >
          {handleExpandStep ? (expanded ? <span>&#9662;</span> : <span>&#9656;</span>) : null}
          <span style={{ marginLeft: '5px' }}>{step.name || `Step ${stepIndex}`}</span>
        </div>
        {
          expanded &&
          parameters?.filter(param => !param.hidden).map((param, index) => (
            <div key={`step${stepIndex}-parameter${index}`}>
              {
                param
                  .ref
                  ? this
                    .getParameterComponentByRef(
                      param
                        .ref,
                    )
                  : (
                    <ParameterComponent
                      caseValues={caseValues}
                      dynamicOptions={dynamicOptions}
                      featureFlags={featureFlags ?? {}}
                      loading={loading}
                      parameter={param}
                      t={t}
                      handleInputChange={this.onInputChange}
                      handleUploadFile={this.onFileUpload}
                      disabled={disabled}
                      executionSteps={this.props.executionSteps}
                      executionStepId={this.props.executionStepId}
                      uploadingFileParameterId={uploadingFileParameterId}
                    />
                  )
              }
            </div>
          ))
        }
        {
          !lastStep && (
            <Button
              overrideColor={submitButtonColor}
              overrideHoverColor={submitButtonColorHover}
              title={submitLabel}
              type='primary'
              disabled={
                disabled ||
              loading ||
              !valid
              }
              onClick={() => handleRunStep(this.props.stepDefinitionId)}
              loading={loading}
            >
              {submitLabel}
            </Button>
          )
        }
      </div>
    )
  }
}

export default StepComponent
