import { request } from '.'

const PATH = 'segment-groups'

export const editSegmentGroupMountLogElementHistoryDetailFilter = (mountLogId: string, newFilter: string) => {
  const errorText = 'Failed to update filter'
  const fullPath = `${PATH}/${mountLogId}/edit-history-detail-filter`

  return request<SegmentGroupMountLog | null>(
    'patch',
    fullPath,
    errorText,
    { data: { segmentGroupMountLogId: mountLogId, newFilter } },
  )
}
