import { filterActions } from 'redux-ignore'

import ErrorActions from './consts'

const initialState = {}

// eslint-disable-next-line @typescript-eslint/ban-types
const actionHandlers: Record<string, Function | undefined> = {
  [ErrorActions.SET_ERROR]: (state: ErrorState, { actionName, error }: { actionName?: string, error?: string }) => {
    if (actionName) {
      return {
        ...state,
        [actionName]: error,
      }
    }
    else {
      return {
        ...state,
      }
    }
  },

  [ErrorActions.RESET_ERROR]: () => ({
    ...initialState,
  }),
}

function errorReducers (
  state: ErrorState = initialState,
  action: { type: ErrorActions, actionName?: string, error?: string },
) {
  const handler = actionHandlers[action.type]

  return handler ? handler(state, action) : state
}

export default filterActions(errorReducers, [
  ErrorActions.SET_ERROR,
  ErrorActions.RESET_ERROR,
]) as typeof errorReducers
