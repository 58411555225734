import cloneDeep from 'lodash/cloneDeep'

import type { CasterElementNames, DrawableCasterElementTypes } from '@/types/data'
import type { ElementMaps, MountLogMapKey, TagName } from '@/types/state'

import { ElementMapsUtil } from './ElementMapsUtil'
import { Mapping } from './mapping/Mapping'

export class ElementsUtil {
  private static getElementMountLogMapByParentMountLogs<P extends BaseMountLog, E extends BaseMountLog> (
    elementMaps: ElementMaps,
    parentMountLogs: P[],
    elementMountLogsKey: keyof P,
    elementMapsKey: keyof ElementMaps,
  ): Record<string, E> {
    const result = {} as Record<string, E>

    if (!elementMaps[elementMapsKey]) {
      return result
    }

    for (const parentMountLog of parentMountLogs) {
      const elementMountLogIds = (parentMountLog[elementMountLogsKey] ?? []) as string[]

      for (const elementMountLogId of elementMountLogIds) {
        const elementMountLog = (elementMaps[elementMapsKey] as unknown as Record<string, E>)[elementMountLogId] as E

        if (elementMountLog) {
          result[elementMountLogId] = elementMountLog
        }
      }
    }

    return result
  }

  public static getStrandGuideMountLog (elementMaps: ElementMaps): StrandGuideMountLog | null {
    const { StrandGuideMountLog, Caster } = elementMaps

    if (!StrandGuideMountLog || !Caster) {
      return null
    }

    return Object.values(StrandGuideMountLog).find(({ casterId }) => casterId === Caster.id) ?? null
  }

  /**
   * @deprecated use Object.values(elementMaps.SegmentGroupMountLog) instead
   */
  public static getSegmentGroupMountLogs (elementMaps: ElementMaps): SegmentGroupMountLogMap {
    return elementMaps.SegmentGroupMountLog ?? {}
  }

  public static getSupportPointMountLogMapBySegmentGroupMountLogs (
    elementMaps: ElementMaps,
    segmentGroupMountLogs: SegmentGroupMountLog[],
  ): SupportPointMountLogMap {
    return ElementsUtil.getElementMountLogMapByParentMountLogs(
      elementMaps,
      segmentGroupMountLogs,
      'supportPointMountLogs',
      'SupportPointMountLog',
    )
  }

  /**
   * @deprecated use Object.values(elementMaps.SupportPointMountLog) instead
   */
  public static getSupportPointMountLogsByDate (
    elementMaps: ElementMaps,
    supportPointMountLogIdsHash: Record<string, boolean>,
    date?: Date,
  ): SupportPointMountLog[] {
    const { SupportPointMountLog } = elementMaps

    if (!SupportPointMountLog || !date) {
      return []
    }

    return Object
      .values(SupportPointMountLog)
      .filter(supportPointMountLog => {
        const mountedAt = new Date(supportPointMountLog.mountedAt ?? 0)
        const removedAt = supportPointMountLog.removedAt !== null ? new Date(supportPointMountLog.removedAt) : null

        return (
          supportPointMountLogIdsHash[supportPointMountLog.id] &&
          mountedAt <= date &&
          (removedAt === null || removedAt > date)
        )
      })
  }

  public static getSegmentMountLogMapBySegmentGroupMountLogs (
    elementMaps: ElementMaps,
    segmentGroupMountLogs: SegmentGroupMountLog[],
  ): SegmentMountLogMap {
    return ElementsUtil.getElementMountLogMapByParentMountLogs(
      elementMaps,
      segmentGroupMountLogs,
      'segmentMountLogs',
      'SegmentMountLog',
    )
  }

  /**
   * @deprecated use Object.values(elementMaps.SegmentMountLog) instead
   */
  public static getSegmentMountLogsByDate (
    elementMaps: ElementMaps,
    segmentMountLogIdsHash: Record<string, boolean>,
    date?: Date,
  ): SegmentMountLog[] {
    const { SegmentMountLog } = elementMaps

    if (!SegmentMountLog || !date) {
      return []
    }

    return Object
      .values(SegmentMountLog)
      .filter(segmentMountLog => {
        const mountedAt = new Date(segmentMountLog.mountedAt ?? 0)
        const removedAt = segmentMountLog.removedAt !== null ? new Date(segmentMountLog.removedAt) : null

        return (
          segmentMountLogIdsHash[segmentMountLog.id] &&
          mountedAt <= date &&
          (removedAt === null || removedAt > date)
        )
      })
  }

  /**
   * @deprecated
   */
  public static getPasslineSectionsByDate (
    elementMaps: ElementMaps,
    date?: Date,
  ): FullCasterElement<PasslineSectionSlot, PasslineSectionMountLog>[] {
    const { PasslineMountLog, PasslineSectionMountLog, PasslineSectionSlot, Caster } = elementMaps

    if (
      !Caster ||
      !Object.keys(PasslineMountLog) ||
      !Object.keys(PasslineSectionMountLog) ||
      !Object.keys(PasslineSectionSlot) ||
      !date
    ) {
      return []
    }

    const passlineMountLog = Object.values(PasslineMountLog).find(passlineMountLog =>
      passlineMountLog.casterId === Caster.id
    )

    if (!passlineMountLog) {
      return []
    }

    const fullPasslineSections = []

    for (const passlineSectionMountLogId of passlineMountLog.passlineSectionMountLogs) {
      const passlineSectionMountLog = PasslineSectionMountLog[passlineSectionMountLogId]
      const passlineSectionSlot = PasslineSectionSlot[passlineSectionMountLog?.slotId ?? '']

      if (!passlineSectionMountLog || !passlineSectionSlot) {
        continue
      }

      fullPasslineSections.push(ElementMapsUtil.getFullCasterElement(passlineSectionSlot, passlineSectionMountLog, -1))
    }

    fullPasslineSections.sort((a, b) => (a.passlineCoord ?? 0) - (b.passlineCoord ?? 0))

    return fullPasslineSections
  }

  private static getMoldMountLog (elementMaps: ElementMaps): MoldMountLog | null {
    const { MoldMountLog, Caster } = elementMaps

    if (!Caster || !MoldMountLog) {
      return null
    }

    return Object.values(MoldMountLog).find(({ casterId }) => casterId === Caster.id) ?? null
  }

  public static getMoldAndMoldMountLogByDate (
    elementMaps: ElementMaps,
  ): { moldSlot: MoldSlot | null, moldMountLog: MoldMountLog | null } {
    const { MoldSlot } = elementMaps

    if (!MoldSlot) {
      return {
        moldSlot: null,
        moldMountLog: null,
      }
    }

    const moldMountLog = ElementsUtil.getMoldMountLog(elementMaps)

    if (!moldMountLog) {
      return {
        moldSlot: null,
        moldMountLog: null,
      }
    }

    return {
      moldSlot: MoldSlot[moldMountLog.slotId] ?? null,
      moldMountLog,
    }
  }

  public static getMoldFaceMountLogsByMoldMountLogId = (
    elementMaps: ElementMaps,
    moldMountLogId: string,
  ): MoldFaceMountLog[] => {
    const { MoldFaceMountLog } = elementMaps

    if (!MoldFaceMountLog) {
      return []
    }

    return Object.values(MoldFaceMountLog).filter(moldFaceMountLog =>
      moldFaceMountLog.moldMountLogId === moldMountLogId
    )
  }

  public static getNozzleMountLogMapBySegmentMountLogs (
    elementMaps: ElementMaps,
    segmentMountLogs: SegmentMountLog[],
  ): NozzleMountLogMap {
    return ElementsUtil.getElementMountLogMapByParentMountLogs(
      elementMaps,
      segmentMountLogs,
      'nozzleMountLogs',
      'NozzleMountLog',
    )
  }

  /**
   * @deprecated use Object.values(elementMaps.NozzleMountLog) instead
   */
  public static getNozzleMountLogsByDate (
    elementMaps: ElementMaps,
    nozzleMountLogIdsHash: Record<string, boolean>,
    date?: Date,
  ): NozzleMountLog[] {
    const { NozzleMountLog } = elementMaps

    if (!NozzleMountLog || !date) {
      return []
    }

    return Object
      .values(NozzleMountLog)
      .filter(nozzleMountLog => {
        const mountedAt = new Date(nozzleMountLog.mountedAt ?? 0)
        const removedAt = nozzleMountLog.removedAt !== null ? new Date(nozzleMountLog.removedAt) : null

        return (
          nozzleMountLogIdsHash[nozzleMountLog.id] &&
          mountedAt <= date &&
          (removedAt === null || removedAt > date)
        )
      })
  }

  public static getRollerMountLogMapBySegmentMountLogs (
    elementMaps: ElementMaps,
    segmentMountLogs: SegmentMountLog[],
  ): RollerMountLogMap {
    return ElementsUtil.getElementMountLogMapByParentMountLogs(
      elementMaps,
      segmentMountLogs,
      'rollerMountLogs',
      'RollerMountLog',
    )
  }

  /**
   * @deprecated use Object.values(elementMaps.RollerMountLog) instead
   */
  public static getRollerMountLogsByDate (
    elementMaps: ElementMaps,
    rollerMountLogIdsHash: Record<string, boolean>,
    date?: Date,
  ): RollerMountLog[] {
    const { RollerMountLog } = elementMaps

    if (!RollerMountLog || !date) {
      return []
    }

    return Object
      .values(RollerMountLog)
      .filter(rollerMountLog => {
        const mountedAt = new Date(rollerMountLog.mountedAt ?? 0)
        const removedAt = rollerMountLog.removedAt !== null ? new Date(rollerMountLog.removedAt) : null

        return (
          rollerMountLogIdsHash[rollerMountLog.id] &&
          mountedAt <= date &&
          (removedAt === null || removedAt > date)
        )
      })
  }

  public static getRollerBodyMountLogMapByRollerMountLogs (
    elementMaps: ElementMaps,
    rollerMountLogs: RollerMountLog[],
  ): RollerBodyMountLogMap {
    return ElementsUtil.getElementMountLogMapByParentMountLogs(
      elementMaps,
      rollerMountLogs,
      'rollerBodyMountLogs',
      'RollerBodyMountLog',
    )
  }

  /**
   * @deprecated use Object.values(elementMaps.RollerBodyMountLog) instead
   */
  public static getRollerBodyMountLogsByDate (
    elementMaps: ElementMaps,
    rollerBodyMountLogIdsHash: Record<string, boolean>,
    date?: Date,
  ): RollerBodyMountLog[] {
    const { RollerBodyMountLog } = elementMaps

    if (!RollerBodyMountLog || !date) {
      return []
    }

    return Object
      .values(RollerBodyMountLog)
      .filter(rollerBodyMountLog => {
        const mountedAt = new Date(rollerBodyMountLog.mountedAt ?? 0)
        const removedAt = rollerBodyMountLog.removedAt !== null ? new Date(rollerBodyMountLog.removedAt) : null

        return (
          rollerBodyMountLogIdsHash[rollerBodyMountLog.id] &&
          mountedAt <= date &&
          (removedAt === null || removedAt > date)
        )
      })
  }

  public static getRollerBearingMountLogMapByRollerMountLogs (
    elementMaps: ElementMaps,
    rollerMountLogs: RollerMountLog[],
  ): RollerBearingMountLogMap {
    return ElementsUtil.getElementMountLogMapByParentMountLogs(
      elementMaps,
      rollerMountLogs,
      'rollerBearingMountLogs',
      'RollerBearingMountLog',
    )
  }

  /**
   * @deprecated use Object.values(elementMaps.RollerBearingMountLog) instead
   */
  public static getRollerBearingMountLogsByDate (
    elementMaps: ElementMaps,
    rollerBearingMountLogIdsHash: Record<string, boolean>,
    date?: Date,
  ): RollerBearingMountLog[] {
    const { RollerBearingMountLog } = elementMaps

    if (!RollerBearingMountLog || !date) {
      return []
    }

    return Object
      .values(RollerBearingMountLog)
      .filter(rollerBearingMountLog => {
        const mountedAt = new Date(rollerBearingMountLog.mountedAt ?? 0)
        const removedAt = rollerBearingMountLog.removedAt !== null ? new Date(rollerBearingMountLog.removedAt) : null

        return (
          rollerBearingMountLogIdsHash[rollerBearingMountLog.id] &&
          mountedAt <= date &&
          (removedAt === null || removedAt > date)
        )
      })
  }

  /**
   * @deprecated use Object.values(elementMaps.RollerDataPointMountLog) instead
   */
  public static getRollerDataPointMountLogsByDate (
    elementMaps: ElementMaps,
    rollerDataPointMountLogIdsHash: Record<string, boolean>,
    date?: Date,
  ): RollerDataPointMountLog[] {
    const { RollerDataPointMountLog } = elementMaps

    if (!RollerDataPointMountLog || !date) {
      return []
    }

    return Object
      .values(RollerDataPointMountLog)
      .filter(rollerDataPointMountLog => {
        const mountedAt = new Date(rollerDataPointMountLog.mountedAt ?? 0)
        const removedAt = rollerDataPointMountLog.removedAt !== null
          ? new Date(rollerDataPointMountLog.removedAt)
          : null

        return (
          rollerDataPointMountLogIdsHash[rollerDataPointMountLog.id] &&
          mountedAt <= date &&
          (removedAt === null || removedAt > date)
        )
      })
  }

  public static getRollerDataPointMountLogMapByRollerMountLogs (
    elementMaps: ElementMaps,
    rollerMountLogs: RollerMountLog[],
  ): RollerDataPointMountLogMap {
    return ElementsUtil.getElementMountLogMapByParentMountLogs(
      elementMaps,
      rollerMountLogs,
      'dataPointMountLogs',
      'RollerDataPointMountLog',
    )
  }

  /**
   * @deprecated use Object.values(elementMaps.RollerBodyDataPointMountLog) instead
   */
  public static getRollerBodyDataPointMountLogsByDate (
    elementMaps: ElementMaps,
    rollerBodyDataPointMountLogIdsHash: Record<string, boolean>,
    date?: Date,
  ): RollerBodyDataPointMountLog[] {
    const { RollerBodyDataPointMountLog } = elementMaps

    if (!RollerBodyDataPointMountLog || !date) {
      return []
    }

    return Object
      .values(RollerBodyDataPointMountLog)
      .filter(rollerBodyDataPointMountLog => {
        const mountedAt = new Date(rollerBodyDataPointMountLog.mountedAt ?? 0)
        const removedAt = rollerBodyDataPointMountLog.removedAt !== null
          ? new Date(rollerBodyDataPointMountLog.removedAt)
          : null

        return (
          rollerBodyDataPointMountLogIdsHash[rollerBodyDataPointMountLog.id] &&
          mountedAt <= date &&
          (removedAt === null || removedAt > date)
        )
      })
  }

  public static getRollerBodyDataPointMountLogMapByRollerBodyMountLogs (
    elementMaps: ElementMaps,
    rollerBodyMountLogs: RollerBodyMountLog[],
  ): RollerBodyDataPointMountLogMap {
    return ElementsUtil.getElementMountLogMapByParentMountLogs(
      elementMaps,
      rollerBodyMountLogs,
      'dataPointMountLogs',
      'RollerBodyDataPointMountLog',
    )
  }

  /**
   * @deprecated use Object.values(elementMaps.SegmentDataPointMountLog) instead
   */
  public static getSegmentDataPointMountLogsByDate (
    elementMaps: ElementMaps,
    segmentDataPointMountLogIdsHash: Record<string, boolean>,
    date?: Date,
  ): SegmentDataPointMountLog[] {
    const { SegmentDataPointMountLog } = elementMaps

    if (!SegmentDataPointMountLog || !date) {
      return []
    }

    return Object
      .values(SegmentDataPointMountLog)
      .filter(segmentDataPointMountLog => {
        const mountedAt = new Date(segmentDataPointMountLog.mountedAt ?? 0)
        const removedAt = segmentDataPointMountLog.removedAt !== null
          ? new Date(segmentDataPointMountLog.removedAt)
          : null

        return (
          segmentDataPointMountLogIdsHash[segmentDataPointMountLog.id] &&
          mountedAt <= date &&
          (removedAt === null || removedAt > date)
        )
      })
  }

  public static getSegmentDataPointMountLogMapBySegmentMountLogs (
    elementMaps: ElementMaps,
    segmentMountLogs: SegmentMountLog[],
  ): SegmentDataPointMountLogMap {
    return ElementsUtil.getElementMountLogMapByParentMountLogs(
      elementMaps,
      segmentMountLogs,
      'dataPointMountLogs',
      'SegmentDataPointMountLog',
    )
  }

  /**
   * @deprecated use Object.values(elementMaps.RollerSensorPointMountLog) instead
   */
  public static getRollerSensorPointMountLogsByDate (
    elementMaps: ElementMaps,
    rollerSensorPointMountLogIdsHash: Record<string, boolean>,
    date?: Date,
  ): RollerSensorPointMountLog[] {
    const { RollerSensorPointMountLog } = elementMaps

    if (!RollerSensorPointMountLog || !date) {
      return []
    }

    return Object
      .values(RollerSensorPointMountLog)
      .filter(rollerSensorPointMountLog => {
        const mountedAt = new Date(rollerSensorPointMountLog.mountedAt ?? 0)
        const removedAt = rollerSensorPointMountLog.removedAt !== null
          ? new Date(rollerSensorPointMountLog.removedAt)
          : null

        return (
          rollerSensorPointMountLogIdsHash[rollerSensorPointMountLog.id] &&
          mountedAt <= date &&
          (removedAt === null || removedAt > date)
        )
      })
  }

  public static getRollerSensorPointMountLogMapByRollerMountLogs (
    elementMaps: ElementMaps,
    rollerMountLogs: RollerMountLog[],
  ): RollerSensorPointMountLogMap {
    return ElementsUtil.getElementMountLogMapByParentMountLogs(
      elementMaps,
      rollerMountLogs,
      'sensorPointMountLogs',
      'RollerSensorPointMountLog',
    )
  }

  /**
   * @deprecated use Object.values(elementMaps.RollerBodySensorPointMountLog) instead
   */
  public static getRollerBodySensorPointMountLogsByDate (
    elementMaps: ElementMaps,
    rollerBodySensorPointMountLogIdsHash: Record<string, boolean>,
    date?: Date,
  ): RollerBodySensorPointMountLog[] {
    const { RollerBodySensorPointMountLog } = elementMaps

    if (!RollerBodySensorPointMountLog || !date) {
      return []
    }

    return Object
      .values(RollerBodySensorPointMountLog)
      .filter(rollerBodySensorPointMountLog => {
        const mountedAt = new Date(rollerBodySensorPointMountLog.mountedAt ?? 0)
        const removedAt = rollerBodySensorPointMountLog.removedAt !== null
          ? new Date(rollerBodySensorPointMountLog.removedAt)
          : null

        return (
          rollerBodySensorPointMountLogIdsHash[rollerBodySensorPointMountLog.id] &&
          mountedAt <= date &&
          (removedAt === null || removedAt > date)
        )
      })
  }

  public static getRollerBodySensorPointMountLogMapByRollerBodyMountLogs (
    elementMaps: ElementMaps,
    rollerBodyMountLogs: RollerBodyMountLog[],
  ): RollerBodySensorPointMountLogMap {
    return ElementsUtil.getElementMountLogMapByParentMountLogs(
      elementMaps,
      rollerBodyMountLogs,
      'sensorPointMountLogs',
      'RollerBodySensorPointMountLog',
    )
  }

  /**
   * @deprecated use Object.values(elementMaps.SegmentSensorPointMountLog) instead
   */
  public static getSegmentSensorPointMountLogsByDate (
    elementMaps: ElementMaps,
    segmentSensorPointMountLogIdsHash: Record<string, boolean>,
    date?: Date,
  ): SegmentSensorPointMountLog[] {
    const { SegmentSensorPointMountLog } = elementMaps

    if (!SegmentSensorPointMountLog || !date) {
      return []
    }

    return Object
      .values(SegmentSensorPointMountLog)
      .filter(segmentSensorPointMountLog => {
        const mountedAt = new Date(segmentSensorPointMountLog.mountedAt ?? 0)
        const removedAt = segmentSensorPointMountLog.removedAt !== null
          ? new Date(segmentSensorPointMountLog.removedAt)
          : null

        return (
          segmentSensorPointMountLogIdsHash[segmentSensorPointMountLog.id] &&
          mountedAt <= date &&
          (removedAt === null || removedAt > date)
        )
      })
  }

  public static getSegmentSensorPointMountLogMapBySegmentMountLogs (
    elementMaps: ElementMaps,
    segmentMountLogs: SegmentMountLog[],
  ): SegmentSensorPointMountLogMap {
    return ElementsUtil.getElementMountLogMapByParentMountLogs(
      elementMaps,
      segmentMountLogs,
      'sensorPointMountLogs',
      'SegmentSensorPointMountLog',
    )
  }

  /**
   * @deprecated use Object.values(elementMaps.DataLineMountLog) instead
   */
  public static getDataLineMountLogs (elementMaps: ElementMaps): DataLineMountLog[] {
    const { DataLineMountLog, Caster } = elementMaps

    if (!DataLineMountLog || !Caster) {
      return []
    }

    return Object.values(DataLineMountLog).filter(({ casterId }) => casterId === Caster.id)
  }

  public static getDrawableElementPaths (elementMaps: ElementMaps): Record<DrawableCasterElementTypes, string[]> {
    const paths: Record<DrawableCasterElementTypes, Record<string, string>> = {} as any

    // SegmentGroups
    const segmentGroupMountLogs = Object.values(elementMaps.SegmentGroupMountLog)

    paths.SegmentGroup = paths.SegmentGroup ?? {}

    segmentGroupMountLogs.forEach((segmentGroupMountLog) => {
      const path = `SegmentGroup:${Mapping.numericIdByMountLogId[segmentGroupMountLog.id]}`

      paths.SegmentGroup[segmentGroupMountLog.id] = path
    })

    // Segments
    const segmentMountLogs = Object.values(elementMaps.SegmentMountLog)

    paths.Segment = paths.Segment ?? {}

    for (const segmentMountLog of segmentMountLogs) {
      const { nozzleMountLogs, rollerMountLogs, dataPointMountLogs } = segmentMountLog

      if (!nozzleMountLogs?.length && !rollerMountLogs?.length && !dataPointMountLogs?.length) {
        continue
      }

      // TODO: how can i get the segment group mount log id??
      const segmentGroupMountLogId = segmentMountLog.segmentGroupMountLogId
      const parentPath = paths.SegmentGroup[segmentGroupMountLogId ?? '']

      if (!parentPath) {
        continue
      }

      const path = `${parentPath}/Segment:${Mapping.numericIdByMountLogId[segmentMountLog.id]}`

      paths.Segment[segmentMountLog.id] = path
    }

    // Nozzles
    const nozzleMountLogs = Object.values(elementMaps.NozzleMountLog)

    paths.Nozzle = paths.Nozzle ?? {}

    for (const nozzleMountLog of nozzleMountLogs) {
      const parentPath = paths.Segment[nozzleMountLog.segmentMountLogId ?? '']

      if (!parentPath) {
        continue
      }

      const path = `${parentPath}/Nozzle:${Mapping.numericIdByMountLogId[nozzleMountLog.id]}`

      paths.Nozzle[nozzleMountLog.id] = path
    }

    // Rollers
    const rollerMountLogs = Object.values(elementMaps.RollerMountLog)

    paths.Roller = paths.Roller ?? {}

    for (const rollerMountLog of rollerMountLogs) {
      const parentPath = paths.Segment[rollerMountLog.segmentMountLogId ?? '']

      if (!parentPath) {
        continue
      }

      const path = `${parentPath}/Roller:${Mapping.numericIdByMountLogId[rollerMountLog.id]}`

      paths.Roller[rollerMountLog.id] = path
    }

    // RollerBodies
    const rollerBodyMountLogs = Object.values(elementMaps.RollerBodyMountLog)

    paths.RollerBody = paths.RollerBody ?? {}

    for (const rollerBodyMountLog of rollerBodyMountLogs) {
      const parentPath = paths.Roller[rollerBodyMountLog.rollerMountLogId ?? '']

      if (!parentPath) {
        continue
      }

      const path = `${parentPath}/RollerBody:${Mapping.numericIdByMountLogId[rollerBodyMountLog.id]}`

      paths.RollerBody[rollerBodyMountLog.id] = path
    }

    // RollerBearings
    const rollerBearingMountLogs = Object.values(elementMaps.RollerBearingMountLog)

    paths.RollerBearing = paths.RollerBearing ?? {}

    for (const rollerBearingMountLog of rollerBearingMountLogs) {
      const parentPath = paths.Roller[rollerBearingMountLog.rollerMountLogId ?? '']

      if (!parentPath) {
        continue
      }

      const path = `${parentPath}/RollerBearing:${Mapping.numericIdByMountLogId[rollerBearingMountLog.id]}`

      paths.RollerBearing[rollerBearingMountLog.id] = path
    }

    // SupportPoints
    const supportPointMountLogMap = ElementsUtil.getSupportPointMountLogMapBySegmentGroupMountLogs(
      elementMaps,
      segmentGroupMountLogs,
    )
    const supportPointMountLogs = Object.values(supportPointMountLogMap)

    paths.SupportPoint = paths.SupportPoint ?? {}

    for (const supportPointMountLog of supportPointMountLogs) {
      const parentPath = paths.SegmentGroup[supportPointMountLog.segmentGroupMountLogId ?? '']

      if (!parentPath) {
        continue
      }

      const path = `${parentPath}/SupportPoint:${Mapping.numericIdByMountLogId[supportPointMountLog.id]}`

      paths.SupportPoint[supportPointMountLog.id] = path
    }

    // DataPoints
    // DataPoints in MoldFaces
    const moldFaceDataPointMountLogs = Object.values(elementMaps.MoldFaceDataPointMountLog)

    paths.DataPoint = paths.DataPoint ?? {}

    // FIXME: @c.betele this is not working, how can we fix this?
    for (const moldFaceDataPointMountLog of moldFaceDataPointMountLogs) {
      const numericId = Mapping.numericIdByMountLogId[moldFaceDataPointMountLog.id]
      // const parentPath = paths.MoldFace[moldFaceDataPointMountLog.moldFaceId]

      // if (!parentPath) {
      //   continue
      // }

      // const path = `${parentPath}/DataPoint:${numericId}`
      // FIXME: this is a temporary workaround
      const path = `MoldFace/DataPoint:${numericId}`

      paths.DataPoint[moldFaceDataPointMountLog.id] = path
    }

    // DataPoints in Strand
    const strandDataPointMountLogs = Object.values(elementMaps.StrandDataPointMountLog)

    // FIXME: @c.betele this is not working, how can we fix this?
    for (const strandDataPointMountLog of strandDataPointMountLogs) {
      const numericId = Mapping.numericIdByMountLogId[strandDataPointMountLog.id]
      // const parentPath = paths.Caster[strandDataPointMountLog.casterId]

      // if (!parentPath) {
      //   continue
      // }

      // const path = `${parentPath}/DataPoint:${numericId}`
      // FIXME: this is a temporary workaround
      const path = `Strand/DataPoint:${numericId}`

      paths.DataPoint[strandDataPointMountLog.id] = path
    }

    // DataPoints in Segments
    const segmentDataPointMountLogs = Object.values(elementMaps.SegmentDataPointMountLog)

    for (const segmentDataPointMountLog of segmentDataPointMountLogs) {
      const numericId = Mapping.numericIdByMountLogId[segmentDataPointMountLog.id]
      const parentPath = paths.Segment[segmentDataPointMountLog.segmentMountLogId ?? '']

      if (!parentPath) {
        continue
      }

      const path = `${parentPath}/DataPoint:${numericId}`

      paths.DataPoint[segmentDataPointMountLog.id] = path
    }

    // DataPoints in Rollers
    const rollerDataPointMountLogs = Object.values(elementMaps.RollerDataPointMountLog)

    paths.DataPoint = paths.DataPoint ?? {}

    for (const rollerDataPointMountLog of rollerDataPointMountLogs) {
      const numericId = Mapping.numericIdByMountLogId[rollerDataPointMountLog.id]
      const parentPath = paths.Roller[rollerDataPointMountLog.rollerMountLogId ?? '']

      if (!parentPath) {
        continue
      }

      const path = `${parentPath}/DataPoint:${numericId}`

      paths.DataPoint[rollerDataPointMountLog.id] = path
    }

    // DataPoints in RollerBodies
    const rollerBodyDataPointMountLogs = Object.values(elementMaps.RollerBodyDataPointMountLog)

    for (const rollerBodyDataPointMountLog of rollerBodyDataPointMountLogs) {
      const numericId = Mapping.numericIdByMountLogId[rollerBodyDataPointMountLog.id]
      const parentPath = paths.RollerBody[rollerBodyDataPointMountLog.rollerBodyMountLogId ?? '']

      if (!parentPath) {
        continue
      }

      const path = `${parentPath}/DataPoint:${numericId}`

      paths.DataPoint[rollerBodyDataPointMountLog.id] = path
    }

    // SensorPoints
    paths.SensorPoint = paths.SensorPoint ?? {}

    // SensorPoints in Segments
    const segmentSensorPointMountLogs = Object.values(elementMaps.SegmentSensorPointMountLog)

    for (const segmentSensorPointMountLog of segmentSensorPointMountLogs) {
      const numericId = Mapping.numericIdByMountLogId[segmentSensorPointMountLog.id]
      const parentPath = paths.Segment[segmentSensorPointMountLog.segmentMountLogId ?? '']

      if (!parentPath) {
        continue
      }

      const path = `${parentPath}/SensorPoint:${numericId}`

      paths.SensorPoint[segmentSensorPointMountLog.id] = path
    }

    // SensorPoints in Rollers
    const rollerSensorPointMountLogs = Object.values(elementMaps.RollerSensorPointMountLog)

    for (const rollerSensorPointMountLog of rollerSensorPointMountLogs) {
      const numericId = Mapping.numericIdByMountLogId[rollerSensorPointMountLog.id]
      const parentPath = paths.Roller[rollerSensorPointMountLog.rollerMountLogId ?? '']

      if (!parentPath) {
        continue
      }

      const path = `${parentPath}/SensorPoint:${numericId}`

      paths.SensorPoint[rollerSensorPointMountLog.id] = path
    }

    // SensorPoints in RollerBodies
    const rollerBodySensorPointMountLogs = Object.values(elementMaps.RollerBodySensorPointMountLog)

    for (const rollerBodySensorPointMountLog of rollerBodySensorPointMountLogs) {
      const numericId = Mapping.numericIdByMountLogId[rollerBodySensorPointMountLog.id]
      const parentPath = paths.RollerBody[rollerBodySensorPointMountLog.rollerBodyMountLogId ?? '']

      if (!parentPath) {
        continue
      }

      const path = `${parentPath}/SensorPoint:${numericId}`

      paths.SensorPoint[rollerBodySensorPointMountLog.id] = path
    }

    // return the paths
    return Object.keys(paths).reduce(
      (acc, key) => {
        ;(acc as any)[key] = Object.values((paths as any)[key])

        return acc
      },
      {} as Record<DrawableCasterElementTypes, string[]>,
    )
  }

  public static getCoolingLoopMountLogsByStrandGuideMountLog (
    coolingLoopMountLogs: CoolingLoopMountLogMap,
    strandGuideMountLog: StrandGuideMountLog | null,
  ): CoolingLoopMountLog[] {
    if (!coolingLoopMountLogs || !strandGuideMountLog) {
      return []
    }

    return Object
      .values(coolingLoopMountLogs)
      .filter(coolingLoopMountLog => coolingLoopMountLog.strandGuideMountLogId === strandGuideMountLog.id)
  }

  public static getDataLineSlotsByDataLineMountLogs (
    dataLineSlotMap: DataLineSlotMap,
    dataLineMountLogs: DataLineMountLog[],
  ): DataLineSlot[] {
    if (!dataLineSlotMap || !dataLineMountLogs) {
      return []
    }

    return dataLineMountLogs
      .map(dataLineMountLog => dataLineSlotMap[dataLineMountLog.slotId])
      .filter(Boolean) as DataLineSlot[]
  }

  public static getSensorPointSide (
    // TODO: type: RollerSensorPointMountLog | RollerBodySensorPointMountLog | SegmentSensorPointMountLog | null
    sensorPointData: any,
    elementMaps: ElementMaps,
  ) {
    // sensor points can be mounted on rollers, rollerBodies and segments
    const { RollerMountLog, RollerSlot, RollerBodyMountLog, RollerBodySlot, SegmentMountLog, SegmentSlot } = elementMaps

    if (!sensorPointData) {
      return null
    }

    if (sensorPointData.rollerMountLogId) {
      const rollerMountLog = RollerMountLog[sensorPointData.rollerMountLogId]
      const rollerSlot = RollerSlot[rollerMountLog?.slotId ?? '']

      return rollerSlot?.side ?? null
    }

    if (sensorPointData.rollerBodyMountLogId) {
      const rollerBodyMountLog = RollerBodyMountLog[sensorPointData.rollerBodyMountLogId]
      const rollerBodySlot = RollerBodySlot[rollerBodyMountLog?.slotId ?? '']

      return rollerBodySlot?.side ?? null
    }

    if (sensorPointData.segmentMountLogId) {
      const segmentMountLog = SegmentMountLog[sensorPointData.segmentMountLogId]
      const segmentSlot = SegmentSlot[segmentMountLog?.slotId ?? '']

      return segmentSlot?.side ?? null
    }

    return null
  }

  public static typeIsOfTypeCasterElementName (type: string) {
    const types: CasterElementNames[] = [
      'AirLoop',
      'CoolingLoop',
      'DataLine',
      'Nozzle',
      'Roller',
      'RollerBearing',
      'RollerBody',
      'SensorPoint',
      'Segment',
      'SegmentGroup',
      'SupportPoint',
      'StrandGuide',
    ]

    return types.includes(type as CasterElementNames)
  }

  public static getMountLogMapKeysByTagName (type: TagName): MountLogMapKey[] {
    switch (type) {
      case 'AirLoop':
        return [ 'AirLoopMountLog' ]
      case 'CoolingLoop':
        return [ 'CoolingLoopMountLog' ]
      case 'CoolingZone':
        return [ 'CoolingZoneMountLog' ]
      case 'DataLine':
        return [ 'DataLineMountLog' ]
      case 'Nozzle':
        return [ 'NozzleMountLog' ]
      case 'Roller':
        return [ 'RollerMountLog' ]
      case 'RollerBearing':
        return [ 'RollerBearingMountLog' ]
      case 'RollerBody':
        return [ 'RollerBodyMountLog' ]
      case 'DataPoint':
        return [
          'MoldFaceDataPointMountLog',
          'StrandDataPointMountLog',
          'SegmentDataPointMountLog',
          'RollerDataPointMountLog',
          'RollerBodyDataPointMountLog',
        ]
      case 'SensorPoint':
        return [
          'SegmentSensorPointMountLog',
          'RollerSensorPointMountLog',
          'RollerBodySensorPointMountLog',
        ]
      case 'Segment':
        return [ 'SegmentMountLog' ]
      case 'SegmentGroup':
        return [ 'SegmentGroupMountLog' ]
      case 'SupportPoint':
        return [ 'SupportPointMountLog' ]
      case 'StrandGuide':
        return [ 'StrandGuideMountLog' ]
      default:
        return []
    }
  }

  public static readonly allNeededParentTypesPerType: Record<string, TagName[]> = {
    AirLoop: [],
    DataLine: [],
    Nozzle: [ 'Segment' ],
    Roller: [ 'Segment' ],
    RollerBearing: [ 'Roller', 'Segment' ],
    RollerBody: [ 'Roller', 'Segment' ],
    SensorPoint: [ 'Roller', 'RollerBody', 'Segment' ],
    Segment: [],
    SegmentGroup: [],
    SupportPoint: [ 'SegmentGroup' ],
  }

  public static normalizeCompareCasterInformation (
    currentData: Record<string, Record<string, any>>,
    data: Record<string, Record<string, any>>,
  ) {
    const newData: Record<string, Record<string, any>> = cloneDeep(currentData)

    for (const caseId in data) {
      // convert key to PascalCase and delete the last character if it is an 's'
      const maps = data[caseId]

      if (!newData[caseId]) {
        newData[caseId] = {}
      }

      for (const mapKey in maps) {
        const newHashKey = mapKey.charAt(0).toUpperCase() + mapKey.slice(1, -1)

        newData[caseId][newHashKey] = maps[mapKey]
      }
    }

    return newData
  }
}
