import hoistStatics from 'hoist-non-react-statics'
import hotkeys from 'hotkeys-js'
import { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'redux'

import { useConfig } from '@/config'
import VisUtil from '@/react/visualization/VisUtil'
import ApiClient from '@/store/apiClient'
import { AppState } from '@/store/application/main/consts'
import * as VisualizationActions from '@/store/visualization/actions'
import type { DefaultState } from '@/types/state'
import type { PlotConfig } from '@/types/visualization'
import { Identifiable } from '@/Util/decorators/Identifiable'

import Commands from './Commands'
import EditBox from './EditBox'
import { Dialog, DialogBackground, Form, Header, I, Tab, TabsWrapper, Text, Title } from '../DialogStyles'

const connector = connect(({ data, visualization, application }: DefaultState) => ({
  plotConfigs: visualization.plotConfigs,
  addPlotViewId: visualization.addPlotViewId,
  currentDashboard: visualization.currentDashboard,
  viewsObject: visualization.viewsObject,
  data: visualization.data,
  appState: application.main.appState,
  currentSimulationCase: application.main.currentSimulationCase,
  catalogList: data.catalogList,
}), {
  addPlotTile: VisualizationActions.addPlotTile,
  showAddPlotDialog: VisualizationActions.showAddPlotDialog,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  t(key: string, params?: Record<string, unknown>): string
}

type State = {
  [key: string]: any
  plotConfigId: string | null
  name: string
  type: string | null
  activeTab: number
  shapeIds: Array<any>
  editableFiles: Array<string>
}

export class AddPlotDialog extends Component<Props, State> {
  @Identifiable('AddPlotDialog') public static readonly NAME: string

  public override state: State = {
    plotConfigId: null,
    name: 'New Plot',
    type: null,
    activeTab: 0,
    shapeIds: [],
    editableFiles: [],
  }
  
  public override componentDidMount () {
    const { currentSimulationCase } = this.props

    ApiClient
      .get(`${useConfig().apiBaseURL}/editable-files/${currentSimulationCase.id}/list`)
      .then((files) =>
        this.setState({
          editableFiles: files,
        }))
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      .catch(() => {})

    hotkeys('Escape', this.handleClose)
  }
  
  public override componentWillUnmount () {
    hotkeys.deleteScope('other')
    hotkeys.unbind('Escape', this.handleClose)
  }

  private readonly handleInput = (event: any) => {
    const { name, value } = event.target

    this.setState({
      [name]: value,
    })
  }

  private readonly handleClose = () => {
    const { showAddPlotDialog } = this.props

    showAddPlotDialog()
  }

  private readonly handleSubmit = () => {
    const { plotConfigId: plotConfigIdRaw, name, type, shapeIds, editableFiles } = this.state
    const {
      appState,
      addPlotViewId,
      addPlotTile,
      showAddPlotDialog,
      plotConfigs,
      currentDashboard,
      currentSimulationCase,
      catalogList,
    } = this.props

    const allEditableFiles = VisUtil.getEditableFileSelectors(editableFiles)
    const allViewableFiles = VisUtil.getViewableFileSelectors(currentSimulationCase, catalogList)
    const allFiles = [ ...allEditableFiles, ...allViewableFiles ]
    const plotConfigId = plotConfigIdRaw ??
      (appState === AppState.ResultDashboard ? Object.keys(plotConfigs ?? {})[0] : allFiles[0]?.key)

    const group = plotConfigs[plotConfigId]?.group ??
      (editableFiles?.includes(plotConfigId)
        ? 'Editable Files'
        : (allViewableFiles.findIndex(file => file.key === plotConfigId) >= 0 ? 'Viewable Files' : null))

    const isTablePlot = plotConfigs[plotConfigId]?.selectedY?.endsWith('|all')

    const types = VisUtil.getTypeSelectors(group ?? '').map(type => type.key)

    const newType = isTablePlot
      ? 'table'
      : type
        ? (types.includes(type) ? type : types[0])
        : types[0]

    const isDynamicData = plotConfigs[plotConfigId]?.group === 'dynamicDataSource'
    const isMergedDynamicData = plotConfigs[plotConfigId]?.isMergedDynamicDataSource

    if (plotConfigId !== undefined) {
      addPlotTile(
        addPlotViewId,
        currentDashboard[addPlotViewId] ?? '',
        {
          configId: plotConfigId,
          name,
          type: newType,
          shapeIds,
          isDynamicData,
          isMergedDynamicData,
        },
      )
      showAddPlotDialog()
    }
  }

  private readonly handleTabClick = (event: any) => {
    this.setState({
      activeTab: Number(event.target.id),
    })
  }
  
  public override render () {
    const {
      plotConfigId,
      name,
      type,
      editableFiles,
      activeTab,
    } = this.state

    const {
      plotConfigs,
      currentDashboard,
      viewsObject,
      addPlotViewId,
      t,
      data,
      currentSimulationCase,
      appState,
      catalogList,
    } = this.props

    const configIds = Object.keys(plotConfigs ?? {})

    if (
      viewsObject[addPlotViewId] &&
      viewsObject[addPlotViewId].dashboards[currentDashboard[addPlotViewId] ?? '']?.blockNewPlots
    ) {
      return (
        <div>
          <DialogBackground />
          <Dialog $height='200px' $half>
            <Header title={t('addPlotDialog.title')}>
              <Title>{t('addPlotDialog.label')}</Title>
              <I className='pe-7s-close' onClick={this.handleClose} />
            </Header>
            <Form>
              <Text>
                {t('addPlotDialog.message')}
              </Text>
            </Form>
          </Dialog>
        </div>
      )
    }

    const allConfigIds = appState === AppState.ResultDashboard
      ? VisUtil.getConfigSelectors(data, plotConfigs)
      : []
    const allEditableFiles = VisUtil.getEditableFileSelectors(editableFiles)
    const allViewableFiles = VisUtil.getViewableFileSelectors(currentSimulationCase, catalogList)
    const allFiles = [ ...allEditableFiles, ...allViewableFiles ]

    const allDataSources = configIds.reduce((acc: any[], plotConfigId: string) => {
      const config = plotConfigs[plotConfigId] ?? {} as Partial<PlotConfig>
      const { name, id } = config

      if (!name || !id || (config.group !== 'dynamicDataSource' && config.group !== 'externalDataSource')) {
        return acc
      }
      
      if (config.group === 'dynamicDataSource') {
        const { value } = VisUtil.getConfigInfo(name, id, id, 'dynamicDataSource', config as PlotConfig)

        return [
          ...acc,
          { group: 'DynamicData', key: plotConfigId, value },
        ]
      }

      const { value } = VisUtil.getConfigInfo(name, id, id, 'externalDataSource', config as PlotConfig)

      return [
        ...acc,
        { group: 'ExternalData', key: plotConfigId, value },
      ]
    }, [])

    allDataSources.push(...configIds.reduce((acc: any[], plotConfigId: string) => {
      if (!plotConfigs[plotConfigId]?.isMergedDynamicDataSource) {
        return acc
      }

      return [
        ...acc,
        { group: 'DynamicData (Merged)', key: plotConfigId, value: plotConfigs[plotConfigId].name },
      ]
    }, []))

    const selectedId = plotConfigId ??
      (appState === AppState.ResultDashboard ? configIds[0] : allFiles[0]?.key)
    const typeSelectors = plotConfigs
      ? VisUtil.getTypeSelectors(
        (
          plotConfigs[selectedId] ??
            allFiles.find(file => file.key === selectedId) ??
            plotConfigs[Object.keys(plotConfigs)[0] ?? '']
        )
          ?.group,
      )
      : []

    const connectTabs = [
      { title: 'edit', icon: 'pe-7s-graph3' },
      { title: 'commands', icon: 'pe-7s-gleam' },
    ]

    // TODO: handle additionalYAxes if plot is merged
    return (
      <div>
        <DialogBackground />
        <Dialog $height='auto' $half>
          <Header title={t('addPlotDialog.title')}>
            <Title>
              {
                t(
                  !activeTab
                    ? 'addPlotDialog.parameterDashboardLabel.editBox'
                    : 'addPlotDialog.parameterDashboardLabel.command',
                )
              }
            </Title>
            <I
              className='pe-7s-close'
              onClick={this.handleClose}
              title={t('addPlotDialog.close')}
            />
          </Header>
          {
            !activeTab
              ? (
                <EditBox
                  onInput={this.handleInput}
                  onSubmit={this.handleSubmit}
                  plotConfigs={plotConfigs}
                  {...{ name, selectedId, allConfigIds, allFiles, allDataSources, typeSelectors, type }}
                />
              )
              : <Commands onClose={this.handleClose} />
          }
          <TabsWrapper>
            {
              connectTabs.map((tab, i) => (
                <Tab
                  id={i.toString()}
                  key={i}
                  title={t(`selectSourceDialog.tabs.${tab.title}`)}
                  $activeTab={activeTab === i}
                  onClick={this.handleTabClick}
                >
                  <i className={tab.icon} />
                </Tab>
              ))
            }
          </TabsWrapper>
        </Dialog>
      </div>
    )
  }
}

const composedComponent = compose<any>(withTranslation('visualization'), connector)(AddPlotDialog)

export default hoistStatics(composedComponent, AddPlotDialog)
