/* eslint-env browser */

import cloneDeep from 'lodash/cloneDeep'
import { enqueueSnackbar } from 'notistack'
import { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'redux'

import { useConfig } from '@/config'
import IpcManager from '@/IpcManager'
import { allVerified } from '@/logic/case-verification'
import TimeUtil from '@/logic/TimeUtil'
import Button from '@/react/components/Button'
import { ConfirmWrapper } from '@/react/components/Button/styles'
import InfoMarker from '@/react/components/InfoMarker'
import FeatureFlags from '@/react/FeatureFlags'
import Input from '@/react/specific/Input'
import ApiClient from '@/store/apiClient'
import * as ErrorActions from '@/store/application/error/actions'
import * as ApplicationActions from '@/store/application/main/actions'
import { AppState } from '@/store/application/main/consts'
import DataActions from '@/store/data/actions'
import * as ElementsActions from '@/store/elements/actions'
import * as LoadingActions from '@/store/LoadingStore'
import * as VisualizationActions from '@/store/visualization/actions'
import type { DefaultState } from '@/types/state'
import type { ArrayOfTranslations, Translation } from '@/types/translation'

import Logic from './Logic'
import { Column, FullHR, Label } from './Styles'
import { EditSimulationCaseDialog } from '../EditSimulationCaseDialog'
import { NewSimulationCaseDialog } from '../NewSimulationCaseDialog'
import { Description } from '../OpenProjectDialog/Styles'

const T = 'projectDataDialog.simulationCase'

const connector = connect((state: DefaultState) => ({
  currentProject: state.application.main.currentProject,
  currentSimulationCase: state.application.main.currentSimulationCase,
  userId: state.application.main.authenticationData.id,
  currentCatalogId: state.data.currentCatalogId,
  error: state.application.error,
  caseVerifications: state.application.main.caseVerifications,
  catalogList: state.data.catalogList,
  currentProjectCasesMetadata: state.application.main.currentProjectCasesMetadata,
  featureFlags: FeatureFlags.getRealFeatureFlags(state),
}), {
  resetReducer: DataActions.resetReducer,
  openDialog: ApplicationActions.openDialog,
  resetAllElements: ElementsActions.resetAllElements,
  setCurrentProject: ApplicationActions.setCurrentProject,
  setCurrentSimulationCase: ApplicationActions.setCurrentSimulationCase,
  setEditSimulationCaseId: ApplicationActions.setEditSimulationCaseId,
  setAppState: ApplicationActions.setAppState,
  saveCatalog: DataActions.saveCatalog,
  setFileUploadLoadingStatus: LoadingActions.setFileUploadLoadingStatus,
  setError: ErrorActions.setError,
  setVisualizationMetaInformation: VisualizationActions.setVisualizationMetaInformation,
  setCurrentCatalogId: DataActions.setCurrentCatalogId,
  deleteIdFromCurrentProjectCasesMetadata: ApplicationActions.deleteIdFromCurrentProjectCasesMetadata,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  t: ArrayOfTranslations & Translation
}

type State = {
  loading: any
  selectedSimulationCase: string | null | undefined
  isConfirmingStop: boolean
  cases: SimulationCase[]
}

export class SimulationCaseComponent extends Component<Props, State> {
  public override state: State = {
    loading: {
      startSimulation: false,
      stopSimulation: false,
      resetSimulation: false,
    },
    selectedSimulationCase: null,
    cases: [] as SimulationCase[],
    isConfirmingStop: false,
  }
  
  public static getDerivedStateFromProps (props: Props, state: State) {
    if (props.currentSimulationCase.id !== state.selectedSimulationCase) {
      return {
        selectedSimulationCase: props.currentSimulationCase.id,
      }
    }

    return null
  }

  public override async componentDidMount (): Promise<void> {
    const { currentProject: { id } } = this.props

    const { cases } = await ApiClient.get(`${useConfig().apiBaseURL}/cases`, { params: { projectId: id } })

    this.setState({
      selectedSimulationCase: cases[0].id,
      cases,
    })
  }

  private readonly handleChangeSimulationCase = (event: any) => {
    const {
      setCurrentSimulationCase,
      resetReducer,
      setVisualizationMetaInformation,
      setCurrentCatalogId,
      resetAllElements,
    } = this.props

    if (event.target.value === 'add') {
      return this.handleNewSimulationCase()
    }

    const selectedSimulationCase = event.target.value || this.state.cases[0]?.id

    this.setState({
      selectedSimulationCase,
    })

    ApiClient
      .get(
        `${useConfig().apiBaseURL}/cases/${selectedSimulationCase}`,
        { params: { withCurrentCaster: true } },
      )
      .then(({ case_ }) => {
        resetReducer()
        resetAllElements()

        setVisualizationMetaInformation('config', '', AppState.ParamDashboard)
        setVisualizationMetaInformation('data', '', AppState.ResultDashboard)
        setVisualizationMetaInformation('config', '', AppState.ResultDashboard)
        setVisualizationMetaInformation('config', '', AppState.Caster)
        setCurrentCatalogId()

        setCurrentSimulationCase(case_)
      })
      .catch((_response) => {
        // TODO: handle error
        // console.log(response)
      })
  }

  private readonly handleNewSimulationCase = () => {
    const { openDialog } = this.props

    openDialog(NewSimulationCaseDialog.NAME)
  }

  private readonly handleDelete = (_type: string, caseId: string) => {
    const {
      deleteIdFromCurrentProjectCasesMetadata,
    } = this.props

    const { cases } = this.state

    ApiClient
      .del(`${useConfig().apiBaseURL}/cases/${caseId}`)
      .then(() => {
        const newCases = cases.filter(c => c.id !== caseId)

        deleteIdFromCurrentProjectCasesMetadata(caseId)
        this.setState({ cases: newCases })
      })
      .catch((error) => {
        enqueueSnackbar('Error deleting case', { variant: 'error', autoHideDuration: 3000 })
        // eslint-disable-next-line no-console
        console.log(error)
      })
  }

  private readonly handleEditOpen = (key: string) => {
    const { openDialog, setEditSimulationCaseId } = this.props

    setEditSimulationCaseId(key)
    openDialog(EditSimulationCaseDialog.NAME)
  }

  private readonly handleDownload = async (caseId: string) => {
    const { data, fileName } = await ApiClient.get(`${useConfig().apiBaseURL}/cases/${caseId}/download`)

    // TODO: Fix, if download is cancelled, snackbar still shows up
    IpcManager.both.send('openSimulationDataDialog', data, fileName)
  }

  private readonly handleStartSimulation = () => {
    // const {
    //   currentSimulationCase,
    //   currentCatalogId,
    //   setCurrentSimulationCase,
    //   setError,
    //   enqueueSnackbar,
    //   t,
    // } = this.props

    this.setState({
      loading: { startSimulation: true },
    })

    // FIXME
    // const selectedCatalog = currentCatalogId ??
    // currentSimulationCase.casterCatalogList?.[0]?.catalogId ?? 'default'

    // ApiClient
    //   .post(
    //     `${'Network.URI(deprecated)'}/simulation_case/simulate/${currentSimulationCase.id}`,
    //     { data: { catalog: selectedCatalog } },
    //   )
    //   .then(({ simulationCase }) => {
    //     setCurrentSimulationCase(simulationCase)

    //     this.setState({
    //       loading: { startSimulation: false },
    //     })

    //     enqueueSnackbar(t(`${T}.simulation.started`), { autoHideDuration: 3000, variant: 'success' })
    //   })
    //   .catch(({ status }) => {
    //     setError('startSimulation', status)

    //     this.setState({
    //       loading: { startSimulation: false },
    //     })

    //     enqueueSnackbar(t(`${T}.simulation.error`), { autoHideDuration: 4000, variant: 'error' })
    //   })
  }

  private readonly handleStopSimulation = () => {
    const { currentSimulationCase, setCurrentProject, setCurrentSimulationCase, setError } = this.props
    const { id } = currentSimulationCase

    this.setState({
      loading: { stopSimulation: true },
      isConfirmingStop: false,
    })

    ApiClient
      .post(`${'Network.URI(deprecated)'}/simulation_case/stop_multiple`, { data: { simulationCaseIds: [ id ] } })
      .then(({ project }: { project: Project }) => {
        if (!project || (Object.keys(project).length === 0)) {
          throw new Error('No project given')
        }

        setCurrentProject(project)

        const simulationCase = cloneDeep(currentSimulationCase)

        const newSimulationCase = this.state.cases.find(simulationCase => simulationCase.id === id)

        if (newSimulationCase) {
          // FIXME: this is not working
          // simulationCase.simulationStartedAt = newSimulationCase?.simulationStartedAt
          // simulationCase.simulationDataReceivedAt = newSimulationCase?.simulationDataReceivedAt
          // simulationCase.simulationStoppedManually = newSimulationCase?.simulationStoppedManually

          setCurrentSimulationCase(simulationCase)

          // TODO: maybe update currentSimulationCase

          this.setState({
            loading: { stopSimulation: false },
          })
        }
      })
      .catch(({ status }) => {
        setError('stopSimulation', status)

        this.setState({
          loading: { stopSimulation: false },
        })
      })
  }

  private readonly handleBeginConfirmStop = () => {
    this.setState({ isConfirmingStop: true })
  }

  private readonly handleCancelStop = () => {
    this.setState({ isConfirmingStop: false })
  }

  private readonly handleResetSimulation = () => {
    const {
      currentSimulationCase,
      resetReducer,
      setCurrentSimulationCase,
      resetAllElements,
      setVisualizationMetaInformation,
      setAppState,
      setError,
      t,
    } = this.props

    this.setState({
      loading: { resetSimulation: true },
      isConfirmingStop: false,
    })

    ApiClient
      .patch(`${'Network.URI(deprecated)'}/simulation_case/reset/${currentSimulationCase.id}`)
      .then(({ simulationCase }) => {
        resetReducer()
        setCurrentSimulationCase(simulationCase)
        resetAllElements()
        setVisualizationMetaInformation('data', '', AppState.ResultDashboard)
        setVisualizationMetaInformation('config', '', AppState.ResultDashboard)

        setAppState(AppState.Caster)

        this.setState({
          loading: { resetSimulation: false },
        })

        enqueueSnackbar(t(`${T}.simulation.reset`), { autoHideDuration: 3000, variant: 'success' })
      })
      .catch(({ status }) => {
        setError('resetSimulation', status)

        this.setState({
          loading: { resetSimulation: false },
        })

        enqueueSnackbar(t(`${T}.simulation.reset_error`), { autoHideDuration: 4000, variant: 'error' })
      })
  }
  
  public override render () {
    const { loading, selectedSimulationCase, isConfirmingStop } = this.state
    const {
      currentSimulationCase,
      catalogList,
      currentCatalogId,
      caseVerifications,
      error,
      t,
      currentProjectCasesMetadata,
      featureFlags,
      userId,
    } = this.props

    const sortedCases = [ ...this.state.cases ]

    sortedCases.sort((a, b) => {
      const createdAtA = currentProjectCasesMetadata.find(c => c.id === a.id)?.createdAt
      const createdAtB = currentProjectCasesMetadata.find(c => c.id === b.id)?.createdAt

      if (createdAtA && createdAtB) {
        return createdAtA.getTime() - createdAtB.getTime()
      }

      return 0
    })

    const canViewRealDataBadges = FeatureFlags.canViewRealDataBadges(featureFlags)

    const simulationCaseSelectors = Logic.getSelectors(sortedCases, 'id', 'name', null, true, entry => {
      if (!canViewRealDataBadges) {
        return <span>&nbsp;</span>
      }

      if (entry.blueprintId) {
        return <span className='badge real-data'>{t('label.realData')}</span>
      }

      return <span className='badge blueprint'>{t('label.blueprint')}</span>
    })

    for (const selector of simulationCaseSelectors) {
      const simulationCase = sortedCases.find(c => c.id === selector.key)

      if (!simulationCase) {
        continue
      }

      if (simulationCase.userId !== userId) {
        selector.notRemovable = true
        selector.notEditable = true
      }
    }

    simulationCaseSelectors.push({
      key: 'add',
      value: t(`${T}.new.label`),
      notRemovable: true,
      hideActions: true,
    })

    const selectedSimulationCaseId = selectedSimulationCase ?? simulationCaseSelectors[0]?.key ?? 'default'

    const {
      simulationStartedAt,
      simulationDataReceivedAt,
    } = currentSimulationCase

    const selectedCatalog = Logic.getSelectedCatalog(currentCatalogId, catalogList)

    const isAllVerified = allVerified(currentSimulationCase.id, caseVerifications, selectedCatalog)

    // FIXME: there is no simulationCases in project
    // const otherSimulationsRunning = currentProject.simulationCases.reduce((condition, simulationCase) => {
    //   return Boolean(condition || (simulationCase.simulationStartedAt && !simulationCase.simulationDataReceivedAt))
    // }, false)
    const otherSimulationsRunning = false

    return (
      <div>
        <Column $width='33%'>
          <InfoMarker message={t(`${T}.info`, { returnObjects: true }).join('\n')} x={222} y={11} size={20} />
          <Input
            name='selectedSimulationCase'
            type='select'
            label={t(`${T}.case.label`)}
            title={t(`${T}.case.title`)}
            value={selectedSimulationCaseId}
            selectors={simulationCaseSelectors}
            onChange={this.handleChangeSimulationCase}
            onDelete={this.handleDelete}
            noDeleteCurrent
            onEdit={this.handleEditOpen}
            onDownload={this.handleDownload}
          />
          {
            !simulationStartedAt &&
            (
              <Button
                onClick={this.handleStartSimulation}
                disabled={!isAllVerified || otherSimulationsRunning}
                loading={loading.startSimulation}
                title={
                  error['startSimulation']
                    ? t([ `error.${error['startSimulation']}`, 'error.default' ])
                    : t(`${T}.start.title`)
                }
                error={error['startSimulation'] && t([ `error.${error['startSimulation']}`, 'error.default' ])}
                hideError
                height={61}
                bold
              >
                {t(`${T}.start.label`)}
              </Button>
            )
          }
          {
            simulationStartedAt && !simulationDataReceivedAt && (
              <ConfirmWrapper>
                {
                  !isConfirmingStop &&
                (
                  <Button
                    loading
                    disabled
                    title={t(`${T}.started`, { date: TimeUtil.getDisplayDateTime(simulationStartedAt) })}
                    icon='pe-7s-hourglass'
                    hideError
                    height={61}
                    bold
                    half
                  >
                    {t(`${T}.loading.label`)}
                  </Button>
                )
                }
                <Button
                  disabled={loading.stopSimulation}
                  loading={loading.stopSimulation}
                  onClick={this.handleStopSimulation}
                  onBeginConfirm={this.handleBeginConfirmStop}
                  onCancel={this.handleCancelStop}
                  hideError
                  height={61}
                  bold
                  half
                  confirm
                >
                  {t(`${T}.stop.label`)}
                </Button>
              </ConfirmWrapper>
            )
          }
          {
            simulationStartedAt && simulationDataReceivedAt && (
              <Button
                onClick={this.handleResetSimulation}
                loading={loading.resetSimulation}
                title={
                  error['resetSimulation']
                    ? t([ `error.${error['resetSimulation']}`, 'error.default' ])
                    : t(`${T}.ended`, { date: TimeUtil.getDisplayDateTime(simulationDataReceivedAt) })
                }
                error={error['resetSimulation'] && t([ `error.${error['resetSimulation']}`, 'error.default' ])}
                icon='pe-7s-lock'
                hideError
                height={61}
                bold
                confirm={t(`${T}.reset.confirm`)}
              >
                {t(`${T}.reset.label`)}
              </Button>
            )
          }
        </Column>
        <Column $width='66%'>
          <Label>{t(`${T}.description.label`, { id: selectedSimulationCase ?? null })}</Label>
          <Description>
            {
              this
                .state
                .cases
                ?.find(simulationCase => simulationCase.id === selectedSimulationCaseId)
                ?.description ?? ''
            }
          </Description>
        </Column>
        <FullHR />
      </div>
    )
  }
}

export default compose<any>(withTranslation('application'), connector)(SimulationCaseComponent)
