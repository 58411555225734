import Tooltip from '@mui/material/Tooltip'
import { withStyles } from '@mui/styles'
import { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Container } from 'react-smooth-dnd'
import { compose } from 'redux'

import ApiClient from '@/store/apiClient'
import type { DefaultState } from '@/types/state'
import type { Translation } from '@/types/translation'

import {
  Content,
  DraggableItem,
  DragNDrop,
  DropContainer,
  I,
  InnerText,
  List,
  ListItem,
} from './Styles'

const StyledTooltip = withStyles(theme => ({
  tooltip: {
    maxWidth: 300,
    fontSize: theme.typography.pxToRem(14),
    boxShadow: '0 0 5px 0 rgba(127,127,127,.5)',
  },
}))(Tooltip)

const connector = connect((state: DefaultState) => ({
  currentSimulationCase: state.application.main.currentSimulationCase,
}), {})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  commands: any
  selectedFile: any
  t: Translation
}

type State = {
  selectedCommands: any[]
}

class DragAndDrop extends Component<Props, State> {
  public override state: State = {
    selectedCommands: [],
  }

  public override componentDidMount () {
    const { selectedFile } = this.props
    const assignedCommands = selectedFile?.assignedCommands ?? []

    this.setState({
      selectedCommands: [ ...assignedCommands ],
    })
  }

  public override componentDidUpdate (prevProps: Props) {
    const { selectedFile } = this.props

    if (prevProps.selectedFile._id !== selectedFile._id) {
      this.setState({
        selectedCommands: [ ...selectedFile.assignedCommands ],
      })
    }
  }

  public override componentWillUnmount () {
    this.handleApiRequest(true)
  }

  private handleTimeout?: number

  private readonly handleDropCard = (event: any) => {
    const { payload, addedIndex, removedIndex } = event
    const { selectedCommands } = this.state

    const sortedSelectedCommand: any[] = [ ...selectedCommands ]

    if (removedIndex !== null) {
      sortedSelectedCommand.splice(removedIndex, 1)
    }

    if (addedIndex !== null) {
      sortedSelectedCommand.splice(addedIndex, 0, payload)
    }

    if (removedIndex === null && selectedCommands.includes(payload)) {
      return
    }

    this.setState({
      selectedCommands: sortedSelectedCommand,
    })

    this.handleApiRequest()
  }

  private readonly handleDelete = (event: any) => {
    const { selectedCommands } = this.state
    const { id } = event.target

    let newSelectedCommands: any[] = []

    if (id !== 'all') {
      newSelectedCommands = [ ...selectedCommands ]

      newSelectedCommands.splice(selectedCommands.indexOf(id), 1)
      delete newSelectedCommands[id]
    }

    this.setState({
      selectedCommands: newSelectedCommands,
    })

    this.handleApiRequest()
  }

  private readonly handleApiRequest = (instant?: boolean) => {
    clearTimeout(this.handleTimeout)

    this.handleTimeout = window.setTimeout(() => {
      const { currentSimulationCase, selectedFile } = this.props
      const { selectedCommands } = this.state

      ApiClient.post(`${'Network.URI(deprecated)'}/visualization_command/file/${selectedFile._id}`, {
        data: {
          assignedCommands: selectedCommands,
          simulationCaseId: currentSimulationCase.id,
        },
      })
    }, instant ? 0 : 1000)
  }

  private readonly handleSortCommands = (commandA: any, commandB: any) => {
    if (commandA.command < commandB.command) {
      return -1
    }

    if (commandA.command > commandB.command) {
      return 1
    }

    return 0
  }

  public override render () {
    const { selectedCommands } = this.state
    const { t, commands } = this.props

    return (
      <Content>
        <DragNDrop>
          <List>
            <Container
              groupName='123'
              behaviour='copy'
              getChildPayload={(i: number) => commands[i]._id}
              dragHandleSelector='.list_item_drag_handle'
              render={
                () => (
                  <>
                    {
                      commands.sort(this.handleSortCommands).map((com: any) => {
                        const disabled = com.parameter.flat().includes('')

                        return (
                          <StyledTooltip
                            key={com._id}
                            title={
                              disabled
                                ? 'This command can not be assigned because ' +
                                  'there are missing values for some parameters.'
                                : ''
                            }
                          >
                            <DraggableItem
                              key={com._id}
                              className={disabled ? '' : 'list_item_drag_handle'}
                              $disabled={disabled}
                              render={
                                () => (
                                  <ListItem>
                                    <InnerText>
                                      {com.command}
                                    </InnerText>
                                  </ListItem>
                                )
                              }
                            />
                          </StyledTooltip>
                        )
                      })
                    }
                  </>
                )
              }
            />
          </List>
        </DragNDrop>
        <DragNDrop $float='right'>
          <DropContainer>
            <List>
              <Container
                groupName='123'
                getChildPayload={(i: number) => selectedCommands[i]}
                onDrop={this.handleDropCard}
                style={{ height: '100%' }}
                lockAxis='y'
                render={
                  () => (
                    <>
                      {
                        selectedCommands.map((selectedCommandId, index) => {
                          const currentCommand = commands.filter((com: any) => com._id === selectedCommandId)[0] ?? {}

                          return (
                            <DraggableItem
                              key={index}
                              render={
                                () => (
                                  <ListItem>
                                    <InnerText>
                                      {currentCommand.command}
                                    </InnerText>
                                    <I
                                      className='pe-7s-close'
                                      id={selectedCommandId}
                                      title={t('plotContent.configurePlot.delete')}
                                      onClick={this.handleDelete}
                                      $size='25px'
                                    />
                                  </ListItem>
                                )
                              }
                            />
                          )
                        })
                      }
                    </>
                  )
                }
              />
            </List>
          </DropContainer>
        </DragNDrop>
      </Content>
    )
  }
}

export default compose<any>(withTranslation('visualization'), connector)(DragAndDrop)
