import React from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'redux'

import type { DefaultState } from '@/types/state'

import Module from './Module'

const connector = connect((state: DefaultState) => ({
  modules: state.modules,
}), {})

type PropsFromRedux = ConnectedProps<typeof connector>

export class Modules extends React.Component<PropsFromRedux> {
  public override render () {
    const { modules: modulesObject } = this.props
    const modules = Object.keys(modulesObject)

    return (
      <div>
        {modules.map(module => <Module key={module} moduleName={module} />)}
      </div>
    )
  }
}

export default compose<any>(connector)(Modules)
