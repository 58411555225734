import { Component } from 'react'

import { InputForm } from '../InputStyles'

type Props = {
  placeholder: string
  massValue: string | undefined
  name: string
  isDisabled?: boolean | undefined
  onChange: (e: any) => void
  inputRef: any
  style?: any | undefined
}

export default class InputField extends Component<Props> {
  public override render () {
    const { placeholder, massValue, isDisabled, onChange, name, inputRef, style } = this.props

    return (
      <InputForm
        placeholder={placeholder}
        value={massValue ?? ''} // '' is a fallback value to prevent error
        title={String(massValue)}
        onChange={onChange}
        disabled={isDisabled}
        name={name}
        ref={inputRef}
        style={style}
      />
    )
  }
}
