import { faCheckSquare } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { PureComponent } from 'react'
import styled from 'styled-components'

import type { ElementMaps } from '@/types/state'
import { ElementMapsUtil } from '@/Util/ElementMapsUtil'

import { SectionContent } from '../AllInOne/styles'

type Props = {
  index: number
  ccElement: CCElement
  groupKey: number | 'default'
  selectedPaths: Set<string>
  elementMaps: ElementMaps
  selectElementPerAttributeUUID: (uuid: string) => void
}

const Button = styled.span`
  width: fit-content;
  background: none;
  color: white;
  border: none;
  cursor: pointer;
  margin-right: 10px;
  margin-top: 3px;

  :hover {
    text-decoration: underline;
  }

  button:focus: {
    outline: none;
    box-shadow: none;
  }
`

const CCItemContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 4px;
  margin-left: 5px;
  background-color: #22282e;
  width: fit-content;
  padding: 1px 2px;

  :hover {
    cursor: pointer;
  }

  span {
    margin-right: 3px;
  }

  svg {
    width: 17px !important;
    height: 17px !important;
  }
`

export class CCElementComponent extends PureComponent<Props> {
  private readonly handleSelectElements = () => {
    const { ccElement, selectElementPerAttributeUUID } = this.props

    selectElementPerAttributeUUID(ccElement.uuid)
  }

  private readonly getIsSelected = () => {
    const { elementMaps, selectedPaths, ccElement } = this.props

    if (!ccElement || !ccElement.uuid) {
      return false
    }

    for (const selectedPath of selectedPaths) {
      const element = ElementMapsUtil.getFullCasterElementByPath(selectedPath, elementMaps)

      if (!element) {
        continue
      }

      const elementKeys = Object.keys(element).filter(key => key.includes('uuid'))

      for (const key of elementKeys) {
        if (ccElement.uuid.includes(String(element[key as keyof typeof element]))) {
          return true
        }
      }
    }

    return false
  }

  public override render () {
    const { ccElement, index, groupKey } = this.props

    const isSelected = this.getIsSelected()
    const containerStyle: any = {
      color: 'white',
      padding: '0 0 0 5px',
      marginBottom: groupKey === 'default' ? '15px' : '7px',
    }

    if (isSelected) {
      containerStyle.borderLeft = '4px solid #ff0000'
      containerStyle.marginLeft = '-4px'
    }

    return (
      <SectionContent
        key={index}
        style={containerStyle}
      >
        <div
          style={
            {
              border: '1px #595959 solid',
              borderRadius: '6px',
              backgroundColor: '#404040',
              minHeight: '50px',
              alignItems: 'left',
              justifyContent: 'center',
              display: 'flex',
              flexDirection: 'column',
            }
          }
        >
          <div>
            <CCItemContainer onClick={this.handleSelectElements}>
              <Button title='Show / Edit'>
                <FontAwesomeIcon icon={faCheckSquare} />
              </Button>
              item-{index}
            </CCItemContainer>
          </div>
          <p style={{ width: '100%', padding: '0px 5px' }}>{ccElement.text}</p>
        </div>
      </SectionContent>
    )
  }
}
