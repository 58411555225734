import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import type { Moment } from 'moment'
import styled, { css } from 'styled-components'

const StyledDateTimePicker = styled(DateTimePicker)<{ $half?: boolean | undefined }>`
  ${({ $half }) =>
    css`
      width: ${$half ? 'calc(50% - 10px)' : '100%'} !important;
      margin-right: ${$half ? '10px' : '0'} !important;
      margin-bottom: 8px !important;

      /* I don't know why it has a margin-right of -12px by default */
      button {
        margin-right: 0px !important;
      }
    `}
`

type DateTimePickerProps = {
  label: string;
  value: Moment;
  onChange: (moment: Moment | null) => void;
  half?: boolean;
};

export function DateTimePickerComponent (props: DateTimePickerProps) {
  const { half, ...restProps } = props

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <StyledDateTimePicker
        format='YYYY-MM-DD HH:mm'
        ampm={false}
        $half={half}
        {...restProps}
      />
    </LocalizationProvider>
  )
}
