import { request } from '@/api'

export type DatasourceResponse = {
  target: string
  refId: string
  datapoints: number[][]
  meta: Meta
}

type Meta = {
  tagId: string
  lang: string
  unit: string
  description: string
  intervalIndex: number
}

const PATH = 'grafana-api'

export function getDashboards (): Promise<{ id: string, name: string }[] | null> {
  // TODO: translate message
  return request('get', `${PATH}/dashboards`, 'Failed to get dashboard ids')
}

export function getPanels (dashboardId: string): Promise<{ id: number, name: string }[] | null> {
  // TODO: translate message
  return request('get', `${PATH}/dashboards/${dashboardId}/panels`, 'Failed to get panel ids')
}

export function getPanelData (dashboardId: string, panelId: number): Promise<DatasourceResponse[] | null> {
  // TODO: translate message
  return request('get', `${PATH}/dashboards/${dashboardId}/panels/${panelId}`, 'Failed to get panel data')
}
