/* eslint-env browser */

import FastBase from './FastBase'
import SVG from './SVG'

export default class Bar extends FastBase {
  public static readonly PLOT_PADDING_FACTOR = 0.02

  public constructor () {
    super('point', 'points', 'trace', 'path')
  }

  private barWidth?: number

  private buildPathDBar (
    x: number,
    y: number,
    width: number,
    height: number,
    traceIndex: number,
  ): string {
    // const { yRange, xScale, yScale } = this
    const { flipAxes, traceCount } = this.additionalData ?? { flipAxes: false, traceCount: 0 }

    // FIXME: this "* (yScale / yRange) * 2" is black magic! burn it with fire!!!
    // const barsWidth = ((flipAxes ? height * (yScale / yRange) * 2 : width) / (barCount - 1)) * 0.8
    // const barWidth = (barsWidth / traceCount)
    // const barOffset = (barsWidth / 2) - (barWidth * traceIndex)
    if (!this.barWidth) {
      return ''
    }

    const barWidth = this.barWidth
    const barOffset = (barWidth * traceCount) / 2 - (barWidth * traceIndex)

    let xValue = this.calcX(x, width)
    let yValue = this.calcY(y, height)

    if (flipAxes) {
      yValue -= barOffset

      const yWidth = yValue + barWidth

      return `M${0},${yWidth.toFixed(2)}V${yValue.toFixed(2)}H${xValue.toFixed(2)}V${yWidth.toFixed(2)}Z`
    }

    xValue -= barOffset

    return `M${xValue.toFixed(2)},${height}V${yValue.toFixed(2)}H${(xValue + barWidth).toFixed(2)}V${height}Z`
  }

  private createPathPartBar (
    container: HTMLElement,
    x: number,
    y: number,
    style: string,
    width: number,
    height: number,
    traceIndex: number,
    partIndex: number,
  ): void {
    const g = SVG.getG()

    g.setAttribute('class', 'point fast-update')
    g.setAttribute('id', `fast-update-bar-${traceIndex}-${partIndex}`)

    const path = SVG.getPath()

    path.setAttribute('style', style)

    path.setAttributeNS(
      null,
      'd',
      this.buildPathDBar(x, y, width, height, traceIndex),
    )

    g.appendChild(path)

    container.appendChild(g)
  }

  protected override getStyles (plot: HTMLElement): Array<string> {
    const bars = plot.querySelectorAll('.point:not(.fast-update):first-of-type path')

    return Array.prototype.map.call(bars, bar => bar.getAttribute('style')) as string[]
  }

  private _calcBarWidth (plot: HTMLElement) {
    const bars = plot.querySelectorAll('.points .point:not(.fast-update):first-of-type path')

    if (!bars || !bars.length) {
      throw new Error(FastBase.TRACE_MISSING)
    }

    const d = bars[0]?.getAttributeNS(null, 'd')
    const matches = /M(-?[\d.]+).*H(-?[\d.]+).*/.exec(d ?? '') ?? []

    this.barWidth = Number(matches[2] ?? '0') - Number(matches[1] ?? '0') || 1
  }

  protected override createTrace (
    plot: HTMLElement,
    barsContainer: HTMLElement,
    data: any,
    style: string,
    traceIndex: number,
  ) {
    const { x, y } = data
    const { width, height } = this.getMeta(plot)

    this.calcNeededValues(plot, data)
    this._calcBarWidth(plot)

    for (let i = 0; i < y.length; i++) {
      this.createPathPartBar(barsContainer, x[i], y[i], style, width, height, traceIndex, i)
    }
  }

  private hexColorToRgb (hex: string): string {
    return `rgb(${parseInt(hex.slice(1, 3), 16)}, ${parseInt(hex.slice(3, 5), 16)}, ${parseInt(hex.slice(5), 16)})`
  }

  protected override getSortedData (data: Array<any>, plot: HTMLElement): Array<any> {
    const traces = plot.querySelectorAll('.points .point:not(.fast-update):first-of-type path')

    return Array.prototype.map.call(traces ?? [], (trace: HTMLElement) => {
      const d = data.filter(d => trace.getAttribute('style')?.includes(this.hexColorToRgb(d.line.color)))[0]

      if (!d) {
        throw new Error(FastBase.TRACE_MISSING)
      }

      return d
    })
  }

  protected override updateTrace (plot: HTMLElement, data: any, previousValues: number[], traceIndex: number) {
    const { x, y } = data
    const { width, height } = this.getMeta(plot)

    this.calcNeededValues(plot, data)
    this._calcBarWidth(plot)

    for (let i = 0; i < y.length; i++) {
      const axisToCompare: number[] = this.additionalData?.flipAxes ? x : y

      if (Boolean(previousValues) && axisToCompare[i] === previousValues[i]) {
        continue
      }

      const bar = plot.querySelector(`#fast-update-bar-${traceIndex}-${i} path`)

      if (!bar) {
        return
      }

      bar.setAttributeNS(
        null,
        'd',
        this.buildPathDBar(x[i], y[i], width, height, traceIndex),
      )
    }
  }
}
