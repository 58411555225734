import { request } from '@/api'

export type CDSTimespan = [number, number]

export type CDSData = Record<string, number>

const PATH = 'caster-data-server'

export function getTimespans (plant: string): Promise<CDSTimespan[] | null> {
  // TODO: translate message
  return request('get', `${PATH}/timespans/${plant}`, 'Failed to get timespans')
}

export function getData (plant: string, timestamp: number): Promise<CDSData | null> {
  // TODO: translate message
  return request('get', `${PATH}/data/${plant}/${timestamp}`, 'Failed to get data')
}
