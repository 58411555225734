import { driver, DriveStep } from 'driver.js'

import 'driver.js/dist/driver.css'
import openProject from './Steps/OpenProject'
import sliderTest from './Steps/SliderTest'

export type DriverStep = Omit<DriveStep, 'element'> & { element?: string }

type Driver = {
  isActive: () => boolean
  // refresh: typeof requireRefresh;
  drive: (stepIndex?: number) => void
  // setConfig: typeof configure;
  setSteps: (steps: DriverStep[]) => void
  // getConfig: typeof getConfig;
  // getState: typeof getState;
  getActiveIndex: () => number | undefined
  isFirstStep: () => boolean
  isLastStep: () => boolean
  getActiveStep: () => DriverStep | undefined
  getActiveElement: () => Element | undefined
  getPreviousElement: () => Element | undefined
  getPreviousStep: () => DriverStep | undefined
  moveNext: () => void
  movePrevious: () => void
  moveTo: (index: number) => void
  hasNextStep: () => DriverStep | false
  hasPreviousStep: () => DriverStep | false
  highlight: (step: DriverStep) => void
  destroy: () => void
}

export enum DriverType {
  OpenProject = 'openProject',
  SliderTest = 'sliderTest',
}

export class DriverManager {
  private static readonly driverSteps: Record<DriverType, any> = {
    openProject,
    sliderTest,
  }

  private static currentDriver: Driver | null = null

  public static get current () {
    return DriverManager.currentDriver
  }

  private static handleClick (event: MouseEvent) {
    if (!DriverManager.currentDriver) {
      return
    }

    const activeStep = DriverManager.currentDriver.getActiveStep()
    const stepElement = activeStep?.element as string ?? ''

    if (stepElement === '') {
      return
    }

    if (activeStep?.popover?.showButtons?.includes('close')) {
      return
    }

    if (!(event.target instanceof Element) || !event.target.closest(stepElement)) {
      return
    }

    if (DriverManager.currentDriver.isLastStep()) {
      DriverManager.currentDriver.destroy()

      return
    }

    DriverManager.currentDriver.moveNext()
  }

  private static reposition () {
    // console.log('reposition')

    const driver = DriverManager.currentDriver

    if (!driver || !driver.isActive()) {
      return
    }

    driver.movePrevious()
    driver.moveNext()
  }

  private static registerEvents () {
    window.addEventListener('resize', DriverManager.reposition)

    document.addEventListener('click', DriverManager.handleClick)
  }

  private static unregisterEvents () {
    window.removeEventListener('resize', DriverManager.reposition)

    document.removeEventListener('click', DriverManager.handleClick)
  }

  public static init (driverType: DriverType) {
    if (DriverManager.currentDriver) {
      DriverManager.currentDriver.destroy()
    }

    DriverManager.currentDriver = driver({
      allowKeyboardControl: false,
      showButtons: [ 'next', 'close' ],
      steps: DriverManager.driverSteps[driverType],
      overlayColor: 'dimgray',
      // DriverManager needs to be false to prevent clicking outside from closing the driver
      allowClose: false,
      // DriverManager is a workaround for the button not being displayed because allowClose is set to false
      onPopoverRender: (popover) => {
        popover.closeButton.style.display = ''
      },
      // DriverManager is a workaround for the button not working because allowClose is set to false
      onCloseClick: () => {
        DriverManager.currentDriver?.destroy()
      },
      onDestroyed: () => {
        DriverManager.unregisterEvents()
      },
    }) as Driver

    DriverManager.registerEvents()

    DriverManager.currentDriver.drive()
  }

  public static getOrCreateElement (id: string, parent: HTMLElement | null): HTMLElement {
    let element = document.getElementById(id)

    if (!element) {
      element = document.createElement('div')
      element.id = id
      ;(parent ?? document.body).appendChild(element)
    }

    return element
  }
}
