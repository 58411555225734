import type { CasterElementNames } from '@/types/data'
import type { ElementMaps } from '@/types/state'

import { nozzleGeneratedFields } from './NozzleDefinition'
import { rollerGeneratedFields } from './RollerDefinition'

type GenerateFunction = (element: any, elementMaps: ElementMaps) => any

const generatedFieldsByType: Partial<
  Record<CasterElementNames, Record<string, GenerateFunction | undefined>>
> = {
  Nozzle: { ...nozzleGeneratedFields },
  Roller: { ...rollerGeneratedFields },
}

export function isGeneratedField (
  elementType: CasterElementNames,
  fieldName: string,
): boolean {
  return Boolean(generatedFieldsByType[elementType]?.[fieldName])
}

export function generateField (
  elementType: CasterElementNames,
  fieldName: string,
  element: any,
  elementMaps: ElementMaps,
): any {
  const generateFunction = generatedFieldsByType[elementType]?.[fieldName]

  if (!generateFunction) {
    return null
  }

  return generateFunction(element, elementMaps)
}
