import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import { ConnectedProps, connect } from 'react-redux'
import styled from 'styled-components'

import FeatureFlags from '@/react/FeatureFlags'
import { getElementMapsObject } from '@/store/elements/logic'
import Util from '@/three/logic/Util'
import type { CasterDialogElementType } from '@/types/filter'
import type { DefaultState } from '@/types/state'

import { FormBuilderUtil } from './FormBuilderUtil'
import CalculateHelperFunctions from '../functions/CalculateHelperFunctions'
import Input from '../Input'
import LabelWithSuggestion from '../LabelWithSuggestion'

const InfoIcon = styled(FontAwesomeIcon)`
  cursor:     pointer;
  font-size:  12px;

  &:hover {
    color: #E00A24;
  }
`
const connector = connect((state: DefaultState) => ({
  editElements: state.data.editElements,
  catalogElement: state.data.catalogElement,
  ...getElementMapsObject(state),
  featureFlags: FeatureFlags.getRealFeatureFlags(state),
}), {
})

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = PropsFromRedux & {
  type: CasterDialogElementType
  path: string
  paths: string[]
  massForm: boolean
  onInput: ({ target }: { target: { name: string, value: any } }) => void
  onUndo: (event: any) => void
  isComparingCasters?: boolean | undefined
  def: any
  element: any
  attribute: string
  selectedParentIds: { Segment: number, SegmentGroup: number }
  hidePaths: string[]
}

const FormBuilderInput = (props: Props) => {
  const {
    type,
    path,
    editElements,
    massForm,
    onInput: handleInput,
    onUndo: handleUndo,
    isComparingCasters,
    SegmentGroupMountLog,
    SupportPointMountLog,
    featureFlags,
    def,
    element,
    attribute,
  } = props
  const { t } = useTranslation('caster')
  const elementMaps = getElementMapsObject(props)

  const handleMassValue = (attribute: string, firstVal: any, isCompareCaster = false, elementMaps?: any) => {
    const { paths, editElements } = props
    let val = true

    if (paths?.length < 2 || (isCompareCaster && !elementMaps)) {
      return false
    }

    paths.forEach((path) => {
      // TODO: fix type
      if (isCompareCaster) {
        const { type, id } = Util.getElementInfo(path)

        if (
          (elementMaps?.[type] ?? {})[id] &&
          ((elementMaps?.[type] ?? {})[id] ?? {})[attribute] !== firstVal
        ) {
          val = false
        }

        return
      }

      if ((editElements as any)[path] && ((editElements as any)[path] as any)[attribute] !== firstVal) {
        val = false
      }
    })

    return val
  }
  const handleHelper = (type: string, value: any) => {
    const { onInput } = props
    const name = type

    onInput({ target: { name, value } })
  }
  const handleGetLabel = (attribute: string, massValue: boolean, elementValue: any) => {
    const {
      catalogElement,
      editElements,
      type,
      selectedParentIds,
      hidePaths,
      featureFlags,
    } = props
    const infoIcon = <InfoIcon icon={faInfoCircle} />
    const label = t(`formBuilder.keys.${attribute}.label`, { defaultValue: attribute })

    if (
      FeatureFlags.canEditNozzle(featureFlags) &&
      attribute === 'passlineCoord' &&
      type === 'Nozzle'
    ) {
      const passLn = []

      Object.values(editElements).forEach(element => passLn.push(Number(element.passlineCoord)))

      if (passLn.length === 0) {
        passLn.push(elementValue)
      }

      const value = CalculateHelperFunctions
        .calculatePassLn(Math.min(...passLn), selectedParentIds, elementMaps, hidePaths)

      if (Number(elementValue) === Number(value)) {
        return (
          <LabelWithSuggestion
            name={label}
            value={infoIcon}
            title={t('formBuilder.suggestion.same')}
            type={type}
          />
        )
      }

      if (!value) {
        return (
          <LabelWithSuggestion
            name={label}
            value={infoIcon}
            title={t('formBuilder.suggestion.notFound')}
            type={type}
          />
        )
      }

      return (
        <LabelWithSuggestion
          onHelper={handleHelper}
          name={label}
          value={value || null}
          display={`(${value || null})`}
          title={t('formBuilder.suggestion.set')}
          type={type}
        />
      )
    }

    if (massValue && elementValue !== undefined && elementValue === catalogElement[attribute]) {
      return (
        <LabelWithSuggestion
          name={label}
          value={infoIcon}
          title={t('formBuilder.suggestion.equal')}
          type={type}
        />
      )
    }

    return (
      <LabelWithSuggestion
        onHelper={handleHelper}
        name={label}
        value={catalogElement[attribute] || null}
        display={`(${catalogElement[attribute] || null})`}
        title=''
        type={type}
      />
    )
  }

  const passLn = []

  Object.values(editElements).forEach(element => passLn.push(Number(element.passlineCoord)))

  const canEditElement = FeatureFlags.canEditElement(type, featureFlags)
  const canViewAttributes = FormBuilderUtil.canViewAttributes(
    type,
    props.paths,
    featureFlags,
  )
  const typeIsNotEditable = [ 'SensorPoint', 'DataLine', 'DataPoint' ].includes(type)
  const min = def.min ? def.min : def.computeMin && def.computeMin(element)
  const max = def.max ? def.max : def.computeMax && def.computeMax(element)
  const value = path && def.generate ? def.generate(element, elementMaps) : (element as any)[attribute]
  const isDisabled = attribute === 'id' ||
    Boolean(isComparingCasters) ||
    !canViewAttributes ||
    !canEditElement ||
    (
      !path
        ? false
        : (typeIsNotEditable
          ? true
          : def.disabled ?? (
            def.computeDisabled &&
            def.computeDisabled(
              element,
              editElements,
              SegmentGroupMountLog,
              SupportPointMountLog,
            )
          ))
    )
  const label = handleGetLabel(
    attribute,
    handleMassValue(
      attribute,
      (element as any)[attribute],
    ),
    (element as any)[attribute],
  )

  return (
    <Input
      attribute={attribute}
      label={label}
      title={t(`formBuilder.keys.${attribute}.title`, { defaultValue: attribute })}
      name={attribute}
      type={def.type ?? 'text'}
      min={min}
      max={max}
      step={def.step}
      decimals={def.decimals}
      value={value ?? ''}
      elementType={type}
      error={massForm ? handleMassValue(attribute, (element as any)[attribute]) : true}
      disabled={isDisabled}
      options={def.options}
      handleUndo={handleUndo}
      hasFilter={type === 'DataLine' ? !(attribute === '_xCoords' || attribute[1] === 'y') : true}
      onChange={handleInput}
      path={path}
      hideValue={!canViewAttributes}
      isComparingCasters={isComparingCasters}
      onlyPositiveNumbers={def.type === 'number' && def.positive}
    />
  )
}

export default connector(FormBuilderInput)
