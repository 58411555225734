import { MatrixActionsEnum } from './consts'

export function setColumns (columns: Columns): any {
  return {
    type: MatrixActionsEnum.ACTION_SET_COLUMNS,
    columns,
  }
}

export function setSelections (selections: Selections): any {
  return {
    type: MatrixActionsEnum.ACTION_SET_SELECTIONS,
    selections,
  }
}

export function setGrid (grid: Grid): any {
  return {
    type: MatrixActionsEnum.ACTION_SET_GRID,
    grid,
  }
}

export function setCloneSimulationCaseId (id: string): any {
  return {
    type: MatrixActionsEnum.ACTION_SET_CLONE_SIMULATION_CASE_ID,
    id,
  }
}

export function updateResultData (data: ResultData): any {
  return {
    type: MatrixActionsEnum.ACTION_UPDATE_RESULT_DATA,
    data,
  }
}

export function setLastScrollPosition (lastScrollTopPosition: number): any {
  return {
    type: MatrixActionsEnum.ACTION_SET_SCROLL_POSITION,
    lastScrollTopPosition,
  }
}
