import * as ReactDOMClient from 'react-dom/client'
import { Provider } from 'react-redux'

import Index from '@/store/'
import TitleBarWrapper from '@/TitleBar/react/TitleBarWrapper'

const container = document.getElementById('titlebar-content')

if (!container) {
  throw new Error('Could not find titlebar container')
}

const root = ReactDOMClient.createRoot(container)

const content = (
  <Provider store={Index.getStore()}>
    <TitleBarWrapper />
  </Provider>
)

root.render(content)
