import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'
import { Component } from 'react'
import { compose } from 'redux'
import styled, { css } from 'styled-components'

import { ResizeDetector } from '@/react/ResizeDetector'

function getIcon ({ icon }: any, color: string) {
  // eslint-disable-next-line no-undef
  return btoa(`
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ${icon[0]} ${icon[1]}">
      <path fill="${color}" d="${icon[4]}" class=""></path>
    </svg>
  `)
}

const pad = 20

const Wrapper = styled(ResizeDetector)`${({ theme }) =>
  css`
  height: 100%;
  position: relative;
  padding: 0 ${pad}px;
  overflow-y: auto;

  &[data-arrow-down="on"]:after,
  &[data-arrow-up="on"]:before {
    content: '';
    position: fixed;
    z-index: 10;
    height: 20px;
    display: inline-block;
  }

  &[data-arrow-up="on"]:before {
    background:
      url('data:image/svg+xml;base64,${getIcon(faChevronUp, theme['colors'].swatch9)}') transparent no-repeat;
    background-position: center center;
    background-size: auto;
    top: 40px;
    width: 20px;
    left: calc(50% - 40px);
  }

  &[data-arrow-down="on"]:after {
    background:
      url('data:image/svg+xml;base64,${getIcon(faChevronDown, theme['colors'].swatch9)}') transparent no-repeat;
    background-position: center center;
    background-size: auto;
    bottom: 20px;
    width: 20px;
    left: calc(50% - 40px);
  }

  &::-webkit-scrollbar {
    display: none;
  }
`}`

const Message = styled.div<{ $type: string }>`${({ theme, $type }) =>
  css`
  color: ${$type === 'warning' ? theme['colors'].swatch16 : theme['colors'].swatch15};
`}`

type Props = {
  id?: string
  message: string
}

type State = {
  messages: { time?: any, message: string, type: string }[]
}

class MessageWrapper extends Component<Props, State> {
  private wrapperRef?: HTMLElement

  public override state: State = {
    messages: [ {
      type: 'warning',
      message: this.props.message,
    } ],
  }
  
  public override componentDidUpdate () {
    this.handleResize()
  }
  
  private readonly handleMouseDown = (event: any) => {
    if (!this.wrapperRef) {
      return
    }

    const offset = event.pageY - this.wrapperRef.getBoundingClientRect().top
    const containerHeight = this.wrapperRef.getBoundingClientRect().height

    if (event.button === 0) {
      if (offset <= 25) {
        this.handleWheel({
          target: this.wrapperRef,
          deltaY: -10,
        })
      }

      if (containerHeight - offset <= 25) {
        this.handleWheel({
          target: this.wrapperRef,
          deltaY: 10,
        })
      }
    }
  }

  private readonly handleWheel = (event: any) => {
    if (!this.wrapperRef) {
      return
    }

    const target = this.wrapperRef

    target.scrollTop += event.deltaY

    if (target.scrollTop === 0) {
      target.setAttribute('data-arrow-up', 'off')
    }
    else {
      target.setAttribute('data-arrow-up', 'on')
    }

    if (target.offsetHeight + target.scrollTop === target.scrollHeight) {
      target.setAttribute('data-arrow-down', 'off')
    }
    else {
      target.setAttribute('data-arrow-down', 'on')
    }
  }

  private readonly handleWrapperRef = (ref: any) => {
    if (ref) {
      this.wrapperRef = ref
    }
  }

  private readonly handleResize = () => {
    this.handleWheel({
      target: this.wrapperRef,
      deltaY: 0,
    })
  }
  
  public override render () {
    const { messages } = this.state

    return (
      <Wrapper
        ref={this.handleWrapperRef}
        onWheel={this.handleWheel}
        onResize={this.handleResize}
        onMouseDown={this.handleMouseDown}
      >
        <div>
          {
            messages.map(({ time, message, type }, index) => (
              <Message key={index} $type={type}>
                {time || null} {message}
              </Message>
            ))
          }
        </div>
      </Wrapper>
    )
  }
}

export default compose<any>()(MessageWrapper)
