import Button from '@mui/material/Button'
import styled from 'styled-components'

export const ActionButton = styled(Button)`
  min-width: 30px !important;
  height: 22px;
  line-height: 10px !important;
  font-size: 13px !important;
  color: #e5e9f2 !important;
  font-weight: 400 !important;
  text-transform: capitalize !important;
  padding: 0 !important;

  &:not(:last-of-type) {
    margin-right: 5px;
  }
`
