import { faCheckCircle } from '@fortawesome/free-regular-svg-icons'
import { faExclamationTriangle, faLock, faSyncAlt } from '@fortawesome/free-solid-svg-icons'

import { allVerified } from '@/logic/case-verification'
import ApiClient from '@/store/apiClient'

import Logic from '.'

export default class Getters {
  public static getMissingData = (missingData: ResultData): void => {
    const { updateResultData } = Logic.dialogInstance.props
    const dataIds = Object.keys(missingData ?? {})

    if (dataIds.length === 0) {
      return
    }

    ApiClient
      .get(`${'Network.URI(deprecated)'}/matrix/result_data`, { params: { dataIds: dataIds.join(',') } })
      .then(({ resultData }) => {
        updateResultData(resultData)
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error)

        const resetData = dataIds.reduce((data, dataId) => ({
          ...data,
          [dataId]: null,
        }), {})

        updateResultData(resetData)
      })
  }

  public static getStatus = (simulationCase?: SimulationCase) => {
    if (!simulationCase) {
      return
    }

    const { caseVerifications, t } = Logic.dialogInstance.props
    const isVerified = allVerified(simulationCase.id, caseVerifications, 'default') // FIXME: get selected catalog
    const { simulationStoppedManually } = simulationCase

    if (!isVerified && !simulationCase.simulationStartedAt) {
      return { fixedWidth: true, icon: faExclamationTriangle, title: t(`${Logic.T}.status.notVerified`) }
    }

    if (isVerified && !simulationCase.simulationStartedAt) {
      return { fixedWidth: true, icon: faCheckCircle, title: t(`${Logic.T}.status.notStarted`) }
    }

    if (isVerified && !simulationCase.simulationDataReceivedAt) {
      return { fixedWidth: true, icon: faSyncAlt, spin: true, title: t(`${Logic.T}.status.started`) }
    }

    if (simulationStoppedManually) {
      return { fixedWidth: true, icon: faLock, title: t(`${Logic.T}.status.doneManually`) }
    }

    return { fixedWidth: true, icon: faLock, title: t(`${Logic.T}.status.done`) }
  }
}
