/**
 * Forces the static member `NAME` of a class to be set to a unique string.
 * Apply this decorator to a static member `NAME` of a class to ensure that it is unique.
 *
 * Usage: `@Identifiable('MyClass') public static readonly NAME: string`
 */
export function Identifiable (name: string) {
  window.identifiableRegistry = window.identifiableRegistry ?? []

  return function (target: any, propertyKey: string | symbol) {
    if (propertyKey !== 'NAME' || typeof target !== 'function') {
      throw new Error(`@Identifiable('${name}') should only be applied to public static readonly member "NAME".`)
    }

    if (target.NAME) {
      throw new Error(`NAME should not be initialized before applying @Identifiable('${name}').`)
    }

    if (window.identifiableRegistry?.includes(name)) {
      throw new Error(`Identifiable name '${name}' is already in use.`)
    }

    window.identifiableRegistry?.push(name)

    target.NAME = name
  }
}
