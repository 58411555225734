import { Vector2 } from 'three'

import UIView from '.'
import CalculationUtil from './CalculationUtil'
import UiViewHandlers from './UiViewHandlers'

export default class EventHandlers {
  public static handleMouseDownAction (name: string, view: UIView) {
    if (!view.views || !view.views.sectionView || !view.views.mainView) {
      return
    }

    let updatePlots = false

    switch (name) {
      case 'scrollBarSlider':
        view.scrollBarButtonGroupActive = true
        view.views.mainView.controls.enabled = false
        break
      case 'scrollBarBtnUp':
        view.views.sectionView.jumpUp = true
        view.views.sectionView.animate()
        updatePlots = true
        break
      case 'scrollBarBtnDown':
        view.views.sectionView.jumpDown = true
        view.views.sectionView.animate()
        updatePlots = true
        break
      case 'switchScrollElement':
        UiViewHandlers.handleSwitchScrollElement(view)
        break
      default:
    }

    // ThreeManager.base.renderScene()

    if (updatePlots) {
      window.dispatchEvent(new CustomEvent('FinishedScrollingPasslnCoord'))
    }
  }

  public static handleSelectionEnd (view: UIView, mouseOnCanvas: Vector2) {
    view.views?.mainView?.handleSelection(view.downMousePosition, mouseOnCanvas)

    UiViewHandlers.hideSelectionRect(view.selectionRect)
  }

  public static handleMouseUpAction (action: string, view: UIView) {
    if (!view.views || !view.views.mainView || !view.views.sectionView) {
      return
    }

    if (action === 'jumpToSection') {
      UiViewHandlers.handleJumpToSection(view)
    }
  }

  public static handleSelection (view: UIView, mouseOnCanvas: Vector2) {
    const x = Math.abs(view.downMousePosition.x - mouseOnCanvas.x)
    const y = Math.abs(view.downMousePosition.y - mouseOnCanvas.y)

    // direction of the selection in each axis
    const sX = view.downMousePosition.x > mouseOnCanvas.x ? 1 : -1
    const sY = view.downMousePosition.y < mouseOnCanvas.y ? 1 : -1

    view.selectionRect.scale.x = x ?? 0.00001
    view.selectionRect.scale.y = y ?? 0.00001

    view.selectionRect.position.x = -view.viewport.width / 2 + (view.downMousePosition.x - x / 2 * sX)
    view.selectionRect.position.y = view.viewport.height / 2 - (view.downMousePosition.y + y / 2 * sY)
  }

  public static handleScroll (view: UIView, mouseOnCanvas: Vector2) {
    view.scrolling = true

    const { height, min } = view.scrollBarButtonGroup.userData

    const max = CalculationUtil.calcMaxPosition(min, 150, 50, height, view.viewport.height)

    const y = mouseOnCanvas.y - height / 2
    let newScrollValue = CalculationUtil.calcNewScrollValue(min, max, y)

    if (newScrollValue < 0) {
      newScrollValue = 0
    }
    else if (newScrollValue > 1) {
      newScrollValue = 1
    }

    const targetHeight = CalculationUtil.calcTargetHeight(newScrollValue, view.plHeight ?? 0)

    view.views?.sectionView?.setView(targetHeight)
  }
}
