import Util from '@/logic/Util'
import type { ElementMaps, TagName } from '@/types/state'
import { Mapping } from '@/Util/mapping/Mapping'

export class AllPaths {
  private static areAllChildrenSelected (
    elementType: TagName,
    elementMountLogId: string,
    parentPath: string,
    allPaths: Set<string>,
    elementMaps: ElementMaps,
  ) {
    const numericId = Mapping.numericIdByMountLogId[elementMountLogId]
    const path = `${(parentPath ? `${parentPath}/` : '') + elementType}:${numericId}`
    const childTypes = Util.getChildTypeForRealData(elementType)

    for (const childType of childTypes) {
      const childMountLogIdsType = `${
        childType[0]?.toLocaleLowerCase() + childType.substring(1)
      }MountLogs` as keyof BaseMountLog
      const childMountLogIds =
        (elementMaps[`${elementType}MountLog` as keyof ElementMaps] as Record<string, BaseMountLog>)[elementMountLogId]
          ?.[childMountLogIdsType] as unknown as string[] ?? []

      for (const childMountLogId of childMountLogIds) {
        AllPaths.areAllChildrenSelected(
          childType,
          childMountLogId,
          path,
          allPaths,
          elementMaps,
        )
      }
    }

    allPaths.add(path)
  }

  public static generate (elementMaps: ElementMaps) {
    const allPaths = new Set<string>()
    const { SegmentGroupMountLog } = elementMaps

    for (const segmentGroupMountLogId in SegmentGroupMountLog) {
      AllPaths.areAllChildrenSelected(
        'SegmentGroup',
        segmentGroupMountLogId,
        '',
        allPaths,
        elementMaps,
      )
    }

    return allPaths
  }
}
