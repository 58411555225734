import type { MountLogIdByTypeAndNumericId, NumericIdByMountLogId } from './NumericIdMapping'

export class Mapping {
  public static numericIdByMountLogId: NumericIdByMountLogId = {}

  public static mountLogIdByTypeAndNumericId: MountLogIdByTypeAndNumericId = {} as MountLogIdByTypeAndNumericId

  public static numericIdByCaseIdAndMountLogId: Record<string, NumericIdByMountLogId> = {}

  public static mountLogIdByCaseIdAndTypeAndNumericId: Record<string, MountLogIdByTypeAndNumericId> = {}

  public static elementPathByMountLogId: Record<string, string> = {}

  public static mountLogIdByElementPath: Record<string, string> = {}

  // used for compare
  public static elementPathByMountLogIdByCaseId: Record<string, Record<string, string>> = {}
}
