import { request } from '@/api'
import type { PlotConfig, TileConfig, ViewsObject } from '@/types/visualization'

const PATH = 'visualization-configs'

export function uploadVisualizationConfig (
  file: File,
  projectId: string,
  appState: AppState,
): Promise<VisualizationConfig | null> {
  const formData = new FormData()

  formData.append('file', file)
  formData.append('projectId', projectId)
  formData.append('appState', appState)

  const errorText = 'Failed to upload visualization config' // TODO: translate message

  return request<VisualizationConfig>('post', PATH, errorText, { data: formData })
}

export function getVisualizationConfigById (
  configId: string,
  params?: any,
  silent = false,
): Promise<VisualizationConfig | null> {
  const errorText = 'Failed to get visualization config' // TODO: translate message

  return request<VisualizationConfig>('get', `${PATH}/${configId}`, errorText, { params }, silent)
}

export function getVisualizationConfigsByAppState (
  projectId: string,
  appState: AppState,
  params?: any,
): Promise<VisualizationConfig[] | null> {
  const errorText = 'Failed to get visualization configs' // TODO: translate message

  return request<VisualizationConfig[]>('get', PATH, errorText, { params: { projectId, appState, ...params } })
}

export function deleteVisualizationConfig (configId: string): Promise<void> {
  const errorText = 'Failed to delete visualization config' // TODO: translate message

  return request<void>('del', `${PATH}/${configId}`, errorText) as Promise<void>
}

export function setVisualizationConfig (
  configId: string,
  data: {
    viewsObject: ViewsObject
    plotConfigs: Record<string, PlotConfig>
    tileConfigs: Record<string, TileConfig>
  },
): Promise<void> {
  if (
    !configId ||
    configId.startsWith('group-') ||
    !data.viewsObject ||
    Object.entries(data.viewsObject).length === 0
  ) {
    return Promise.resolve()
  }

  const errorText = 'Failed to set visualization config' // TODO: translate message

  return request<void>('patch', `${PATH}/${configId}`, errorText, { data: { d: 1, data } }) as Promise<void>
}

export async function getGroupVisualizationConfigFile (fileId: string, silent = false) {
  const errorText = 'Failed to get group visualization config file' // TODO: translate message

  const stringConfig = await request<VisualizationConfigFile>('get', `files/${fileId}`, errorText, {}, silent)

  if (!stringConfig) {
    return null
  }

  return stringConfig
}

export async function checkVisConfigNameAvailability (name: string, projectId: string): Promise<boolean> {
  const errorText = 'Failed to check visualization config name availability' // TODO: translate message

  const res = await request<{ available: boolean }>('get', `${PATH}/check-name`, errorText, {
    params: { name, projectId },
  })

  return res?.available ?? false
}

export function renameVisualizationConfig (configId: string, name: string) {
  const errorText = 'Failed to rename visualization config' // TODO: translate message

  return request<void>('patch', `${PATH}/${configId}`, errorText, { data: { name } }) as Promise<void>
}
