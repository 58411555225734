import styled, { css, keyframes } from 'styled-components'

export const Dialog = styled.div<{
  $width?: string
  $height?: string
  $noBottomBorder?: boolean
  $half?: boolean
}>`${({ theme, $width, $height, $noBottomBorder, $half }) =>
  css`
  position: fixed;
  top: calc(50% + 32px);
  left: 50%;
  height: ${$height ?? '80vh'};
  width: ${$width ?? ($half ? '331px' : '662px')};
  transform: translate(-50%, -50%);
  background: ${theme['colors'].swatch5};
  z-index: 5000;
  border: 1px solid ${theme['borderColor']};
  border-radius: 10px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.5);
  overflow: hidden;
  ${$noBottomBorder && 'border-bottom: none;'}
`}`

export const Bottom = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  height: 65px;
`

export const DialogBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  filter: blur(5px);
  transform: scale(1.1);
  z-index: 2000;
`

export const Header = styled.div`${({ theme }) =>
  css`
  position: relative;
  display: inline-block;
  width: 100%;
  height: 60px;
  line-height: 60px;
  border-bottom: 1px solid ${theme['borderColor']};
`}`

export const Form = styled.div<{
  $maxWidth?: string
  $padding?: string
  $minHeight?: number
}>`${({ $maxWidth, $padding, $minHeight }) =>
  css`
  display: inline-block;
  width: 100%;
  ${$maxWidth && `max-width: ${$maxWidth};`}
  padding: ${$padding ?? '12px 28px 30px'};
  min-height: ${$minHeight ?? 250}px;
`}`

export const FormWrapper = styled.div<{ $fullscreen?: boolean }>`${({ $fullscreen }) =>
  css`
  display: inline-block;
  position: absolute;
  overflow-y: auto;
  overflow-x: hidden;
  left: 20px;
  right: 0;
  padding-right: 20px;
  bottom: 70px;
  top: ${$fullscreen ? '12px' : '70px'};
`}`

export const Title = styled.div`${({ theme }) =>
  css`
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  color: ${theme['colors'].swatch9};
  user-select: none;
  font-size: 20px;
  text-align: center;
  cursor: default;
  max-width: 70%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`}`

export const Text = styled.div<{ $fontSize?: number, $margin?: number }>`${({ theme, $fontSize, $margin }) =>
  css`
  color: ${theme['colors'].swatch9};
  user-select: none;
  font-size: ${$fontSize ?? 14}px;
  margin: ${$margin ?? 0}px;
`}`

export const TextBody = styled.div<{ $fontSize?: number, $noMargin?: boolean }>`${(
  { theme, $fontSize, $noMargin },
) =>
  css`
  color: ${theme['colors'].swatch9};
  user-select: none;
  font-size: ${$fontSize ?? 14}px;
  max-height: 300px;
  overflow: auto;
  margin: ${$noMargin ? 0 : '10px 0 55px 0'};

  ul > {
    margin: 0;
  }
`}`

export const I = styled.i`${({ theme }) =>
  css`
  position: absolute;
  font-size: 40px;
  color: ${theme['colors'].swatch9};
  right: 10px;
  top: 10px;
  cursor: pointer;
`}`

export const IButton = styled.i`${({ theme }) =>
  css`
  font-size: 26px;
  color: ${theme['colors'].swatch9};
  cursor: pointer;
  margin-top: 5px;
`}`

const loading = keyframes`
  0% {
    background-position: 0
  }
  
  100% {
    background-position: 80px
  }
`

// TODO: DELETE USE BUTTON COMPONENT!!!
export const Button = styled.button<{
  $type?: string
  $isLoading?: boolean
  $half?: boolean
  $noMargin?: boolean
}>`${({ theme, $type, $isLoading, $half, $noMargin }) =>
  css`
  background-color: ${theme[$type ?? 'primary'].main};
  color: ${theme[$type ?? 'primary'].font};
  position: relative;
  text-align: center;
  display: inline-block;
  width: ${!$half ? '100%' : 'calc(50% - 7px)'};
  height: 42px;
  line-height: 42px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  
  &:first-of-type {
    ${
    Boolean($half) && css`
      margin-right: 14px;
    `
  }
  outline: none;
  font-size: 14px;
  ${!$noMargin && css`margin-top: 17px;` as any}

  &:-moz-focusring {
    outline: none;
  }
  ${
    $half && css`
    :first-of-type {
      margin-right: 14px;
    }
  ` as any
  }
  
  
  ${
    $isLoading && css`
    background-image: linear-gradient(
      -45deg, rgba(255,255,255,0.2) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255,255,255,0.2) 50%,
      rgba(255,255,255,0.2) 75%,
      transparent 75%,
      transparent
    );
    background-size: 80px 80px;
    animation: ${loading} 1.5s linear infinite;
    text-shadow: 0 1px 0 #333;
  ` as any
  }
  
  :hover {
    transition: 3ms ease-in;
    background-color: ${theme[$type ?? 'primary'].dark};
  }
  
  :disabled {
    background: ${theme[$type ?? 'primary'].disabled};
    cursor: not-allowed;
  }

  div.cut {
    overflow: hidden;
    border-radius: 50%;
  }

  div {
    width: 20px;
    height: 20px;
    position: absolute;
    left: 11px;
    top: 11px;
  }

  div i {
    font-size: 30px;
    transform: translate(-5px, -5px);
  }
`}`

export const TabsWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 65px;
`

export const Tab = styled.div<{ $activeTab?: boolean }>`${({ theme, $activeTab }) =>
  css`
  position: relative;
  flex: 1;
  min-width: 65px;
  background-color: ${$activeTab ? theme['colors'].swatch5 : theme['colors'].swatch21};
  color: ${$activeTab ? theme['colors'].swatch8 : theme['colors'].swatch9};
  cursor: ${$activeTab ? 'default' : 'pointer'};

  i, span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;
  }
  
  i {
    font-size: 45px;
  }
  
  span {
    font-size: 20px;
  }
`}`

export const ListItem = styled.div`${({ theme }) =>
  css`
  height: 50px;
  margin: 4px auto;
  color: ${theme['mainFontColor']};
  border-bottom: solid 2px ${theme['borderColor']};
  padding: 15px 10px 0;
`}`

export const CheckListHeader = styled.div<{ $width?: string }>`${({ theme, $width }) =>
  css`
  display: inline-block;
  font-size: 16px;
  color: ${theme['mainFontColor']};
  user-select: none;
  ${$width && `width: ${$width};`}

  &:last-of-type {
    display: block;
  }
`}`

export const SourceErrorMessage = styled.div`${({ theme }) =>
  css`
  font-size: 14px;
  margin-top: 12px;
  color: ${theme['colors'].swatch16};

  svg {
    margin-left: 6px;
  }
`}`

export const InputWrapper = styled.div<{ $fullscreen?: boolean, $half?: boolean }>`${({ $fullscreen, $half }) =>
  css`
  ${!$fullscreen && 'display: flex;'}
  ${$half && 'width: calc(50% - 10px);'}
`}`

export const CheckListItem = styled.div<{ $active?: boolean }>`${({ theme, $active }) =>
  css`
  position: relative;
  height: 43px;
  width: 100px;
  margin: 12px auto 0;
  color: ${$active ? theme['colors'].swatch19 : theme['mainFontColor']};
  padding: 15px 0 0 35px;
  cursor: pointer;
  user-select: none;

  &:before {
    content: '';
    display: inline-block;
    position: absolute;
    top: 12px;
    left: 1px;
    width: 20px;
    height: 20px;
    border: 1px solid ${$active ? theme['colors'].swatch19 : theme['mainFontColor']};
    border-radius: 3px;
  }
  
  ${
    !$active && css`
    &:after {
      content: '';
      position: absolute;
      left: 7px;
      top: 22px;
      background: white;
      width: 2px;
      height: 2px;
      box-shadow: 
        2px 0 0 ${theme['mainFontColor']}, 
        4px 0 0 ${theme['mainFontColor']}, 
        4px -2px 0 ${theme['mainFontColor']}, 
        4px -4px 0 ${theme['mainFontColor']},
        4px -6px 0 ${theme['mainFontColor']},
        4px -8px 0 ${theme['mainFontColor']};
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  ` as any
  }
`}`

export const Error = styled.div`${({ theme }) =>
  css`
  width: 100%;
  text-align: center;
  padding: 5px 0;
  color: ${theme['error'].main};
`}`

export const List = styled.ul`
  list-style: none;
  padding-inline-start: 25px;
`

export const Marker = styled.div`${() =>
  css`
  display: flex;
`}`
