import styled from 'styled-components'

export default styled.div`
  display:    inline-block;
  position:   relative;
  width:      100%;
  height:     100%;
  overflow-y: auto;
  overflow-x: hidden;
`
