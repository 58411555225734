import cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'

export class ObjectUtil {
  /**
   * recursively remove undefined values from an object
   */
  private static objectWithoutUndefined (object: any): any {
    const objectCopy = cloneDeep(object)

    if (typeof objectCopy !== 'object') {
      return objectCopy
    }

    if (Array.isArray(objectCopy)) {
      return objectCopy.map((item) => ObjectUtil.objectWithoutUndefined(item))
    }

    const newObj: any = {}

    for (const key in objectCopy) {
      if (objectCopy[key] !== undefined) {
        newObj[key] = ObjectUtil.objectWithoutUndefined(objectCopy[key])
      }
    }

    return newObj
  }

  public static isEqualWithoutUndefined (a: any, b: any): boolean {
    const cleanA = ObjectUtil.objectWithoutUndefined(a)
    const cleanB = ObjectUtil.objectWithoutUndefined(b)

    return isEqual(cleanA, cleanB)
  }
}
