import { request } from '@/api'

const PATH = 'shares'

export type ProjectGroupShare = ProjectScopeBaseEntity & {
  ownerId: string
  groupId: string
}

export type ProjectUserShare = ProjectScopeBaseEntity & {
  ownerId: string
  granteeId: string
}

export function getShares (projectId: string) {
  const errorText = 'Failed to get shares' // TODO: translate message

  return request<{ groupShares: ProjectGroupShare[], userShares: ProjectUserShare[] }>(
    'get',
    `${PATH}/${projectId}`,
    errorText,
  )
}

export function createUserShare (projectId: string, userId: string) {
  const errorText = 'Failed to create user share' // TODO: translate message

  return request<ProjectUserShare>('post', `${PATH}/user`, errorText, { data: { projectId, userId } })
}

export function createGroupShare (projectId: string, groupId: string) {
  const errorText = 'Failed to create group share' // TODO: translate message

  return request<ProjectGroupShare>('post', `${PATH}/group`, errorText, { data: { projectId, groupId } })
}

export function deleteUserShare (shareId: string) {
  const errorText = 'Failed to delete user share' // TODO: translate message

  return request<void>('del', `${PATH}/user/${shareId}`, errorText)
}

export function deleteGroupShare (shareId: string) {
  const errorText = 'Failed to delete group share' // TODO: translate message

  return request<void>('del', `${PATH}/group/${shareId}`, errorText)
}
