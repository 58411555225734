import hoistStatics from 'hoist-non-react-statics'
import hotkeys from 'hotkeys-js'
import { enqueueSnackbar } from 'notistack'
import { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'redux'

import { checkVisConfigNameAvailability, renameVisualizationConfig } from '@/api/visualization-config'
import { useConfig } from '@/config'
import Util from '@/logic/Util'
import Button from '@/react/components/Button'
import Input from '@/react/specific/Input'
import { Form } from '@/react/visualization/dashboard/Dialogs/DialogStyles'
import ApiClient from '@/store/apiClient'
import * as ApplicationActions from '@/store/application/main/actions'
import type { DefaultState } from '@/types/state'
import { Identifiable } from '@/Util/decorators/Identifiable'

import BaseDialog from '../BaseDialog'

const connector = connect((state: DefaultState) => ({
  editConfigId: state.application.main.editConfigId,
  currentProject: state.application.main.currentProject,
}), {
  closeDialog: ApplicationActions.closeDialog,
  setEditConfigId: ApplicationActions.setEditConfigId,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  t(key: string, params?: Record<string, unknown>): string
}

type State = {
  [key: string]: boolean | string
  initialName: string
  name: string
  nameAvailable: boolean
  error: string
  loading: boolean
}

export class EditConfigDialog extends Component<Props, State> {
  @Identifiable('EditConfigDialog') public static readonly NAME: string

  private checkCaseNameTimeout?: NodeJS.Timeout

  public override state: State = {
    initialName: '',
    name: '',
    nameAvailable: true,
    error: '',
    loading: false,
  }
  
  public override componentDidMount () {
    this.handleInit()

    hotkeys('Escape', this.handleClose as any)
  }
  
  public override componentWillUnmount () {
    hotkeys.deleteScope('other')
    hotkeys.unbind('Escape', this.handleClose as any)
  }

  private readonly handleInit = async () => {
    const { editConfigId } = this.props

    try {
      const config = await ApiClient.get(`${useConfig().apiBaseURL}/visualization-configs/${editConfigId ?? ''}`)
      const name = config?.name ?? ''

      this.setState({
        initialName: name,
        name,
      })
    }
    catch (error: any) {
      // eslint-disable-next-line no-console
      console.log(error)
      this.handleClose()
    }
  }

  private readonly handleClose = () => {
    const { closeDialog, setEditConfigId } = this.props

    closeDialog(EditConfigDialog.NAME)
    setEditConfigId()
  }

  private readonly handleInput = (event: any) => {
    const { currentProject } = this.props
    const { name, value }: { name: string, value: string } = event.target

    this.setState({
      [name]: value,
    })

    if (name === 'name') {
      Util.checkNameAvailability(
        value,
        this.state.initialName,
        (state) => this.setState(state),
        this.checkCaseNameTimeout,
        (timeout) => {
          this.checkCaseNameTimeout = timeout 
        },
        checkVisConfigNameAvailability as any,
        currentProject.id,
      )
    }
  }

  private readonly handleKeyDown = (event: any) => {
    if (event.keyCode === 13) {
      this.handleSubmit()
    }
  }

  private readonly handleSubmit = async () => {
    const { name, initialName } = this.state
    const { editConfigId } = this.props

    if (!name || name === initialName) {
      return
    }

    if (!editConfigId) {
      enqueueSnackbar('editConfigId is not defined', { variant: 'error' })

      return
    }

    this.setState({
      loading: true,
    })

    try {
      await renameVisualizationConfig(editConfigId, name)

      this.handleClose()
    }
    catch (error: any) {
      this.setState({
        error: error ? error.status : 'unknown',
        loading: false,
      })
    }

    ApiClient
      .patch(`${useConfig().apiBaseURL}/visualization-configs/${editConfigId}`, { data: { name } })
      .then(() => {
        this.handleClose()
      })
      .catch((response) => {
        const { error } = response

        this.setState({
          error: error ? error.status : 'unknown',
          loading: false,
        })
      })
  }
  
  public override render () {
    const { name, error, loading, nameAvailable } = this.state
    const { t } = this.props
    const saveDisabled = !name.length

    return (
      <BaseDialog
        title={t('editConfigDialog.title')}
        icon='pe-7s-folder'
        header={t('editConfigDialog.header')}
        onClose={this.handleClose}
        small
      >
        <Form>
          <Input
            label={t('editConfigDialog.name.label')}
            title={t('editConfigDialog.name.label')}
            placeholder={t('editConfigDialog.name.placeholder')}
            name='name'
            type='text'
            value={name}
            onChange={this.handleInput}
            onKeyDown={this.handleKeyDown}
            error={!nameAvailable}
            helperText={nameAvailable ? '' : t('editConfigDialog.name.notAvailable')}
          />
          <Button
            type='primary'
            disabled={saveDisabled}
            onClick={this.handleSubmit}
            error={error}
            loading={loading}
            isRef
          >
            {t('editConfigDialog.save')}
          </Button>
          <Button
            value=''
            onClick={this.handleClose}
            title={t('editConfigDialog.cancel')}
          >
            {t('editConfigDialog.cancel')}
          </Button>
        </Form>
      </BaseDialog>
    )
  }
}

const composedComponent = compose<any>(withTranslation('application'), connector)(EditConfigDialog)

export default hoistStatics(composedComponent, EditConfigDialog)
