import { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'redux'

import DataActions from '@/store/data/actions'
import { getElementMapsObject } from '@/store/elements/logic'
import * as FilterActions from '@/store/filter/actions'
// import Util from '@/three/logic/Util'
// import ThreeManager from '@/three/ThreeManager'
import type { DefaultState } from '@/types/state'

import { CCGroup } from './CCGroup'
import { SectionContainer } from '../AllInOne/styles'

const connector = connect((state: DefaultState) => ({
  ...getElementMapsObject(state),
  selectedPaths: state.data.selectedPaths,
  rollerChildren: state.util.rollerChildren,
}), {
  setSelectedElementPaths: DataActions.setSelectedElementPaths,
  setTerm: FilterActions.setTerm,
})

type PropsFromRedux = ConnectedProps<typeof connector>

type State = void

export class CCElementList extends Component<PropsFromRedux, State> {
  private readonly selectElementPerAttributeUUID = (_uuid: string) => {
    // FIXME: this needs to be fixed when Consistency Check is used again

    // const { elementMaps, setSelectedElementPaths, rollerChildren, setTerm } = this.props
    // let selectedPaths: string[] = []

    // if (!uuid) {
    //   setSelectedElementPaths()

    //   return
    // }

    // const elementTypes = Object.keys(elementMaps) as (keyof ElementMaps)[]
    // const isElementTypeSelected = {
    //   Nozzle: false,
    //   Roller: false,
    //   RollerBearing: false,
    //   RollerBody: false,
    //   SensorPoint: false,
    //   DataPoint: false,
    //   DataLine: false,
    // }

    // for (const type of elementTypes) {
    //   const elements =
    //     (elementMaps[type] ? Object.values(elementMaps[type]) : []) as ElementMaps[keyof ElementMaps][]

    //   elements.forEach((element) => {
    //     if (!element) {
    //       return
    //     }

    //     const matchedUUID = Object
    //       .keys(element)
    //       .filter(key => (key.includes('uuid') && uuid.includes(element[key as keyof typeof element])))

    //     if (matchedUUID.length) {
    //       const path = Util.getElementPath(element, elementMaps, `${type}:${element._id}`)

    //       isElementTypeSelected[type] = true

    //       selectedPaths.push(path)
    //     }
    //   })
    // }

    // const action = this.shouldRollerViewGetToggled(isElementTypeSelected, rollerChildren)

    // switch (action) {
    //   case 'FILTER_ONLY_ROLLER':
    //     selectedPaths = this.filteredSelectedPaths(selectedPaths, 'ROLLER')
    //     break
    //   case 'FILTER_ONLY_ROLLER_CHILDREN':
    //     selectedPaths = this.filteredSelectedPaths(selectedPaths, 'ROLLER_CHILDREN')
    //     break
    //   case 'TOGGLE':
    //   // toggle roller children
    //     ThreeManager.toggleRollerVisibility()
    //     break
    //   default:
    //     break
    // }

    // // filter out elements which are invisible because of the filter
    // setTerm('')
    // setSelectedElementPaths()
    // setSelectedElementPaths(selectedPaths, true)
    // ThreeManager.base.jumpToFilter(selectedPaths[0])
  }

  // private filteredSelectedPaths (selectedPaths: string[], selectedType: 'ROLLER_CHILDREN' | 'ROLLER') {
  //   return selectedPaths.filter((path: string) => {
  //     const { type } = Util.getElementInfo(path)

  //     if (selectedType === 'ROLLER' && (type === 'RollerBearing' || type === 'RollerBody')) {
  //       return false
  //     }

  //     return !(selectedType === 'ROLLER_CHILDREN' && type === 'Roller')
  //   })
  // }

  // private readonly shouldRollerViewGetToggled = (isElementTypeSelected: any, rollerChildren: any) => {
  //   if (
  //     rollerChildren === 1 &&
  //     isElementTypeSelected.Roller &&
  //     (
  //       isElementTypeSelected.RollerBearing ||
  //       isElementTypeSelected.RollerBody
  //     )
  //   ) {
  //     return 'FILTER_ONLY_ROLLER'
  //   }

  //   if (
  //     rollerChildren !== 1 &&
  //     isElementTypeSelected.Roller &&
  //     (
  //       isElementTypeSelected.RollerBearing ||
  //       isElementTypeSelected.RollerBody
  //     )
  //   ) {
  //     return 'FILTER_ONLY_ROLLER_CHILDREN'
  //   }

  //   if (
  //     rollerChildren === 1 &&
  //     !isElementTypeSelected.Roller &&
  //     (
  //       isElementTypeSelected.RollerBearing ||
  //       isElementTypeSelected.RollerBody
  //     )
  //   ) {
  //     return 'TOGGLE'
  //   }

  //   if (
  //     rollerChildren !== 1 &&
  //     isElementTypeSelected.Roller &&
  //     !isElementTypeSelected.RollerBearing &&
  //     !isElementTypeSelected.RollerBody
  //   ) {
  //     return 'TOGGLE'
  //   }

  //   return ''
  // }

  public override render () {
    const { CCElement, selectedPaths } = this.props
    const elementMaps = getElementMapsObject(this.props)

    // cc elements grouped by group
    const ccElementsByGroup: any = { default: [] }
    const ccElements = Object.values(CCElement ?? {})
    const elementsLength = ccElements.length

    for (let i = 0; i < elementsLength; i++) {
      if (ccElements[i]?.group === undefined) {
        ccElementsByGroup.default.push(ccElements[i])
        continue
      }

      const ccElement = ccElements[i]

      if (!ccElement || !ccElementsByGroup[ccElement.group]) {
        continue
      }

      if (!ccElementsByGroup[ccElement.group]) {
        ccElementsByGroup[ccElement.group] = []
      }

      ccElementsByGroup[ccElement.group].push(ccElement)
    }

    let ccElementAmountCounter = 0

    return (
      <div>
        <SectionContainer style={{ paddingTop: '5px' }}>
          {
            CCElement && Object.keys(ccElementsByGroup).map((groupKey, index) => {
              const groupIndex = index === 0 ? 0 : ccElementAmountCounter

              ccElementAmountCounter += ccElementsByGroup[groupKey].length ?? 0

              return (
                <CCGroup
                  key={index}
                  index={groupIndex}
                  ccGroup={ccElementsByGroup[groupKey]}
                  groupKey={groupKey as any}
                  selectElementPerAttributeUUID={this.selectElementPerAttributeUUID}
                  elementMaps={elementMaps}
                  selectedPaths={selectedPaths}
                />
              )
            })
          }
        </SectionContainer>
      </div>
    )
  }
}

export default compose<any>(withTranslation('caster'), connector)(CCElementList)
