import { DefinitionHelper } from './DefinitionHelper'

// this could be in the consts.file but it creates a circular dependency
export const defaultFields = {
  // id: { hidden: true },
  projectId: { hidden: true },
  slotHash: { hidden: true },
  blueprintId: { hidden: true },
  mountLogHash: { hidden: true },
  createdAt: { hidden: true },
  updatedAt: { hidden: true },
  mountedAt: { hidden: true },
  removedAt: { hidden: true },
  userId: { hidden: true },
  casterId: { hidden: true },
  originalId: { hidden: true },
  parentId: { hidden: true },
  eDocHRef: { hidden: true },
  slotId: { hidden: true },
  mountLogId: { hidden: true },
  side: { hidden: true },
  realDataUUIDType: { type: 'text', category: 2, defaultValue: '', hidden: true },
  realDataUUID: { type: 'text', category: 2, defaultValue: '', hidden: DefinitionHelper.isBlueprintCase },
}

export const getHiddenFields = (fields: string[]): Record<string, any> =>
  fields.reduce((acc, field) => {
    acc[field] = { hidden: true }

    return acc
  }, {} as Record<string, any>)
