import { request } from '.'

type WarehouseMetadata = {
  availableAmounts: {
    airLoops: number
    coolingLoops: number
    coolingZones: number
    dataLines: number
    dataPoints: number
    nozzles: number
    rollerBearings: number
    rollerBodies: number
    rollers: number
    segmentGroups: number
    segments: number
  }
}

const PATH = 'real-data'

export function getRealDataMetadata (projectId: string): Promise<WarehouseMetadata | null> {
  const errorText = 'Failed retrieve metadata' // TODO: translate message

  return request('get', `${PATH}/${projectId}/metadata`, errorText)
}

export function getRealDataPerType (projectId: string, elementType: string, page: number, take: number): Promise<any> {
  const errorText = 'Failed retrieve data' // TODO: translate message
  const skip = page * take

  return request('get', `${PATH}/${projectId}`, errorText, { params: { elementType, take, skip } })
}

export function getFilteredRealDataPerType (
  projectId: string,
  elementType: string,
  page: number,
  take: number,
  filter: string,
): Promise<{ result: any[], count: number } | null> {
  const errorText = 'Failed retrieve data' // TODO: translate message
  const skip = page * take

  return request('get', `${PATH}/${projectId}`, errorText, { params: { elementType, take, skip, filter } })
}
