import { functionIndex } from './actions'
import LogicInterface from '../LogicInterface'

export default class ServerLogic extends LogicInterface {
  public constructor () {
    super()

    window.addEventListener('beforeunload', event => {
      this.send('closeApplication', event)
    })
  }

  private static events: { [key: string]: any } = {}

  public override on (...params: Array<any>) {
    const [ functionName, callback ] = params

    if (!ServerLogic.events[functionName]) {
      ServerLogic.events[functionName] = callback
    }
  }

  public override send (...params: any[]) {
    const [ functionName, ...restParams ] = params

    if (functionIndex[functionName]) {
      functionIndex[functionName](...restParams)
    }
    else if (ServerLogic.events[functionName]) {
      ServerLogic.events[functionName](...restParams)
    }
  }

  public override removeListener (...params: any[]) {
    const [ functionName ] = params

    delete ServerLogic.events[functionName]
  }

  public override has (functionName: string) {
    return Boolean(ServerLogic.events[functionName])
  }
}
