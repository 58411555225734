import createStore from './createStore'

export default class Index {
  private static store: any = null

  public static getStore () {
    if (!Index.store) {
      const initialState = window.___INITIAL_STATE__

      Index.store = createStore(initialState)
    }

    return Index.store
  }
}
