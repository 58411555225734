/* eslint-env browser */

import hoistStatics from 'hoist-non-react-statics'
import { enqueueSnackbar } from 'notistack'
import { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'redux'

import { getNozzleCatalogs } from '@/api/nozzle-catalog'
import { changeCatalog, deleteCatalog, loadCatalog } from '@/logic/catalogUtil'
import Button from '@/react/components/Button'
import BaseDialog from '@/react/dialogs/BaseDialog'
import FeatureFlags from '@/react/FeatureFlags/index'
import Input from '@/react/specific/Input'
import { Form } from '@/react/visualization/dashboard/Dialogs/DialogStyles'
import * as ErrorActions from '@/store/application/error/actions'
import * as ApplicationActions from '@/store/application/main/actions'
import DataActions from '@/store/data/actions'
import type { DefaultState } from '@/types/state'
import type { ArrayOfTranslations, Translation } from '@/types/translation'
import { Identifiable } from '@/Util/decorators/Identifiable'

import Logic from './Logic'

const T = 'projectDataDialog.caster'

const connector = connect((state: DefaultState) => ({
  currentSimulationCase: state.application.main.currentSimulationCase,
  currentCatalogId: state.data.currentCatalogId,
  error: state.application.error,
  featureFlags: FeatureFlags.getRealFeatureFlags(state),
  catalogList: state.data.catalogList,
}), {
  updateCurrentProjectContainer: ApplicationActions.updateCurrentProjectContainer,
  setLastLoadedCasterCatalogId: ApplicationActions.setLastLoadedCasterCatalogId,
  setError: ErrorActions.setError,
  setCurrentCatalogId: DataActions.setCurrentCatalogId,
  closeDialog: ApplicationActions.closeDialog,
  setCurrentSimulationCase: ApplicationActions.setCurrentSimulationCase,
  saveCatalog: DataActions.saveCatalog,
  setCatalogList: DataActions.setCatalogList,
})

type PropsFromRedux = ConnectedProps<typeof connector>

export interface Props extends PropsFromRedux {
  t: ArrayOfTranslations & Translation
}

type State = {
  selectedCatalogId: string
}

export class SelectCatalogDialog extends Component<Props, State> {
  @Identifiable('SelectCatalogDialog') public static readonly NAME: string

  public constructor (props: Props) {
    super(props)

    this.state = {
      selectedCatalogId: props.currentCatalogId ?? 'default',
    }
  }

  public override async componentDidMount () {
    await this.handleGetCatalogs()
  }

  public override componentDidUpdate () {
    const newSelectedCatalogId = Logic.getSelectedCatalog(this.state.selectedCatalogId, this.props.catalogList)

    if (newSelectedCatalogId !== this.state.selectedCatalogId) {
      this.setState({ selectedCatalogId: newSelectedCatalogId })
    }
  }

  private readonly handleGetCatalogs = async () => {
    const { currentSimulationCase: { id }, setCatalogList } = this.props

    try {
      const catalogs = await getNozzleCatalogs(id)

      if (!catalogs?.length) {
        return
      }

      setCatalogList(catalogs)
    }
    catch (error: any) {
      enqueueSnackbar('Error fetching catalogs', { variant: 'error' })

      this.handleClose()
    }
  }

  private readonly handleChangeCatalog = (event: any) => {
    const params = {
      ...this.props,
      setSelectedCatalogId: (id: string) => this.setState({ selectedCatalogId: id }),
    }

    changeCatalog(event, params)
  }

  private readonly handleDeleteCatalog = async (_type: string, key: string) => {
    await deleteCatalog(key, this.props)
  }

  private readonly handleClose = () => {
    const { closeDialog } = this.props

    closeDialog(SelectCatalogDialog.NAME)
  }

  private readonly handleLoadCatalog = () => {
    loadCatalog({
      ...this.props,
      closeDialogCallBack: this.handleClose,
      selectedCatalogId: this.state.selectedCatalogId,
    })
  }

  public override render () {
    const {
      t,
      currentSimulationCase,
      featureFlags,
      error,
      catalogList,
    } = this.props

    const { selectedCatalogId } = this.state

    const {
      simulationStartedAt,
    } = currentSimulationCase

    const catalogSelectors = Logic.getSelectors<CaseFile>(
      catalogList,
      'id',
      'fileName',
      { value: t(`${T}.catalog.default`), disabled: true },
    )

    return (
      <BaseDialog
        title={t(`${T}.catalog.load`)}
        icon='pe-7s-folder'
        header={t(`${T}.catalog.load`)}
        onClose={this.handleClose}
        small
      >
        <Form>
          <Input
            name='catalog'
            type='select'
            label={t(`${T}.catalog.label`)}
            title={
              error['catalog']
                ? t([ `error.${error['catalog']}`, 'error.default' ])
                : t(`${T}.catalog.title`)
            }
            error={error['catalog'] && t([ `error.${error['catalog']}`, 'error.default' ])}
            value={selectedCatalogId}
            selectors={
              FeatureFlags.canUploadNozzleCatalog(featureFlags)
                ? [
                  ...catalogSelectors,
                  {
                    key: 'add',
                    notRemovable: true,
                    value: t(`${T}.catalog.add`),
                  },
                ]
                : [ ...catalogSelectors ]
            }
            onChange={this.handleChangeCatalog}
            onDelete={this.handleDeleteCatalog}
            disabled={Boolean(simulationStartedAt)}
          />
          <Button
            value=''
            onClick={this.handleLoadCatalog}
            title={t(`${T}.catalog.load`)}
            disabled={selectedCatalogId === 'default'}
          >
            {t(`${T}.catalog.load`)}
          </Button>
        </Form>
      </BaseDialog>
    )
  }
}

const composedComponent = compose<any>(withTranslation('application'), connector)(SelectCatalogDialog)

export default hoistStatics(composedComponent, SelectCatalogDialog)
