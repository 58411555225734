// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { configureStore } from '@reduxjs/toolkit'
import deepMerge from 'deepmerge'

import { NetworkEvent } from '@/api/network-event'
import { AuthDataStore } from '@/logic/auth-data-store'
import { Info } from '@/logic/Info'
import type { DefaultState } from '@/types/state'

import ApiClient from './apiClient'
import createMiddleware from './asyncMiddleware'
import { injectReducer, makeRootReducer } from './reducers'

window.meta = window.meta ?? {}

export default (initialState = {}) => {
  const middleware = [ createMiddleware(ApiClient) ]

  const store = configureStore({
    reducer: (state: DefaultState = {} as DefaultState, action: any) => {
      const rootReducer = makeRootReducer()
      const initialState = rootReducer({}, {}) // get the initial state

      switch (action.type) {
        case 'UPDATE_WHOLE_STATE':
          if (action.data.caster) {
            NetworkEvent.setClientData({ casterId: action.data.caster.id ?? null })
          }

          return { ...state, ...action.data }

        case 'ACTION_SWITCH_PROJECT':
          return {
            ...initialState,
            util: state.util,
            application: {
              ...initialState.application,
              main: {
                ...initialState.application.main,
                authenticationData: state.application.main.authenticationData,
                appState: state.application.main.appState,
                networkStatus: state.application.main.networkStatus,
              },
            },
            visualization: { ...initialState.visualization },
          }

        case 'ACTION_RESET_STORE':
          AuthDataStore.clear()
          Info.removeRecentlyUsedInfo()

          return {
            ...initialState,
            util: state.util,
          }

        default:
          return rootReducer(state, action)
      }
    },
    // TODO: serializableCheck is disabled for now, as we are currently using Date objects in the state.
    // We need to address this in the future. And use proper serialization for Date objects.
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }).concat(middleware),
    preloadedState: deepMerge(makeRootReducer()({}, {}), initialState),
    devTools: process.env.NODE_ENV !== 'production',
  })

  store.asyncReducers = {}

  store.injectReducer = (params) => {
    injectReducer(store, params)
  }

  return store
}
