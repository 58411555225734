import { Component } from 'react'

import { AbortIcon, CheckIcon, DeleteComponent, DeleteIcon, Loading } from './styles'

type Props = {
  onClick: (e: any) => void
  isLoading?: boolean
  disabled?: boolean
}

type State = {
  showApproval: boolean
}

class Delete extends Component<Props, State> {
  public override state: State = {
    showApproval: false,
  }

  private readonly handleApproval = (showApproval: boolean) => {
    this.setState({
      showApproval,
    })
  }

  private readonly handleOpen = this.handleApproval.bind(this, true)

  private readonly handleClose = this.handleApproval.bind(this, false)
  
  public override render () {
    const { showApproval } = this.state
    const { onClick: handleClick, isLoading, disabled } = this.props ?? {}

    if (disabled) {
      return null
    }

    return (
      <DeleteComponent>
        {!isLoading && !showApproval && <DeleteIcon className='pe-7s-less' onClick={this.handleOpen} />}
        {
          !isLoading && showApproval && (
            <div>
              <CheckIcon className='pe-7s-check' onClick={handleClick} />
              <AbortIcon className='pe-7s-close-circle' onClick={this.handleClose} />
            </div>
          )
        }
        {isLoading && <Loading type='spin' />}
      </DeleteComponent>
    )
  }
}

export default Delete
