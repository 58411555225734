import type { TagName } from '@/types/state'

export const PARENT: Record<TagName, Extract<TagName, 'Roller' | 'Segment' | 'SegmentGroup'> | null> = {
  SegmentGroup: null,
  Segment: 'SegmentGroup',
  SupportPoint: 'SegmentGroup',
  Nozzle: 'Segment',
  Roller: 'Segment',
  RollerBody: 'Roller',
  RollerBearing: 'Roller',
  AirLoop: null,
  CoolingLoop: null,
  CoolingZone: null,
  LoopAssignment: null,
  SensorPoint: null,
  DataPoint: null,
  DataLine: null,
  StrandGuide: null,
  Mold: null,
  MoldBCArea: null,
  MoldFace: null,
  Passline: null,
  PasslineSection: null,
}

export const CHILDREN: Record<TagName, TagName[] | null> = {
  SegmentGroup: [ 'Segment', 'SupportPoint' ],
  Segment: [ 'Nozzle', 'Roller' ],
  Roller: [ 'RollerBody', 'RollerBearing' ],
  SupportPoint: null,
  Nozzle: null,
  RollerBody: null,
  RollerBearing: null,
  SensorPoint: null,
  DataPoint: null,
  DataLine: null,
  AirLoop: null,
  CoolingLoop: null,
  CoolingZone: null,
  LoopAssignment: null,
  StrandGuide: null,
  Mold: null,
  MoldBCArea: null,
  MoldFace: null,
  Passline: null,
  PasslineSection: null,
}
