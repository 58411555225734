import cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'

import type { Definition } from '@/types/visualization'
import CalcUtil from '@/Util/CalcUtil'

import type { Props } from '.'

export default class logic {
  private static didCreate = false

  public static getData (props: Props) {
    const { xRange, configId, configIds } = props
    const length = [ configId, ...(configIds ?? []) ].length

    const xDiff = Math.ceil(Math.abs(Number(xRange[1] ? xRange[1] : 1) - Number(xRange[0] ?? 0)))
    const singleTextData = {
      y: Array(xDiff).fill(0),
    }

    return Array(length).fill(singleTextData).map((val, i) => ({ ...val, name: i }))
  }

  public static connect (
    prevProps: Props,
    props: Props,
    forceReload: boolean,
    setData: ({ tileId, definitions }: { tileId: string, definitions: Definition[] }) => void,
  ) {
    const {
      tileId,
      xRange,
      valueRange,
      frequency,
      configId,
      plotConfigs,
      data,
      tileConfigs,
      isDynamicData,
    } = props

    let plotConfigIds = [ configId ]

    if (!logic.getData(props) || !plotConfigs[tileConfigs[tileId]?.configId ?? '']) {
      return
    }

    const {
      frequency: prevFrequency,
      xRange: prevXRange,
      valueRange: prevValueRange,
    } = prevProps

    const { configIds: prevConfigIds } = prevProps.plotConfigs[tileConfigs[tileId]?.configId ?? ''] ?? {}
    const { configIds } = plotConfigs[tileConfigs[tileId]?.configId ?? ''] ?? {}

    const prevDataRange = [ ...(prevXRange ?? []) ]
    const dataRange = [ ...xRange ]

    // TODO: this looks wrong, if it is not needed remove it!
    // prevDataRange[1] = Math.min(prevDataRange[1])
    // dataRange[1] = Math.min(dataRange[1])

    let redraw = Boolean(prevConfigIds?.length) && !isEqual(prevConfigIds, configIds)

    redraw = redraw || (prevXRange && (prevXRange.length > 0) && !isEqual(prevDataRange, dataRange))
    redraw = redraw || (prevValueRange && (prevValueRange.length > 0) && !isEqual(prevValueRange, valueRange))
    redraw = redraw || (prevFrequency !== frequency)
    // redraw = redraw || (prevType !== type)
    redraw = redraw || (prevProps.configId !== configId)
    redraw = redraw || !isEqual(prevProps.tileConfigs[tileId], tileConfigs[tileId])

    if (redraw) {
      logic.didCreate = false
    }

    // eslint-disable-next-line
    if (!isEqual(props, prevProps) || forceReload) {
      logic.didCreate = false
    }

    if (!logic.didCreate) {
      logic.didCreate = true

      if (configIds) {
        plotConfigIds = cloneDeep(configIds)
      }

      // const dataRange = [ ...xRange ]

      // dataRange[1] = Number(xRange[1])

      if ((plotConfigIds || plotConfigIds === 0) && !(plotConfigIds instanceof Array)) {
        plotConfigIds = [ plotConfigIds ]
      }

      if (isDynamicData) {
        setData({ tileId, definitions: [] })
      }

      if (data && (Object.keys(data).length > 0)) {
        setData({
          tileId,
          definitions: plotConfigIds.map(plotConfigId => {
            const config = plotConfigs[plotConfigId]
            const values = data[config?.group ?? '']?.[config?.key ?? ''] ?? []

            const { min: xMin, max: xMax } = CalcUtil.getMinMax(xRange)

            const start = Math.max(xMin, 0)
            const end = Math.min(xMax, values.length)

            const newData = []

            for (let j = start; j < end; j++) {
              newData.push(values[j])
            }

            return {
              id: plotConfigId,
              length: values.length,
              bytes: null,
              data: newData,
            }
          }),
        })
      }
    }
  }
}
