/* eslint-disable camelcase */
/* eslint-disable key-spacing */

import { defaultFields, getHiddenFields } from './defaults'

export const SensorPoint = {
  categories: {
    1: 'general',
  },
  fields: {
    ...defaultFields,
    passlineCoord: { type: 'number', category: 1, defaultValue: 0, positive: true, disabled: true },
    widthCoord: { type: 'number', category: 1, defaultValue: 0, disabled: true },
    ...getHiddenFields([
      'sensorPointId',
      'rollerBodyMountLogId',
      'segmentMountLogId',
      'rollerMountLogId',
      'width_coord',
      'passln_coord',
    ]),
  },
}
/* eslint-enable key-spacing */
