import cloneDeep from 'lodash/cloneDeep'

import type { ElementMaps, ElementName } from '@/types/state'
import { ElementsUtil } from '@/Util/ElementsUtil'
import { Mapping } from '@/Util/mapping/Mapping'

export default class CalculateHelperFunctions {
  public static byPasslineCoordAsc<T extends BaseSlot> (a: T, b: T) {
    return (a.passlineCoord ?? 0) - (b.passlineCoord ?? 0)
  }

  public static byPasslineCoordDesc<T extends BaseSlot> (a: T, b: T) {
    return (b.passlineCoord ?? 0) - (a.passlineCoord ?? 0)
  }

  public static sortedMountLogsBySlot<Slot extends BaseSlot, MountLog extends BaseMountLog> (
    name: ElementName,
    mountLogMaps: MountLog[],
    elementMaps: ElementMaps,
    compareFn: (a: Slot, b: Slot) => number,
  ) {
    const slotMap = elementMaps[`${name}Slot`]

    if (!slotMap) {
      return []
    }

    const sortedMountLogs = cloneDeep(mountLogMaps)

    sortedMountLogs.sort((a, b) => {
      const slotA = slotMap[a.slotId] as Slot
      const slotB = slotMap[b.slotId] as Slot

      if (!slotA || !slotB) {
        return 0
      }

      return compareFn(slotA, slotB)
    })

    return sortedMountLogs
  }

  public static getRollerList (
    elementMaps: ElementMaps,
    startId: string,
    side: string,
    hidePaths: string[],
  ): RollerMountLog[] {
    const segmentGroupMountLogMap = cloneDeep(elementMaps.SegmentGroupMountLog)

    const segmentGroupMountLogs = CalculateHelperFunctions.sortedMountLogsBySlot(
      'SegmentGroup',
      Object.values(segmentGroupMountLogMap),
      elementMaps,
      CalculateHelperFunctions.byPasslineCoordAsc,
    )

    if (!segmentGroupMountLogs?.length || !side) {
      return []
    }

    const startIndex = segmentGroupMountLogs.findIndex(segmentGroup => segmentGroup.id === startId)
    const rollerList: RollerMountLog[] = []

    for (let i = startIndex; i < segmentGroupMountLogs.length; i++) {
      const segmentGroupMountLog = segmentGroupMountLogs[i]

      if (!segmentGroupMountLog) {
        continue
      }

      const segmentMountLogMap = ElementsUtil.getSegmentMountLogMapBySegmentGroupMountLogs(
        elementMaps,
        [ segmentGroupMountLog ],
      )

      const segmentMountLog = Object.values(segmentMountLogMap).find(segmentMountLog =>
        elementMaps.SegmentSlot[segmentMountLog?.slotId ?? '']?.side === side
      )

      if (!segmentMountLog) {
        continue
      }

      const segmentGroupMountLogNumericId = Mapping.numericIdByMountLogId[segmentGroupMountLog.id]
      const segmentMountLogNumericId = Mapping.numericIdByMountLogId[segmentMountLog.id]

      if (segmentGroupMountLogNumericId === undefined || segmentMountLogNumericId === undefined) {
        continue
      }

      // eslint-disable-next-line max-len
      const initialRollerPath =
        `SegmentGroup:${segmentGroupMountLogNumericId}/Segment:${segmentMountLogNumericId}/Roller`

      const rollerMountLogMap = ElementsUtil.getRollerMountLogMapBySegmentMountLogs(
        elementMaps,
        [ segmentMountLog ],
      )

      const rollerMountLogs = Object.values(rollerMountLogMap)

      // get rollers and filter the ones which should be hidden
      for (const rollerMountLog of rollerMountLogs) {
        if (typeof rollerMountLog !== 'object') {
          continue
        }

        const rollerMountLogNumericId = Mapping.numericIdByMountLogId[rollerMountLog.id]
        const rollerPath = `${initialRollerPath}/:${rollerMountLogNumericId}}`

        if (hidePaths.includes(rollerPath)) {
          continue
        }

        rollerList.push(rollerMountLog)
      }
    }

    return rollerList
  }

  public static calculatePassLn (
    minPassLn: number,
    { SegmentGroup, Segment }: { SegmentGroup: number, Segment: number },
    elementMaps: ElementMaps,
    hidePaths: string[],
  ) {
    if (!elementMaps.StrandGuideSlot || !SegmentGroup || Boolean(Segment)) {
      return null
    }

    const segmentGroupMountLogMap = ElementsUtil.getSegmentGroupMountLogs(elementMaps)
    const segmentGroupMountLogs = CalculateHelperFunctions.sortedMountLogsBySlot(
      'SegmentGroup',
      Object.values(segmentGroupMountLogMap),
      elementMaps,
      CalculateHelperFunctions.byPasslineCoordAsc,
    )

    if (!segmentGroupMountLogs || (segmentGroupMountLogs.length === 0)) {
      return null
    }

    const firstSegmentGroup = segmentGroupMountLogs[0]
    const segmentMountLogId = Mapping.mountLogIdByTypeAndNumericId.Segment[Segment]
    const segmentMountLog = elementMaps.SegmentMountLog[segmentMountLogId ?? '']
    const segmentSlot = elementMaps.SegmentSlot[segmentMountLog?.slotId ?? '']

    if (!segmentSlot || !firstSegmentGroup || !segmentMountLog) {
      return null
    }

    const rollerMountLogArray = CalculateHelperFunctions.getRollerList(
      elementMaps,
      firstSegmentGroup.id,
      segmentSlot.side ?? '',
      hidePaths,
    )

    const segmentGroupMountLogId = Mapping.mountLogIdByTypeAndNumericId.SegmentGroup[SegmentGroup]
    const segmentGroupMountLog = elementMaps.SegmentGroupMountLog[segmentGroupMountLogId ?? '']
    const segmentGroupSlot = elementMaps.SegmentGroupSlot[segmentGroupMountLog?.slotId ?? '']

    if (rollerMountLogArray.length === 0) {
      return Number(segmentGroupSlot?.passlineCoord ?? 0)
    }

    if (rollerMountLogArray.length === 1) {
      const firstRollerMountLog = rollerMountLogArray[0]
      const rollerSlot = elementMaps.RollerSlot[firstRollerMountLog?.slotId ?? '']

      return Number(rollerSlot?.passlineCoord) - Number(firstRollerMountLog?.diameter ?? 0) / 2
    }

    const sortedRollerMountLogs = CalculateHelperFunctions.sortedMountLogsBySlot(
      'Roller',
      rollerMountLogArray,
      elementMaps,
      CalculateHelperFunctions.byPasslineCoordAsc,
    )

    const rollerMountLogsPasslnLow = sortedRollerMountLogs
      .filter(roller => Number(elementMaps.RollerSlot[roller.slotId]?.passlineCoord) <= minPassLn)

    let rollerMountLogPasslnLow = rollerMountLogsPasslnLow[rollerMountLogsPasslnLow.length - 1]

    const rollerMountLogsPasslnHigh = sortedRollerMountLogs
      .filter(roller => Number(elementMaps.RollerSlot[roller.slotId]?.passlineCoord) > minPassLn)

    let rollerMountLogPasslnHigh = rollerMountLogsPasslnHigh[0]

    if (!rollerMountLogPasslnHigh || !rollerMountLogPasslnLow) {
      const segmentGroupIndex = segmentGroupMountLogs
        .findIndex(segmentGroup => segmentGroup.id === segmentGroupMountLog?.id)
      const prevSegmentGroup = segmentGroupIndex - 1

      if (prevSegmentGroup < 0) {
        const rollerMountLog = sortedRollerMountLogs[0]
        const rollerSlot = elementMaps.RollerSlot[rollerMountLog?.slotId ?? '']

        return Number(rollerSlot?.passlineCoord ?? 0) - Number(rollerMountLog?.diameter ?? 0) / 2
      }

      const rollerMountLogMap = ElementsUtil.getRollerMountLogMapBySegmentMountLogs(
        elementMaps,
        [ segmentMountLog ],
      )
      const prevRollerMountLogs = CalculateHelperFunctions.sortedMountLogsBySlot(
        'Roller',
        Object.values(rollerMountLogMap),
        elementMaps,
        CalculateHelperFunctions.byPasslineCoordDesc,
      )

      if (!rollerMountLogPasslnLow) {
        rollerMountLogPasslnLow = prevRollerMountLogs[0]
      }
      else {
        rollerMountLogPasslnLow = prevRollerMountLogs[0]
        rollerMountLogPasslnHigh = sortedRollerMountLogs[0]
      }
    }

    if (rollerMountLogPasslnHigh && rollerMountLogPasslnLow) {
      const rollerSlotPasslnLow = elementMaps.RollerSlot[rollerMountLogPasslnLow.slotId ?? '']
      const rollerSlotPasslnHigh = elementMaps.RollerSlot[rollerMountLogPasslnHigh.slotId ?? '']
      const low = Number(rollerSlotPasslnLow?.passlineCoord) + Number(rollerMountLogPasslnLow?.diameter ?? 0) / 2
      const high = Number(rollerSlotPasslnHigh?.passlineCoord) - Number(rollerMountLogPasslnHigh?.diameter ?? 0) / 2

      return (low + (high - low) / 2).toString()
    }

    return null
  }
}
