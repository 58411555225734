import { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { compose } from 'redux'

import type { Translation } from '@/types/translation'

import Btn, { BtnWrapper, ConfirmWrapper, ErrorMessage, Label } from './styles'

type Props = {
  // TODO: should not be optional
  id: string
  children: any
  type?: 'error' | 'primary' | 'secondary' | undefined
  overrideColor?: string | undefined
  overrideHoverColor?: string | undefined
  confirm?: string | true | undefined
  title?: string | undefined
  error?: string | undefined
  icon?: string | undefined
  label?: string | undefined
  loading?: boolean | undefined
  disabled?: boolean | undefined
  half?: boolean | undefined
  oneThird?: boolean | undefined
  twoThirds?: boolean | undefined
  right?: boolean | undefined
  noMargin?: boolean | undefined
  nextToDropDown?: boolean | undefined
  nextToDateTime?: boolean | undefined
  noSuccessIcon?: boolean | undefined
  hideError?: boolean | undefined
  isRef?: boolean | undefined
  bold?: boolean | undefined
  textPaddingLeft?: boolean | undefined
  height?: number | undefined
  ref?: React.RefObject<unknown> | undefined
  onClick: (event: React.MouseEvent) => void
  onBeginConfirm?: ((event?: React.MouseEvent) => void) | undefined
  onCancel?: ((event?: React.MouseEvent) => void) | undefined
  t: Translation
}

type State = {
  success: boolean
  error: boolean
  confirmActive: boolean
}

class Button extends Component<Props, State> {
  // Default props are automatically used if no value is provided by the parent component
  public static defaultProps = {
    type: 'primary',
    isRef: false,
  }

  public override state: State = {
    success: false,
    error: false,
    confirmActive: false,
  }
  
  public override componentDidUpdate (prevProps: Props): void {
    const { loading, error } = this.props

    if (!loading && prevProps.loading) {
      let timeout = 1000

      if (error) {
        this.setState({
          error: true,
        })

        timeout = 5000
      }
      else {
        this.setState({
          success: true,
        })
      }

      setTimeout(() => {
        this.setState({
          success: false,
          error: false,
        })
      }, timeout)
    }
  }

  private readonly handleClick = (event: React.MouseEvent) => {
    const { confirmActive } = this.state
    const { confirm, onClick, onBeginConfirm } = this.props

    if (confirm && !confirmActive) {
      this.setState({
        confirmActive: true,
      })

      if (onBeginConfirm) {
        onBeginConfirm(event)
      }

      return
    }

    this.setState({
      success: false,
      error: false,
      confirmActive: false,
    })

    onClick(event)
  }

  private readonly handleCancel = (event?: React.MouseEvent) => {
    const { onCancel } = this.props

    this.setState({ confirmActive: false })

    if (onCancel) {
      onCancel(event)
    }
  }

  private readonly setFocus = (ref: any) => {
    if (ref) {
      ref.focus()
    }
  }
  
  public override render () {
    const { confirmActive } = this.state
    const {
      id,
      children,
      confirm,
      title,
      disabled,
      half,
      oneThird,
      twoThirds,
      noMargin,
      right,
      nextToDropDown,
      nextToDateTime,
      noSuccessIcon,
      error: errorCode,
      loading,
      type,
      icon,
      label,
      hideError,
      isRef,
      bold,
      textPaddingLeft,
      height,
      ref,
      overrideColor,
      overrideHoverColor,
      t,
    } = this.props

    if (confirmActive) {
      return (
        <ConfirmWrapper>
          <Button
            id={`${id}-confirm`}
            title={title}
            onClick={this.handleClick}
            half
            t={t}
            type='secondary'
            bold={bold}
            height={height}
          >
            {typeof confirm === 'string' ? confirm : t('button.confirm')}
          </Button>
          <Button
            id={`${id}-cancel`}
            onClick={this.handleCancel}
            half
            t={t}
            bold={bold}
            height={height}
          >
            {t('button.cancel')}
          </Button>
        </ConfirmWrapper>
      )
    }

    const { success, error } = this.state

    const iconClass = success ? 'pe-7s-check' : (error ? 'pe-7s-close-circle' : icon)

    return (
      <BtnWrapper
        $half={half}
        $oneThird={oneThird}
        $twoThirds={twoThirds}
        $right={right}
        $nextToDropDown={nextToDropDown}
        $nextToDateTime={nextToDateTime}
      >
        {label && <Label>{label}</Label>}
        <Btn
          id={id}
          $overrideHoverColor={overrideHoverColor}
          $overrideColor={overrideColor}
          onClick={this.handleClick}
          title={errorCode ?? title}
          disabled={disabled}
          $isLoading={loading}
          $hasError={error}
          $isSuccessful={success}
          $type={type}
          $textPaddingLeft={(Boolean(icon && half) || textPaddingLeft) as boolean}
          $noMargin={Boolean(noMargin) || Boolean(label)}
          ref={ref ?? (isRef ? this.setFocus : null)}
          $height={height}
          $bold={bold}
        >
          {
            (Boolean(icon) || error || success) && !noSuccessIcon && (
              <div>
                <i className={iconClass} />
              </div>
            )
          }
          <span>{children}</span>
        </Btn>
        {!hideError && errorCode && <ErrorMessage>{t([ `error.${errorCode}`, 'error.default' ])}</ErrorMessage>}
      </BtnWrapper>
    )
  }
}

export default compose<any>(withTranslation('application'))(Button)
