import { Reducer } from 'redux'

import type { DefaultState, ElementMaps } from '@/types/state'

import { casterElementNamesArray } from './actions'

// some object containing ElementMaps
export type ElementMapsLike = unknown & (DefaultState | ElementMaps)

export function getElementMapsObject (state: ElementMapsLike): ElementMaps {
  const result: any = {}

  for (const key of casterElementNamesArray) {
    result[key] = state[key]
  }

  return result as ElementMaps
}

export function getChildRollerBodyMountLogsByIds (elementMaps: ElementMaps, ids: readonly string[]) {
  const { RollerBodyMountLog } = elementMaps

  const mountLogs: RollerBodyMountLog[] = []

  for (const id of ids) {
    if (!RollerBodyMountLog[id]) {
      continue
    }

    mountLogs.push(RollerBodyMountLog[id])
  }

  return mountLogs
}

export function getElementReducers () {
  return casterElementNamesArray.reduce((acc, type) => {
    // Set the initial state based on the type
    const initialState = type === 'Caster' ? null : {}

    acc[type] = (state: any = initialState, action: any) => {
      const handler = {
        [`${type}/UPDATE`]: (state: any, action: { id: string, data: any }): any => ({
          ...state,
          [action.id]: {
            ...state[action.id],
            ...action.data,
          },
        }),
        [`${type}/RESET`]: (_state: any, _action: any): any =>
          // Return null for "Caster" on RESET, otherwise return an empty object
          type === 'Caster' ? null : {},
      }[action.type]

      return handler ? handler(state, action) : state
    }

    return acc
  }, {} as Record<string, Reducer<any, any, any>>)
}
