import hoistStatics from 'hoist-non-react-statics'
import hotkeys from 'hotkeys-js'
import cloneDeep from 'lodash/cloneDeep'
import type { Moment } from 'moment'
import moment from 'moment'
import { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'redux'
import styled, { css } from 'styled-components'

import Button from '@/react/components/Button'
import { DateTimePickerComponent } from '@/react/specific/DateTimePicker'
import { Form } from '@/react/visualization/dashboard/Dialogs/DialogStyles'
import * as ApplicationActions from '@/store/application/main/actions'
import * as VisualizationActions from '@/store/visualization/actions'
import type { DefaultState } from '@/types/state'
import { Identifiable } from '@/Util/decorators/Identifiable'

import BaseDialog from './BaseDialog'

const T = 'selectCompareCasterTimeDialog'

export const CaseName = styled.div`${({ theme }) =>
  css`
  color: ${theme['mainFontColor']};
  font-size: 16px;
  margin-bottom: 15px;
`}`

const connector = connect((state: DefaultState) => ({
  selectedComparisonCaseIds: state.visualization.selectedComparisonCaseIds,
  applicationParams: state.application.main.params,
  comparisonTimestamps: state.visualization.comparisonTimestamps,
}), {
  closeDialog: ApplicationActions.closeDialog,
  setSelectedComparisonCaseIds: VisualizationActions.setSelectedComparisonCaseIds,
  setComparisonTimestamps: VisualizationActions.setComparisonTimestamps,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  t(key: string, params?: Record<string, unknown>): string
}

type State = {
  selectedTimestamp: number | null
}

export class SelectCompareCasterTimeDialog extends Component<Props, State> {
  @Identifiable('SelectCompareCasterTimeDialog') public static readonly NAME: string

  public override state: State = {
    selectedTimestamp: null,
  }

  public override componentDidMount () {
    hotkeys('Escape', this.handleClose)
  }

  public override componentWillUnmount () {
    hotkeys.deleteScope('other')
    hotkeys.unbind('Escape', this.handleClose)
  }

  private readonly handleClose = () => {
    const { closeDialog } = this.props

    closeDialog(SelectCompareCasterTimeDialog.NAME)
  }

  private readonly handleSelectTimestamp = (moment: Moment | null) => {
    if (!moment?.isValid()) {
      return
    }

    const selectedTimestamp = moment.toDate().getTime()

    this.setState({ selectedTimestamp })
  }

  private readonly handleSubmit = () => {
    const { selectedTimestamp } = this.state
    const {
      closeDialog,
      selectedComparisonCaseIds,
      setComparisonTimestamps,
      applicationParams,
      comparisonTimestamps,
    } = this.props

    if (!selectedTimestamp) {
      // eslint-disable-next-line no-console
      console.error('No selected timestamp')

      return
    }

    const caseId = applicationParams?.timestampBeingEditCaseInfo?.id

    const newSelectedComparisonCaseIds = cloneDeep(selectedComparisonCaseIds)
    const newIdTimestamp = `${caseId}_${selectedTimestamp}`

    newSelectedComparisonCaseIds.push(newIdTimestamp)

    // setSelectedComparisonCaseIds(newSelectedComparisonCaseIds)
    setComparisonTimestamps([ ...comparisonTimestamps, newIdTimestamp ])

    closeDialog(SelectCompareCasterTimeDialog.NAME)
  }

  public override render () {
    const { selectedTimestamp } = this.state
    const { t, applicationParams } = this.props
    const timestampBeingEditCaseInfo = applicationParams?.timestampBeingEditCaseInfo

    return (
      <BaseDialog
        title={t(`${T}.title`)}
        icon='pe-7s-server'
        header={t(`${T}.header`)}
        onClose={this.handleClose}
        contentMinHeight={160}
        small
      >
        <Form $minHeight={160}>
          <CaseName>{t(`${T}.caseBeingEdited`, { name: timestampBeingEditCaseInfo?.name ?? '-' })}</CaseName>
          <DateTimePickerComponent
            label={t(`${T}.timestamp`)}
            onChange={this.handleSelectTimestamp}
            value={moment(selectedTimestamp ?? Date.now())}
          />
          <Button
            type='primary'
            onClick={this.handleSubmit}
            icon='pe-7s-check'
            isRef
          >
            {t(`${T}.submit`)}
          </Button>
        </Form>
      </BaseDialog>
    )
  }
}

const composedComponent = compose<any>(withTranslation('application'), connector)(SelectCompareCasterTimeDialog)

export default hoistStatics(composedComponent, SelectCompareCasterTimeDialog)
