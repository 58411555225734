import { faClone } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from '@mui/material/Button'
import { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'redux'

import * as ApplicationActions from '@/store/application/main/actions'
import type { DefaultState } from '@/types/state'
import type { Translation } from '@/types/translation'

import Dialog from '../DuplicateSimulationCaseDialog'

const T = 'projectMatrixDialog'

const connector = connect((state: DefaultState) => ({
  selections: state.matrix.selections,
}), {
  openDialog: ApplicationActions.openDialog,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  handleLoadingStatus?: any
  isSelected: boolean
  contentRef?: HTMLElement | null
  t: Translation
}

class Duplicate extends Component<Props> {
  private readonly handleClick = () => {
    const { openDialog } = this.props

    openDialog(Dialog.NAME)
  }

  private readonly getTitle = () => {
    const { isSelected, t } = this.props

    if (!isSelected) {
      return t(`${T}.titles.duplicateSelected.nothingSelected`)
    }

    return t(`${T}.titles.duplicateSelected.default`)
  }
  
  public override render () {
    const { isSelected, ...restProps } = this.props

    return (
      <Button {...restProps} title={this.getTitle()} disabled={!isSelected} onClick={this.handleClick}>
        <FontAwesomeIcon icon={faClone} />
      </Button>
    )
  }
}

export default compose<any>(withTranslation('application'), connector)(Duplicate)
