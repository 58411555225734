/* eslint-disable max-len */

// Source: node_modules/plotly.js/src/components/drawing/symbol_defs
export const markers = {
  circle: 'M12,0A12,12 0 1,1 0,-12A12,12 0 0,1 12,0Z',
  square: 'M12,12H-12V-12H12Z',
  diamond: 'M15.6,0L0,15.6L-15.6,0L0,-15.6Z',
  cross: 'M14.4,4.8H4.8V14.4H-4.8V4.8H-14.4V-4.8H-4.8V-14.4H4.8V-4.8H14.4Z',
  x: 'M0,6.79l6.79,6.79l6.79,-6.79l-6.79,-6.79l6.79,-6.79l-6.79,-6.79l-6.79,6.79l-6.79,-6.79l-6.79,6.79l6.79,6.79l-6.79,6.79l6.79,6.79Z',
  'triangle-up': 'M-13.86,6H13.86L0,-12Z',
  'triangle-down': 'M-13.86,-6H13.86L0,12Z',
  'triangle-left': 'M6,-13.86V13.86L-12,0Z',
  'triangle-right': 'M-6,-13.86V13.86L12,0Z',
  'triangle-ne': 'M-14.4,-7.2H7.2V14.4Z',
  'triangle-se': 'M7.2,-14.4V7.2H-14.4Z',
  'triangle-sw': 'M14.4,7.2H-7.2V-14.4Z',
  'triangle-nw': 'M-7.2,14.4V-7.2H14.4Z',
  pentagon: 'M11.41,-3.71L7.06,9.71H-7.06L-11.41,-3.71L0,-12Z',
  hexagon: 'M10.39,-6V6L0,12L-10.39,6V-6L0,-12Z',
  hexagon2: 'M-6,10.39H6L12,0L6,-10.39H-6L-12,0Z',
  octagon: 'M-4.6,-11.09H4.6L11.09,-4.6V4.6L4.6,11.09H-4.6L-11.09,4.6V-4.6Z',
  star: 'M3.78,-5.19H15.98L6.1,1.98L9.88,13.59L0,6.42L-9.88,13.59L-6.1,1.98L-15.98,-5.19H-3.78L0,-16.8Z',
  hexagram:
    'M-9.12,0l-4.56,-7.92h9.12l4.56,-7.92l4.56,7.92h9.12l-4.56,7.92l4.56,7.92h-9.12l-4.56,7.92l-4.56,-7.92h-9.12Z',
  'star-triangle-up': 'M-16.63,9.6A 48,48 0 0 1 16.63,9.6A 48,48 0 0 1 0,-19.2A 48,48 0 0 1 -16.63,9.6Z',
  'star-triangle-down': 'M16.63,-9.6A 48,48 0 0 1 -16.63,-9.6A 48,48 0 0 1 0,19.2A 48,48 0 0 1 16.63,-9.6Z',
  'star-square':
    'M-13.2,-13.2A 24,24 0 0 1 -13.2,13.2A 24,24 0 0 1 13.2,13.2A 24,24 0 0 1 13.2,-13.2A 24,24 0 0 1 -13.2,-13.2Z',
  'star-diamond':
    'M-16.8,0A 22.8,22.8 0 0 1 0,16.8A 22.8,22.8 0 0 1 16.8,0A 22.8,22.8 0 0 1 0,-16.8A 22.8,22.8 0 0 1 -16.8,0Z',
  'diamond-tall': 'M0,16.8L8.4,0L0,-16.8L-8.4,0Z',
  'diamond-wide': 'M0,8.4L16.8,0L0,-8.4L-16.8,0Z',
  hourglass: 'M12,12H-12L12,-12H-12Z',
  bowtie: 'M12,12V-12L-12,12V-12Z',
  'arrow-up': 'M0,0L-12,24H12Z',
  'arrow-down': 'M0,0L-12,-24H12Z',
  'arrow-left': 'M0,0L24,-12V12Z',
  'arrow-right': 'M0,0L-24,-12V12Z',
} as Record<string, string>
