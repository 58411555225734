export default function clientMiddleware (client: any) {
  return ({ dispatch, getState }: any) => (next: any) => (action: any) => {
    if (typeof action === 'function') {
      return action(dispatch, getState)
    }

    const { promise, types, ...rest } = action

    if (!promise) {
      return next(action)
    }

    const [ REQUEST, SUCCESS, FAILURE ] = types

    next({ ...rest, type: REQUEST })

    return promise(client, getState(), dispatch)
      .then(
        (result: any) => next({ ...rest, result, type: SUCCESS }),
        (error: any) => next({ ...rest, error, type: FAILURE }),
      )
      .catch((error: any) => {
        if (window.meta?.DEV) {
          // eslint-disable-next-line no-console
          console.error(error)
        }

        next({ ...rest, error, type: FAILURE })
      })
  }
}
