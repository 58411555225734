import cloneDeep from 'lodash/cloneDeep'

import { generateField, isGeneratedField } from '@/store/type/GeneratedFields'
import type { ElementCache, ElementCacheKey } from '@/three/objects'
import Mold from '@/three/objects/Mold'
import type { CasterElementNames } from '@/types/data'
import type { FilterableElementType } from '@/types/filter'
import type {
  DataPointMountLogMapKey,
  DataPointParent,
  ElementMaps,
  ElementName,
  SensorPointMountLogMapKey,
  SensorPointParent,
} from '@/types/state'
import { ElementMapsUtil } from '@/Util/ElementMapsUtil'
import { Mapping } from '@/Util/mapping/Mapping'

import Util from '../logic/Util'

type FilterAttribute<Slot extends BaseSlot, MountLog extends BaseMountLog> = {
  key: keyof Omit<FullCasterElement<Slot, MountLog>, 'additionalData'>
  value: string
  operator: string
}

export type Filter<Slot extends BaseSlot, MountLog extends BaseMountLog> = {
  id: string
  type: string
  attributes: FilterAttribute<Slot, MountLog>[]
}

export type FilterFunction = {
  (
    path: string,
    type: FilterableElementType,
    element: any,
    elementMaps: ElementMaps,
  ): boolean
}

type FilterHandlerData = {
  filterableTypes: Record<string, boolean>
  typeMatch: Record<string, string>
  sideMatch: Record<string, string>
}

type TypeInfo = {
  type: ElementName
  parentType?: ElementName
}

export default class FilterHandler {
  private static filterCache: Record<string, { timestamp: number, data: Record<string, FilterableElementType> }> = {}

  public static readonly CHILDREN = {
    SegmentGroup: [ 'Segment', 'Nozzle', 'Roller', 'RollerBody', 'RollerBearing', 'SupportPoint' ],
    Segment: [ 'Nozzle', 'Roller', 'RollerBody', 'RollerBearing', 'SensorPoint' ],
    SupportPoint: [],
    Nozzle: [],
    Roller: [ 'RollerBody', 'RollerBearing', 'SensorPoint' ],
    RollerBody: [ 'SensorPoint' ],
    RollerBearing: [],
    SensorPoint: [],
    DataPoint: [],
    DataLine: [],
  }

  private static visibleElements: Record<string, FilterableElementType> = {}

  private static filteredElements: Record<string, Record<string, FilterableElementType>> = {}

  public static filteredElementsByReferencedCase: {
    [caseId: string]: Record<string, FilterableElementType>
  } = {}

  private static readonly data: FilterHandlerData = {
    filterableTypes: {
      SegmentGroup: true,
      Segment: true,
      SupportPoint: true,
      Nozzle: true,
      Roller: true,
      RollerBody: true,
      RollerBearing: true,
      SensorPoint: true,
      DataPoint: true,
      DataLine: true,
      CoolingLoop: true,
      AirLoop: true,
    },
    typeMatch: {
      sg: 'SegmentGroup',
      s: 'Segment',
      su: 'SupportPoint',
      n: 'Nozzle',
      ry: 'RollerBody',
      rg: 'RollerBearing',
      r: 'Roller',
      sp: 'SensorPoint',
      segmentgroup: 'SegmentGroup',
      segment: 'Segment',
      supportpoint: 'SupportPoint',
      nozzle: 'Nozzle',
      rollerbody: 'RollerBody',
      rollerbearing: 'RollerBearing',
      roller: 'Roller',
      sensorpoint: 'SensorPoint',
      datapoint: 'DataPoint',
      dataline: 'DataLine',
      coolingloop: 'CoolingLoop',
      airloop: 'AirLoop',
    },
    sideMatch: {
      right: 'right',
      left: 'left',
      fixed: 'fixed',
      loose: 'loose',
    },
  }

  public static readonly variables = [
    'CurrentPassLnCoord',
    'HalfStrandWidth',
    'CurrentSegmentGroupStartPasslnCoord',
  ]

  // static deconstructedFiltersPerTerm: Record<string, Filter[] | Filter> = {}

  public static variableRegEx = new RegExp(`(${FilterHandler.variables.join('|')})`, 'g')

  public static readonly elementTypeInfoArray: TypeInfo[] = [
    {
      type: 'SegmentGroup',
    },
    {
      type: 'SupportPoint',
      parentType: 'SegmentGroup',
    },
    {
      type: 'Segment',
      parentType: 'SegmentGroup',
    },
    {
      type: 'Nozzle',
      parentType: 'Segment',
    },
    {
      type: 'Roller',
      parentType: 'Segment',
    },
    {
      type: 'RollerBody',
      parentType: 'Roller',
    },
    {
      type: 'RollerBearing',
      parentType: 'Roller',
    },
  ]

  public static readonly dataPointInfoArray: {
    mountLogType: DataPointMountLogMapKey
    type: ElementName
    parentType: DataPointParent
  }[] = [
    {
      mountLogType: 'MoldFaceDataPointMountLog',
      type: 'MoldFaceDataPoint',
      parentType: 'MoldFace',
    },
    {
      mountLogType: 'StrandDataPointMountLog',
      type: 'StrandDataPoint',
      parentType: 'Strand',
    },
    {
      mountLogType: 'SegmentDataPointMountLog',
      type: 'SegmentDataPoint',
      parentType: 'Segment',
    },
    {
      mountLogType: 'RollerDataPointMountLog',
      type: 'RollerDataPoint',
      parentType: 'Roller',
    },
    {
      mountLogType: 'RollerBodyDataPointMountLog',
      type: 'RollerBodyDataPoint',
      parentType: 'RollerBody',
    },
  ]

  public static readonly sensorPointInfoArray: {
    mountLogType: SensorPointMountLogMapKey
    type: ElementName
    parentType: SensorPointParent
  }[] = [
    {
      mountLogType: 'SegmentSensorPointMountLog',
      type: 'SegmentSensorPoint',
      parentType: 'Segment',
    },
    {
      mountLogType: 'RollerSensorPointMountLog',
      type: 'RollerSensorPoint',
      parentType: 'Roller',
    },
    {
      mountLogType: 'RollerBodySensorPointMountLog',
      type: 'RollerBodySensorPoint',
      parentType: 'RollerBody',
    },
  ]

  private static isFilterable (type: string) {
    return FilterHandler.data.filterableTypes[type] ?? false
  }

  private static getFullType (type: string) {
    return FilterHandler.data.typeMatch[type.toLowerCase()] ?? type
  }

  private static getFullSide (side: string) {
    return FilterHandler.data.sideMatch[side.toLowerCase()] ?? side
  }

  private static handleBooleanOperator (a: number | string, b: number | string, operator: string) {
    const nA = typeof a === 'string' ? Number(a.toLowerCase() === 'true' || a === '1') : a as number
    const nB = typeof b === 'string' ? Number(b.toLowerCase() === 'true' || b === '1') : b as number

    switch (operator) {
      case '':
        return !b
      case '!=':
        return nA !== nB
      case '=':
        return nA === nB
      case '>':
        return nA > nB
      case '<':
        return nA < nB
      default:
    }

    return false
  }

  private static handleOperator (a: string, b: string, operator: string) {
    const nA = Number(a)
    const nB = Number(b)

    if (a === 'true' || a === 'false' || a === 'null' || b === 'true' || b === 'false') {
      return FilterHandler.handleBooleanOperator(a, b, operator)
    }

    switch (operator) {
      case '':
        return !b
      case '!=':
        return a !== b
      case '>=':
        return nA >= nB
      case '<=':
        return nA <= nB
      case '=':
        return a === b
      case '>':
        return nA > nB
      case '<':
        return nA < nB
      default:
    }

    return false
  }

  public static getFilter<Slot extends BaseSlot, MountLog extends BaseMountLog> (
    filter: string,
  ): Filter<Slot, MountLog> | Filter<Slot, MountLog>[] {
    if (filter.includes('/')) {
      const levels = filter.split('/')
      const filters: any[] = levels.map(FilterHandler.getFilter)

      return filters.reduce((list: any, element) => {
        const elements = element instanceof Array ? element : [ element ]

        elements.forEach(filterElem => {
          list.push(filterElem)
        })

        return list
      }, [])
    }

    const hashIndex = filter.indexOf('#')
    const colonIndex = filter.indexOf(':')
    const hasHash = hashIndex !== -1
    const hasColon = colonIndex !== -1 && !filter.includes('#ref=')
    let typeRaw = hasHash ? filter.substring(0, hashIndex) : filter

    typeRaw = hasColon ? filter.substring(0, colonIndex) : typeRaw

    const type = FilterHandler.getFullType(typeRaw)
    const attributePairs = hasHash ? filter.substring(hashIndex + 1) : ''
    const id = hasColon ? filter.substring(colonIndex + 1) : null

    const attributes = !attributePairs
      ? []
      : attributePairs.split(',').map(pair => {
        const parts = pair.replace(/(!=|>=|<=|[=<>])/, '#').split('#')

        const key = String(parts[0])
        const value = parts[1] ?? ''
        const operator = pair.substring(parts[0]?.length ?? 0, pair.length - value.length) ?? ''

        return {
          key,
          value,
          operator,
        }
      })

    return {
      type,
      attributes,
      id,
    } as Filter<Slot, MountLog>
  }

  public static handleLevel<Slot extends BaseSlot, MountLog extends BaseMountLog> (
    filter: Filter<Slot, MountLog>,
    path: string,
    element: FullCasterElement<Slot, MountLog>,
    elementMaps: ElementMaps,
  ) {
    let hit = true

    if (filter.attributes.length > 0) {
      const hitAttributes = filter.attributes.filter((attribute) => {
        if (attribute.key in element && attribute.key in element.additionalData) { // TODO: check if this is correct
          return false
        }

        const fieldIsGenerated = isGeneratedField(filter.type as CasterElementNames, attribute.key as string)
        const elementKeyValue = fieldIsGenerated
          ? generateField(filter.type as CasterElementNames, attribute.key as string, element, elementMaps)
          : element[attribute.key] ?? element.additionalData?.[attribute.key as string]

        return FilterHandler.handleOperator(String(elementKeyValue), attribute.value, attribute.operator)
      })

      hit = hitAttributes.length === filter.attributes.length
    }
    else if (filter.type === 'SegmentGroup' && filter.id === '*') {
      hit = true
    }
    else if (filter.type === 'SegmentGroup' && filter.id && filter.id.includes(',')) {
      hit = `,${filter.id},`.includes(`,${element.id},`)
    }
    else if (filter.type === 'SegmentGroup' && filter.id && filter.id.includes(':')) {
      const colonIndex = filter.id.indexOf(':')
      const rangeStart = filter.id.substring(0, colonIndex)
      const rangeStop = filter.id.substring(colonIndex + 1)

      hit = Number(rangeStart) <= Number(element.id) && Number(element.id) <= Number(rangeStop)
    }
    else if (filter.type === 'Segment' && typeof filter.id === 'string') {
      const lastPart = path ? path.substring(path.lastIndexOf('/') + 1) : ''

      hit = lastPart === `${filter.type}:${filter.id}` ||
        (element as unknown as SegmentSlot).side === FilterHandler.getFullSide(filter.id)
    }
    else if (filter.id) {
      const lastPart = path ? path.substring(path.lastIndexOf('/') + 1) : ''

      hit = lastPart === `${filter.type}:${filter.id}`
    }

    return hit
  }

  private static handleElement<Slot extends BaseSlot, MountLog extends BaseMountLog> (
    term: string,
    filters: Filter<Slot, MountLog> | Filter<Slot, MountLog>[],
    path: string,
    type: FilterableElementType,
    element: FullCasterElement<Slot, MountLog>,
    elementMaps: ElementMaps,
  ) {
    let hit = false

    if (term.includes('@') && FilterHandler.filteredElementsByReferencedCase[term]?.[path]) {
      hit = true
    }

    const parentInfo = path ? Util.getParentInfo(path) : null
    const isParentVisible = Boolean(FilterHandler.filteredElements[term]?.[parentInfo?.path ?? ''])

    const arrayOfFilters = filters instanceof Array ? filters : [ filters ]

    arrayOfFilters.forEach((filterElement, index) => {
      const allowed = index && filters instanceof Array
        ? filters[index - 1]?.type !== type
        : true
      const isChild = index && filters instanceof Array
        ? (FilterHandler.CHILDREN as any)[filters[index - 1]?.type ?? '']?.includes(type)
        : false

      if (isParentVisible && allowed && isChild && filterElement.type === '*') {
        hit = true

        return
      }

      if (filterElement.type !== type) {
        return
      }

      if (isParentVisible || index === 0) {
        hit = FilterHandler.handleLevel(filterElement, path, element, elementMaps)
      }
    })

    if (hit) {
      if (!FilterHandler.filteredElements[term]) {
        FilterHandler.filteredElements[term] = {}
      }

      FilterHandler.filteredElements[term][path] = type
    }

    return hit
  }

  private static handleCurrentPassLnCoord (): string {
    return String((window as any).currentPasslnPosition ?? 0)
  }

  private static handleHalfStrandWidth (): string {
    return String(Mold.width / 2 * 1000)
  }

  private static handleFilterControlVariables (variable: string): string {
    return String((window as any).filterControlVariables?.[variable] ?? 1)
  }

  public static handleVariables (_match: string, group1: string): string {
    if (group1 === 'CurrentPassLnCoord') {
      return FilterHandler.handleCurrentPassLnCoord()
    }
    else if (group1 === 'HalfStrandWidth') {
      return FilterHandler.handleHalfStrandWidth()
    }
    else if (group1 === 'CurrentSegmentGroupStartPasslnCoord') {
      return String((window as any).currentSegmentGroupStartPasslnCoord ?? -Infinity)
    }
    else if (FilterHandler.variables.includes(group1)) {
      return FilterHandler.handleFilterControlVariables(group1)
    }

    return group1
  }

  public static handleCalculate (_match: string, g1: string): string {
    // prepare the expression, do not allow any code injection other than the allowed Math functions
    // e.g.:
    // input: [console.log('asd');(Math.sin(1)+5)*3.5;console.log('asd')]
    // clean: (Math.sin(1)+5)*3.5
    // result: 20.445148446827638
    const calc = g1.replace(/.*?(\d\.\d|[\d+\-*()]|Math\.[a-zA-Z]+\()]?/g, '$1').replace(/\(\)/g, '')

    let result = 0

    try {
      // eslint-disable-next-line no-eval
      result = eval(calc)
    }
    catch (error: any) {
      // eslint-disable-next-line no-console
      console.log('Calculation Invalid:', calc, error)
      // TODO: display error
    }

    return String(result)
  }

  private static prepareFilters (term: string): (FilterFunction | string)[] {
    // e.g. Nozzle#passln_coord=[CurrentPassLnCoord+1-1]
    let preparedTerm = term.replace(FilterHandler.variableRegEx, FilterHandler.handleVariables)

    preparedTerm = preparedTerm.replace(/(\[[^\]]{3,}\])/g, FilterHandler.handleCalculate)

    return FilterHandler.tokenizeExpression(preparedTerm)
  }

  public static isValidFilterTerm (filterTerm: string) {
    // First, we check if the filter term ends with an incomplete operator
    if (/(\|\||&&)$/.test(filterTerm)) {
      return false
    }

    // Then, we split the filter term into tokens
    const tokens = filterTerm.split(/(\|\||&&|\(|\)|#|=)/).filter(token => token.trim() !== '')

    // We keep track of the number of opening and closing parentheses
    let numOpenParens = 0
    let numCloseParens = 0

    // We iterate through the tokens and check for syntax errors
    for (let i = 0; i < tokens.length; i++) {
      const token = tokens[i]

      if (token === '(') {
        if (tokens[i + 1] && tokens[i + 1] === ')') {
          // If we encounter an empty pair of parentheses, the syntax is invalid
          return false
        }

        numOpenParens++
      }
      else if (token === ')') {
        numCloseParens++

        // If we encounter more closing parentheses than opening ones, the syntax is invalid
        if (numCloseParens > numOpenParens) {
          return false
        }
      }
      else if (token === '||' || token === '&&') {
        // If an operator is encountered without two expressions on either side, the syntax is invalid
        if (i === 0 || i === tokens.length - 1 || tokens[i - 1] === '(' || tokens[i + 1] === ')') {
          return false
        }
      }
      else if (token === '#' || token === '=') {
        // If a '#' or '=' is encountered without valid identifiers or values on either side, the syntax is invalid
        if (i === 0 || i === tokens.length - 1) {
          return false
        }
      }
      else {
        // If an identifier or value is encountered without valid tokens on either side, the syntax is invalid
        if (
          (i > 0 && !/(\|\||&&|\(|#|=)/.test(tokens[i - 1]!)) ||
          (i < tokens.length - 1 && !/(\|\||&&|\)|#|=)/.test(tokens[i + 1]!))
        ) {
          return false
        }
      }
    }

    // Finally, we check if there are the same number of opening and closing parentheses
    // If none of the syntax checks failed, the filter term is valid
    return numOpenParens === numCloseParens
  }

  public static getTokens (filterTerm: string) {
    return filterTerm.replace(/\s/g, '').match(/([()])|\|\||&&|[^()|&]+/g) ?? []
  }

  private static tokenizeExpression (expression: string) {
    const precedence = {
      '(': 1,
      ')': 1,
      '||': 2,
      '&&': 3,
    }

    const filters: (FilterFunction | string)[] = []
    const filterStack: string[] = []

    // eslint-disable-next-line no-useless-escape
    const tokens = FilterHandler.getTokens(expression)

    if (!tokens) {
      return []
    }

    tokens.forEach(token => {
      if (token === '||' || token === '&&') {
        while (
          filterStack.length > 0 &&
          (precedence as any)[filterStack[filterStack.length - 1]!] >= precedence[token]
        ) {
          const nextFilter = filterStack.pop()

          if (nextFilter) {
            filters.push(nextFilter)
          }
        }

        filterStack.push(token)
      }
      else if (token === '(') {
        filterStack.push(token)
      }
      else if (token === ')') {
        while (filterStack[filterStack.length - 1] !== '(') {
          const nextFilter = filterStack.pop()

          if (nextFilter) {
            filters.push(nextFilter)
          }
        }

        filterStack.pop()
      }
      else {
        const deconstructedFilters = FilterHandler.getFilter(token)

        filters.push((path: string, type: FilterableElementType, element: any, elementMaps: ElementMaps) =>
          FilterHandler.handleElement(token, deconstructedFilters as any, path, type, element, elementMaps)
        )
      }
    })

    while (filterStack.length > 0) {
      const nextFilter = filterStack.pop()

      if (nextFilter) {
        filters.push(nextFilter)
      }
    }

    return filters
  }

  public static getFilterElementTypes (expression: string) {
    const types: string[] = []

    // eslint-disable-next-line no-useless-escape
    const tokens = expression.replace(/\s/g, '').match(/([()])|\|\||&&|[^()|&]+/g)

    if (!tokens) {
      return []
    }

    tokens.forEach(token => {
      if (token === '||' || token === '&&' || token === '(' || token === ')') {
        // do nothing
      }
      else {
        const deconstructedFilters = FilterHandler.getFilter(token)

        const arrayOfFilters = deconstructedFilters instanceof Array ? deconstructedFilters : [ deconstructedFilters ]

        arrayOfFilters.forEach((filterElement) => {
          if (!types.includes(filterElement.type)) {
            types.push(filterElement.type)
          }
        })
      }
    })

    return types
  }

  private static applyFiltersToElement (
    tokens: (FilterFunction | string)[],
    path: string,
    type: FilterableElementType,
    element: any,
    elementMaps: ElementMaps,
  ) {
    if (!FilterHandler.applyTokensToElement(tokens, path, type, element, elementMaps)) {
      return
    }

    FilterHandler.visibleElements[path] = type
  }

  private static applyTokensToElement (
    tokens: (FilterFunction | string)[],
    path: string,
    type: FilterableElementType,
    element: any,
    elementMaps: ElementMaps,
  ) {
    const stack: FilterFunction[] = []

    tokens.forEach(token => {
      if (token === '&&') {
        const filter2 = stack.pop()
        const filter1 = stack.pop()

        if (!filter1 || !filter2) {
          return
        }

        stack.push((path, type, element) =>
          filter1(path, type, element, elementMaps) && filter2(path, type, element, elementMaps)
        )
      }
      else if (token === '||') {
        const filter2 = stack.pop()
        const filter1 = stack.pop()

        if (!filter1 || !filter2) {
          return
        }

        stack.push((path, type, element) =>
          filter1(path, type, element, elementMaps) || filter2(path, type, element, elementMaps)
        )
      }
      else if (typeof token === 'string' && token.includes('@')) {
        if (FilterHandler.filteredElementsByReferencedCase[token]?.[path]) {
          stack.push(() => true)
        }
      }
      else {
        if (typeof token === 'string') {
          return
        }

        // is a filter
        stack.push(token)
      }
    })

    return stack[0]?.(path, type, element, elementMaps)
  }

  public static getFilteredElements (
    elementMaps: ElementMaps,
    term: string | undefined,
    skipRollerChildren: boolean,
    override = false,
  ) {
    FilterHandler.filteredElements = {}

    if (term && !FilterHandler.isValidFilterTerm(term)) {
      return {}
    }

    // TODO: this does not always work, we need to include filter variables in the cache key
    const filterKey = term ?? 'AllElements'

    // potential cache hit
    if (FilterHandler.filterCache[filterKey]) {
      const { timestamp, data } = FilterHandler.filterCache[filterKey]

      // cache hit
      if (Date.now() - timestamp < 100) {
        return data
      }

      // cache too old - delete the cache
      delete FilterHandler.filterCache[filterKey]
    }

    // cache miss - calculate the filters

    const filters = term ? FilterHandler.prepareFilters(term) : []

    FilterHandler.visibleElements = {}
    FilterHandler.filteredElements = {}

    const filterTypes = term ? FilterHandler.getFilterElementTypes(term) : []

    if (filters.length > 0) {
      FilterHandler.elementTypeInfoArray.forEach(elementTypeInfo => {
        const { type } = elementTypeInfo

        if (!filterTypes.includes(type) && !filterTypes.includes('*')) {
          return
        }

        if ((type === 'RollerBearing' || type === 'RollerBody') && skipRollerChildren) {
          return
        }

        const mountLogMap = elementMaps[`${type}MountLog`]

        for (const mountLogId in mountLogMap) {
          const mountLog = mountLogMap[mountLogId]!
          const fullElement = ElementMapsUtil.getFullCasterElementByMountLog(type, mountLog, elementMaps)
          const path = Mapping.elementPathByMountLogId[mountLog.id]

          if (!path) {
            continue
          }

          FilterHandler.applyFiltersToElement(filters, path, type as FilterableElementType, fullElement, elementMaps)
        }
      })

      // sensor points
      FilterHandler.sensorPointInfoArray.forEach(({ mountLogType, type }) => {
        if (!filterTypes.includes('SensorPoint') && !filterTypes.includes('*')) {
          return
        }

        const mountLogMap = elementMaps[mountLogType]

        for (const mountLogId in mountLogMap) {
          const mountLog = mountLogMap[mountLogId]!
          const fullElement = ElementMapsUtil.getFullCasterElementByMountLog(type, mountLog, elementMaps)
          const path = Mapping.elementPathByMountLogId[mountLog.id]

          if (!path) {
            continue
          }

          FilterHandler.applyFiltersToElement(filters, path, 'SensorPoint', fullElement, elementMaps)
        }
      })

      // data points
      FilterHandler.dataPointInfoArray.forEach(({ mountLogType, type }) => {
        if (!filterTypes.includes('DataPoint') && !filterTypes.includes('*')) {
          return
        }

        const mountLogMap = elementMaps[mountLogType]

        for (const mountLogId in mountLogMap) {
          const mountLog = mountLogMap[mountLogId]!
          const fullElement = ElementMapsUtil.getFullCasterElementByMountLog(type, mountLog, elementMaps)
          const path = Mapping.elementPathByMountLogId[mountLog.id]

          if (!path) {
            continue
          }

          FilterHandler.applyFiltersToElement(filters, path, 'DataPoint', fullElement, elementMaps)
        }
      })

      // data lines, handle like with the sensor points
      for (const mountLogId in elementMaps.DataLineMountLog) {
        const mountLog = elementMaps.DataLineMountLog[mountLogId]!
        const fullElement = ElementMapsUtil.getFullCasterElementByMountLog('DataLine', mountLog, elementMaps)

        // const path = Mapping.elementPathByMountLogId[mountLog.id]

        // TODO: if the path above works this can be removed
        const numericId = Mapping.numericIdByMountLogId[mountLog.id]
        const path = `DataLine:${numericId}`

        FilterHandler.applyFiltersToElement(filters, path, 'DataLine', fullElement, elementMaps)
      }

      // coolingLoop
      const coolingLoopMountLogs = Object.values(elementMaps.CoolingLoopMountLog ?? {})

      coolingLoopMountLogs.forEach((mountLog) => {
        const numericId = Mapping.numericIdByMountLogId[mountLog.id]

        if (numericId === undefined) {
          return
        }

        const coolingLoop = elementMaps.CoolingLoopSlot[mountLog.slotId ?? '']

        if (!coolingLoop) {
          return
        }

        const path = `CoolingLoop:${numericId}`
        const element = ElementMapsUtil.getFullCasterElement(coolingLoop, mountLog, numericId)

        FilterHandler.applyFiltersToElement(
          filters,
          path,
          'CoolingLoop',
          element,
          elementMaps,
        )
      })

      const airLoopMountLogs = Object.values(elementMaps.AirLoopMountLog ?? {})

      airLoopMountLogs.forEach((mountLog) => {
        const numericId = Mapping.numericIdByMountLogId[mountLog.id]

        if (numericId === undefined) {
          return
        }

        const airLoop = elementMaps.AirLoopSlot[mountLog.slotId ?? '']

        if (!airLoop) {
          return
        }

        const path = `AirLoop:${numericId}`
        const element = ElementMapsUtil.getFullCasterElement(airLoop, mountLog, numericId)

        FilterHandler.applyFiltersToElement(
          filters,
          path,
          'AirLoop',
          element,
          elementMaps,
        )
      })
    }

    const ret: Record<string, FilterableElementType> = cloneDeep(FilterHandler.visibleElements)

    if (override && !term) {
      return null
    }

    FilterHandler.filterCache[filterKey] = {
      timestamp: Date.now(),
      data: ret,
    }

    return ret
  }

  public static getAllElementPaths (elementMaps: ElementMaps, type?: string) {
    const currentMountLogMapsPerType: Record<string, any[]> = {}
    const paths: string[] = []

    const filteredTypeInfo = type
      ? FilterHandler.elementTypeInfoArray.filter(elementTypeInfo => elementTypeInfo.type === type)
      : FilterHandler.elementTypeInfoArray

    filteredTypeInfo.forEach(elementTypeInfo => {
      const { type } = elementTypeInfo
      const currentMountLogMaps = elementMaps[`${type}MountLog`] ?? {}

      currentMountLogMapsPerType[type] = Object.values(currentMountLogMaps)

      const mountLogs = Object.values(currentMountLogMaps)

      mountLogs.forEach((mountLog: any) => {
        // const numericId = Mapping.numericIdByMountLogId[mountLog.id]

        // if (numericId === undefined) {
        //   return
        // }

        // type with first letter in lower case
        // const elementIdKey = `${type[0]?.toLowerCase()}${type.substring(1)}Id`
        // const elementInfoId = mountLog[elementIdKey]

        // if (!elementInfoId) {
        //   return
        // }

        const path = Mapping.elementPathByMountLogId[mountLog.id]

        if (!path) {
          return
        }

        paths.push(path)
      })
    })

    FilterHandler.sensorPointInfoArray.forEach(sensorPointInfo => {
      const { mountLogType } = sensorPointInfo
      const mountLogs = Object.values(elementMaps[mountLogType] ?? {}).filter(Boolean)

      mountLogs.forEach((mountLog: any) => {
        // const numericId = Mapping.numericIdByMountLogId[mountLog.id]

        // if (numericId === undefined) {
        //   return
        // }

        const path = Mapping.elementPathByMountLogId[mountLog.id]

        if (!path) {
          return
        }

        paths.push(path)
      })
    })

    // AirLoops
    const airLoopMountLogs = Object.values(elementMaps.AirLoopMountLog ?? {})

    airLoopMountLogs.forEach((mountLog) => {
      const numericId = Mapping.numericIdByMountLogId[mountLog.id]

      if (numericId === undefined) {
        return
      }

      const path = `AirLoop:${numericId}`

      paths.push(path)
    })

    // CoolingLoops
    const coolingLoopMountLogs = Object.values(elementMaps.CoolingLoopMountLog ?? {})

    coolingLoopMountLogs.forEach((mountLog) => {
      const numericId = Mapping.numericIdByMountLogId[mountLog.id]

      if (numericId === undefined) {
        return
      }

      const path = `CoolingLoop:${numericId}`

      paths.push(path)
    })

    return paths
  }

  public static applyFilterToElements (
    // SegmentGroupSlot: SegmentGroupSlotMap,
    visibleElements: Record<string, FilterableElementType> | null,
    elementList: ElementCache,
    // views: Views,
    elementMaps: ElementMaps,
    skipRollerChildren = false,
  ) {
    const list = visibleElements ? Object.entries(visibleElements) as [string, ElementCacheKey][] : []
    const shouldHide = Boolean(list.length) || visibleElements !== null

    // Commented to not hide section view when there are no elements to show
    // const filteredTypes = Object.keys(filteredElements).reduce((list: any[], filteredElement) => ([
    //   ...list,
    //   ...JSON.parse(filteredElement).map((element) => element.type),
    // ]), [])

    // const nozzleIncl = filteredTypes.includes('Nozzle')

    // if (views.uiView?.isSectionActive) {
    //   views.uiView?.sectionViewHandler(nozzleIncl || !(filteredTypes.length > 0))
    // }

    // handle all
    // const ids = Object.keys(SegmentGroup).filter(key => !key.includes('hasChanges')).map(key => parseInt(key))

    const dataPointSlotMaps = {
      ...elementMaps.RollerDataPointSlot,
      ...elementMaps.RollerBodyDataPointSlot,
      ...elementMaps.StrandDataPointSlot,
      ...elementMaps.MoldFaceDataPointSlot,
      ...elementMaps.SegmentDataPointSlot,
    }

    Object.keys(dataPointSlotMaps).forEach(id => {
      if (!elementList.DataPoint) {
        return
      }

      const fullPath = Mapping.elementPathByMountLogId[id] ?? ''
      const object = elementList.DataPoint[fullPath]

      if (!object) {
        return
      }

      if (shouldHide) {
        return object.hide()
      }

      object.show()
    })

    Object.keys(elementMaps.DataLineSlot).filter(key => !key.includes('hasChanges')).forEach(id => {
      if (!elementList.DataLine) {
        return
      }

      const object = elementList.DataLine[`DataLine:${id}`]

      if (!object) {
        return
      }

      if (shouldHide) {
        return object.hide()
      }

      object.show()
    })

    // FIXME: just to make it work for now
    // const moldFaces = views.mainView?.data?.Caster?.Mold?.MoldFace
    const moldFaces: any = {}

    if (moldFaces) {
      Object.values(moldFaces).forEach(_moldFace => {
        const sensorPoints: any[] = []
        const amountOfSensorPoints = sensorPoints.length

        for (let i = 0; i < amountOfSensorPoints; i++) {
          const object = elementList.SensorPoint?.[`Mold/SensorPoint:${sensorPoints[i].id}`]

          if (!object) {
            return
          }

          if (shouldHide) {
            object.hide()
            continue
          }

          object.show(skipRollerChildren)
        }
      })
    }

    Object
      .keys(elementList)
      .filter(type => (
        FilterHandler.isFilterable(type) ||
        (skipRollerChildren && (type === 'RollerBody' || type === 'RollerBearing'))
      ))
      .forEach((type) => {
        Object.keys((elementList as any)[type]).forEach(path => {
          const object = (elementList as any)[type]?.[path]

          if (!object) {
            return
          }

          if (shouldHide) {
            return object.hide()
          }

          if (skipRollerChildren && (type === 'RollerBody' || type === 'RollerBearing')) {
            return
          }

          object.show(skipRollerChildren)
        })
      })

    // show filtered
    list.forEach(([ path, type ]) => {
      if (elementList[type] && elementList[type]?.[path]) {
        elementList[type]?.[path]?.show(skipRollerChildren)
      }
    })
  }
}
