/* eslint-disable camelcase */
/* eslint-disable max-len */

import type { ElementMaps } from '@/types/state'

import { defaultFields, getHiddenFields } from './defaults'

/* eslint-disable key-spacing */
const getFraction = (element: any, elementMaps: ElementMaps) => {
  const loopAmount = Object
    .values(elementMaps.NozzleMountLog ?? {})
    .filter((nozzle: NozzleMountLog) => Number(nozzle?.coolLoopIndex) === Number(element.coolLoopIndex))
    .length

  return (1 / (loopAmount || 1)).toFixed(4)
}

export const nozzleGeneratedFields = {
  waterFluxFraction: getFraction,
}

export const Nozzle = {
  categories: {
    1: 'geometry',
    2: 'process',
  },
  fields: {
    passlineCoord: { type: 'number', category: 1, defaultValue: 0, positive: true },
    widthCoord: { type: 'number', category: 1, defaultValue: 0 },
    height: { type: 'number', category: 1, defaultValue: 0, positive: true },
    angleLength: { type: 'number', category: 1, defaultValue: 0, positive: true },
    angleWidth: { type: 'number', category: 1, defaultValue: 0, positive: true },
    format: { type: 'select', category: 1, defaultValue: 'conic', options: [ 'conic', 'rectangular' ] },
    coolLoopIndex: { type: 'number', category: 2, defaultValue: 0, positive: true },
    waterFluxFraction: {
      type: 'number',
      category: 2,
      defaultValue: 1,
      disabled: true,
      generate: getFraction,
      positive: true,
    },
    // id:                  { type: 'number', category: 2, defaultValue: 0, disabled: true },
    coolWidthIndex: { type: 'number', category: 2, defaultValue: 0, positive: true },
    coolZoneIndex: { type: 'number', category: 2, defaultValue: 0, positive: true },
    nozzleType: { type: 'number', category: 2, defaultValue: 0, positive: true },
    name: { type: 'text', category: 2, defaultValue: '' },
    ...defaultFields,
    ...getHiddenFields([ 'nozzleId', 'segmentMountLogId', 'thicknessCoord' ]),
  },
}
/* eslint-enable key-spacing */
