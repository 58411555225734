export const MenuID = {
  MenuFile: {
    ID: 'caster-menu-file',
    OpenProject: 'caster-menu-file-open-project',
    NewProject: 'caster-menu-file-new-project',
    UploadCaster: 'caster-menu-file-upload-caster',
    UploadCatalog: 'caster-menu-file-upload-catalog',
    LoadCaster: 'caster-menu-file-load-caster',
    SelectCasterDashboard: 'caster-menu-file-select-caster-dashboard',
    SendToConsistencyCheck: 'caster-menu-file-send-to-consistency-check',
    SaveCasterXML: 'caster-menu-file-save-caster-xml',
    SaveVisualizationChanges: 'caster-menu-file-save-visualization-changes',
  },
  MenuEdit: {
    ID: 'caster-menu-edit',
    ResetAllShim: 'caster-menu-edit-reset-all-shim',
    ManageDynamicDataSources: 'caster-menu-edit-manage-dynamic-data-sources',
    ManageExternalDataSources: 'caster-menu-edit-manage-external-data-sources',
    ConfigurePlots: 'caster-menu-edit-configure-plots',
    ToggleEditMode: 'caster-menu-edit-toggle-edit-mode',
    ExportPlots: 'caster-menu-edit-export-plots',
    MapCommands: 'caster-menu-edit-map-commands',
    CreateRealDataCase: 'caster-menu-edit-create-real-data-case',
    ShareState: 'caster-menu-edit-share-state',
  },
  MenuView: {
    ID: 'caster-menu-view',
    Reload3D: 'caster-menu-view-reload-3d',
    ExitFullScreen: 'caster-menu-view-exit-full-screen',
    ToggleFullScreen: 'caster-menu-view-toggle-full-screen',
  },
  MenuHelp: {
    ID: 'caster-menu-help',
    about: 'caster-menu-help-about',
  },
  MenuUser: {
    ID: 'caster-menu-user',
    Logout: 'caster-menu-user-logout',
    Settings: 'caster-menu-user-settings',
  },
  MenuTreeView: {
    Backdrop: 'caster-menu-tree-view-backdrop',
    CreateRealData: 'caster-menu-tree-view-create-real-data',
  },
}

export const DialogID = {
  OpenProject: {
    ID: 'open-project-dialog',
    OpenButton: 'open-project-dialog-open-button',
    ProjectSelector: 'open-project-dialog-project-selector',
  },
  CreateProject: {
    ID: 'create-project-dialog',
    NameInput: 'create-project-dialog-name-input',
    DescriptionInput: 'create-project-dialog-description-input',
    CreateButton: 'create-project-dialog-create-button',
  },
  ProjectData: {
    ID: 'project-data-dialog',
    UploadButton: 'project-data-dialog-upload-button',
    OpenCasterButton: 'project-data-dialog-open-caster-button',
  },
  SelectDashboard: {
    LoadConfigButton: 'select-dashboard-dialog-load-config-button',
  },
  CreateRealDataCase: {
    ID: 'create-real-data-case-dialog',
    NameInput: 'create-real-data-case-dialog-name-input',
    DescriptionInput: 'create-real-data-case-dialog-description-input',
    OpenNewCaseInput: 'create-real-data-case-dialog-open-new-case-input',
    CreateButton: 'create-real-data-case-dialog-create-button',
  },
  CreateRealData: {
    ID: 'create-real-data-dialog',
    CopyCountInput: 'create-real-data-dialog-copy-count-input',
    UseExistingUUIDsInput: 'create-real-data-dialog-use-existing-uuids-input',
    CreateButton: 'create-real-data-dialog-create-button',
  },
  RealDataElementDetails: {
    ID: 'real-data-element-details-dialog',
  },
  ShareProjectDialog: {
    ID: 'share-project-dialog',
    ShareCheckbox: 'share-project-dialog-share-checkbox',
  },
  ShareStateDialog: {
    ID: 'share-state-dialog',
    CreateButton: 'share-state-dialog-create-button',
  },
  ExecutableDialog: {
    ID: 'executable-dialog',
    SelectCaseDropDown: 'executable-dialog-select-case-drop-down',
    RunButton: 'executable-dialog-run-button',
  },
}

export const UI3DID = {
  ToggleFilter: 'ui3d-toggle-filter',
  ToggleBending: 'ui3d-toggle-bending',
  ToggleRollerVisibility: 'ui3d-toggle-roller-visibility',
  ToggleSectionView: 'ui3d-toggle-section-view',
  DeselectElements: 'ui3d-deselect-elements',
  CenterView: 'ui3d-center-view',
  FilterControl: 'ui3d-filter-control',
}

export const PartsWarehouseID = {
  ShowButton: 'parts-warehouse-show-button',
}

export const CasterDetailsID = {
  ShowButton: 'caster-details-show-button',
  ToggleSidebarButton: 'caster-details-toggle-sidebar-button',
}

export const TreeViewID = {
  // IDs
  ToggleSidebarButton: 'tree-view-toggle-sidebar-button',
  OpenAllButton: 'tree-view-open-all-button',
  OpenDefaultButton: 'tree-view-open-default-button',
  CloseAllButton: 'tree-view-close-all-button',
  RenameSegmentGroupInput: 'tree-view-rename-segment-group-input',
  // Classes
  RenameSegmentGroupButton: 'tree-view-rename-segment-group-button',
  EDocHRefButton: 'tree-view-edoc-href-button',
  OpenHistoryDetailDialogButton: 'tree-view-open-history-detail-dialog-button',
  JumpToElementButton: 'tree-view-jump-to-element-button',
  SetFilterButton: 'tree-view-set-filter-button',
  ChildrenSelector: 'tree-view-children-selector',
}
