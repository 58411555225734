import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

import type { CasterElementNames } from '@/types/data'

export interface WarehouseState {
  countPerType: Record<string, number | undefined>
  elementsPerType: Record<CasterElementNames, Array<{ id: string, realDataUUID: string, name?: string }>>
  filterCache: {
    count: number
    elements: { id: string, realDataUUID: string, name?: string }[]
  }
}

const initialState = { countPerType: {}, elementsPerType: {}, filterCache: {} } as WarehouseState

const warehouseSlice = createSlice({
  name: 'warehouse',
  initialState,
  reducers: {
    setCountPerType (state, action: PayloadAction<Record<string, number>>) {
      state.countPerType = action.payload
    },
    setElementsPerType (
      state,
      action: PayloadAction<Record<CasterElementNames, Array<{ id: string, realDataUUID: string, name?: string }>>>,
    ) {
      state.elementsPerType = action.payload
    },
    setFilterCache (state, action: PayloadAction<WarehouseState['filterCache']>) {
      state.filterCache = action.payload
    },
  },
})

export const { setCountPerType, setElementsPerType, setFilterCache } = warehouseSlice.actions

export default warehouseSlice.reducer
