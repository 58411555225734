/* eslint-env browser */

import { faChartLine, faDownload, faExclamationCircle, faFilter, faLock } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { enqueueSnackbar } from 'notistack'
import Plotly from 'plotly.js-dist-min'
import { Children, cloneElement, PureComponent } from 'react'
import { withTranslation } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'redux'
import styled, { css } from 'styled-components'

import FeatureFlags from '@/react/FeatureFlags'
import { ResizeData } from '@/react/ResizeDetector'
import * as ApplicationActions from '@/store/application/main/actions'
import * as FilterActions from '@/store/filter/actions'
import * as VisualizationActions from '@/store/visualization/actions'
import type { DefaultState } from '@/types/state'
import type { Translation } from '@/types/translation'
import type { PlotConfig, TileConfig } from '@/types/visualization'

import { EditCriticalStrainCurveDialog } from './Dialogs/EditCriticalStrainCurveDialog'
import { Container, Menu, Name, Pipe, PlotTitle, TopBar, Wrapper } from './PlotStyles'
import { ViewLogic } from './ViewLogic'
import Logic from '../PlotWrapper/EditBoxWrapper/Logic'

const connector = connect((state: DefaultState) => ({
  plotConfigs: state.visualization.plotConfigs,
  tileConfigs: state.visualization.tileConfigs,
  isEditModeOn: state.visualization.isEditModeOn,
  currentSimulationCase: state.application.main.currentSimulationCase,
  term: state.filter.term,
  tileWarnings: state.tileWarnings,
  featureFlags: FeatureFlags.getRealFeatureFlags(state),
  appState: state.application.main.appState,
  visualizationMetaInformation: state.visualization.visualizationMetaInformation,
  catalogList: state.data.catalogList,
}), {
  showConfigDialog: VisualizationActions.showConfigDialog,
  showDeleteDialog: VisualizationActions.showDeleteDialog,
  saveCurrentTile: VisualizationActions.saveCurrentTile,
  setTerm: FilterActions.setTerm,
  openDialog: ApplicationActions.openDialog,
})

type PropsFromRedux = ConnectedProps<typeof connector>

const StyledFilterIcon = styled(FontAwesomeIcon)`${() =>
  css`
  cursor: pointer;
  font-size: 19px;
`}`

const WarningMessage = styled.div`${() =>
  css`
  height: 20px;
  line-height: 20px;
  width: fit-content;
  color: #BB1B1B;
  cursor: help;
`}`

interface Props extends PropsFromRedux {
  children: any
  tileId: string
  additionalId?: string
  onlyChild?: boolean
  // TODO: if this tile config is always the same as the one we get via tileConfigs[tileId] we can get rid of it
  tileConfig: TileConfig
  t: Translation
}

type State = {
  closed: boolean
  childState: any | null | undefined
}

class GridItemContainer extends PureComponent<Props, State> {
  public override state: State = {
    closed: true,
    childState: null,
  }

  private readonly handleOpenSettings = () => {
    const { childState } = this.state
    const { showConfigDialog, tileId, saveCurrentTile, t, tileConfigs } = this.props

    if (childState && childState.isEditing) {
      enqueueSnackbar(t('gridItemContainer.edit.warning'), { autoHideDuration: 3000, variant: 'info' })

      return
    }

    const { configId: plotConfigId } = tileConfigs?.[tileId] ?? {}

    if (plotConfigId) {
      showConfigDialog(plotConfigId, true)
    }

    saveCurrentTile(tileId)
  }

  private readonly handleDownload = async () => {
    const { tileId, tileConfigs, plotConfigs } = this.props
    const plot: any = document.getElementById(`plot_${tileId}`)
    const tileConfig = tileConfigs?.[tileId] ?? {} as Partial<TileConfig>
    let filename = tileConfig.name

    if (filename === 'New Plot' || !filename) {
      const config = plotConfigs?.[tileConfig.configId ?? ''] ?? {} as Partial<PlotConfig>

      filename = config.name ?? 'export'
    }

    // replace white spaces with underscores
    filename = filename.replace(/\s/g, '_')

    if (!plot || !plot.data || !plot.layout) {
      enqueueSnackbar('No plot to download', { autoHideDuration: 3000, variant: 'error' })

      return
    }

    const tempDiv = document.createElement('tmp-div')
    const imageHeight = plot.layout.height * 2
    const imageWidth = plot.layout.width * 2
    const newPlot = await ViewLogic.clonePlotAndChangeFontColorToBlack(plot, tempDiv, imageHeight, imageWidth)

    await Plotly.relayout(newPlot, { width: imageWidth, height: imageHeight })

    Plotly.downloadImage(newPlot, {
      format: 'jpeg',
      width: imageWidth,
      height: imageHeight,
      filename,
    })

    tempDiv.remove()
  }

  private readonly handleOpenEditCriticalStrainCurveDialog = () => {
    const { tileConfig, openDialog } = this.props

    openDialog(EditCriticalStrainCurveDialog.NAME, { plotConfigId: tileConfig?.configId, tileId: tileConfig?.id })
  }

  private readonly handleFilter = (event: any) => {
    event.stopPropagation()

    const { plotConfigs, setTerm, term, tileConfig } = this.props
    const config = plotConfigs[tileConfig.configId]

    if (!config) {
      return
    }

    const isMerged = config.isMergedDynamicDataSource

    const ctrl = event.ctrlKey || event.metaKey

    if (isMerged) {
      const filtersArray = this.getMergedPlotsFilter(config, plotConfigs) // Array of all filters of the MergedPlot
      const concatenatedFilters = filtersArray.map(filter => `(${filter})`).join(' || ')

      // if all elements are contained in the term, we should erase them
      if (filtersArray.every(filter => term.includes(filter))) {
        // we split the actual term and then set a new one without the mergedPlot's filters
        // TODO: rework this, after the filter rework it has become too complex
        // const splitTerm = term.split(' ')
        // const newFilters = splitTerm.filter(filter => !filtersArray.includes(filter)).join(' || ') ?? ''

        setTerm('')
      }
      else {
        /* if only some filters are present we should verify which ones, and depending if ctrl was pressed we should add
        the missing filters, if it was not the new filter is simply all mergedPlot's filters */
        // const splitTerm = term.split(' ')
        // let newFilters = ''

        // if (ctrl) {
        //   for (const filter of filtersArray) {
        //     if (!splitTerm.includes(filter)) {
        //       splitTerm.push(filter)
        //     }
        //   }

        //   newFilters = splitTerm.join(' ?? ')
        // }
        // else {
        //   newFilters = concatenatedFilters
        // }

        setTerm(concatenatedFilters)
      }
    }
    else {
      setTerm(config.filter, ctrl, false, true)
    }
  }

  private readonly handleDeletePlot = () => {
    const { showDeleteDialog, children } = this.props

    showDeleteDialog(children.props, true)
  }

  private readonly handleResize = ({ width, height }: ResizeData) => {
    const { tileId } = this.props
    const plot: any = document.getElementById(`plot_${tileId}`)

    if (plot && plot.layout) {
      try {
        Plotly.relayout(plot, { ...plot.layout, width, height })
      }
      catch (e) {}
    }
  }

  private readonly handleCloseTopBar = () => {
    this.setState({
      closed: true,
    })
  }

  private readonly handleChildState = (childState: any) => this.setState({ childState })

  private readonly handleEditEditBox = (event: any) => {
    const { id } = event.currentTarget
    const editableContent = document.querySelector(`#editable_content_${id}`)

    if (editableContent) {
      // TODO: check if this works in other browsers than chrome/electron
      const doubleClickEvent = new MouseEvent('dblclick', { view: window, bubbles: true, cancelable: true })

      editableContent.dispatchEvent(doubleClickEvent)
    }
  }

  private getMergedPlotsFilter (mergedConfig: PlotConfig, plotConfigs: Record<string, PlotConfig>): string[] {
    const filters: string[] = []

    if (!mergedConfig.configs || !mergedConfig.configs.length) {
      return []
    }

    for (const plotConfigId of mergedConfig.configIds) {
      const config = plotConfigs[plotConfigId] ?? {} as Partial<PlotConfig>

      if (config.filter && config.filter.length) {
        filters.push(config.filter)
      }
    }

    return filters
  }

  private isFilterable (config: PlotConfig) {
    if (config.isMergedDynamicDataSource) {
      return config.configs.some((config: PlotConfig) => (config.filter ?? '').length)
    }

    return config.filter && config.filter.length
  }

  private isCriticalStrainCurve (config: PlotConfig) {
    if (config.isMergedDynamicDataSource) {
      return config.configs.some((config: PlotConfig) => (
        config.filter ?? '').length &&
        config.filter.toLowerCase().includes('dataline') &&
        config.selectedY?.toLowerCase().includes('critical'))
    }

    return false
  }

  private readonly getTileWarningMessage = (config: PlotConfig) => {
    const { tileWarnings } = this.props

    if (config.isMergedDynamicDataSource) {
      let warningMessage = ''

      // append to message the warning message of each merged plot
      config.configs.forEach((cfg: PlotConfig) => {
        if (tileWarnings && tileWarnings[cfg.id] && (Object.keys(tileWarnings[cfg.id]!).length > 0)) {
          const message = `No data found for config ${cfg.name} for cases: ` +
            `${Object.keys(tileWarnings[cfg.id]!).join(', ')}\n`

          warningMessage += message
        }
      })

      return warningMessage
    }

    if (tileWarnings && tileWarnings[config.id] && (Object.keys(tileWarnings[config.id]!).length > 0)) {
      return `No data found for config ${config.name} for cases: ${
        String(Object.keys(tileWarnings[config.id]!).join(', '))
      }`
    }

    return ''
  }

  public override render () {
    const { closed: closedRaw } = this.state
    const {
      currentSimulationCase,
      children,
      tileId,
      tileConfig: tileConfigProp,
      plotConfigs,
      tileConfigs,
      isEditModeOn,
      term,
      featureFlags,
      t,
      appState,
      visualizationMetaInformation,
      catalogList,
    } = this.props
    
    const rawPlotConfig = plotConfigs[tileConfigProp?.configId ?? '']
    const plotConfig = rawPlotConfig ?? {} as Partial<PlotConfig>
    const tileConfig = tileConfigs[tileId] ?? {} as Partial<TileConfig>
    const closed = closedRaw && !(isEditModeOn || (!isEditModeOn && tileConfig?.name?.length))
    const type = tileConfig.type ?? plotConfig.type
    const { configId } = tileConfig
    const filterable = rawPlotConfig ? this.isFilterable(rawPlotConfig) : false
    const filterActive = plotConfig.isMergedDynamicDataSource
      ? this
        .getMergedPlotsFilter(rawPlotConfig!, plotConfigs)
        .map(filter => `(${filter})`)
        .every(filter => term.includes(filter))
      : Boolean(plotConfig?.filter) && term.includes(plotConfig.filter!)
    const isCriticalStrainCurve = filterable && this.isCriticalStrainCurve(rawPlotConfig!)
    const warningMessage = rawPlotConfig ? this.getTileWarningMessage(rawPlotConfig) : ''

    const extendedChildren = Children.map(
      children,
      child => cloneElement(child, { onChildState: this.handleChildState }),
    )

    let displayName = tileConfig.name ?? ''
    let displayTitle = ''

    if ((!displayName || displayName === 'New Plot') && type === 'edit_box') {
      displayName = configId?.substring(configId?.lastIndexOf('/') + 1) ?? ''
      displayTitle = configId ?? ''
    }

    if (!displayName) {
      displayTitle = displayName = (plotConfig.name ?? 'Unnamed Plot') +
        (plotConfig.isMergedDynamicDataSource ? ' (Various Plots)' : '')
    }

    const canActivateFilterPlot = FeatureFlags.canActivateFilterPlot(featureFlags)
    const canEditCriticalStrainCurve = FeatureFlags.canEditCriticalStrainCurve(featureFlags)
    const canDeletePlot = FeatureFlags.canDeletePlotsInCurrentDashboardType(
      featureFlags,
      visualizationMetaInformation,
      appState,
    )
    const canEditPlot = FeatureFlags.canEditPlotsInCurrentDashboardType(
      featureFlags,
      visualizationMetaInformation,
      appState,
    )
    const canMovePlot = FeatureFlags.canMovePlotsInCurrentDashboardType(
      featureFlags,
      visualizationMetaInformation,
      appState,
    )
    const canDownloadPlotImage = FeatureFlags.canDownloadPlotImage(featureFlags)

    return (
      <Wrapper>
        <TopBar
          className='header_bar'
          $canMove={canMovePlot}
          $closed={closed}
          onMouseLeave={this.handleCloseTopBar}
        >
          <PlotTitle $closed={closed}>
            <Name $isEditModeOn={isEditModeOn} title={displayTitle}>{displayName}</Name>
            <Menu
              onMouseDown={event => event.stopPropagation()}
            >
              {
                Boolean(warningMessage) &&
                (
                  <WarningMessage>
                    <FontAwesomeIcon
                      icon={faExclamationCircle}
                      title={warningMessage ?? ''}
                      fixedWidth
                    />
                  </WarningMessage>
                )
              }
              {canDownloadPlotImage && <Pipe />}
              {
                canDownloadPlotImage && (
                  <StyledFilterIcon
                    icon={faDownload}
                    className='pe-7s-download'
                    onClick={this.handleDownload}
                    title='Export plot'
                  />
                )
              }
              {isCriticalStrainCurve && canEditCriticalStrainCurve && <Pipe />}
              {
                isCriticalStrainCurve &&
                canEditCriticalStrainCurve &&
                (
                  <StyledFilterIcon
                    icon={faChartLine}
                    onClick={this.handleOpenEditCriticalStrainCurveDialog}
                    title='Open Critical Strain Curve Editor'
                  />
                )
              }
              {filterable && canActivateFilterPlot && <Pipe />}
              {
                filterable &&
                canActivateFilterPlot &&
                (
                  <StyledFilterIcon
                    icon={faFilter}
                    className='pe-7s-filter'
                    onClick={this.handleFilter}
                    title='Toggle Filter'
                    style={{ color: filterActive ? '#BB1B1B' : '#CCCCCC' }}
                  />
                )
              }
              {type === 'edit_box' && <Pipe />}
              {
                type === 'edit_box' && (
                  !Logic.isFrozen(currentSimulationCase) &&
                  !Logic.isOnlyViewable(currentSimulationCase, configId ?? '', catalogList)
                    ? (
                      <i
                        className='pe-7s-pen'
                        onClick={this.handleEditEditBox}
                        id={tileId}
                        title={t('gridItemContainer.editable')}
                      />
                    )
                    : (
                      <FontAwesomeIcon
                        icon={faLock}
                        fixedWidth
                        title={t('gridItemContainer.not_editable')}
                      />
                    )
                )
              }
              {canDeletePlot && <Pipe />}
              {
                canDeletePlot &&
                (
                  <i
                    className='pe-7s-trash'
                    onClick={this.handleDeletePlot}
                    id={tileId}
                    title={t('gridItemContainer.trash')}
                  />
                )
              }
              {canEditPlot && <Pipe />}
              {
                canEditPlot &&
                (
                  <i
                    className='pe-7s-config'
                    onClick={this.handleOpenSettings}
                    id={tileId}
                    title={t('gridItemContainer.config')}
                  />
                )
              }
            </Menu>
          </PlotTitle>
        </TopBar>
        <Container
          // TODO: this prevents initial double render, but it causes min width dashboards to not render plots properly
          // skipOnMount
          onResize={this.handleResize}
        >
          {extendedChildren}
        </Container>
      </Wrapper>
    )
  }
}

export default compose<any>(withTranslation('visualization'), connector)(GridItemContainer)
