/* eslint-env browser */

import hoistStatics from 'hoist-non-react-statics'
import hotkeys from 'hotkeys-js'
import { enqueueSnackbar } from 'notistack'
import { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'redux'

import {
  createExternalDataSource,
  deleteExternalDataSource,
  updateExternalDataSource,
} from '@/api/external-data-sources'
import { getDashboards, getPanels } from '@/api/grafana-api'
import { getVisualizationConfigById } from '@/api/visualization-config'
import Button from '@/react/components/Button'
import { ConfirmWrapper } from '@/react/components/Button/styles'
import { Spacer } from '@/react/dialogs/project/OpenProjectDialog/Styles'
import FeatureFlags from '@/react/FeatureFlags/index'
import Input from '@/react/specific/Input'
import { Form } from '@/react/visualization/dashboard/Dialogs/DialogStyles'
import VisUtil from '@/react/visualization/VisUtil'
import * as ApplicationActions from '@/store/application/main/actions'
import { AppState } from '@/store/application/main/consts'
import * as VisualizationActions from '@/store/visualization/actions'
import type { DefaultState } from '@/types/state'
import type { PlotConfig } from '@/types/visualization'
import { Identifiable } from '@/Util/decorators/Identifiable'

import BaseDialog from '../BaseDialog'

const connector = connect((state: DefaultState) => ({
  plotConfigs: state.visualization.plotConfigs,
  appState: state.application.main.appState,
  visualizationData: state.visualization.data,
  error: state.application.error,
  featureFlags: FeatureFlags.getRealFeatureFlags(state),
  visualizationMetaInformation: state.visualization.visualizationMetaInformation,
  Caster: state.Caster,
}), {
  setDataSources: VisualizationActions.setDataSources,
  setConfig: VisualizationActions.setConfig,
  closeDialog: ApplicationActions.closeDialog,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  t(key: string, params?: Record<string, unknown>): string
}

type State = {
  name: string
  error: string
  loading: boolean
  selectedDataSource: string
  dashboardId: string
  panelId: number
  isValid: boolean
  dashboards: { id: string, name: string }[]
  panels: { id: number, name: string }[]
}

const T = 'manageExternalDataSourcesDialog'

export class ManageExternalDataSourcesDialog extends Component<Props, State> {
  @Identifiable('ManageExternalDataSourcesDialog') public static readonly NAME: string

  public override state: State = {
    name: '',
    error: '',
    loading: false,
    selectedDataSource: 'new',
    dashboardId: '',
    panelId: 0,
    isValid: false,
    dashboards: [],
    panels: [],
  }
  
  public override componentDidMount () {
    const { Caster, closeDialog, t } = this.props

    if (!Caster) {
      enqueueSnackbar(t(`${T}.casterNeeded`), { autoHideDuration: 3000, variant: 'info' })

      closeDialog(ManageExternalDataSourcesDialog.NAME)

      // TODO: project dialog?

      return
    }

    this.handleInit()

    hotkeys('Escape', this.handleClose)
  }
  
  public override componentDidUpdate (_prevProps: Props, prevState: State) {
    this.handleInit()
    this.updateIsValid(prevState, this.state)
  }
  
  public override componentWillUnmount () {
    hotkeys.deleteScope('other')
    hotkeys.unbind('Escape', this.handleClose)
  }

  private readonly handleInit = () => {
    const { dashboardId, panelId } = this.state

    if (dashboardId && panelId) {
      return
    }

    getDashboards()
      .then((dashboards) => {
        if (!dashboards?.[0]?.id) {
          return
        }

        this.setState({ dashboards, dashboardId: dashboards[0].id })

        return getPanels(dashboards[0].id)
      })
      .then((panels) => {
        if (!panels?.[0]?.id) {
          return
        }

        this.setState({ panels, panelId: panels[0].id })
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error)
      })
  }

  private readonly handleClose = () => {
    const { closeDialog } = this.props

    closeDialog(ManageExternalDataSourcesDialog.NAME)
  }

  private readonly handleNameInput = (event: KeyboardEvent) => {
    const { name, value } = event.target as HTMLInputElement & { name: 'name' }

    this.setState({ [name]: value })
  }

  private readonly handleKeyDown = (event: any) => {
    if (event.keyCode === 13 && this.state.isValid) {
      this.handleSubmit()
    }
  }

  private readonly handleSubmit = async () => {
    const { name, selectedDataSource, dashboardId, panelId } = this.state
    const { visualizationMetaInformation, plotConfigs, setDataSources } = this.props
    const { config } = visualizationMetaInformation?.[AppState.Caster] ?? {}

    this.setState({ loading: true })

    try {
      let dataSources: any[] = []

      if (selectedDataSource === 'new') {
        const res =
          await createExternalDataSource(name, 'grafana', { dashboardId, panelId }, config)

        dataSources = [ ...Object.values(plotConfigs), res ]
      }
      else {
        // split because the id is in format: config_${dataSourceId}
        const dataSourceId = plotConfigs[selectedDataSource]?.id?.split('_')[1]

        if (!dataSourceId) {
          // eslint-disable-next-line no-console
          console.error('Error: dataSourceId is undefined')

          return
        }

        const res =
          await updateExternalDataSource(dataSourceId, name, 'grafana', { dashboardId, panelId })

        const oldDataSource = Object.values(plotConfigs).find((config) => config.id === dataSourceId)
        const newDataSource = { ...oldDataSource, ...res }

        dataSources = [ ...Object.values(plotConfigs).filter((config) => config.id !== dataSourceId), newDataSource ]
      }

      setDataSources({ externalDataSources: dataSources } as VisualizationConfig)
      this.handleClose()
    }
    catch (response: any) {
      // eslint-disable-next-line no-console
      console.log(response)

      const { error } = response

      this.setState({
        error: error ? error.status : 'unknown',
        loading: false,
      })
    }
  }

  private readonly handleSourceChange = (event: any) => {
    const { value } = event.target

    getPanels(value)
      .then((panels) => {
        if (!panels?.[0]?.id) {
          return
        }

        this.setState({ dashboardId: value, panels, panelId: panels[0].id })
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error)
      })
  }

  private readonly handlePanelChange = (event: any) => {
    const { value } = event.target

    this.setState({ panelId: Number(value) })
  }

  private readonly handleChosenDataSourceChange = (event: any) => {
    const { value } = event.target 
    
    if (value === 'new' || value === '') {
      const { dashboards, panels } = this.state 

      this.setState({
        selectedDataSource: value || 'new',
        dashboardId: dashboards[0]?.id ?? '',
        panelId: panels[0]?.id ?? 0,
      })
    }
    else {
      const { plotConfigs } = this.props
      const { name, data } = plotConfigs[value] ?? {}
      const { dashboardId, panelId } = data ?? {}

      if (!dashboardId || !panelId || !name) {
        // eslint-disable-next-line no-console
        console.error('Error: dashboardId or panelId is undefined')

        return
      }

      this.setState({
        selectedDataSource: value,
        name,
        dashboardId,
        panelId,
      })
    }
  }

  private readonly handleSourceClick = () => {
    const { selectedDataSource } = this.state
    const { plotConfigs } = this.props
    const filter = plotConfigs[selectedDataSource]?.filter

    if (filter) {
      navigator.clipboard.writeText(filter)
    }
  }

  private readonly handleDeleteDataSource = async (_inputName: string, plotConfigId: string) => {
    const { plotConfigs, setConfig, visualizationMetaInformation } = this.props
    const { config } = visualizationMetaInformation?.[AppState.Caster] ?? {}

    const dataSourceId = plotConfigs[plotConfigId]?.dataSourceId

    if (!dataSourceId) {
      enqueueSnackbar('Error deleting data source', { variant: 'error', autoHideDuration: 4000 })

      return
    }

    try {
      await deleteExternalDataSource(dataSourceId)

      const visualizationConfig = await getVisualizationConfigById(config)

      if (!visualizationConfig) {
        return
      }

      setConfig(visualizationConfig)
    }
    catch (error: any) {
      // eslint-disable-next-line no-console
      console.log(error)
      enqueueSnackbar('Error deleting data source', { variant: 'error', autoHideDuration: 4000 })
    }
  }

  private readonly updateIsValid = (prevState: State, currentState: State) => {
    // compare previous and current state keys used in the updateIsValid function to avoid unnecessary re-renders
    const keys: (keyof State)[] = [
      'name',
      'selectedDataSource',
      'dashboardId',
      'panelId',
    ]
    const changedKeys = keys.filter(key => prevState[key] !== currentState[key])

    if (changedKeys.length === 0) {
      return
    }

    const { name, dashboardId, panelId } = currentState

    const condition = Boolean(name) && dashboardId !== '' && panelId !== 0

    if (this.state.isValid === condition) {
      return
    }
    
    this.setState({ isValid: condition })
  }

  // TODO: handle duplicate names, show message and confirm override
  
  public override render () {
    const {
      name,
      error,
      loading,
      dashboardId,
      panelId,
      dashboards,
      panels,
    } = this.state
    const {
      t,
      plotConfigs,
      featureFlags,
      visualizationMetaInformation,
      appState,
    } = this.props

    const configIds = Object.keys(plotConfigs ?? {})

    const allDataSources = []

    allDataSources.push(...configIds.reduce((acc: any[], plotConfigId: string) => {
      const config = plotConfigs[plotConfigId] ?? {} as Partial<PlotConfig>
      const { name, _id } = config

      if (!name || !_id || config.group !== 'externalDataSource') {
        return acc
      }

      const { value } = VisUtil.getConfigInfo(name, _id, _id, 'externalDataSource', config as PlotConfig)

      return [
        ...acc,
        { key: plotConfigId, value },
      ]
    }, []))

    // sort data sources alphabetically case unsensitive
    allDataSources.sort((a, b) => a.value.toLowerCase().localeCompare(b.value.toLowerCase()))

    if (FeatureFlags.canAddExternalData(featureFlags, visualizationMetaInformation, appState)) {
      allDataSources.unshift({ key: 'new', value: 'New External Data Source', notRemovable: true })
    }

    const currentDataSourceInfo = plotConfigs[this.state.selectedDataSource]?.filter ?? ''
    const disableEdition = this.state.selectedDataSource !== 'new' &&
      !FeatureFlags.canEditExternalData(featureFlags, visualizationMetaInformation, appState)

    const dataSourceSelectors = dashboards.map(({ id, name }) => ({ key: id, value: name }))
    const panelSelectors = panels.map(({ id, name }) => ({ key: id, value: name }))

    return (
      <BaseDialog
        title={t(`${T}.title`)}
        icon='pe-7s-server'
        header={t(`${T}.header`)}
        headerWidth='300px'
        onClose={this.handleClose}
        small
      >
        <Form>
          <Input
            name='externalDataSources'
            type='select'
            label='External Data'
            title={currentDataSourceInfo}
            value={this.state.selectedDataSource}
            selectors={[ ...allDataSources ]}
            onDelete={
              FeatureFlags.canDeleteExternalData(featureFlags, visualizationMetaInformation, appState) &&
              this.handleDeleteDataSource
            }
            onChange={this.handleChosenDataSourceChange}
            onLabelRightClick={this.handleSourceClick}
          />
          <Input
            label={t(`${T}.name.label`)}
            title={t(`${T}.name.label`)}
            name='name'
            type='text'
            value={name}
            onChange={this.handleNameInput}
            onKeyDown={this.handleKeyDown}
            disabled={disableEdition}
          />
          <Input
            name='source'
            type='select'
            label={t(`${T}.source.label`)}
            title={t(`${T}.source.title`)}
            value={dashboardId}
            selectors={dataSourceSelectors}
            onChange={this.handleSourceChange}
            disabled={disableEdition}
          />
          <Spacer $h={5} $br />
          <Input
            name='panel'
            type='select'
            label={t(`${T}.panel.label`)}
            title={t(`${T}.panel.title`)}
            value={panelId}
            selectors={panelSelectors}
            onChange={this.handlePanelChange}
            disabled={!dashboardId || dataSourceSelectors.length < 1 || disableEdition}
          />
          <Spacer $h={5} $br />
          <ConfirmWrapper>
            <Button
              title={this.state.selectedDataSource === 'new' ? 'Create' : 'Edit'}
              type='primary'
              disabled={!this.state.isValid}
              onClick={this.handleSubmit} // see if this.state.selectedDataSource is 'New ..' if is, post , else patch
              error={error}
              loading={loading}
              isRef
              half
            >
              {this.state.selectedDataSource === 'new' ? t(`${T}.save`) : 'Update'}
            </Button>
            <Button
              value=''
              onClick={this.handleClose}
              title={t(`${T}.cancel`)}
              half
            >
              {t(`${T}.cancel`)}
            </Button>
          </ConfirmWrapper>
        </Form>
      </BaseDialog>
    )
  }
}

const composedComponent = compose<any>(withTranslation('application'), connector)(ManageExternalDataSourcesDialog)

export default hoistStatics(composedComponent, ManageExternalDataSourcesDialog)
