import cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'

import CalcUtil from '@/Util/CalcUtil'

import type { Props } from '.'

export default class logic {
  private static didCreate = false

  public static connect (
    prevProps: Props,
    props: Props,
    setData: ({ tileId, definitions }: { tileId: string, definitions: any[] }) => void,
  ) {
    const {
      tileId,
      xRange,
      valueRange,
      frequency,
      configId,
      plotConfigs,
      data,
      tileConfigs,
    } = props

    let plotConfigIds = [ configId ]

    const {
      frequency: prevFrequency,
      // type: prevType,
      xRange: prevXRange,
      valueRange: prevValueRange,
    } = prevProps

    const { configIds: prevConfigIds } = prevProps.plotConfigs[tileConfigs[tileId]?.configId ?? ''] ?? {}
    const { configIds } = plotConfigs[tileConfigs[tileId]?.configId ?? ''] ?? {}

    const prevDataRange = [ ...(prevXRange ?? []) ]
    const dataRange = [ ...xRange ]

    // TODO: this looks wrong, if it is not needed remove it!
    // prevDataRange[1] = Math.min(prevDataRange[1])
    // dataRange[1] = Math.min(dataRange[1])

    const redraw = (Boolean(prevConfigIds?.length) && !isEqual(prevConfigIds, configIds)) ||
      (prevXRange && (prevXRange.length > 0) && !isEqual(prevDataRange, dataRange)) ||
      (prevValueRange && (prevValueRange.length > 0) && !isEqual(prevValueRange, valueRange)) ||
      (prevFrequency !== frequency) ||
      // (prevType !== type) ||
      (prevProps.configId !== configId) ||
      !isEqual(prevProps.tileConfigs[tileId], tileConfigs[tileId])

    if (redraw) {
      logic.didCreate = false
    }

    // eslint-disable-next-line
    if (!isEqual(props, prevProps)) {
      logic.didCreate = false
    }

    if (!logic.didCreate) {
      logic.didCreate = true

      if (configIds) {
        plotConfigIds = cloneDeep(configIds)
      }

      // const dataRange = [ ...xRange ]

      // dataRange[1] = Number(xRange[1])

      if ((plotConfigIds || plotConfigIds === 0) && !(plotConfigIds instanceof Array)) {
        plotConfigIds = [ plotConfigIds ]
      }

      if (data && (Object.keys(data).length > 0)) {
        setData({
          tileId,
          definitions: plotConfigIds.map(plotConfigId => {
            const { group, key } = plotConfigs[plotConfigId] ?? {}
            const values = ((data as any)[group ?? ''] ?? {})[key ?? ''] ?? []

            const { min: xMin, max: xMax } = CalcUtil.getMinMax(xRange)

            const start = Math.max(xMin, 0)
            const end = Math.min(xMax, values.length)

            const newData = []

            for (let j = start; j < end; j++) {
              newData.push(values[j])
            }

            return {
              id: plotConfigId,
              length: values.length,
              bytes: null,
              data: newData,
            }
          }),
        })
      }
    }
  }
}
