import styled, { css } from 'styled-components'

export const Header = styled.div<{ $isSelected: boolean }>`${({ $isSelected }) =>
  css`
  width: 100%;
  color: #CCCCCC;
  border-top: solid 1px #000000;
  border-bottom: solid 1px #000000;
  margin-bottom: -1px;
  position: relative;
  background-color: ${$isSelected ? '#0777BD' : 'none'}
`}`

export const IconBox = styled.div<{ $isChild?: boolean }>`${({ $isChild }) =>
  css`
  display: inline-block;
  width: 50px;
  height: 50px;
  color: #EEEEEE;
  background: ${$isChild ? '#333a44' : '#E1071B'};
  text-align: center;
  position: relative;
  overflow: hidden;
  vertical-align: top;
  
  svg {
    font-size: 35px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`}`

export const NameBox = styled.div<{ $pad: number }>`${({ $pad }) =>
  css`
  display: inline-block;
  height: 50px;
  width: calc(100% - 100px);
  vertical-align: top;
  position: relative;
  font-size: 18px;
  font-weight: bold;
  text-align: left;
  padding-left: ${$pad}px;
  line-height: 54px;
  user-select: none;
  cursor: pointer;
`}`

export const Trigger = styled.div`
  display: inline-block;
  height: 50px;
  width: 50px;
  vertical-align: top;
  border-left: solid 1px #000000;
  position: relative;
  cursor: pointer;

  i {
    font-size: 50px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`

export const Lock = styled.span`
  position: absolute;
  top: 54%;
  transform: translateY(-50%);
  right: 60px;
  font-size: 20px;
  cursor: help;
  
  i {
    font-weight: 600;
  }
`
