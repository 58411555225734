import { Component } from 'react'

import CommandWrapper from './CommandWrapper'
import EditBoxWrapper from './EditBoxWrapper'
import GageWrapper from './GageWrapper'
import MessageWrapper from './MessageWrapper'
import PlotlyWrapper from './PlotlyWrapper'
import TextWrapper from './TextWrapper'

type Props = {
  tileId: string
  type: 'area' |
  'bar' |
  'command' |
  'contour' |
  'edit_box' |
  'gage' |
  'line' |
  'message' |
  'pie' |
  'radar' |
  'table' |
  'text'
  message?: string | undefined
  key: string
  /**
   * PlotConfig ID
   */
  configId: string
  viewOnly?: boolean | undefined
  additionalId?: string | undefined
  xDomain?: Domain | undefined
  yDomain?: Domain | undefined
  xRange?: number[] | undefined
  xValues?: number[] | undefined
  yValueRange?: number[] | undefined
  valueRange?: number[] | undefined
  shapeIds?: string[] | undefined
  radiusDomain?: Domain | undefined
  radius0?: number | undefined
  radius?: number | undefined
  frequency?: number | undefined
  xLabel?: string | undefined
  yLabel?: string | undefined
  viewId?: string | undefined
  flipAxes?: boolean | undefined
  dynamicData?: any | undefined
  isDynamicData?: boolean | undefined
  dynamicDataList?: any[] | undefined
  isMergedDynamicData?: boolean | undefined
  hasNoData?: boolean | undefined
  isMultiLinePlot?: boolean | undefined
  shapeData?: any[] | undefined
  forceUpdateHandler?: (() => void) | undefined
}

export default class PlotWrapper extends Component<Props> {
  public override render () {
    const { type, tileId } = this.props

    switch (type) {
      case 'area':
      case 'line':
      case 'bar':
      case 'contour':
      case 'pie':
      case 'radar':
        return <PlotlyWrapper {...this.props} />
      case 'text':
        return <TextWrapper {...this.props} />
      case 'gage':
        return <GageWrapper {...{ ...this.props, xRange: [ 0, 5 ] }} />
      case 'message':
        return <MessageWrapper id={`text_${tileId}`} message={this.props.message ?? ''} />
      case 'edit_box':
        return <EditBoxWrapper {...this.props} />
      case 'command':
        return <CommandWrapper {...this.props} />
      default:
        return null
    }
  }
}
