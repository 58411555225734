import LogicUtil from '@/logic/Util'
import { MountLogToKeyUUIDsMap } from '@/store/mountLogToKeyUUIDs'
import type { FilterableElementType } from '@/types/filter'
import type { ElementMaps } from '@/types/state'

const elementInfoMountLogMap: Record<Partial<FilterableElementType>, string[]> = {
  AirLoop: [],
  CoolingLoop: [ 'CoolingLoopMountLog' ],
  DataLine: [ 'DataLineMountLog' ],
  DataPoint: [
    'MoldFaceDataPointMountLog',
    'StrandDataPointMountLog',
    'SegmentDataPointMountLog',
    'RollerDataPointMountLog',
    'RollerBodyDataPointMountLog',
  ],
  Nozzle: [ 'NozzleMountLog' ],
  Roller: [ 'RollerMountLog' ],
  RollerBearing: [ 'RollerBearingMountLog' ],
  RollerBody: [ 'RollerBodyMountLog' ],
  Segment: [ 'SegmentMountLog' ],
  SegmentGroup: [ 'SegmentGroupMountLog' ],
  SensorPoint: [
    'SegmentSensorPointMountLog',
    'RollerSensorPointMountLog',
    'RollerBodySensorPointMountLog',
  ],
  SupportPoint: [ 'SupportPointMountLog' ],
}

export const buildElementMountLogsToKeyUUIDsMap = (elementMaps: ElementMaps): MountLogToKeyUUIDsMap => {
  const elementMountLogsToKeyUUIDsMap: MountLogToKeyUUIDsMap = {
    AirLoop: {},
    CoolingLoop: {},
    DataLine: {},
    DataPoint: {},
    Nozzle: {},
    Roller: {},
    RollerBearing: {},
    RollerBody: {},
    Segment: {},
    SegmentGroup: {},
    SensorPoint: {},
    SupportPoint: {},
  }
  const filterableElements = Object.keys(elementInfoMountLogMap) as FilterableElementType[]

  for (const filterableElement of filterableElements) {
    const correspondingMountLogs = elementInfoMountLogMap[filterableElement] as Array<keyof ElementMaps>

    if (correspondingMountLogs.length === 0) { // AirLoop
      const airLoopMountLogMap = elementMaps[`${filterableElement}MountLog` as keyof ElementMaps] as AirLoopMountLogMap
      const airLoopMountLogs = Object.values(airLoopMountLogMap ?? {})

      for (const airLoopMountLog of airLoopMountLogs) {
        const additionalData = airLoopMountLog.additionalData
        const uuidAttributes = Object.keys(additionalData ?? {}).filter(key => key.includes('_uuid'))

        for (const uuidAttribute of uuidAttributes) {
          const uuid = additionalData[uuidAttribute] as string | undefined
          const attributeWithoutUuid = LogicUtil.fromSnakeToCamelCase(uuidAttribute.replace('_uuid', ''))

          if (!elementMountLogsToKeyUUIDsMap.AirLoop[attributeWithoutUuid]) {
            elementMountLogsToKeyUUIDsMap.AirLoop[attributeWithoutUuid] = {}
          }

          if (uuid) {
            elementMountLogsToKeyUUIDsMap.AirLoop[attributeWithoutUuid][airLoopMountLog.id] = uuid
          }
        }
      }
    }
    else {
      for (const mountLogName of correspondingMountLogs) {
        const mountLogMap = elementMaps[mountLogName]
        const mountLogs = Object.values(mountLogMap ?? {}) as BaseMountLog[]

        for (const mountLog of mountLogs) {
          // const elementInfoKey = filterableElement.charAt(0).toLowerCase() + filterableElement.slice(1)
          // const elementInfoId = mountLog[`${elementInfoKey}Id` as keyof BaseMountLog]

          // if (!elementInfoId) {
          //   continue
          // }

          // const element = elementMaps[filterableElement]?.[elementInfoId]

          // if (!element) {
          //   continue
          // }

          const additionalData = mountLog.additionalData
          const uuidAttributes = Object.keys(additionalData ?? {}).filter(key => key.includes('_uuid'))

          for (const uuidAttribute of uuidAttributes) {
            const uuid = additionalData[uuidAttribute] as string | undefined
            const attributeWithoutUuid = LogicUtil.fromSnakeToCamelCase(uuidAttribute.replace('_uuid', ''))

            if (!elementMountLogsToKeyUUIDsMap[filterableElement][attributeWithoutUuid]) {
              elementMountLogsToKeyUUIDsMap[filterableElement][attributeWithoutUuid] = {}
            }

            if (uuid) {
              elementMountLogsToKeyUUIDsMap[filterableElement][attributeWithoutUuid][mountLog.id] = uuid
            }
          }
        }
      }
    }
  }

  return elementMountLogsToKeyUUIDsMap
}
