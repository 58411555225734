import { Buffer } from 'buffer'

interface JWTData {
  exp: number
  groups: Array<string>
  iat: number
  isDemoUser: boolean
  sub: string
  username: string
}

export function parseJWT (token: string): JWTData | null {
  const parts = token.split('.')

  if (!parts.length || !parts[1]) {
    return null
  }

  return JSON.parse(Buffer.from(parts[1], 'base64').toString())
}
