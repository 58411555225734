import ErrorActions from './consts'

export function setError (actionName: string, error?: string) {
  return {
    type: ErrorActions.SET_ERROR,
    actionName,
    error,
  }
}

export function resetErrors () {
  return {
    type: ErrorActions.RESET_ERROR,
  }
}
