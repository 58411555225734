import { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { compose } from 'redux'

import type { Translation } from '@/types/translation'

import AdditionalTab from './Tabs/Additional'
import CommandSettings from './Tabs/CommandSettings'
import DataTab from './Tabs/Data'
import EditTab from './Tabs/Edit'
import EditableFilesTab from './Tabs/EditableFiles'
import ExtendedTab from './Tabs/Extended'
import GeneralTab from './Tabs/General'
import MergedTab from './Tabs/Merged'
import ShowTab from './Tabs/Show'
import TablePreferencesTab from './Tabs/TablePreferences'
import VerticalTab from './Tabs/Vertical'
import { Form } from '../Dialogs/DialogStyles'

type Props = {
  allowDeletion?: boolean
  fullscreen?: boolean
  activeTab: number
  tabs: Array<any>
  t: Translation
  setEditIdToNull: () => void
}

class PlotConfig extends Component<Props> {
  private readonly renderTab = () => {
    const { activeTab, fullscreen, tabs, t, allowDeletion, setEditIdToNull } = this.props

    switch (tabs[activeTab]?.title) {
      case 'general':
        return <GeneralTab fullscreen={fullscreen} />
      case 'merged':
        return <MergedTab fullscreen={fullscreen} allowDeletion={allowDeletion} />
      case 'data':
        return <DataTab fullscreen={fullscreen} />
      case 'extended':
        return <ExtendedTab fullscreen={fullscreen} allowDeletion={allowDeletion} setEditIdToNull={setEditIdToNull} />
      case 'vertical':
        return <VerticalTab fullscreen={fullscreen} />
      case 'show':
        return <ShowTab fullscreen={fullscreen} />
      case 'edit':
        return <EditTab fullscreen={fullscreen} />
      case 'additional':
        return <AdditionalTab fullscreen={fullscreen} />
      case 'table_preferences':
        return <TablePreferencesTab fullscreen={fullscreen} />
      case 'editable_files':
        return <EditableFilesTab fullscreen={fullscreen} />
      case 'edit_command':
        return <CommandSettings fullscreen={fullscreen} />
      default:
        return <Form>{t('plotConfig.selectItem')}</Form>
    }
  }
  
  public override render () {
    return (
      <Form>
        {this.renderTab()}
      </Form>
    )
  }
}

export default compose<any>(withTranslation('visualization'))(PlotConfig)
