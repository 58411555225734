import type { ElementMaps, ElementName, MountLogMapKey } from '@/types/state'
import { ElementMapsUtil } from '@/Util/ElementMapsUtil'
import { Mapping } from '@/Util/mapping/Mapping'

const parentInfoPerChildType = {
  NozzleMountLog: 'segmentMountLogId',
  RollerMountLog: 'segmentMountLogId',
  RollerBodyMountLog: 'rollerMountLogId',
  RollerBearingMountLog: 'rollerMountLogId',
  SegmentMountLog: 'segmentGroupMountLogId',
  RollerBodySensorPointMountLog: 'rollerBodyMountLogId',
}

function removeElementFromParent (
  mountLog: BaseMountLog,
  mountLogType: MountLogMapKey,
  elementMaps: ElementMaps,
) {
  const parentKey = parentInfoPerChildType[mountLogType as keyof typeof parentInfoPerChildType]

  if (!parentKey) {
    return
  }

  const parentMountLogId = mountLog[parentKey as keyof BaseMountLog]

  if (!parentMountLogId) {
    return
  }

  // capitalize first letter and remove last 2 letters
  const parentMountLogType = parentKey[0]?.toUpperCase() + parentKey.slice(1, -2) as MountLogMapKey
  const parentElement = elementMaps[parentMountLogType]?.[parentMountLogId as string]

  if (!parentElement) {
    return
  }

  const childIdsKey = `${mountLogType[0]?.toLowerCase()}${mountLogType.slice(1)}s` as keyof typeof parentElement

  if (!parentElement[childIdsKey] || !Array.isArray(parentElement[childIdsKey])) {
    return
  }

  const index = parentElement[childIdsKey].indexOf(mountLog.id)

  if (index === -1) {
    return
  }

  parentElement[childIdsKey].splice(index, 1)
}

export function deleteElement (elementName: ElementName, id: number, elementMaps: ElementMaps): void {
  const mountLogType = `${elementName}MountLog` as MountLogMapKey
  const tagName = ElementMapsUtil.getTagName(elementName)
  const mountLogId = Mapping.mountLogIdByTypeAndNumericId[tagName][id]

  if (mountLogId === undefined) {
    return
  }

  const mountLog = elementMaps[mountLogType][mountLogId]

  if (!mountLog) {
    return
  }

  Object.keys(mountLog).filter(key => key.includes('Ids')).forEach(childTypeKey => {
    const childrenType = childTypeKey.slice(1, -3) as ElementName
    const potentialArrayField = mountLog[childTypeKey as keyof typeof mountLog]

    if (!potentialArrayField || !Array.isArray(potentialArrayField)) {
      return
    }

    potentialArrayField.forEach((childId: number) => {
      deleteElement(childrenType, childId, elementMaps)
    })
  })

  delete (elementMaps[mountLogType as keyof ElementMaps] as Record<string, BaseMountLog>)[mountLogId]

  removeElementFromParent(mountLog, mountLogType, elementMaps)
}
