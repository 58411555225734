/**
 * Dispatching custom events for testing purposes (cypress)
 *
 * @param eventName - name of the event
 */
export function dispatchTestingEvent (eventName: string) {
  const event = new CustomEvent(eventName)

  window.dispatchEvent(event)
}
