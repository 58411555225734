/* eslint-disable n/no-callback-literal */
// FIXME: n/no-callback-literal should not be disabled and the callback should be refactored

import csv from 'csvtojson'

import Util from '@/logicHandlers/ServerLogic/actions/Util'
import { AppState } from '@/store/application/main/consts'

import { Props } from '.'

export default class Logic {
  public static init (dialogInstance: any): void {
    Logic.dialogInstance = dialogInstance
  }

  private static dialogInstance: { props: Props }

  public static handleUploadCasterCatalog (
    callback: (catalog: CaseFile | 'default') => void,
    setError: (type: string, error?: string) => void,
    props?: any,
  ): void {
    const { currentSimulationCase } = props || Logic.dialogInstance.props

    if (currentSimulationCase.id) {
      // TODO: move request to api/nozzle-catalog.ts maybe use callback in openUploadFileDialog
      Util
        .openUploadFileDialog('.csv', '/nozzle-catalogs', 'post', (formData: any) => {
          formData.append('caseId', currentSimulationCase.id)
          formData.append('projectId', currentSimulationCase.projectId)
        })
        .then((newCatalog: CaseFile) => {
          setError('catalog')
          callback(newCatalog)
        })
        .catch(({ status }) => {
          setError('catalog', status)

          callback('default')
        })
    }
    else {
      callback('default')
    }
  }

  public static handleUploadVisualizationConfig (
    callback: any,
    setError: (type: string, error?: string) => void,
    usedAppState: AppState,
  ): void {
    const {
      currentSimulationCase,
      logout,
    }: {
      currentSimulationCase: SimulationCase
      setCurrentSimulationCase: (simCase: SimulationCase) => void
      openDialog: (dialogName: string) => void
      setRequiredCommandFiles: any
      logout: () => void
    } = Logic.dialogInstance.props

    if (currentSimulationCase.id) {
      Util
        .openUploadFileDialog('.json', '/visualization-configs', 'post', (formData: any) => {
          formData.append('projectId', currentSimulationCase.projectId)
          formData.append('appState', usedAppState)
        })
        .then(response => {
          // ({ // FIXME: uncomment when CreateCommandFileDialog is ready
          //   config,
          //   nonexistentCommandFiles,
          //   nonexistentCommandFileMapping,
          //   visualizationConfigId,
          // }: {
          //   config: any,
          //   nonexistentCommandFiles: Record<string, NonexistentCommandFile>
          //   nonexistentCommandFileMapping: string[] | null
          //   visualizationConfigId: string
          // }) => {
          //   if (Object.keys(nonexistentCommandFiles).length > 0) {
          //     setRequiredCommandFiles(nonexistentCommandFiles, nonexistentCommandFileMapping, visualizationConfigId)
          //     // openDialog(CreateCommandFileDialog.NAME)
          //   }

          setError('config')
          callback('config', response)
        })
        .catch(({ status }) => {
          setError('config', status)

          // TODO: move to middleware
          if (status === 'Unauthorized') {
            logout()

            // TODO: notification
          }

          callback('config', 'default', usedAppState)
        })
    }
    else {
      callback('config', 'default', usedAppState)
    }
  }

  public static getSelectors <T> (
    list: T[],
    key: keyof T,
    value: keyof T,
    defaultObj?: any | null,
    showIndex?: boolean | null,
    valueSuffix?: (entry: T) => React.ReactNode | string | null,
  ): Selector[] {
    const selectors = list?.map((entry, index) => ({
      key: entry[key],
      value: <span>{`${showIndex ? `${index + 1}. ` : ''}${entry[value]}`}{valueSuffix?.(entry) ?? ''}</span>,
    } as Selector)) ?? []

    if (defaultObj) {
      selectors.unshift({ key: 'default', notRemovable: true, ...defaultObj })
    }

    return selectors
  }

  // TODO: move to API
  public static getParsedCsv (csvString: string) {
    return csv({
      delimiter: ';',
      noheader: true,
      quote: 'off',
    })
      .fromString(csvString)
  }

  public static getSelectedConfig (selectedConfig: string | null | undefined, configs: VisualizationConfig[]): string {
    if (selectedConfig === 'default' || (selectedConfig && (configs.length === 0))) {
      return 'default'
    }

    if (selectedConfig && !configs.some(config => config.id === selectedConfig)) {
      return configs[0]?.id ?? 'default'
    }

    return selectedConfig ?? configs[0]?.id ?? 'default'
  }

  public static markDefaultDashboardConfig (
    configSelectors: Array<any>,
    defaultCaseConfigId?: string | null,
    defaultGroupConfigId?: string | null,
  ): void {
    if (!defaultGroupConfigId && !defaultCaseConfigId) {
      return
    }

    if (defaultCaseConfigId) {
      const defaultCaseConfig = configSelectors.find(configSelectors => configSelectors.key === defaultCaseConfigId)

      if (defaultCaseConfig) {
        defaultCaseConfig.value = <>{defaultCaseConfig.value} (case def.)</>
        defaultCaseConfig.isDefault = true
      }

      return
    }

    if (defaultGroupConfigId) {
      const defaultGroupConfig = configSelectors.find(configSelectors => configSelectors.key === defaultGroupConfigId)

      if (defaultGroupConfig) {
        defaultGroupConfig.value = <>{defaultGroupConfig.value} (group def.)</>
        defaultGroupConfig.isDefault = true
      }
    }
  }

  public static getSelectedCatalog (selectedCatalog: string | null | undefined, catalogs: CaseFile[]): string {
    if (selectedCatalog === 'default' || (selectedCatalog && !catalogs?.length)) {
      return 'default'
    }

    if (selectedCatalog && catalogs.findIndex(catalog => catalog.id === selectedCatalog) === -1) {
      return catalogs[0]?.id ?? 'default'
    }

    return selectedCatalog?.length ? selectedCatalog : catalogs[0]?.id ?? 'default'
  }
}
