import { StrandSides } from '@/types/elements/enum'

import SectionView from '.'
import ToggleHandlers from './ToggleHandlers'

export default class EventHandlers {
  public static handleActions (intersects: any[], view: SectionView) {
    for (let i = 0; i < intersects.length; i++) {
      const { userData } = intersects[i].object

      if (userData.type === 'Button' && userData.action) {
        const similarActions = [
          `toggle${StrandSides.Left}`,
          `toggle${StrandSides.Right}`,
          `toggle${StrandSides.Fixed}`,
          `toggle${StrandSides.Loose}`,
        ]

        if (similarActions.includes(userData.action)) {
          ToggleHandlers.toggleSides(view, userData.action.replace('toggle', ''))
        }
        else {
          // TODO: what if userData['action'] is not a function? Check and fallback!
          ;(ToggleHandlers as any)[userData.action](view)
        }
      }
    }
  }
}
