import { combineReducers } from '@reduxjs/toolkit'

import application from './application'
import casterDataServer from './casterDataServer'
import data from './data'
import { getElementReducers } from './elements/logic'
import filter from './filter'
import grafanaData from './grafanaData'
import loading from './LoadingStore'
import matrix from './matrix'
import modules from './modules'
import mountLogToKeyUUIDsMap from './mountLogToKeyUUIDs'
import temporalData from './temporalData'
import tileWarnings from './tileWarnings'
import timestamps from './timestamps'
import util from './util'
import visualization from './visualization'
import warehouse from './warehouse'

export const makeRootReducer = (asyncReducers?: any) =>
  combineReducers({
    application,
    data,
    matrix,
    filter,
    loading,
    util,
    visualization,
    tileWarnings,
    modules,
    timestamps,
    casterDataServer,
    mountLogToKeyUUIDsMap,
    temporalData,
    grafanaData,
    warehouse,
    ...getElementReducers(), // used for ElementMaps
    ...asyncReducers,
  })

export const injectReducer = (store: any, { key, reducer }: any) => {
  if (Object.hasOwnProperty.call(store.asyncReducers, key)) {
    return
  }

  store.asyncReducers[key] = reducer
  store.replaceReducer(makeRootReducer(store.asyncReducers))
}
