import * as THREE from 'three'
import { Vector2 } from 'three'

import { Views } from '@/three/ThreeBase'

import CubeHandlers from './CubeHandlers'
import BaseView from '../BaseView'

export default class ViewsView extends BaseView {
  public constructor (renderer: THREE.WebGLRenderer, views: Views) {
    super(renderer, views)

    ViewsView.staticClassName = 'ViewsView'
    this.className = 'ViewsView'

    this.camera = new THREE.PerspectiveCamera(45, 1, 0.1, 1000)
    this.camera.position.z = 3

    this.cube = CubeHandlers.initCube()
    this.cube.name = 'ViewCube'

    this.clickableObjects.push(this.cube)
    this.scene.add(this.cube)
  }

  private readonly cube: THREE.Mesh<THREE.BoxGeometry, THREE.MeshBasicMaterial[]>

  public override resize (width: number, height: number) {
    const size = 100

    this.viewport = {
      x: width - size,
      y: height - size,
      width: size,
      height: size,
    }
  }

  public override animate () {
    if (!this.camera || !this.views.mainView?.camera) {
      return
    }

    const { camera, controls } = this.views.mainView

    this.camera?.position?.copy(camera.position)
    this.camera?.position?.sub(controls.target)
    this.camera?.position?.setLength(3)
    this.camera?.lookAt(this.scene.position)
  }

  public override handleMouseDown (event: any, mouseOnCanvas: Vector2) {
    const newMouseOnCanvas = mouseOnCanvas.clone()
    const { height: canvasHeight } = event.target.getBoundingClientRect()
    const { height } = this.viewport

    // this is necessary because the mouseOnCanvas.y is calculated from the top of the canvas and not from the bottom
    newMouseOnCanvas.y = canvasHeight - (height - mouseOnCanvas.y)

    return super.handleMouseDown(event, newMouseOnCanvas)
  }

  public override handleMouseUp (event: any, mouseOnCanvas: Vector2): any {
    const newMouseOnCanvas = mouseOnCanvas.clone()
    const { height: canvasHeight } = event.target.getBoundingClientRect()
    const { height } = this.viewport

    // this is necessary because the mouseOnCanvas.y is calculated from the top of the canvas and not from the bottom
    newMouseOnCanvas.y = canvasHeight - (height - mouseOnCanvas.y)

    const intersects = super.handleMouseUp(event, newMouseOnCanvas)

    if (
      (intersects.length > 0) &&
      intersects[0]?.object.name === this.cube.name &&
      intersects[0].face &&
      intersects[0].face?.materialIndex !== undefined
    ) {
      this.views?.mainView?.setView(intersects[0].face.materialIndex)
    }
  }

  public hide () {
    this.cube.visible = false
  }

  public show () {
    this.cube.visible = true
  }
}
