/**
 * This Teleport component is equivalent to Vue 3's Teleport component.
 */

import type { FC, PropsWithChildren } from 'react'
import { createPortal } from 'react-dom'

type Props = {
  to: string;
}

const Teleport: FC<PropsWithChildren<Props>> = ({ children, to }) => createPortal(
  children,
  document.querySelector(to) ?? document.body,
)

export default Teleport
