import { filterActions } from 'redux-ignore'

import { Info } from '@/logic/Info'
import type { CasterDialogElementType, FilterState } from '@/types/filter'

import { FilterActionsEnum, initialState } from './consts'

const {
  ACTION_GET_FILTER_CONTROL_DEFINITIONS_SUCCESS,
  ACTION_RESET_TARGET,
  ACTION_SET_ACTIVE_FILTER_CONTROL_INDEXES,
  ACTION_SET_CURRENT_FILTER_CONTROL_VALUES,
  ACTION_SET_IS_TERM_DISABLED,
  ACTION_SET_FILTER_VALUES,
  ACTION_SET_TARGET,
  ACTION_SET_TERM,
} = FilterActionsEnum

type FilterAction = {
  type: FilterActionsEnum
  term?: string
  target?: string
  filterElement?: Record<CasterDialogElementType, any>
}

// eslint-disable-next-line @typescript-eslint/ban-types
const actionHandlers: Record<string, Function | undefined> = {
  [ACTION_SET_TERM]: (state: FilterState, action: any) => {
    let term = action.term

    if (action.isElementClick) {
      if (state.term.includes(term)) {
        term = state.term.replace(term, '').replace('  ', ' ').trim()
      }
      else {
        if (action.isCtrl && state.term) {
          term = `${state.term} ${action.term}`
        }
        else {
          term = action.term
        }
      }
    }

    if (action.isFilterClick) {
      const parts = action.term.split('#')

      let attributes: string[] = []

      if (parts[1]) {
        attributes = parts[1].split(',')
      }

      const space = state.term.includes(' ')
      const comma = state.term.includes(',')

      if (
        (action.isCtrl || (!space && !comma)) &&
        (attributes.length > 0) &&
        attributes.reduce((con, att) => Boolean(con || state.term.includes(att)), false)
      ) {
        term = state
          .term
          .split(' ')
          .map(part => {
            let partTerm = part

            attributes.forEach(attr => {
              if (partTerm.includes(attr)) {
                partTerm = partTerm.replace(attr, '').replace('#,', '#').replace(/,$/, '')
              }
            })

            if (partTerm.substr(-1) === '#') {
              partTerm = ''
            }

            return partTerm
          })
          .join(' ')
          .trim()
          .replace('  ', ' ')
      }
      else if (action.isCtrl) {
        const type = parts[0]
        const attribute = parts[1]

        term = state
          .term
          .split(' ')
          .map(part => {
            if (!part.includes(type) && part.includes('/')) {
              let lastP = ''

              part = part
                .split('/')
                .map(p => {
                  // TODO: this seems not to work for Roller: SegmentGroupSlot:1/Segment:1/Roller:1/*
                  if (p.includes('*')) {
                    p = p.replace('*', lastP.includes('SegmentGroup') ? `Segment/${type}` : type)
                  }

                  lastP = p

                  return p
                })
                .join('/')
            }

            if (part.includes(type) && !part.includes(attribute)) {
              return `${part}${part.includes('#') ? ',' : '#'}${attribute}`
            }

            return part
          })
          .join(' ')

        if (state.term === term) {
          term += ` ${action.term}`
          term = term.trim()
        }
      }
    }

    Info.setRecentlyUsedInfo({ filterTerm: term })

    return {
      ...state,
      term,
      target: initialState.target,
      termDisabled: false,
    }
  },
  [ACTION_SET_IS_TERM_DISABLED]: (state: FilterState, { termDisabled }: { termDisabled: boolean }) => ({
    ...state,
    term: '',
    termDisabled,
  }),
  [ACTION_SET_TARGET]: (state: FilterState, { target }: { target: string }) => ({
    ...state,
    target,
  }),
  [ACTION_RESET_TARGET]: (state: FilterState) => ({
    ...state,
    target: initialState.target,
  }),
  [ACTION_SET_FILTER_VALUES]: (state: FilterState, { filterElement }: FilterAction) => ({
    ...state,
    filterElement,
  }),
  [ACTION_GET_FILTER_CONTROL_DEFINITIONS_SUCCESS]: (state: FilterState, { result }: any) => ({
    ...state,
    filterControlDefinitions: result.filterControlDefinitions,
    filterControlVariables: result.filterControlVariables,
  }),
  [ACTION_SET_ACTIVE_FILTER_CONTROL_INDEXES]: (state: FilterState, { activeFilterControlIndexes }: any) => ({
    ...state,
    activeFilterControlIndexes,
  }),
  [ACTION_SET_CURRENT_FILTER_CONTROL_VALUES]: (state: FilterState, { values }: any) => ({
    ...state,
    currentFilterControlValues: values,
  }),
}

function filterReducers (state = initialState, action: FilterAction) {
  const handler = actionHandlers[action.type]

  return handler ? handler(state, action) : state
}

export default filterActions(filterReducers, [
  ACTION_SET_TERM,
  ACTION_SET_IS_TERM_DISABLED,
  ACTION_SET_TARGET,
  ACTION_RESET_TARGET,
  ACTION_SET_FILTER_VALUES,
  ACTION_GET_FILTER_CONTROL_DEFINITIONS_SUCCESS,
  ACTION_SET_ACTIVE_FILTER_CONTROL_INDEXES,
  ACTION_SET_CURRENT_FILTER_CONTROL_VALUES,
]) as typeof filterReducers
