/* eslint-disable key-spacing */
import { ConsistencyCheck } from './ConsistencyCheckDefinition'
import { DataLine } from './DataLineDefinition'
import { DataPoint } from './DataPointDefinition'
import { General } from './GeneralDefinition'
import { Nozzle } from './NozzleDefinition'
import { RollerBearing } from './RollerBearingDefinition'
import { RollerBody } from './RollerBodyDefinition'
import { Roller } from './RollerDefinition'
import { Segment } from './SegmentDefinition'
import { SegmentGroup } from './SegmentGroupDefinition'
import { SegmentGroupSupportPoints } from './SegmentGroupSupportPointsDefinition'
import { SensorPoint } from './SensorPointDefinition'
import { SupportPoint } from './SupportPointDefinition'

export type DefinitionKey =
  | 'ConsistencyCheck'
  | 'DataLine'
  | 'DataPoint'
  | 'General'
  | 'Nozzle'
  | 'Roller'
  | 'RollerBearing'
  | 'RollerBody'
  | 'Segment'
  | 'SegmentGroup'
  | 'SegmentGroupSupportPoints'
  | 'SensorPoint'
  | 'SupportPoint'

export const DEFINITION: Record<string, any> = { // TODO: add Definition type
  General,
  SegmentGroup,
  SegmentGroupSupportPoints,
  Segment,
  SupportPoint,
  Nozzle,
  Roller,
  RollerBody,
  RollerBearing,
  SensorPoint,
  DataPoint,
  DataLine,
  ConsistencyCheck,
}
/* eslint-enable key-spacing */
