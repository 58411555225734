import { Component } from 'react'
import styled from 'styled-components'

import { downloadExecutionFile } from '@/api/executables'
import Button from '@/react/components/Button'
import FeatureFlags from '@/react/FeatureFlags'
import InputComponent, { InputWrapper, Label } from '@/react/specific/Input'
import type { ArrayOfTranslations, Translation } from '@/types/translation'

interface Props {
  parameter: Parameter
  caseValues: Record<string, any>
  dynamicOptions: Record<string, Option[]>
  featureFlags: Record<string, boolean>
  handleInputChange?: (event: Event & { target: HTMLInputElement }) => void
  handleSliderChange?: (name: string, value: number) => void
  loading: boolean
  uploadingFileParameterId: string
  t: ArrayOfTranslations & Translation
  handleUploadFile?: (id: string) => void
  disabled?: boolean | undefined
  executionStepId?: string | undefined
  executionSteps: ExecutionStepEntity[] | null
}

const StyledDownloadButton = styled.span`
  cursor: pointer;

  &:hover {
    color: #007bff;
  }
`

const T = 'executableDialog'

class ParameterComponent extends Component<Props> {
  private readonly handleDownloadExecutionFile = () => {
    const { parameter, caseValues } = this.props

    const executionFileId = caseValues[parameter.id]

    downloadExecutionFile(executionFileId)
  }

  private readonly getUploadButtonContent = (param: Parameter) => {
    const { t, executionSteps, executionStepId } = this.props
    let fullContent = String(t(`${T}.button.upload`))

    const step = executionSteps?.find(step => step.id === executionStepId)
    const filename = step?.files?.find(file => file.paramId === param.id)?.fileName

    if (filename) {
      fullContent += ` (${filename})`
    }

    if (fullContent.length > 45) {
      fullContent = `${fullContent.substring(0, 42)}...`
    }

    return fullContent
  }

  private readonly getParamSelectors = (parameter: Parameter) => {
    const { dynamicOptions } = this.props

    let options: Selector[] = []

    if (parameter.optionsFile) {
      options = dynamicOptions?.[parameter.id]?.map(({ name, name2, value }) => {
        const fullName = name2 ? `${name} (${name2})` : name

        return { key: value, value: fullName } as Selector
      }) ?? []
    }
    else if (parameter.options) {
      options = parameter.options.map(({ name, name2, value }) => {
        const fullName: string = name2 ? `${name} (${name2})` : name

        return { key: value, value: fullName } as Selector
      })
    }

    options.unshift({ key: 'default', value: 'Please select an option', disabled: true })

    return options
  }

  private readonly getInputType = (parameter: Parameter) => {
    if (parameter.options || parameter.optionsFile) {
      return 'select'
    }

    switch (parameter.type) {
      case 'string':
        return 'text'
      case 'number':
        return 'number'
      case 'slider':
        return 'slider'
      case 'bool':
      case 'boolean':
        return 'checkbox'
      // file upload is handled by the upload button
      default:
        return 'text'
    }
  }

  private readonly getFilename = (param: Parameter) => {
    const { executionSteps, executionStepId, t } = this.props
    const step = executionSteps?.find(step => step.id === executionStepId)

    return step?.files?.find(file => file.paramId === param.id)?.fileName ?? t(`${T}.button.upload`)
  }

  private readonly getInputValue = (selectors: Selector[]) => {
    const { parameter, caseValues } = this.props

    const value = caseValues[parameter.id] !== undefined ? caseValues[parameter.id] : parameter.value

    const valueIsInSelectors = selectors.map(selector => selector.key).includes(value)

    if (this.getInputType(parameter) === 'select' && (value === undefined || !valueIsInSelectors)) {
      return 'default'
    }

    return value
  }

  private readonly getDownloadButton = () => {
    const { parameter, caseValues, featureFlags } = this.props

    if (parameter.type === 'file' &&
      caseValues[parameter.id] &&
      FeatureFlags.canDownloadUploadedFileInExecutablesDialog(featureFlags)
    ) {
      return (
        <StyledDownloadButton
          title='Download'
          onClick={this.handleDownloadExecutionFile}
        >
          [Download]
        </StyledDownloadButton>
      )
    }

    return null
  }

  public override render () {
    const {
      parameter,
      loading,
      featureFlags,
      disabled,
      handleInputChange,
      handleSliderChange,
      handleUploadFile,
      uploadingFileParameterId,
    } = this.props

    const selectors = this.getParamSelectors(parameter)
    const inputType = this.getInputType(parameter)

    return (
      <div>
        {
          parameter.type !== 'file'
            ? (
              <InputComponent
                key={parameter.id}
                name={parameter.id}
                type={inputType}
                min={parameter.numberMin}
                max={parameter.numberMax}
                step={parameter.numberStep}
                label={parameter.name}
                title={parameter.name}
                value={this.getInputValue(selectors)}
                precision={parameter.precision ?? 2}
                selectors={selectors}
                onChange={handleInputChange}
                onSliderChange={handleSliderChange}
                disabled={Boolean(disabled) || loading || !FeatureFlags.canEditExecutableDialog(featureFlags)}
                marks={parameter.marks}
              />
            )
            : (
              <InputWrapper key={parameter.id}>
                <Label>{parameter.name} {this.getDownloadButton()}</Label>
                <Button
                  title={this.getFilename(parameter)}
                  type='primary'
                  onClick={() => handleUploadFile ? handleUploadFile(parameter.id) : null}
                  noMargin
                  disabled={
                    Boolean(disabled) ||
                    loading ||
                    !FeatureFlags.canEditExecutableDialog(featureFlags) ||
                    !FeatureFlags.canUploadExecutableFile(featureFlags) ||
                    (uploadingFileParameterId && uploadingFileParameterId !== parameter.id)
                  }
                  loading={uploadingFileParameterId === parameter.id}
                >
                  {this.getUploadButtonContent(parameter)}
                </Button>
              </InputWrapper>
            )
        }
      </div>
    )
  }
}

export default ParameterComponent
