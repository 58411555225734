import { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { compose } from 'redux'

import { SubmitButton } from './Button'

type Props = {
  onClick: (event: React.MouseEvent) => void
  value: string
  className?: string
  title?: string
  small?: boolean
  disabled?: boolean
}

class SecondaryButton extends Component<Props> {
  public override render () {
    const { onClick: handleClick, value, small, className, disabled, title } = this.props ?? {}

    return (
      <SubmitButton
        className={className ?? ''}
        onClick={handleClick}
        value={value}
        title={title ?? value}
        $small={small}
        disabled={disabled}
      >
        {value}
      </SubmitButton>
    )
  }
}

export default compose<any>(withTranslation('caster'))(SecondaryButton)
