import styled, { css, keyframes } from 'styled-components'

const loading = keyframes`
  0% {
    background-position: 0
  }
  
  100% {
    background-position: 80px
  }
`

type ButtonProps = { theme: any, $type?: string, $isLoading?: boolean, $half?: boolean, $noMargin?: boolean }

const Button = styled.button<ButtonProps>`${({ theme, $type, $isLoading, $half, $noMargin }) =>
  css`
  background-color: ${theme[$type ?? 'primary'].main};
  color: ${theme[$type ?? 'primary'].font};
  position: relative;
  text-align: center;
  display: inline-block;
  width: ${!$half ? '100%' : 'calc(50% - 7px)'};
  height: 42px;
  line-height: 42px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  outline: none;
  font-size: 14px;
  ${!$noMargin && css`margin-top: 17px` as any};

  &:-moz-focusring {
    outline: none;
  }

  ${
    $half && css`
    :first-of-type {
      margin-right: 14px;
    }
  ` as any
  };
  
  ${
    $isLoading && css`
    background-image: linear-gradient(
      -45deg, rgba(255,255,255,0.2) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255,255,255,0.2) 50%,
      rgba(255,255,255,0.2) 75%,
      transparent 75%,
      transparent
    );
    background-size: 80px 80px;  
    animation: ${loading} 1.5s linear infinite;
    text-shadow: 0 1px 0 #333;
  ` as any
  }
  
  :hover {
    transition: 3ms ease-in;
    background-color: ${theme[$type ?? 'primary'].dark};
  }
  
  :disabled {
    background: ${theme[$type ?? 'primary'].disabled}
    cursor: not-allowed;
  }

  div {
    border-radius: 50%;
    overflow: hidden;
    width: 20px;
    height: 20px;
    position: absolute;
    left: 11px;
    top: 11px;
  }

  div i {
    font-size: 30px;
    transform: translate(-5px, -5px);
  }
`}`

export default Button
