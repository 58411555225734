/* eslint-env browser */

import hoistStatics from 'hoist-non-react-statics'
import { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'redux'

import IpcManager from '@/IpcManager'
import Input from '@/react/specific/Input'
import * as VisualizationActions from '@/store/visualization/actions'
import type { DefaultState } from '@/types/state'
import { Identifiable } from '@/Util/decorators/Identifiable'

import Logic from './Logic'
import {
  Button,
  Dialog,
  DialogBackground,
  Form,
  Header,
  I,
  Text,
  Title,
} from '../DialogStyles'

const connector = connect((state: DefaultState) => ({
  tileConfigs: state.visualization.tileConfigs,
  plotConfigs: state.visualization.plotConfigs,
  appState: state.application.main.appState,
  currentProject: state.application.main.currentProject,
  currentSimulationCase: state.application.main.currentSimulationCase,
  visualizationMetaInformation: state.visualization.visualizationMetaInformation,
}), {
  openPlotExportDialog: VisualizationActions.openPlotExportDialog,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  handleClosePlotExportDialog: () => void // TODO: remove, not being used
  t(key: string, params?: Record<string, unknown>): string
}

type State = {
  [key: string]: string
  exportType: 'pdf' | 'zip'
}

export class PlotExportDialog extends Component<Props, State> {
  @Identifiable('PlotExportDialog') public static readonly NAME: string

  public override state: State = {
    exportType: 'zip',
  }

  private readonly handlePlotExport = (_e?: any) => {
    const { exportType } = this.state
    const {
      appState,
      tileConfigs,
      plotConfigs,
      visualizationMetaInformation,
    } = this.props

    Logic
      .handleExport(tileConfigs, plotConfigs)
      .then(exportData => {
        IpcManager.both.send(
          'plotExportImages',
          exportData,
          exportType,
          visualizationMetaInformation?.[appState] ?? {},
        )

        this.handleClose() // TODO: call this function when export is done
      })
  }

  private readonly handleClose = () => {
    const { openPlotExportDialog } = this.props

    openPlotExportDialog(false)
  }

  private readonly handleInput = (event: any) => {
    const { name, value } = event.target

    this.setState({
      [name]: value,
    })
  }

  public override render () {
    const { t } = this.props
    const { exportType } = this.state

    return (
      <div>
        <DialogBackground />
        <Dialog $height='auto' $half>
          <Header title={t('plotExportDialog.label')}>
            <Title>{t('plotExportDialog.label')}</Title>
            <I
              className='pe-7s-close'
              onClick={this.handleClose}
              title={t('plotExportDialog.close')}
            />
          </Header>
          <Form>
            <Text>{t('plotExportDialog.message')}</Text>
            <br />
            <Input
              label={t('plotExportDialog.export.label')}
              title={t('plotExportDialog.export.title')}
              name='exportType'
              type='select'
              value={exportType}
              selectors={[ 'zip', 'pdf' ]}
              onChange={this.handleInput}
              data-tile
            />
            <Button
              onClick={this.handlePlotExport}
              title={t('plotExportDialog.apply.title')}
            >
              {t('plotExportDialog.apply.label')}
            </Button>
          </Form>
        </Dialog>
      </div>
    )
  }
}

const composedComponent = compose<any>(withTranslation('visualization'), connector)(PlotExportDialog)

export default hoistStatics(composedComponent, PlotExportDialog)
