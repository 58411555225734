import * as THREE from 'three'

import { StrandSides } from '@/types/elements/enum'

import BaseObject, { BaseObjects, SetValuesData } from './BaseObject'
import Mold from './Mold'
import PasslineCurve from './PasslineCurve'
import ThreeSegment from './Segment'
import LodUtil from '../logic/LodUtil'
import Util from '../logic/Util'

interface Objects extends BaseObjects {
  roller: THREE.Group
  rollerGroupText: THREE.Mesh<THREE.BufferGeometry, THREE.MeshBasicMaterial>
  rollerGroupTextBackside: THREE.Mesh<THREE.BufferGeometry, THREE.MeshBasicMaterial>
  [LodUtil.LOD_ROLLER]: THREE.LOD
}

export default class ThreeRoller extends BaseObject<RollerSlot, RollerMountLog, ThreeSegment, Objects> {
  private static Geometry3DCache: Record<string, THREE.CylinderGeometry> = {}

  private static Geometry3DMeasureCache: Record<string, THREE.Box3> = {}

  private static Geometry3DCacheNextId = 1

  private static Geometry2DCache: THREE.PlaneGeometry

  private static readonly defaultMaterial1 = new THREE.MeshStandardMaterial({
    color: '#94bfe0',
    transparent: true,
    opacity: 0.4,
    roughness: 0.5,
    metalness: 0.5,
  })

  private static readonly defaultMaterial2 = new THREE.MeshStandardMaterial({
    color: '#e09494',
    transparent: true,
    opacity: 0.4,
    roughness: 0.5,
    metalness: 0.5,
  })

  private static readonly phantomMaterial = new THREE.MeshStandardMaterial({
    color: '#7eda41',
    transparent: true,
    opacity: 0.3,
  })

  private static readonly selectedMaterial = new THREE.MeshStandardMaterial({
    color: '#7eda41',
    transparent: true,
    opacity: 0.4,
  })

  private static readonly deletedMaterial = new THREE.MeshStandardMaterial({
    color: '#da131b',
    transparent: true,
    opacity: 0.4,
  })

  private static readonly selectedDeletedMaterial = new THREE.MeshStandardMaterial({
    color: '#da4515',
    transparent: true,
    opacity: 0.4,
  })

  private clickableObjects: any

  public radius: number

  private isPhantom: boolean = false

  public rollerData?: any

  private sectionDetail: boolean = false

  public plCoord: number = 0

  public readonly type = 'Roller'

  public static DisplayTypes = {
    All: 0,
    Roller: 1,
    RollerChildren: 2,
  }

  private static readonly DetailDistance = [ 0, 3, 8 ]

  private static get3DGeometry (r: number, rollerWidth: number, i: number, isPhantom?: boolean) {
    const radial = isPhantom ? 9 : 12 - i * 3

    const geometryKey = `${r}_${rollerWidth}_${radial}`
    let geometry = ThreeRoller.Geometry3DCache[geometryKey]

    if (!geometry) {
      geometry = new THREE.CylinderGeometry(r, r, rollerWidth, radial) // no Buffer!
      geometry.rotateZ(90 * Util.RAD)

      geometry = Util.getSmoothedGeometry(geometry, 80)

      geometry.userData['id'] = ThreeRoller.Geometry3DCacheNextId++

      ThreeRoller.Geometry3DCache[geometryKey] = geometry
    }

    return geometry
  }

  private static get2DGeometry () {
    let geometry = ThreeRoller.Geometry2DCache

    if (!geometry) {
      geometry = new THREE.PlaneGeometry(1, 1, 1, 1)

      ThreeRoller.Geometry2DCache = geometry
    }

    return geometry
  }

  public constructor (container: any, parent: any, clickableObjects: any, _phantomGroup?: THREE.Group) {
    super(container, parent)

    this.clickableObjects = clickableObjects

    this.objects.roller = new THREE.Group()

    this.radius = 0.0001

    container.add(this.objects.roller)
  }

  public override dispose (): void {
    super.dispose()

    for (const element of this.clickableObjects) {
      if (element.material && element.material.dispose) {
        element.material.dispose()
      }

      if (element.geometry && element.geometry.dispose) {
        element.geometry.dispose()
      }
    }

    for (const element of this.container.children) {
      if (!(element instanceof THREE.Mesh)) {
        continue
      }

      if (element.material && element.material.dispose) {
        element.material.dispose()
      }

      if (element.geometry && element.geometry.dispose) {
        element.geometry.dispose()
      }

      this.container.remove(element)
    }

    this.objects = {} as Objects
    this.clickableObjects = []
    this.container.clear()
  }

  protected override internalSetValues (data: SetValuesData<RollerSlot, RollerMountLog>) {
    super.internalSetValues(data)

    const { elementData, view } = data

    this.isPhantom = data.isPhantom
    this.rollerData = elementData
    this.sectionDetail = view.sectionDetail

    if (this.sectionDetail) {
      let updateY = false

      if (this.plCoord !== view.plHeight) {
        this.plCoord = view.plHeight
        updateY = true
      }

      this.renderSectionDetail(elementData, data.path, updateY)
    }
    else {
      this.renderNormal(elementData, data.path, data.isDeleted)
    }
  }

  public updateTransform () {
    const { roller } = this.objects
    const { side } = this.container.userData
    const { rollWidth } = this.rollerData

    const { position, angleX, normal } = PasslineCurve.getInfoAtPlCoord(
      this.plCoord ?? 0,
      this.sectionDetail ? true : undefined,
    )
    const newPosition = new THREE.Vector3(0, 0, 0)
    const newRotation = new THREE.Euler(0, 0, 0, 'XYZ')

    const { FixedSide, LooseSide, NarrowFaceRight, NarrowFaceLeft } = Mold.sideDistance

    // TODO: Use when needed
    // const rollWidthIs = this.sectionDetail ? this.rollerData._roll_width_is / 1000 : 0
    const rollWidthIs = 0

    switch (side) {
      case StrandSides.Fixed:
        newPosition.set(0, position.y, position.z + FixedSide.x - rollWidthIs)
        newPosition.add(normal.clone().setLength(FixedSide.z + this.radius))
        newRotation.set(-Util.RAD90 - angleX, 0, 0)
        break
      case StrandSides.Loose:
        newPosition.set(0, position.y, position.z + LooseSide.x + rollWidthIs)
        newPosition.add(normal.clone().setLength(LooseSide.z - this.radius))
        newRotation.set(Util.RAD90 - angleX, 0, 0)
        break
      case StrandSides.Right:
        newPosition.set(NarrowFaceRight.x - this.radius - rollWidthIs, position.y, position.z)
        newPosition.add(normal.clone().setLength(NarrowFaceRight.z - (rollWidth / 1000 / 2)))
        newRotation.set(-Util.RAD90 - angleX, 0, Util.RAD90)
        break
      case StrandSides.Left:
        newPosition.set(NarrowFaceLeft.x + this.radius + rollWidthIs, position.y, position.z)
        newPosition.add(normal.clone().setLength(NarrowFaceLeft.z - (rollWidth / 1000 / 2)))
        newRotation.set(-Util.RAD90 - angleX, 0, -Util.RAD90)
        break
      default:
    }

    roller.position.copy(newPosition)
    roller.rotation.copy(newRotation)
  }

  public getMeasures () {
    const { position: { y } } = this.objects.roller
    const { geometry } = this.objects[LodUtil.LOD_ROLLER].children[0] as THREE.Mesh
    const { id } = geometry.userData

    if (id && !ThreeRoller.Geometry3DMeasureCache[id]) {
      geometry.computeBoundingBox()

      if (geometry.boundingBox) {
        ThreeRoller.Geometry3DMeasureCache[id] = geometry.boundingBox
      }
    }

    const { min, max } = ThreeRoller.Geometry3DMeasureCache[id] ?? { min: { y: 0 }, max: { y: 0 } }

    return {
      heightMin: y + min.y,
      heightMax: y + max.y,
    }
  }

  public override setSelected (isSelected: boolean) {
    const { deleted } = this.objects.roller.userData

    if (this.isPhantom || this.sectionDetail) {
      return
    }

    const parentInfo = Util.getParentInfo(this.container.userData['path'])
    const isSegmentGroupIDEven = parentInfo.id % 2 === 0 // TODO: check if this works

    this.objects.roller.userData['selected'] = isSelected
    this.objects[LodUtil.LOD_ROLLER].children.forEach((child: any) => {
      const visible = child.material.visible

      child.material = (
        isSelected
          ? (deleted ? ThreeRoller.selectedDeletedMaterial : ThreeRoller.selectedMaterial)
          : (
            deleted
              ? ThreeRoller.deletedMaterial
              : (isSegmentGroupIDEven ? ThreeRoller.defaultMaterial1 : ThreeRoller.defaultMaterial2)
          )
      )
        .clone()

      child.material.visible = visible
    })
  }

  private internalSetVisibility (
    roller: any,
    rollerTextA: any,
    rollerTextB: any,
    bodies: any,
    bearings: any,
    rollerVisible: boolean,
    textVisible: boolean,
    childrenVisible: boolean,
  ) {
    roller.children.forEach((child: any) => {
      child.material.visible = rollerVisible
    })

    if (!this.isPhantom && !this.sectionDetail) {
      rollerTextA.material.visible = textVisible
      rollerTextB.material.visible = textVisible
    }

    bodies.forEach((body: any) => {
      body.parent.children.filter((child: any) => child.name.startsWith('Roller_Body_Number_')).forEach(
        (child: any) => {
          child.material.visible = childrenVisible
        },
      )

      body.visible = childrenVisible
    })

    bearings.forEach((bearing: any) => {
      bearing
        .parent
        .children
        .filter((child: any) => child.name.startsWith('Roller_Bearing_Number_'))
        .forEach((child: any) => {
          child.material.visible = childrenVisible
        })

      bearing.visible = childrenVisible
    })
  }

  public setVisibility (displayType: number) {
    const { [LodUtil.LOD_ROLLER]: lod, roller, rollerGroupText, rollerGroupTextBackside } = this.objects

    const bodies = roller.children.filter((element: THREE.Object3D) =>
      element.userData['type'] === 'RollerBody' && !element.name.includes('Phantom') && !element.userData['hidden']
    )

    const bearings = roller.children.filter((element: THREE.Object3D) =>
      element.userData['type'] === 'RollerBearing' && !element.name.includes('Phantom') && !element.userData['hidden']
    )

    if (this.sectionDetail) {
      this.internalSetVisibility(lod, rollerGroupText, rollerGroupTextBackside, bodies, bearings, false, false, true)

      return
    }

    switch (displayType) {
      case ThreeRoller.DisplayTypes.All:
        this.internalSetVisibility(lod, rollerGroupText, rollerGroupTextBackside, bodies, bearings, true, false, true)
        break
      case ThreeRoller.DisplayTypes.Roller:
        this.internalSetVisibility(lod, rollerGroupText, rollerGroupTextBackside, bodies, bearings, true, true, false)
        break
      case ThreeRoller.DisplayTypes.RollerChildren:
        this.internalSetVisibility(lod, rollerGroupText, rollerGroupTextBackside, bodies, bearings, false, false, true)
        break
      default:
    }
  }

  private renderNormal (rollerData: any, path: string, isDeleted: boolean) {
    const { diameter, passlineCoord: plCoord, rollWidth, id } = rollerData
    const { roller } = this.objects

    const rollerWidth = rollWidth / 1000
    const passlnCoord = plCoord / 1000
    const r = diameter / 1000 / 2

    this.plCoord = passlnCoord
    this.radius = r

    if (!this.isPhantom) {
      const rollerGroupText = Util.getText(id, 0.08, false, true)

      if (!rollerGroupText) {
        // eslint-disable-next-line no-console
        console.warn('Roller text not found')

        return
      }

      const rollerGroupTextBackside = rollerGroupText.clone()

      rollerGroupText.rotateY(Util.RAD90)
      rollerGroupText.name = `Roller_${id}`
      rollerGroupText.userData['number'] = id
      rollerGroupText.position.set(rollerWidth / 2 + 0.0001, 0, 0)

      this.objects.rollerGroupText = rollerGroupText

      rollerGroupTextBackside.rotateY(-Util.RAD90)
      rollerGroupTextBackside.name = `Roller_Backside_${id}`
      rollerGroupTextBackside.position.set((rollerWidth / 2 + 0.0001) * -1, 0, 0)

      this.objects.rollerGroupTextBackside = rollerGroupTextBackside

      Util.addOrReplace(roller, this.objects.rollerGroupText)
      Util.addOrReplace(roller, this.objects.rollerGroupTextBackside)
    }

    const parentInfo = Util.getParentInfo(this.container.userData['path'])
    const isSegmentGroupIDEven = parentInfo.id % 2 === 0 // TODO: check if this works
    const material = (
      !this.isPhantom
        ? (isSegmentGroupIDEven ? ThreeRoller.defaultMaterial1 : ThreeRoller.defaultMaterial2)
        : ThreeRoller.phantomMaterial
    )
      .clone()

    const lod = new THREE.LOD()

    for (let i = 0; i < 3; i++) {
      const geometry = ThreeRoller.get3DGeometry(r, rollerWidth, i, this.isPhantom)

      const mesh = new THREE.Mesh(geometry, material)

      mesh.name = `${path}_${i}${this.isPhantom ? '_Phantom' : ''}`
      mesh.userData['type'] = 'Roller'
      mesh.userData['path'] = path

      lod.addLevel(mesh, ThreeRoller.DetailDistance[i])

      if (!this.isPhantom) {
        const oldElement = this.objects[LodUtil.LOD_ROLLER]
          ? this.objects[LodUtil.LOD_ROLLER].getObjectByName(mesh.name)
          : null

        Util.addOrReplaceInList(oldElement, mesh, this.clickableObjects)
      }
    }

    const rollerLoD = lod

    rollerLoD.name = `${path}${this.isPhantom ? '_Phantom' : ''}`
    rollerLoD.rotation.x = -90 * Util.RAD
    rollerLoD.userData['type'] = 'Roller'
    rollerLoD.userData['path'] = path

    this.objects[LodUtil.LOD_ROLLER] = rollerLoD

    roller.userData['type'] = 'Roller'
    roller.userData['path'] = path

    this.updateTransform()
    this.setVisibility(this.isPhantom ? ThreeRoller.DisplayTypes.Roller : ThreeRoller.DisplayTypes.RollerChildren)

    this.objects.roller.userData['deleted'] = isDeleted
    this.objects.roller.userData['width'] = rollWidth

    Util.addOrReplace(roller, this.objects[LodUtil.LOD_ROLLER])

    this.setSelected(this.objects.roller.userData['selected'])
  }

  private renderSectionDetail (rollerData: any, path: string, updateY = false) {
    // TODO: this is not needed since roller don't have 2D ...

    const { roller } = this.objects
    const lod = new THREE.LOD()
    const { diameter, passlineCoord } = rollerData
    const passlnCoord = passlineCoord / 1000
    const r = diameter / 1000 / 2

    this.plCoord = passlnCoord
    this.radius = r

    for (let i = 0; i < 3; i++) {
      // this is just dummy geometry so that the rest works ...
      const geometry = ThreeRoller.get2DGeometry()

      const mesh = new THREE.Mesh(geometry, ThreeRoller.defaultMaterial1.clone())

      mesh.name = `${path}_${i}${this.isPhantom ? '_Phantom' : ''}`
      mesh.userData['type'] = 'Roller'
      mesh.userData['path'] = path

      lod.addLevel(mesh, ThreeRoller.DetailDistance[i])

      if (!this.isPhantom) {
        const oldElement = this.objects[LodUtil.LOD_ROLLER]
          ? this.objects[LodUtil.LOD_ROLLER].getObjectByName(mesh.name)
          : null

        Util.addOrReplaceInList(oldElement, mesh, this.clickableObjects)
      }
    }

    const rollerLoD = lod

    rollerLoD.name = `${path}${this.isPhantom ? '_Phantom' : ''}`
    // rollerLoD.rotation.x = -90 * Util.RAD
    rollerLoD.userData['type'] = 'Roller'
    rollerLoD.userData['path'] = path

    this.updateTransform()

    this.objects[LodUtil.LOD_ROLLER] = rollerLoD

    roller.userData['type'] = 'Roller'
    roller.userData['path'] = path

    if (updateY) {
      const { position } = PasslineCurve.getInfoAtPlCoord(this.plCoord ?? 0, this.sectionDetail ? true : undefined)

      roller.position.y = position.y
    }

    Util.addOrReplace(roller, this.objects[LodUtil.LOD_ROLLER])
  }
}
