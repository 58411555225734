const elementKeysToExclude = [
  'updatedAt',
  'projectId',
  'createdAt',
  'userId',
  'casterId',
  'realDataUUID',
  'mountLogHash',
  'sensorPointId',
  'nozzleId',
  'rollerId',
  'rollerBearingId',
  'rollerBodyId',
  'segmentGroupId',
  'segmentMountLogId',
  'segmentId',
  'slotId',
]

export const getAllKeysFromMountLogs = (data: any[]): string[] => {
  const keys: string[] = data.reduce((acc, row) => {
    const mountLogInfo = {
      ...row,
      ...row?.additionalData,
    }

    delete mountLogInfo.additionalData

    Object.keys(mountLogInfo).forEach(key => {
      if (!acc.includes(key) && !elementKeysToExclude.includes(key)) {
        acc.push(key)
      }
    })

    return acc
  }, [] as string[])

  return keys
}
