import { Object3D, Raycaster, Vector2 } from 'three'

export default class Getters {
  public static getMouse (mouseOnCanvas: Vector2, x: number, y: number, width: number, height: number) {
    const mouse = new Vector2()

    mouse.x = ((mouseOnCanvas.x - x) / width) * 2 - 1
    mouse.y = -((mouseOnCanvas.y - y) / height) * 2 + 1

    return mouse
  }

  public static getIntersects (baseClickableObjects: Object3D[], raycaster: Raycaster) {
    const clickableObjects = baseClickableObjects.filter(object => object?.visible)

    return raycaster.intersectObjects(clickableObjects, false)
  }
}
