/* eslint-disable camelcase */

export const General_Set_Default_Case = 'General_Set_Default_Case'

export const Caster__View = 'Caster__View'

export const ExecutablesDialog_ViewAndUse_DownloadUploadedFile = 'ExecutablesDialog_ViewAndUse_DownloadUploadedFile'

// new ff
export const CasterDashboard_Filter_Plot = 'CasterDashboard_Filter_Plot'
export const CasterDashboard_Edit_CriticalStrainCurve = 'CasterDashboard_Edit_CriticalStrainCurve'
export const PlotSettings_ZoomIn = 'PlotSettings_ZoomIn'
export const PlotSettings_ZoomOut = 'PlotSettings_ZoomOut'
export const PlotSettings_ResetZoom = 'PlotSettings_ResetZoom'
export const CasterDashboard_Edit_CasterComparison = 'CasterDashboard_Edit_CasterComparison'
export const CasterDashboard_View_CasterComparison = 'CasterDashboard_View_CasterComparison'
export const Menu_Modules_Use = 'Menu_Modules_Use'
export const Menu_Modules_View = 'Menu_Modules_View'

// select elements
export const ThreeD_Caster_Select_DataLine = 'ThreeD_Caster_Select_DataLine'
export const ThreeD_Caster_Select_DataPoint = 'ThreeD_Caster_Select_DataPoint'
export const ThreeD_Caster_Select_Nozzle = 'ThreeD_Caster_Select_Nozzle'
export const ThreeD_Caster_Select_RollerBearing = 'ThreeD_Caster_Select_RollerBearing'
export const ThreeD_Caster_Select_RollerBody = 'ThreeD_Caster_Select_RollerBody'
export const ThreeD_Caster_Select_Roller = 'ThreeD_Caster_Select_Roller'
export const ThreeD_Caster_Select_SensorPointInMoldFace = 'ThreeD_Caster_Select_SensorPointInMoldFace'
export const ThreeD_Caster_Select_SensorPointInNozzle = 'ThreeD_Caster_Select_SensorPointInNozzle'
export const ThreeD_Caster_Select_SensorPointInRoller = 'ThreeD_Caster_Select_SensorPointInRoller'
export const ThreeD_Caster_Select_SensorPointInRollerBearing = 'ThreeD_Caster_Select_SensorPointInRollerBearing'
export const ThreeD_Caster_Select_SensorPointInRollerBody = 'ThreeD_Caster_Select_SensorPointInRollerBody'
export const ThreeD_Caster_Select_SensorPointInSegment = 'ThreeD_Caster_Select_SensorPointInSegment'
export const ThreeD_Caster_Select_Segment = 'ThreeD_Caster_Select_Segment'
export const ThreeD_Caster_Select_SupportPoint = 'ThreeD_Caster_Select_SupportPoint'

// display elements in 3d
export const ThreeD_Caster_Display_Nozzle = 'ThreeD_Caster_Display_Nozzle'
export const ThreeD_Caster_Display_Roller = 'ThreeD_Caster_Display_Roller'
export const ThreeD_Caster_Display_SensorPointInRollerBody = 'ThreeD_Caster_Display_SensorPointInRollerBody'
export const ThreeD_Caster_Display_SupportPoint = 'ThreeD_Caster_Display_SupportPoint'

// view element's attributes
export const Caster_Details_View_Mold = 'Caster_Details_View_Mold'
export const Caster_Details_View_DataLine = 'Caster_Details_View_DataLine'
export const Caster_Details_View_DataPoint = 'Caster_Details_View_DataPoint'
export const Caster_Details_View_Nozzle = 'Caster_Details_View_Nozzle'
export const Caster_Details_View_RollerBearing = 'Caster_Details_View_RollerBearing'
export const Caster_Details_View_RollerBody = 'Caster_Details_View_RollerBody'
export const Caster_Details_View_Roller = 'Caster_Details_View_Roller'
export const Caster_Details_View_SensorPointInMoldFace = 'Caster_Details_View_SensorPointInMoldFace'
export const Caster_Details_View_SensorPointInNozzle = 'Caster_Details_View_SensorPointInNozzle'
export const Caster_Details_View_SensorPointInRoller = 'Caster_Details_View_SensorPointInRoller'
export const Caster_Details_View_SensorPointInRollerBearing = 'Caster_Details_View_SensorPointInRollerBearing'
export const Caster_Details_View_SensorPointInRollerBody = 'Caster_Details_View_SensorPointInRollerBody'
export const Caster_Details_View_SensorPointInSegment = 'Caster_Details_View_SensorPointInSegment'
export const Caster_Details_View_SupportPoint = 'Caster_Details_View_SupportPoint'
export const Caster_Details_Compare = 'Caster_Details_Compare'

// edit elements
export const Caster_Details_Edit_CoolingLoop = 'Caster_Details_Edit_CoolingLoop'
export const Caster_Details_Edit_Roller = 'Caster_Details_Edit_Roller'
export const Caster_Details_Edit_RollerBody = 'Caster_Details_Edit_RollerBody'
export const Caster_Details_Edit_RollerBearing = 'Caster_Details_Edit_RollerBearing'
export const Caster_Details_Edit_SensorPointInMoldFace = 'Caster_Details_Edit_SensorPointInMoldFace'
export const Caster_Details_Edit_SensorPointInRollerBody = 'Caster_Details_Edit_SensorPointInRollerBody'
export const Caster_Details_Edit_SensorPointInRollerBearing = 'Caster_Details_Edit_SensorPointInRollerBearing'
export const Caster_Details_Edit_SensorPointInRoller = 'Caster_Details_Edit_SensorPointInRoller'
export const Caster_Details_Edit_SensorPointInSegment = 'Caster_Details_Edit_SensorPointInSegment'
export const Caster_Details_Edit_SensorPointInNozzle = 'Caster_Details_Edit_SensorPointInNozzle'
export const Caster_Details_Edit_DataPoint = 'Caster_Details_Edit_DataPoint'
export const Caster_Details_Edit_DataLine = 'Caster_Details_Edit_DataLine'
export const Caster_Details_Edit_Nozzle = 'Caster_Details_Edit_Nozzle'
export const Caster_Details_Edit_Mold = 'Caster_Details_Edit_Mold'
export const Caster_Details_Edit_Segment = 'Caster_Details_Edit_Segment'
export const Caster_Details_Edit_SupportPoint = 'Caster_Details_Edit_SupportPoint'
export const ThreeD_Buttons_Toggle_LiveMode = 'ThreeD_Buttons_Toggle_LiveMode'
export const ThreeD_Buttons_Toggle_Bending = 'ThreeD_Buttons_Toggle_Bending'
export const ThreeD_Buttons_Toggle_SectionView = 'ThreeD_Buttons_Toggle_SectionView'

export const ThreeD_SidesCube_View = 'ThreeD_SidesCube_View'
export const ThreeD_Buttons_Toggle_RollerDetails = 'ThreeD_Buttons_Toggle_RollerDetails'
export const ThreeD_Buttons_DeselectAll = 'ThreeD_Buttons_DeselectAll'
export const ThreeD_Buttons_CenterPosition = 'ThreeD_Buttons_CenterPosition'
export const ThreeD_Buttons_Toggle_Filter = 'ThreeD_Buttons_Toggle_Filter'

export const Caster_TreeView_View = 'Caster_TreeView_View'

export const SlimVersion = 'SlimVersion'

export const ThreeD_SectionView_View_MoldMin = 'ThreeD_SectionView_View_MoldMin'
export const ThreeD_SectionView_View_MoldMax = 'ThreeD_SectionView_View_MoldMax'

// filter controls
export const ThreeD_FilterControls_Use = 'ThreeD_FilterControls_Use'

export const Caster_TreeView_Rename_Elements = 'Caster_TreeView_Rename_Elements'
export const Caster_TreeView_JumpToElement = 'Caster_TreeView_JumpToElement'
export const Caster_FilterBar_Use = 'Caster_FilterBar_Use'
export const Caster_FilterBar_View = 'Caster_FilterBar_View'
export const FILTER_ELEMENTS = 'FILTER_ELEMENTS'
export const Caster_TreeView_SetFilterAndJumpToElement = 'Caster_TreeView_SetFilterAndJumpToElement'

export const ThreeD_SectionSlider_JumpOver_Nozzles = 'ThreeD_SectionSlider_JumpOver_Nozzles'
export const ThreeD_SectionSlider_JumpOver_Roller = 'ThreeD_SectionSlider_JumpOver_Roller'
export const ThreeD_SectionSlider_JumpOver_DataPoints = 'ThreeD_SectionSlider_JumpOver_DataPoints'

export const Caster_Modules_View = 'Caster_Modules_View'
export const Caster_Modules_Filter_Module = 'Caster_Modules_Filter_Module'
export const Caster_Modules_Filter_ModuleElementType = 'Caster_Modules_Filter_ModuleElementType'

export const Menu_View_Use_ToggleFullScreen = 'Menu_View_Use_ToggleFullScreen'
export const Menu_View_View_ToggleFullScreen = 'Menu_View_View_ToggleFullScreen'
export const Menu_View_Use_ExitFullScreen = 'Menu_View_Use_ExitFullScreen'
export const Menu_View_View_ExitFullScreen = 'Menu_View_View_ExitFullScreen'
export const Menu_View_Use_Recreate3D = 'Menu_View_Use_Recreate3D'
export const Menu_View_View_Recreate3D = 'Menu_View_View_Recreate3D'
export const ProjectData__Verify_Caster = 'ProjectData__Verify_Caster'
export const ProjectData__Verify_NozzleCatalog = 'ProjectData__Verify_NozzleCatalog'
export const ProjectData__Verify_ProcessParameters = 'ProjectData__Verify_ProcessParameters'
export const ProjectData__Start_Simulation = 'ProjectData__Start_Simulation'
export const ProjectData__Use_GenerateCaster = 'ProjectData__Use_GenerateCaster'
export const ProjectData__Upload_NozzleCatalog = 'ProjectData__Upload_NozzleCatalog'
export const ProjectData__Delete_NozzleCatalog = 'ProjectData__Delete_NozzleCatalog'
export const ProjectData__Download_NozzleCatalog = 'ProjectData__Download_NozzleCatalog'
export const ProjectData__Download_CasterXML = 'ProjectData__Download_CasterXML'
export const ProjectData__Create_Project = 'ProjectData__Create_Project'
export const ProjectData__Delete_Project = 'ProjectData__Delete_Project'
export const ProjectData__Rename_Project = 'ProjectData__Rename_Project'
export const ProjectData__Edit_ProjectDescription = 'ProjectData__Edit_ProjectDescription'
export const ProjectData__Create_Case = 'ProjectData__Create_Case'
export const ProjectData__Delete_Case = 'ProjectData__Delete_Case'
export const ProjectData__Rename_Case = 'ProjectData__Rename_Case'
export const ProjectData__Edit_CaseDescription = 'ProjectData__Edit_CaseDescription'
export const CasterDashboard_Add_DynamicData = 'CasterDashboard_Add_DynamicData'
export const CasterDashboard_Edit_DynamicData = 'CasterDashboard_Edit_DynamicData'
export const CasterDashboard_Delete_DynamicData = 'CasterDashboard_Delete_DynamicData'
export const CasterDashboard_Merge_DynamicData = 'CasterDashboard_Merge_DynamicData'
export const CasterDashboard_Add_ExternalData = 'CasterDashboard_Add_ExternalData'
export const CasterDashboard_Edit_ExternalData = 'CasterDashboard_Edit_ExternalData'
export const CasterDashboard_Delete_ExternalData = 'CasterDashboard_Delete_ExternalData'
export const CasterDashboard_Merge_ExternalData = 'CasterDashboard_Merge_ExternalData'

export const CasterDashboard_View = 'CasterDashboard_View'
export const CasterDashboard_Download = 'CasterDashboard_Download'
export const CasterDashboard_Create_Config = 'CasterDashboard_Create_Config'
export const CasterDashboard_Edit_Config = 'CasterDashboard_Edit_Config'
export const CasterDashboard_Delete_Config = 'CasterDashboard_Delete_Config'
export const CasterDashboard_Add_Plot = 'CasterDashboard_Add_Plot'
export const CasterDashboard_Resize_Plot = 'CasterDashboard_Resize_Plot'
export const CasterDashboard_Edit_Plot = 'CasterDashboard_Edit_Plot'
export const CasterDashboard_Delete_Plot = 'CasterDashboard_Delete_Plot'
export const CasterDashboard_Edit_Tab = 'CasterDashboard_Edit_Tab'
export const CasterDashboard_Add_Tab = 'CasterDashboard_Add_Tab'
export const CasterDashboard_Delete_Tab = 'CasterDashboard_Delete_Tab'
export const CasterDashboard_Resize = 'CasterDashboard_Resize'
export const CasterDashboard_Upload_Config = 'CasterDashboard_Upload_Config'
export const CasterDashboard_Move_Plot = 'CasterDashboard_Move_Plot'
export const CasterDashboard_Download_Plot = 'CasterDashboard_Download_Plot'
export const CasterDashboard_View_ExportPlots = 'CasterDashboard_View_ExportPlots'
export const CasterDashboard_Use_ExportPlots = 'CasterDashboard_Use_ExportPlots'

export const CasterDashboard_Open_CasterDataServerDialog = 'CasterDashboard_Open_CasterDataServerDialog'
export const CasterDashboard_Open_CasterDateTimePickerDialog = 'CasterDashboard_Open_CasterDateTimePickerDialog'

export const Dialog_Run_UploadFile = 'Dialog_Run_UploadFile'
export const Dialog_Run_Edit = 'Dialog_Run_Edit'

// PARAMETER DASHBOARD
export const ParameterDashboard_View = 'ParameterDashboard_View'
export const ParameterDashboard_Download = 'ParameterDashboard_Download'
export const ParameterDashboard_Create_Config = 'ParameterDashboard_Create_Config'
export const ParameterDashboard_Delete_Plot = 'ParameterDashboard_Delete_Plot'
export const ParameterDashboard_Move_Plot = 'ParameterDashboard_Move_Plot'
export const ParameterDashboard_Resize_Plot = 'ParameterDashboard_Resize_Plot'
export const ParameterDashboard_Edit_Plot = 'ParameterDashboard_Edit_Plot'
export const ParameterDashboard_Edit = 'ParameterDashboard_Edit'
export const ParameterDashboard_Add_Tab = 'ParameterDashboard_Add_Tab'
export const ParameterDashboard_Add_Plot = 'ParameterDashboard_Add_Plot'
export const ParameterDashboard_Delete_Tab = 'ParameterDashboard_Delete_Tab'
export const ParameterDashboard_Upload_Config = 'ParameterDashboard_Upload_Config'
export const ParameterDashboard_Delete_Config = 'ParameterDashboard_Delete_Config'

// RESULT DASHBOARD
export const ResultDashboard_View = 'ResultDashboard_View'
export const ResultDashboard_Download = 'ResultDashboard_Download'
export const ResultDashboard_Create_Config = 'ResultDashboard_Create_Config'
export const ResultDashboard_Move_Plot = 'ResultDashboard_Move_Plot'
export const ResultDashboard_Resize_Plot = 'ResultDashboard_Resize_Plot'
export const ResultDashboard_Edit_Plot = 'ResultDashboard_Edit_Plot'
export const ResultDashboard_Delete_Plot = 'ResultDashboard_Delete_Plot'
export const ResultDashboard_Edit = 'ResultDashboard_Edit'
export const ResultDashboard_Add_Plot = 'ResultDashboard_Add_Plot'
export const ResultDashboard_Add_Tab = 'ResultDashboard_Add_Tab'
export const ResultDashboard_Delete_Config = 'ResultDashboard_Delete_Config'
export const ResultDashboard_Delete_Tab = 'ResultDashboard_Delete_Tab'
export const ResultDashboard_Upload_Config = 'ResultDashboard_Upload_Config'

// Consistency Check
export const Menu_File_Use_SendCasterToConsistencyCheck = 'Menu_File_Use_SendCasterToConsistencyCheck'
export const Menu_File_View_SendCasterToConsistencyCheck = 'Menu_File_View_SendCasterToConsistencyCheck'
export const Menu_Edit_Use_ToggleEditMode = 'Menu_Edit_Use_ToggleEditMode'
export const Menu_Edit_View_ToggleEditMode = 'Menu_Edit_View_ToggleEditMode'
export const Menu_Edit_Use_CommandFileMapping = 'Menu_Edit_Use_CommandFileMapping'
export const Menu_Edit_View_CommandFileMapping = 'Menu_Edit_View_CommandFileMapping'

export const Menu_File_View_OpenProject = 'Menu_File_View_OpenProject'
export const Menu_File_Use_OpenProject = 'Menu_File_Use_OpenProject'
export const Menu_File_View_CreateProject = 'Menu_File_View_CreateProject'
export const Menu_File_Use_CreateProject = 'Menu_File_Use_CreateProject'
export const Menu_File_View_UploadCasterXML = 'Menu_File_View_UploadCasterXML'
export const Menu_File_Use_UploadCasterXML = 'Menu_File_Use_UploadCasterXML'
export const Menu_File_View_UploadNozzleCatalog = 'Menu_File_View_UploadNozzleCatalog'
export const Menu_File_Use_UploadNozzleCatalog = 'Menu_File_Use_UploadNozzleCatalog'
export const Menu_File_View_DownloadCasterXML = 'Menu_File_View_DownloadCasterXML'
export const Menu_File_Use_DownloadCasterXML = 'Menu_File_Use_DownloadCasterXML'
export const Menu_File_View_LoadCasterView = 'Menu_File_View_LoadCasterView'
export const Menu_File_Use_LoadCasterView = 'Menu_File_Use_LoadCasterView'
export const Menu_File_View_SelectCasterDashboard = 'Menu_File_View_SelectCasterDashboard'
export const Menu_File_Use_SelectCasterDashboard = 'Menu_File_Use_SelectCasterDashboard'
export const Menu_Edit_View_ManageDynamicData = 'Menu_Edit_View_ManageDynamicData'
export const Menu_Edit_Use_ManageDynamicData = 'Menu_Edit_Use_ManageDynamicData'
export const Menu_Edit_View_ConfigurePlots = 'Menu_Edit_View_ConfigurePlots'
export const Menu_Edit_Use_ConfigurePlots = 'Menu_Edit_Use_ConfigurePlots'
export const Menu_Edit_View_CreateRealDataCase = 'Menu_Edit_View_CreateRealDataCase'
export const Menu_Edit_Use_CreateRealDataCase = 'Menu_Edit_Use_CreateRealDataCase'
export const Menu_Edit_View_FullyResetShim = 'Menu_Edit_View_FullyResetShim'
export const Menu_Edit_Use_FullyResetShim = 'Menu_Edit_Use_FullyResetShim'
export const Menu_Edit_View_ExternalDataSources = 'Menu_Edit_View_ExternalDataSources'
export const Menu_Edit_Use_ExternalDataSources = 'Menu_Edit_Use_ExternalDataSources'
export const Menu_Edit_View_ShareState = 'Menu_Edit_View_ShareState'
export const Menu_Edit_Use_ShareState = 'Menu_Edit_Use_ShareState'

export const Caster_TreeView_ViewAndUse_HistoryDialogButton = 'Caster_TreeView_ViewAndUse_HistoryDialogButton'
export const Caster_TreeView_ContextMenu_View_CreateRealData = 'Caster_TreeView_ContextMenu_View_CreateRealData'
export const Caster_TreeView_ContextMenu_Use_CreateRealData = 'Caster_TreeView_ContextMenu_Use_CreateRealData'
export const Caster_TreeView_ViewAndUse_SelectionForRealData = 'Caster_TreeView_ViewAndUse_SelectionForRealData'

export const OpenProject__Use_HideUser = 'OpenProject__Use_HideUser'
export const OpenProject__Use_Share = 'OpenProject__Use_Share'

export const RealData__View_Badges = 'RealData__View_Badges'

export const Caster_PartsWarehouse_View = 'Caster_PartsWarehouse_View'
