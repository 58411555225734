import { ApplicationMainActionsEnum as Actions } from '../consts'

const updateReducers = {
  [Actions.ACTION_UPDATE_CURRENT_PROJECT_SCHEMA]: (
    state: ApplicationMainState,
    { containerName, data }: { containerName: string, data: any },
  ) => ({
    ...state,
    currentSimulationCase: {
      ...state.currentSimulationCase,
      [containerName]: data,
    },
  }),
  [Actions.ACTION_UPDATE_CURRENT_PROJECT_ADDITIONAL_DATA]: (
    state: ApplicationMainState,
    { additionalData }: { additionalData: Record<string, any> },
  ) => ({
    ...state,
    currentProject: {
      ...state.currentProject,
      additionalData,
    },
  }),
  [Actions.ACTION_UPDATE_CASE_CURRENT_CASTER_ID]: (
    state: ApplicationMainState,
    { currentCasterId }: { currentCasterId: string },
  ) => {
    if (!state.currentSimulationCase || !currentCasterId) {
      return state
    }

    return {
      ...state,
      currentSimulationCase: {
        ...state.currentSimulationCase,
        currentCasterId,
      },
    }
  },
}

export default updateReducers
