/* eslint-disable camelcase */
/* eslint-disable key-spacing */
import { defaultFields, getHiddenFields } from './defaults'

export const DataLine = {
  categories: {
    1: 'general',
  },
  fields: {
    passlineCoord: { type: 'number', category: 1, defaultValue: 0, positive: true, disabled: true },
    widthCoord: { type: 'number', category: 1, defaultValue: 0, disabled: true },
    thicknessCoord: { type: 'number', category: 1, defaultValue: 0, disabled: true },
    ...defaultFields,
    ...getHiddenFields([ 'dataLineId' ]),
  },
}
/* eslint-enable key-spacing */
