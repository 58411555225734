import { faDotCircle } from '@fortawesome/free-regular-svg-icons'
import { faChevronCircleDown, faChevronCircleUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import isEqual from 'lodash/isEqual'
import { Component } from 'react'
import { FixedSizeList as List } from 'react-window'
import styled, { css } from 'styled-components'

import Util from '@/logic/Util'
import ThreeUtil from '@/three/logic/Util'
import ThreeManager from '@/three/ThreeManager'
import type { ElementMaps, ElementName } from '@/types/state'
import { ElementMapsUtil } from '@/Util/ElementMapsUtil'

import { TreeViewID } from './driver/DriverID'
import ChildrenElements from './tree/Elements/ChildrenElements'
import ElementsComponent from './tree/Elements/ElementsComponent'
import { TreeViewBuilder } from './tree/TreeViewBuilder'

const OpenIconContainer = styled.div<{ $left: number }>`${({ $left }) =>
  css`
  left:      ${$left}px;
  position: fixed;
  top: 48px;
  cursor: pointer;

  svg {
    font-size: 20px;
    color: white;
  }
`}`

type TreeViewNodesProps = {
  referenceTimestamp: Date
  elementMaps: ElementMaps
  selectedPaths: Set<string>
  setTerm: (term: string, isCtrl?: boolean, isFilterClick?: boolean, isElementClick?: boolean) => void
  setSelectedElementPaths: (elementPath?: string, ctrlKey?: boolean, shiftKey?: boolean) => void
  onSelect: (event: any, path: string) => void
  term: string
  setTarget: (target: string) => void
  target: string
  openDialogs: string[]
}

export type MatchHashElement = {
  show: boolean
  selected: boolean
  parentTypeAndId: string
  showChildren: boolean
  children: string[]
}

type TreeViewNodesState = {
  elementsArray: NodeElement[]
  shownPaths: string[]
  matchHash: Record<string, MatchHashElement>
}

export type NodeElement = {
  name: string
  nameAndId: string
  element: any
  depth: number
  hasChildren: boolean
  fullPath: string
  amountOfChildren?: number
}

function openParentTreeViewNodes (state: TreeViewNodesState, elementTypeAndId: string, showAllChildren = false) {
  // Retrieve the elementHashInfo

  const elementHashInfo =
    state.matchHash[elementTypeAndId] = state.matchHash[elementTypeAndId] ?? {} as MatchHashElement

  if (elementHashInfo.show) {
    return
  }

  elementHashInfo.show = true
  elementHashInfo.showChildren = true

  // Retrieve parent's info
  const parentTypeAndId = elementHashInfo.parentTypeAndId

  // If doesn't have parent or parent is
  if (!parentTypeAndId) {
    return
  }

  const parentHashInfo = state.matchHash[parentTypeAndId] = state.matchHash[parentTypeAndId] ?? {} as MatchHashElement

  if (showAllChildren) {
    // for each child, set the show property to true
    parentHashInfo.children.forEach(childNameAndId => {
      const childHashInfo = state.matchHash[childNameAndId]

      if (!childHashInfo) {
        return
      }

      childHashInfo.show = true
      childHashInfo.showChildren = false
    })

    state.shownPaths.push(...(parentHashInfo.children ?? []))
  }
  else {
    state.shownPaths.push(elementTypeAndId)

    const selectorPaths = parentHashInfo.children.filter(child => child.includes('Selector'))

    for (let i = 0; i < selectorPaths.length; i++) {
      const selectorPath = selectorPaths[i]
      const selectorHashInfo = state.matchHash[selectorPath ?? '']

      if (!selectorPath || !selectorHashInfo) {
        continue
      }

      selectorHashInfo.show = true

      if (state.shownPaths.includes(selectorPath)) {
        continue
      }

      state.shownPaths.push(selectorPath)
    }
  }

  if (!parentHashInfo.show) {
    openParentTreeViewNodes(state, parentTypeAndId)

    return
  }

  parentHashInfo.showChildren = true
}

const Row = ({ data, index, style }: any) => {
  const { openedElements, matchHash } = data

  const element = openedElements[index]

  if (!element) {
    return <></>
  }

  const handleClick = (...args: any[]) => {
    const event = args[0] as MouseEvent
    const forceSelect = event.ctrlKey
    const func = element.hasChildren && !forceSelect ? data.showChildren : data.handleSelect

    func.apply(this, args)
  }

  const elemType = element.nameAndId.split(':')[0]
  let selfTerm = element.fullPath

  if (elemType !== 'Nozzle' && !elemType.includes('RollerB')) {
    selfTerm += '/*'
  }

  const active = data.term.includes(selfTerm)

  const targetCondition = data.target === selfTerm

  if (element.nameAndId.includes('Selector')) {
    let nextElement = String(element.nameAndId.substring(0, element.nameAndId.indexOf('Selector')))

    nextElement = ElementMapsUtil.getTagName(nextElement as ElementName)

    if (nextElement !== 'RollerBody') {
      nextElement += 's'
    }
    else {
      nextElement = `${nextElement.substring(0, nextElement.length - 1)}ies`
    }

    return (
      <div style={style}>
        <ChildrenElements
          counter={element.amountOfChildren ?? 0}
          onClick={handleClick}
          spacer={element.depth}
          nextElement={nextElement}
          selected=''
          visibleChildren={{}}
          name={element.nameAndId}
          element={element.element}
          open={matchHash[element.nameAndId]?.showChildren ?? false}
          path={element.fullPath}
        />
      </div>
    )
  }

  return (
    <div style={style}>
      <ElementsComponent
        depth={element.depth}
        key={index}
        element={element.element}
        selected={matchHash[element.nameAndId]?.selected ?? false}
        childrenElement={[]}
        name={elemType}
        type={elemType}
        elementName={[ 'SegmentGroup', 'Segment' ].includes(elemType) ? element.name : ''}
        fullPath={element.fullPath}
        visible={matchHash[element.nameAndId]?.showChildren ?? false}
        active={active}
        target={targetCondition}
        hasChildren={element.hasChildren}
        onClick={handleClick}
        // eslint-disable-next-line react/jsx-handler-names
        onFilter={data.onFilter}
        // eslint-disable-next-line react/jsx-handler-names
        onTarget={data.onTarget}
      />
    </div>
  )
}

export class TreeViewNodes extends Component<TreeViewNodesProps, TreeViewNodesState> {
  public constructor (props: any) {
    super(props)

    const elementsArray: NodeElement[] = []
    const shownPaths: string[] = []
    const matchHash: Record<string, MatchHashElement> = {}

    this.handleBuildTree(elementsArray, shownPaths, matchHash)
    this.getOpenedElements(shownPaths, elementsArray)
    this.state = { elementsArray, shownPaths, matchHash }
  }

  public override componentDidMount (): void {
    window.addEventListener('resize', this.handleWindowSizeChange)
  }

  public override componentDidUpdate (prevProps: TreeViewNodesProps) {
    if (isEqual(this.props, prevProps)) {
      return
    }

    const { elementMaps, selectedPaths } = this.props
    const newState = { ...this.state }

    if (!isEqual(prevProps.elementMaps, elementMaps)) {
      newState.elementsArray = []
      newState.shownPaths = []
      newState.matchHash = {}

      this.handleBuildTree(newState.elementsArray, newState.shownPaths, newState.matchHash)
      this.getOpenedElements(newState.shownPaths, newState.elementsArray)
    }

    // Unselect all elements
    for (let i = 0; i < newState.elementsArray.length; i++) {
      if (!newState.elementsArray[i] || !newState.matchHash[newState.elementsArray[i]?.nameAndId ?? '']) {
        continue
      }

      newState.matchHash[newState.elementsArray[i]!.nameAndId!]!.selected = false
    }

    // FIXME: this fallback should not be necessary - selectedPaths needs to be fixed!
    const selectedPathsSet = Util.isIterable(selectedPaths) ? selectedPaths : new Set<string>()

    for (const path of selectedPathsSet) {
      const elementTypeAndId = path.substring(path.lastIndexOf('/') + 1)

      if (!newState.matchHash[elementTypeAndId]) {
        break
      }

      if (!newState.matchHash[elementTypeAndId].show) {
        openParentTreeViewNodes(newState, elementTypeAndId, true)
      }

      newState.matchHash[elementTypeAndId].selected = true
    }

    this.setState(newState)
  }

  public override componentWillUnmount (): void {
    window.removeEventListener('resize', this.handleWindowSizeChange)
  }

  private readonly handleWindowSizeChange = () => {
    this.forceUpdate()
  }

  private handleBuildTree (
    elementsArray: NodeElement[],
    shownPaths: string[],
    matchHash: Record<string, MatchHashElement>,
    showAll = false,
  ) {
    const { elementMaps } = this.props

    TreeViewBuilder.buildTree(elementMaps, elementsArray, shownPaths, matchHash, showAll)
  }

  private readonly handleFilterClick = (ctrl: boolean, name: string, elementPath: string) => {
    const { setTerm, setSelectedElementPaths, term } = this.props

    const filter = this.getTerm(name, elementPath)

    if (term.includes(filter)) {
      setTerm(filter, ctrl, false, true)
      setTimeout(() => {
        ThreeManager.base.jumpToFiltered()
      }, 1)
    }
    else {
      setTerm(filter, true, false, true)
      setTimeout(() => {
        ThreeManager.base.jumpToFilter(filter, () => {
          setTimeout(() => {
            if (!ctrl) {
              setTerm(filter, false, false, false)
            }
          }, 1)
        })
      }, 1)
    }

    setSelectedElementPaths()
  }

  private readonly handleTargetClick = (name: string, elementPath: string) => {
    const { setTarget } = this.props

    const term = this.getTerm(name, elementPath)

    setTarget(term)
    ThreeManager.base.jumpToFilter(term)
  }

  private readonly handleSelect = (event: MouseEvent, path: string) => {
    event.stopPropagation()

    const { onSelect, elementMaps } = this.props

    const { type, id } = ThreeUtil.getElementInfo(path)

    if (type === 'SegmentGroup') {
      const currentSegmentGroupStartPasslnCoord = Util.getCurrentSegmentGroupPasslnCoord(elementMaps, id)
      
      window.dispatchEvent(
        new CustomEvent(
          'CurrentSegmentGroupStartPasslnCoordChanged',
          { detail: { currentSegmentGroupStartPasslnCoord } },
        ),
      )
      ;(window as any).currentSegmentGroupStartPasslnCoord = currentSegmentGroupStartPasslnCoord
    }

    onSelect(event, path)
  }

  private readonly handleOpenAll = () => {
    const elementsArray: NodeElement[] = []
    const shownPaths: string[] = []
    const matchHash: Record<string, MatchHashElement> = {}

    this.handleBuildTree(elementsArray, shownPaths, matchHash, true)

    return this.setState({ elementsArray, shownPaths, matchHash })
  }

  private readonly handleCloseAll = () => {
    const matchHashCopy = { ...this.state.matchHash }

    Object.keys(matchHashCopy).forEach(key => {
      // if key starts with SegmentGroup, return, we always want to show them
      if (!key.indexOf('SegmentGroup')) {
        matchHashCopy[key]!.showChildren = false

        return
      }

      matchHashCopy[key]!.show = false
      matchHashCopy[key]!.showChildren = false
    })

    const newShownPaths = this.state.shownPaths.filter(path => path.indexOf('SegmentGroup') === 0)

    this.setState({ matchHash: matchHashCopy, shownPaths: newShownPaths })
  }

  private readonly handleOpenLikeDefault = () => {
    const elementsArray: NodeElement[] = []
    const shownPaths: string[] = []
    const matchHash: Record<string, MatchHashElement> = {}

    this.handleBuildTree(elementsArray, shownPaths, matchHash)

    return this.setState({ elementsArray, shownPaths, matchHash })
  }

  private getOpenedElements (shownPaths: string[], elementsArray: NodeElement[]) {
    if (!shownPaths) {
      return []
    }

    return elementsArray.filter(element => shownPaths.includes(element.nameAndId))
  }

  private closeAllChildren (newState: TreeViewNodesState, elementTypeAndId: string) {
    const elementMatch = newState.matchHash[elementTypeAndId]

    if (!elementMatch) {
      return
    }

    elementMatch.children.forEach(childNameAndId => {
      const childMatch = newState.matchHash[childNameAndId]

      if (childMatch && childMatch.show) {
        childMatch.showChildren = false
        childMatch.show = false

        const index = newState.shownPaths.indexOf(childNameAndId)

        if (~index) {
          newState.shownPaths.splice(index, 1)
        }

        this.closeAllChildren(newState, childNameAndId)
      }
    })
  }

  private readonly toggleChildren = (_event: Event, _path: string, _element: any, elementTypeAndId: string) => {
    const newState = { ...this.state }
    const elementMatch =
      newState.matchHash[elementTypeAndId] = newState.matchHash[elementTypeAndId] ?? {} as MatchHashElement
    const newShowChildren = !elementMatch.showChildren

    elementMatch.showChildren = newShowChildren

    if (newShowChildren) {
      elementMatch.children.forEach(childPath => {
        if (newState.matchHash[childPath]) {
          newState.shownPaths.push(childPath)
          newState.matchHash[childPath].show = true
        }
      })
    }
    else {
      this.closeAllChildren(newState, elementTypeAndId)
    }

    this.setState(newState)
  }

  private readonly getTerm = (name: string, elementPath: string) => {
    let term = elementPath

    if (name !== 'Nozzle' && !name.includes('RollerB')) {
      term += '/*'
    }

    return term
  }

  public override render () {
    const { shownPaths, elementsArray } = this.state
    const { openDialogs } = this.props
    const openedElements = this.getOpenedElements(shownPaths, elementsArray)
    const data = {
      openedElements,
      matchHash: this.state.matchHash,
      showChildren: this.toggleChildren,
      onFilter: this.handleFilterClick,
      term: this.props.term,
      target: this.props.target,
      onTarget: this.handleTargetClick,
      handleSelect: this.handleSelect,
    }
    const windowHeight = window && window.innerHeight ? window.innerHeight - 84 : 600

    return (
      <div>
        {
          openDialogs.includes('CasterTree') && (
            <div>
              <OpenIconContainer $left={180}>
                <FontAwesomeIcon
                  id={TreeViewID.OpenAllButton}
                  icon={faChevronCircleDown}
                  onClick={this.handleOpenAll}
                  title='Open all'
                />
              </OpenIconContainer>
              <OpenIconContainer $left={210}>
                <FontAwesomeIcon
                  id={TreeViewID.OpenDefaultButton}
                  icon={faDotCircle}
                  onClick={this.handleOpenLikeDefault}
                  title='Default'
                />
              </OpenIconContainer>
              <OpenIconContainer $left={240}>
                <FontAwesomeIcon
                  id={TreeViewID.CloseAllButton}
                  icon={faChevronCircleUp}
                  onClick={this.handleCloseAll}
                  title='Close all'
                />
              </OpenIconContainer>
            </div>
          )
        }
        {/* FIXME: it seems like all children rerender on scroll, we need to find the right way to do this! */}
        <List
          key='List'
          height={windowHeight} // TODO: make this autosize
          itemSize={25}
          itemCount={this.state.shownPaths.length}
          width={270}
          itemData={data}
          overscanCount={10}
        >
          {Row}
        </List>
      </div>
    )
  }
}
