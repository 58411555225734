import { filterActions } from 'redux-ignore'

import { actionList, initialState } from './consts'
import dialogReducers from './reducers/dialogReducers'
import setReducers from './reducers/setReducers'
import updateReducers from './reducers/updateReducers'

// eslint-disable-next-line @typescript-eslint/ban-types
const actionHandlers: Record<string, Function | undefined> = {
  ...dialogReducers,
  ...setReducers,
  ...updateReducers,
}

function applicationMainReducer (state = initialState, action: unknown & { type: string }) {
  const handler = actionHandlers[action.type]

  return handler ? handler(state, action) : state
}

export default filterActions(applicationMainReducer, actionList) as typeof applicationMainReducer
