import { getTemporalData, TemporalDataResponse, TypeAndIdNeededData } from '@/api/temporal-data'
import { getCurrentDashboardEntry } from '@/App/util'
import { getElementMapsObject } from '@/store/elements/logic'
import FilterHandler from '@/three/logic/FilterHandler'
import ThreeUtil from '@/three/logic/Util'
import { ElementMaps, TagName } from '@/types/state'
import { PlotConfig } from '@/types/visualization'
import { ElementsUtil } from '@/Util/ElementsUtil'
import { Mapping } from '@/Util/mapping/Mapping'

import { Props } from './index'
export default class TemporalDataHandler {
  public static async getTemporalData (props: Props) {
    const {
      plotConfigs,
      setTemporalData,
      temporalData,
      currentProject,
    } = props

    const elementMaps = getElementMapsObject(props)
    const plotIds = this.getPlotIds(props)

    if (!plotIds) {
      return
    }

    const neededData = this.getNeededDataArray(plotConfigs, plotIds, temporalData, elementMaps)

    if (neededData.length === 0) {
      return
    }

    const mountLogsTypeArray = (await getTemporalData(currentProject.id, neededData)) ?? []
    const data = TemporalDataHandler.mapMountLogsToTemporalData(mountLogsTypeArray, neededData)

    setTemporalData(data)
  }

  public static async getTemporalDataForSpecificFilter (
    setTemporalData: (data: any) => void,
    filter: string,
    projectId: string,
  ) {
    const neededData: TypeAndIdNeededData[] = []

    const neededDataString = this.getNeededDataStringByFilter(filter)

    if (!neededDataString) {
      return
    }

    neededData.push(neededDataString)

    const mountLogsTypeArray = (await getTemporalData(projectId, neededData)) ?? []
    const data = this.mapMountLogsToTemporalData(mountLogsTypeArray, neededData)

    setTemporalData(data)
  }

  private static mapMountLogsToTemporalData (mountLogsTypeArray: TemporalDataResponse, neededData: string[]) {
    const data = {} as any

    const mountLogsPerRealDataUUID: Record<string, any[]> = {}

    for (const mountLogType of mountLogsTypeArray) {
      for (const mountLog of mountLogType) {
        const realDataUUID = mountLog.realDataUUID

        if (!realDataUUID) {
          continue
        }

        if (!mountLogsPerRealDataUUID[realDataUUID]) {
          mountLogsPerRealDataUUID[realDataUUID] = []
        }

        mountLogsPerRealDataUUID[realDataUUID].push(mountLog)
      }
    }

    Object.entries(mountLogsPerRealDataUUID).forEach(([ realDataUUID, mountLogs ]) => {
      data[realDataUUID] = mountLogs
    })

    //  go through all needed data, if it is not in the data object, add it with an empty array
    for (const needed of neededData) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [ , realDataUUID ] = needed.split('|')

      if (realDataUUID && !data[realDataUUID]) {
        data[realDataUUID] = []
      }
    }

    return data
  }

  private static getPlotIds (props: Props) {
    const { currentDashboard, viewsObject, tileConfigs } = props
    const { viewId, dashboardId } = getCurrentDashboardEntry(currentDashboard, viewsObject)

    if (!viewId || !dashboardId) {
      return
    }

    const tileIds: string[] = viewsObject[viewId]?.dashboards?.[dashboardId]?.tileIds ?? []

    if (!tileIds?.length) {
      return
    }

    return tileIds.map(tileId => tileConfigs[tileId]?.configId).filter(Boolean) as string[]
  }

  private static getNeededDataStringByFilter (
    filter: string,
    temporalData?: TemporalDataState,
  ): TypeAndIdNeededData | null {
    const [ type, rest = '' ] = filter.split('#')
    const [ , realDataUUID ] = rest.split('realDataUUID=')

    if (!realDataUUID || (temporalData?.[realDataUUID])) {
      return null
    }

    return `${type}|${realDataUUID}` as TypeAndIdNeededData
  }

  private static getNeededDataArray (
    plotConfigs: any,
    plotIds: string[],
    temporalData: TemporalDataState,
    elementMaps: ElementMaps,
  ): TypeAndIdNeededData[] {
    const neededData: TypeAndIdNeededData[] = []

    for (const plotId of plotIds) {
      const plotConfig = plotConfigs[plotId] ?? {}

      if (plotConfig.configIds?.length) {
        TemporalDataHandler.getNeededDataArrayForMergedConfig(plotConfig, temporalData, elementMaps, neededData)
      }

      const { filter, selectedY, selectedX } = plotConfig

      if (selectedX?.includes('dataOverTime')) {
        const [ type ] = selectedY.split('|')
        const filteredElements = FilterHandler.getFilteredElements(elementMaps, filter, false)
        const firstElementPath = Object.keys(filteredElements ?? {}).find(path => {
          const { type: elementType } = ThreeUtil.getElementInfo(path)

          return elementType === type
        })

        if (!firstElementPath) {
          continue
        }

        const mountLogId = Mapping.mountLogIdByElementPath[firstElementPath]

        if (!mountLogId) {
          continue
        }

        let element: any

        // const element = (elementMaps as any)[`${type}MountLog`]?.[mountLogId]
        ElementsUtil.getMountLogMapKeysByTagName(type).forEach((key) => {
          if (elementMaps[key]?.[mountLogId]) {
            element = elementMaps[key]?.[mountLogId]
          }
        })

        const realDataUUID = element?.realDataUUID

        if (!realDataUUID || temporalData[realDataUUID]) {
          continue
        }

        const info = `${type}|${realDataUUID}` as TypeAndIdNeededData

        neededData.push(info)
      }
    }

    return neededData
  }

  private static getNeededDataArrayForMergedConfig (
    plotConfig: PlotConfig,
    temporalData: TemporalDataState,
    elementMaps: ElementMaps,
    neededDataArray: TypeAndIdNeededData[],
  ) {
    for (const config of plotConfig.configs) {
      const { filter, selectedY, selectedX } = config

      if (!selectedX?.includes('dataOverTime')) {
        continue
      }

      const [ type ] = selectedY.split('|')

      if (!type) {
        continue
      }

      const filteredElements = FilterHandler.getFilteredElements(elementMaps, filter, false)
      const firstElementPath = Object.keys(filteredElements ?? {}).find(path => {
        const { type: elementType } = ThreeUtil.getElementInfo(path)

        return elementType === type
      })

      if (!firstElementPath) {
        continue
      }

      const mountLogId = Mapping.mountLogIdByElementPath[firstElementPath]

      if (!mountLogId) {
        continue
      }

      let element: any

      // const element = (elementMaps as any)[`${type}MountLog`]?.[mountLogId]
      ElementsUtil.getMountLogMapKeysByTagName(type as TagName).forEach((key) => {
        if (elementMaps[key]?.[mountLogId]) {
          element = elementMaps[key]?.[mountLogId]
        }
      })

      const realDataUUID = element?.realDataUUID

      if (!realDataUUID || temporalData[realDataUUID]) {
        continue
      }

      const info = `${type}|${realDataUUID}` as TypeAndIdNeededData

      if (neededDataArray.includes(info)) {
        continue
      }

      neededDataArray.push(info)
    }
  }
}
