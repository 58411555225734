import isEqual from 'lodash/isEqual'
import { filterActions } from 'redux-ignore'

import type { VisualizationState } from '@/types/visualization'

import {
  initialState,
  treeViewWidth,
  VisualizationAction as VisAction,
  visualizationActionList,
  VisualizationActionsEnum as VisActionsEnum,
} from './consts'
import contextMenuReducers from './reducers/contextMenuReducers'
import dashboardReducers from './reducers/dashboardReducers'
import dataReducers from './reducers/dataReducers'
import dialogReducers from './reducers/dialogReducers'
import hdf5Reducers from './reducers/hdf5Reducers'
import plotReducers from './reducers/plotReducers'
import viewReducers from './reducers/viewReducers'
import Util from './util/Util'

// eslint-disable-next-line @typescript-eslint/ban-types
const actionHandlers: Record<string, Function | undefined> = {
  ...contextMenuReducers,

  ...dashboardReducers,

  ...dataReducers,

  ...dialogReducers,

  ...hdf5Reducers,

  ...plotReducers,

  ...viewReducers,

  [VisActionsEnum.ACTION_VISUALIZATION_SHOW_COMMAND_MAPPING]: (state: VisualizationState) => ({
    ...state,
    isCommandMappingOpen: !state.isCommandMappingOpen,
  }),

  [VisActionsEnum.ACTION_VISUALIZATION_SET_EDIT_MODE]: (state: VisualizationState, { mode }: VisAction) => ({
    ...state,
    isEditModeOn: mode ?? !state.isEditModeOn,
  }),

  [VisActionsEnum.ACTION_VISUALIZATION_SET_LOADING_BUTTON_STATUS]: (
    state: VisualizationState,
    { status, buttonType }: { status: boolean, buttonType: string },
  ) => {
    if (!buttonType && !status) {
      return ({
        ...state,
        loadingStatus: {},
      })
    }

    return ({
      ...state,
      loadingStatus: {
        ...state.loadingStatus,
        [buttonType]: status,
      },
    })
  },

  [VisActionsEnum.ACTION_VISUALIZATION_SET_CURRENT_DASHBOARD_WIDTH]: (
    state: VisualizationState,
    { width }: VisAction,
  ) => ({
    ...state,
    casterDashboardWidth: width,
  }),

  [VisActionsEnum.ACTION_VISUALIZATION_SET_CURRENT_CASTER_DIALOG_WIDTH]: (
    state: VisualizationState,
    { width }: VisAction,
  ) => ({
    ...state,
    currentCasterDialogWidth: width,
  }),

  [VisActionsEnum.ACTION_VISUALIZATION_SET_AMOUNT_OF_COMPARISON_CASTER_COLUMNS]: (
    state: VisualizationState,
    { amountOfComparisonCasterColumns }: VisAction,
  ) => ({
    ...state,
    amountOfComparisonCasterColumns,
  }),

  [VisActionsEnum.ACTION_RESET_VISUALIZATION]: () => ({
    ...initialState,
  }),

  [VisActionsEnum.ACTION_SET_SELECTED_COMPARISON_CASE_IDS]: (state: VisualizationState, { ids }: VisAction) => ({
    ...state,
    selectedComparisonCaseIds: ids,
  }),

  [VisActionsEnum.ACTION_SET_COMPARISON_TIMESTAMPS]: (state: VisualizationState, { idsAndTimestamps }: VisAction) => ({
    ...state,
    comparisonTimestamps: idsAndTimestamps,
  }),

  [VisActionsEnum.ACTION_SET_COMPARE_CASTER_INFORMATION]: (state: VisualizationState, { data }: VisAction) => ({
    ...state,
    compareCasterInformation: data,
  }),

  [VisActionsEnum.ACTION_SET_PLOTS_COMPARE_CASTER_INFORMATION]: (state: VisualizationState, { data }: VisAction) => ({
    ...state,
    plotsCompareCasterInformation: data,
  }),

  [VisActionsEnum.ACTION_SET_CASTER_DASHBOARD_TAB_INDEX]: (
    state: VisualizationState,
    { tabIndex }: { tabIndex: number },
  ) => {
    const { currentDashboard, viewsObject } = state
    const newCurrentDashboardsObject = Util.getNewCurrentDashboardsObject(viewsObject, currentDashboard, tabIndex)
    const newWidth = tabIndex === 0
      ? treeViewWidth
      : Util.getNewCasterDashboardWidth(newCurrentDashboardsObject, viewsObject)

    return {
      ...state,
      casterDashboardTabIndex: tabIndex,
      casterDashboardWidth: newWidth,
      currentDashboard: newCurrentDashboardsObject,
    } as VisualizationState
  },

  'data/ACTION_RESET_ALL': (state: VisualizationState, action: { skipVisualization: boolean }) => {
    if (action.skipVisualization) {
      return state
    }

    return ({
      ...initialState,
    })
  },
}

function visualizationReducers (state: VisualizationState = initialState, action: VisAction) {
  if (isEqual(state, {})) {
    state = {
      ...initialState,
      ...state,
    }
  }

  const handler = actionHandlers[action.type]

  return handler ? handler(state, action) : state
}

export default filterActions(visualizationReducers as any, [
  ...visualizationActionList,
  'data/ACTION_RESET_ALL',
]) as typeof visualizationReducers
