/* eslint-env browser */

import { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Container } from 'react-smooth-dnd'
import { compose } from 'redux'
import { v4 as uuid } from 'uuid'

import FeatureFlags from '@/react/FeatureFlags'
import type { ResizeData } from '@/react/ResizeDetector'
import { AppState } from '@/store/application/main/consts'
import * as VisualizationActions from '@/store/visualization/actions'
import type { DefaultState } from '@/types/state'
import type { Translation } from '@/types/translation'

import { AddDashboard, CompactViewIcons, Tab, TabsWrapper, ViewIcon, ViewIconsWrapper } from './Dashboard/styles'

const connector = connect((state: DefaultState) => ({
  featureFlags: FeatureFlags.getRealFeatureFlags(state),
  appState: state.application.main.appState,
  viewsObject: state.visualization.viewsObject,
  currentDashboard: state.visualization.currentDashboard,
  isEditModeOn: state.visualization.isEditModeOn,
}), {
  setDashboardObject: VisualizationActions.setDashboardObject,
  showDeleteDashboardDialog: VisualizationActions.showDeleteDashboardDialog,
  splitView: VisualizationActions.splitView,
  deleteSplitView: VisualizationActions.deleteSplitView,
  showAddPlotDialog: VisualizationActions.showAddPlotDialog,
  changeTabs: VisualizationActions.changeTabs,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  viewId: string
  t: Translation
}

type State = {
  middleMouseDownTab: string | null | undefined
  compactMenu: boolean
  size: { width: number; height: number }
}

export class Tabs extends Component<Props, State> {
  private tabWrapperRef?: HTMLElement = undefined

  public override state: State = {
    middleMouseDownTab: null,
    compactMenu: false,
    size: { width: 0, height: 0 },
  }
  
  public override componentDidMount () {
    const { viewsObject, viewId } = this.props
    const dashboards = viewsObject[viewId]?.dashboards ?? {}
    const currentDashboardKeys = Object.keys(dashboards)

    if (currentDashboardKeys.length < 1) {
      this.handleAddDashboard(true)
    }
  }
  
  public override componentDidUpdate () {
    const { size } = this.state

    this.handleResize(size as ResizeData)
  }

  // TODO: without any type it would be an event, is this correct?
  private readonly handleAddDashboard = (storeOnly: any = false) => {
    const { viewId, setDashboardObject, t } = this.props
    const dashboardId = `dashboard_${uuid()}`

    setDashboardObject(viewId, { name: t('tabs.defaultName') }, dashboardId, storeOnly)
  }

  private readonly handleResize = ({ width, height }: ResizeData) => {
    this.handleWheel({
      target: this.tabWrapperRef,
      deltaY: 0,
    })
    
    const { size } = this.state

    if (size.width === width && size.height === height) {
      return
    }

    requestAnimationFrame(() => {
      this.setState({ size: { width: width ?? 0, height: height ?? 0 } })
    })
  }

  private readonly handleWheel = (event: any) => {
    if (!this.tabWrapperRef) {
      return
    }

    const target = this.tabWrapperRef

    target.scrollLeft += event.deltaY

    if (target.scrollLeft < 25) {
      target.setAttribute('data-arrow-left', 'off')
    }
    else {
      target.setAttribute('data-arrow-left', 'on')
    }

    if (target.offsetWidth + target.scrollLeft > target.scrollWidth - 25) {
      target.setAttribute('data-arrow-right', 'off')
    }
    else {
      target.setAttribute('data-arrow-right', 'on')
    }
  }

  private readonly handleTabWrapperRef = (ref: any) => {
    if (ref) {
      this.tabWrapperRef = ref.container.querySelector('.smooth-dnd-container')
    }
  }

  private readonly handleDeleteDashboard = (event: any) => {
    const { showDeleteDashboardDialog, viewId } = this.props

    showDeleteDashboardDialog(viewId, event.target.id)
  }

  private readonly handleSplitHorizontal = () => {
    const { viewId, splitView } = this.props

    splitView(viewId, 'vertical')
  }

  private readonly handleSplitVertical = () => {
    const { viewId, splitView } = this.props

    splitView(viewId, 'horizontal')
  }

  private readonly handleDeleteSplit = () => {
    const { viewId, deleteSplitView } = this.props

    deleteSplitView(viewId)
  }

  private readonly handleMouseDown = (event: any) => {
    if (!this.tabWrapperRef) {
      return
    }

    const offset = event.pageX - this.tabWrapperRef.getBoundingClientRect().left
    const containerWidth = this.tabWrapperRef.getBoundingClientRect().width

    if (event.button === 0) {
      if (offset <= 20) {
        this.handleWheel({
          target: this.tabWrapperRef,
          deltaY: -156,
        })
      }

      const { isEditModeOn } = this.props
      const marginA = isEditModeOn ? 57 : 21
      const marginB = isEditModeOn ? 37 : 0

      if (offset >= containerWidth - marginA && offset < containerWidth - marginB) {
        this.handleWheel({
          target: this.tabWrapperRef,
          deltaY: 156,
        })
      }
    }
  }

  private readonly handleDrop = (data: any) => {
    const { viewId, changeTabs } = this.props
    const { addedIndex, removedIndex, payload } = data

    this.tabWrapperRef?.classList?.remove('drag_over')

    if (addedIndex !== removedIndex) {
      changeTabs(addedIndex, removedIndex, viewId, payload.key, payload.data)
    }
  }

  private readonly handleDragStart = () => {
    if (!document) {
      // eslint-disable-next-line no-console
      return console.error('document is null or undefined')
    }

    document.querySelectorAll('.drag_container .smooth-dnd-container').forEach(container => {
      container.classList.add('drag_over')
    })
  }

  private readonly handleDragEnd = () => {
    if (!document) {
      // eslint-disable-next-line no-console
      return console.error('document is null or undefined')
    }

    document.querySelectorAll('.drag_container .smooth-dnd-container').forEach(container => {
      container.classList.remove('drag_over')
    })
  }

  // handleContainerMouseDown = (event: any) => { // TODO: Remove?
  //   const { isEditModeOn } = this.props

  //   if (!isEditModeOn) {
  //     event.stopPropagation()

  //     return false
  //   }
  // };

  private readonly handleOpenCompactMenu = () => {
    this.setState({
      compactMenu: true,
    })
  }

  private readonly handleCloseCompactMenu = () => {
    this.setState({
      compactMenu: false,
    })
  }

  private readonly getChildPayload = (index: number) => {
    const { viewsObject, viewId } = this.props
    const currentDashboardKeys = Object.keys(viewsObject[viewId]?.dashboards ?? {})

    return {
      key: currentDashboardKeys[index] ?? '',
      data: viewsObject[viewId ?? '']?.dashboards?.[currentDashboardKeys[index] ?? ''] ?? null,
    }
  }
  
  public override render () {
    const { viewsObject, viewId, currentDashboard, isEditModeOn, t, appState, featureFlags } = this.props
    const { compactMenu, size } = this.state

    const dashboards = viewsObject[viewId]?.dashboards ?? {}
    const currentDashboardKeys = Object.keys(dashboards)
    const isSplit = viewId.includes('split')
    const compactMode = size.width <= 345
    const canAddNewTab = (appState !== AppState.ParamDashboard) ||
      (appState === AppState.ParamDashboard && FeatureFlags.canAddParameterDashboardTab(featureFlags))

    return (
      <div>
        <TabsWrapper
          $split={isSplit}
          $edit={isEditModeOn}
          $compact={compactMode}
          className='drag_container'
          onResize={this.handleResize}
          onMouseDown={this.handleMouseDown}
          ref={this.handleTabWrapperRef}
          onWheel={this.handleWheel}
          data-wheel
        >
          {/* @ts-expect-error props definition is wrong https://github.com/kutlugsahin/react-smooth-dnd/issues/88 */}
          <Container
            orientation='horizontal'
            groupName='tabs'
            onDrop={this.handleDrop}
            getChildPayload={this.getChildPayload}
            onDragStart={this.handleDragStart}
            onDragEnd={this.handleDragEnd}
            dragClass='dragging'
            // onMouseDown={this.handleContainerMouseDown} TODO: test this, this props doesnt exist
            dragHandleSelector='.tab_drag_handle'
          >
            <>
              {
                currentDashboardKeys.map((dashboardId, index) =>
                // FIXME: these do not exist on Draggable, do we need them?
                // @ts-expect-error props definition is wrong https://github.com/kutlugsahin/react-smooth-dnd/issues/88 
                  <Tab
                    key={index}
                    $active={dashboardId === currentDashboard[viewId]}
                    className={currentDashboardKeys.length > 1 && isEditModeOn ? 'tab_drag_handle' : ''}
                    $compact={compactMode}
                  >
                    <>
                      <span className='tab-text'>{dashboards[dashboardId]?.name}</span>
                      {
                        isEditModeOn && (
                          <i
                            className='pe-7s-close pe-va tab-text'
                            title={t('tabs.delete')}
                            onClick={this.handleDeleteDashboard}
                            id={dashboardId}
                          />
                        )
                      }
                    </>
                  </Tab>)
              }
              {
                isEditModeOn && canAddNewTab && size.width > 345 && (
                  <AddDashboard onClick={this.handleAddDashboard} title={t('tabs.new')}>
                    <i className='pe-7s-plus pe-va' />
                  </AddDashboard>
                )
              }
            </>
          </Container>
        </TabsWrapper>
        {
          isEditModeOn && size.width > 345 && (
            <ViewIconsWrapper $isSplit={isSplit}>
              {
                isSplit &&
              (
                <ViewIcon onClick={this.handleDeleteSplit} title={t('tabs.merge')}>
                  <svg width='33px' height='33px'>
                    <use xlinkHref='icons/dashboardIcons.svg#merge' />
                  </svg>
                </ViewIcon>
              )
              }
              <ViewIcon onClick={this.handleSplitVertical} title={t('tabs.splitVertical')}>
                <svg width='33px' height='33px'>
                  <use xlinkHref='icons/dashboardIcons.svg#splitV' />
                </svg>
              </ViewIcon>
              <ViewIcon onClick={this.handleSplitHorizontal} title={t('tabs.splitHorizontal')}>
                <svg width='33px' height='33px'>
                  <use xlinkHref='icons/dashboardIcons.svg#splitH' />
                </svg>
              </ViewIcon>
            </ViewIconsWrapper>
          )
        }
        {
          isEditModeOn && compactMode && (
            <ViewIconsWrapper $compact>
              <ViewIcon onMouseEnter={this.handleOpenCompactMenu} onMouseLeave={this.handleCloseCompactMenu} $compact>
                <i className='pe-7s-config pe-va' />
              </ViewIcon>
            </ViewIconsWrapper>
          )
        }
        {
          isEditModeOn && compactMode && compactMenu && (
            <CompactViewIcons
              $isSplit={isSplit}
              onMouseEnter={this.handleOpenCompactMenu}
              onMouseLeave={this.handleCloseCompactMenu}
            >
              <AddDashboard onClick={this.handleAddDashboard} title={t('tabs.new')} $compact>
                <i className='pe-7s-plus pe-va' />
              </AddDashboard>
              {
                isSplit &&
              (
                <ViewIcon onClick={this.handleDeleteSplit} title={t('tabs.merge')} $compact>
                  <svg width='33px' height='33px'>
                    <use xlinkHref='icons/dashboardIcons.svg#merge' />
                  </svg>
                </ViewIcon>
              )
              }
              <ViewIcon onClick={this.handleSplitVertical} title={t('tabs.splitVertical')} $compact>
                <svg width='33px' height='33px'>
                  <use xlinkHref='icons/dashboardIcons.svg#splitV' />
                </svg>
              </ViewIcon>
              <ViewIcon onClick={this.handleSplitHorizontal} title={t('tabs.splitHorizontal')} $compact>
                <svg width='33px' height='33px'>
                  <use xlinkHref='icons/dashboardIcons.svg#splitH' />
                </svg>
              </ViewIcon>
            </CompactViewIcons>
          )
        }
      </div>
    )
  }
}

export default compose<any>(withTranslation('visualization'), connector)(Tabs)
