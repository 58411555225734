import { Component } from 'react'

import type { CasterElementNames } from '@/types/data'

import ChildrenSelector from './ChildrenSelector'
import { Actions, Center, Counter, Elements, Spacer } from '../ElementGroupStyles'

type Props = {
  visibleChildren: any
  selected: string
  spacer: number
  counter: number
  nextElement: string
  title?: string
  name: string
  element: any
  open: boolean
  path: string
  onClick: (event: any, path: string, element?: any, name?: string) => void
}

export default class ChildrenElement extends Component<Props> {
  private readonly handleClick = (event: any) => {
    event.stopPropagation()

    const { name, onClick, element } = this.props

    onClick(event, element.path, element, name)
  }
  
  public override render () {
    const {
      selected,
      spacer,
      counter,
      nextElement,
      title,
      open,
      name,
      path,
    } = this.props

    const selectType = name.substring(0, name.indexOf('Selector-')) as CasterElementNames

    const arrowChildren = open
      ? <Spacer onClick={this.handleClick}>&#9662;</Spacer>
      : <Spacer onClick={this.handleClick}>&#9656;</Spacer>

    return (
      <Elements
        $selected={Boolean(selected)}
        onClick={this.handleClick}
        $spacer={spacer * 15}
        title={title}
      >
        <span>{arrowChildren} {nextElement}</span>
        <Center>
          <Counter>
            {counter}
          </Counter>
          <Actions>
            <ChildrenSelector path={path} selectType={selectType} />
          </Actions>
        </Center>
      </Elements>
    )
  }
}
