import styled, { css } from 'styled-components'

export const MenuBar = styled.div`
  flex:  1;
  width: auto;
`

export const Label = styled.span`
  flex: 1;
`

export const Shortcut = styled.span<{ $disabled?: boolean }>`${({ $disabled }) =>
  css`
  flex: 1;
  text-align: right;
  padding: 0 15px;
  color: ${$disabled ? '#777777' : '#EEEEEE'};
  font-size: 10px;
  line-height: 16px;
`}`
