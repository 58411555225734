import type { EditElements } from '@/types/data'
import { Mapping } from '@/Util/mapping/Mapping'

import { defaultFields, getHiddenFields } from './defaults'

function computeMin (element: any) {
  return element.shimMin
}

function computeMax (element: any) {
  return element.shimMax
}

function getSegmentGroupMountLog (
  element: any,
  SegmentGroupMountLog: SegmentGroupMountLogMap,
  SupportPointMountLog: SupportPointMountLogMap,
) {
  if (!element?.id) {
    return null
  }

  if (typeof element.id === 'number') {
    const supportPointMountLogUUID = Mapping.mountLogIdByTypeAndNumericId.SupportPoint[element.id]
    const supportPointMountLog = SupportPointMountLog[supportPointMountLogUUID ?? '']

    return SegmentGroupMountLog[supportPointMountLog?.segmentGroupMountLogId ?? ''] ?? null
  }

  if (element?.segmentGroupMountLogId) {
    return SegmentGroupMountLog[element.segmentGroupMountLogId]
  }

  return null
}

function getSupportPointMountLogIdsAndParent (
  element: any,
  SegmentGroupMountLog: SegmentGroupMountLogMap,
  SupportPointMountLog: SupportPointMountLogMap,
) {
  if (!element?.id) {
    return {}
  }

  const segmentGroupMountLog = getSegmentGroupMountLog(element, SegmentGroupMountLog, SupportPointMountLog)
  const supportPointMountLogIds = segmentGroupMountLog
    ?.supportPointMountLogs
    // this filter should not be necessary, but it is because for some reason the mountLogs array contains more than
    // the used supportPointMountLogs
    .filter((id) => SupportPointMountLog[id])
  const segmentGroupNumericId = Mapping.numericIdByMountLogId[segmentGroupMountLog?.id ?? '']

  return {
    supportPointMountLogIds,
    parentPath: `SegmentGroup:${segmentGroupNumericId}`,
  }
}

export function getSupportPointPaths (
  element: any,
  SegmentGroupMountLog: SegmentGroupMountLogMap,
  SupportPointMountLog: SupportPointMountLogMap,
): string[] {
  const { supportPointMountLogIds, parentPath } = getSupportPointMountLogIdsAndParent(
    element,
    SegmentGroupMountLog,
    SupportPointMountLog,
  )

  return supportPointMountLogIds?.map((id) => {
    const numericId = Mapping.numericIdByMountLogId[id]

    return `${parentPath}/SupportPoint:${numericId}`
  }) ?? []
}

export function getChangedPaths (
  element: any,
  editElements: EditElements,
  SegmentGroupMountLog: SegmentGroupMountLogMap,
  SupportPointMountLog: SupportPointMountLogMap,
): string[] {
  const { supportPointMountLogIds, parentPath } = getSupportPointMountLogIdsAndParent(
    element,
    SegmentGroupMountLog,
    SupportPointMountLog,
  )

  if (supportPointMountLogIds?.length !== 4 && supportPointMountLogIds?.length !== 2) {
    return []
  }

  const supportPointMountLogs = supportPointMountLogIds
    .filter((id) => SupportPointMountLog[id])
    .map((id) => SupportPointMountLog[id])
  const changeElementPaths = []

  for (const supportPointMountLog of supportPointMountLogs) {
    const numericId = Mapping.numericIdByMountLogId[supportPointMountLog?.id ?? '']

    const path = `${parentPath}/SupportPoint:${numericId}`
    const editElement = editElements[path]

    if (editElement?.shimPropose === null || editElement?.shimPropose === '') {
      continue
    }

    // fallback to -Infinity if shimPropose is null/undefined because Number(null) would be 0
    if (Number(editElement?.shimPropose) !== Number(supportPointMountLog?.shimPropose ?? -Infinity)) {
      changeElementPaths.push(path)
    }
  }

  return changeElementPaths
}

function computeDisabled (
  element: any,
  editElements: EditElements,
  SegmentGroupMountLog: SegmentGroupMountLogMap,
  SupportPointMountLog: SupportPointMountLogMap,
): boolean {
  if (!element?.id) {
    return false
  }

  // if any segmentGroupMountLog needs to be shimmed or has shim applied, all supportPoints should be disabled
  if (
    Object.keys(SegmentGroupMountLog ?? {}).some((key) => (
      Boolean(SegmentGroupMountLog[key]?.shimMarker) ||
      Boolean(SegmentGroupMountLog[key]?.shimApplied)
    ))
  ) {
    return true
  }

  const segmentGroupMountLog = getSegmentGroupMountLog(element, SegmentGroupMountLog, SupportPointMountLog)
  const supportPointCount = segmentGroupMountLog
    ?.supportPointMountLogs
    // this filter should not be necessary, but it is because for some reason the mountLogs array contains more than
    // the used supportPointMountLogs
    ?.filter((id) => SupportPointMountLog[id])
    ?.length ?? 0

  if (supportPointCount < 4) {
    return true
  }

  const changeElementPaths = getChangedPaths(
    element,
    editElements,
    SegmentGroupMountLog,
    SupportPointMountLog,
  )

  return (
    changeElementPaths.length === (supportPointCount - 1) &&
    (element?.shimPropose === null || element?.shimPropose === '')
  )
}

/* eslint-disable camelcase */
/* eslint-disable key-spacing */
export const SupportPoint = {
  categories: {
    1: 'general',
  },
  fields: {
    id: { type: 'number', category: 1, defaultValue: 0, disabled: true },
    name: { type: 'text', category: 1, disabled: true },
    shimMin: { type: 'number', category: 1, defaultValue: 0, disabled: true },
    shimActual: { type: 'number', category: 1, defaultValue: 0, disabled: true },
    // eslint-disable-next-line max-len
    shimPropose: {
      type: 'number',
      category: 1,
      defaultValue: 0,
      step: 0.1,
      decimals: 2,
      computeMin,
      computeMax,
      computeDisabled,
    },
    shimMax: { type: 'number', category: 1, defaultValue: 0, disabled: true },
    normalX: { type: 'number', category: 1, defaultValue: 0, disabled: true },
    normalY: { type: 'number', category: 1, defaultValue: 0, disabled: true },
    normalZ: { type: 'number', category: 1, defaultValue: 0, disabled: true },
    posX: { type: 'number', category: 1, defaultValue: 0, disabled: true },
    posY: { type: 'number', category: 1, defaultValue: 0, disabled: true },
    posZ: { type: 'number', category: 1, defaultValue: 0, disabled: true },
    ...defaultFields,
    ...getHiddenFields([
      'passlineCoord',
      'widthCoord',
      'thicknessCoord',
      'segmentGroupMountLogId',
      'supportPointId',
    ]),
  } as Record<string, any>,
}
/* eslint-enable key-spacing */
