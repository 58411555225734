import type { PassedData } from '@/react/Caster'
import DrawUtil from '@/three/logic/DrawUtil'
import Util from '@/three/logic/Util'
import type { DrawableCasterElementTypes } from '@/types/data'
import type { ElementMaps } from '@/types/state'
import { ElementsUtil } from '@/Util/ElementsUtil'

export default class UpdateTransformHandler {
  public static updateTransformDrawableElementsAndPhantoms (
    elementList: any,
    phantomElementList: any,
    elementMaps: ElementMaps,
  ) {
    const drawableElementsPaths = ElementsUtil.getDrawableElementPaths(elementMaps)
    const drawableTypes = Object.keys(drawableElementsPaths) as DrawableCasterElementTypes[]

    for (let i = 0; i < drawableTypes.length; i++) {
      const type = drawableTypes[i]!
      const paths = drawableElementsPaths[type]

      for (let j = 0; j < paths.length; j++) {
        const path = paths[j]!

        if (
          type !== 'Segment' &&
          DrawUtil.isDrawable(type) &&
          elementList[type] &&
          elementList[type][path] &&
          elementList[type][path].updateTransform
        ) {
          elementList[type][path].updateTransform()

          if (
            Util.isPhantom(type) &&
            phantomElementList[type] &&
            phantomElementList[type][path]
          ) {
            phantomElementList[type][path].updateTransform()
          }
        }
      }
    }
  }

  public static updateTransformNewPhantoms (phantomElementList: any) {
    Util.phantomTypes.forEach(type => {
      if (Object.keys(phantomElementList[type] ?? {}).includes('new')) {
        phantomElementList[type].new.updateTransform()
      }
    })
  }

  public static updateTransformElements (elementList: any, data: PassedData) {
    const { elementMaps } = data

    const drawableElementsPaths = ElementsUtil.getDrawableElementPaths(elementMaps)
    const drawableTypes = Object.keys(drawableElementsPaths) as DrawableCasterElementTypes[]

    for (let i = 0; i < drawableTypes.length; i++) {
      const type = drawableTypes[i]!
      const paths = drawableElementsPaths[type]

      // don't redraw support points on mold update
      if (type === 'SupportPoint') {
        continue
      }

      for (let j = 0; j < paths.length; j++) {
        const path = paths[j]!

        if (
          type !== 'Segment' &&
          DrawUtil.isDrawable(type) &&
          elementList[type] &&
          elementList[type][path] &&
          elementList[type][path].updateTransform
        ) {
          elementList[type][path].updateTransform()
        }
      }
    }
  }

  public static updateTransformSegments (containerListSegment: any, elementListSegment: any) {
    Object.keys(containerListSegment ?? {}).forEach(path => {
      elementListSegment[path].updateTransform()
    })
  }

  public static updateTransformDataPoints (strand: any, dataPoints: any) {
    strand?.children?.forEach((child: any) => {
      if (child.type === 'DataPoint' && dataPoints[child.path]) {
        dataPoints[child.path].updateTransform()
      }
    })
  }

  public static updateTransformDataLines (strand: any, dataLines: any) {
    strand?.children?.forEach((child: any) => {
      const { type, path } = child?.userData ?? {}

      const dataLine = dataLines[path]

      if (type === 'DataLine' && dataLine) {
        dataLine.updateTransform()
      }
    })
  }
}
