import cloneDeep from 'lodash/cloneDeep'
import { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'redux'

import Button from '@/react/components/Button/index'
import FeatureFlags from '@/react/FeatureFlags/index'
import Input from '@/react/specific/Input'
import * as ApplicationActions from '@/store/application/main/actions'
import type { DefaultState } from '@/types/state'

import Logic, { T as T_PARENT } from './Logic'
import { FileName } from './styles'

const T = `${T_PARENT}.strandGuide`

const connector = connect((state: DefaultState) => ({
  authenticationData: state.application.main.authenticationData,
  currentSimulationCase: state.application.main.currentSimulationCase,
  caseLocks: state.application.main.caseLocks,
}), {
  setCurrentSimulationCase: ApplicationActions.setCurrentSimulationCase,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  t(key: string, params?: Record<string, unknown>): string
}

type State = {
  uploadType: string
}

export class StrandGuide extends Component<Props, State> {
  public override state: State = {
    uploadType: '',
  }
  
  public override componentDidMount () {
    this.handleData()
  }
  
  public override componentDidUpdate () {
    this.handleData()
  }

  private readonly handleData = () => {
    if (this.state.uploadType !== '') {
      return
    }

    // FIXME: this type comes from a .d.ts but requires an import for "nozzles" to have a "uploadType" field
    const { currentSimulationCase: { inputData: { strandGuide: { uploadType } } } } = this.props as any

    if (!uploadType) {
      return
    }

    this.setState({ uploadType })
  }

  private readonly handleChangeUploadType = (event: any) => this.setState({ uploadType: event.target.value })

  private readonly handleUploadStrandGuideProjectFile = () => {
    const { uploadType: uType } = this.state
    const { currentSimulationCase, setCurrentSimulationCase } = this.props

    Logic.handleUpload('.prj', 'strandGuide', 'fileProject', currentSimulationCase, setCurrentSimulationCase, uType)
  }

  private readonly handleUploadStrandGuideMA1File = () => {
    const { uploadType } = this.state
    const { currentSimulationCase, setCurrentSimulationCase } = this.props

    Logic.handleUpload('.dat', 'strandGuide', 'fileMA1', currentSimulationCase, setCurrentSimulationCase, uploadType)
  }

  private readonly handleUploadStrandGuideMA6File = () => {
    const { uploadType } = this.state
    const { currentSimulationCase, setCurrentSimulationCase } = this.props

    Logic.handleUpload('.csv', 'strandGuide', 'fileMA6', currentSimulationCase, setCurrentSimulationCase, uploadType)
  }

  private readonly handleGenerate = async () => {
    const { uploadType } = this.state
    const { currentSimulationCase, setCurrentSimulationCase } = this.props

    const data = await Logic.handleGenerateStrandGuide(currentSimulationCase.id, uploadType)

    if (Object.keys(data).length === 0) {
      return
    }

    const simulationCase = cloneDeep(currentSimulationCase)

    simulationCase.inputData['strandGuide'] = data

    setCurrentSimulationCase(simulationCase)
  }
  
  public override render () {
    const { uploadType } = this.state
    const { authenticationData, currentSimulationCase, caseLocks, t } = this.props
    const {
      inputData: { strandGuide, nozzles },
      simulationStartedAt,
    } = currentSimulationCase
    const { fileMA1, fileMA6, fileProject, generateStartedBy } = strandGuide ?? {}
    const { generateStartedBy: otherGenerateStartedBy } = nozzles ?? {}

    const uploadTypeSelectors = [
      { key: '1', value: t(`${T}.uploadType.1`) },
      { key: '2', value: t(`${T}.uploadType.2`) },
      { key: '3', value: t(`${T}.uploadType.3`) },
    ]

    const noFile = t(`${T_PARENT}.noFile`)

    const { isLocked: generalLocked } = FeatureFlags.getLockedStatus('General', authenticationData, caseLocks)
    const { isLocked: rollerLocked } = FeatureFlags.getLockedStatus('Roller', authenticationData, caseLocks)
    const { isLocked: nozzlesLocked } = FeatureFlags.getLockedStatus('Nozzles', authenticationData, caseLocks)

    const isLocked = generalLocked || rollerLocked || nozzlesLocked

    const disabled = Boolean(simulationStartedAt) ||
      Boolean(generateStartedBy) ||
      Boolean(otherGenerateStartedBy) ||
      isLocked ||
      (uploadType === '1' && !fileProject) ||
      (uploadType === '2' && !fileMA1) ||
      (uploadType === '3' && (!fileMA1 || !fileMA6))

    return (
      <div>
        <Input
          name='uploadType'
          type='select'
          label={t(`${T}.uploadType.label`)}
          title={t(`${T}.uploadType.title`)}
          value={uploadType}
          selectors={uploadTypeSelectors}
          onChange={this.handleChangeUploadType}
        />
        <div>
          {
            uploadType === '1' &&
            (
              <div>
                <Button
                  title={t(`${T}.uploadProjectFile.title`)}
                  onClick={this.handleUploadStrandGuideProjectFile}
                  disabled={simulationStartedAt}
                  type='secondary'
                  icon='pe-7s-cloud-upload'
                  textPaddingLeft
                >
                  {t(`${T}.uploadProjectFile.label`)}
                </Button>
                <FileName title={fileProject ?? noFile}>{fileProject ?? noFile}</FileName>
              </div>
            )
          }
          {
            (uploadType === '2' || uploadType === '3') &&
            (
              <div>
                <Button
                  title={t(`${T}.uploadMA1File.title`)}
                  onClick={this.handleUploadStrandGuideMA1File}
                  disabled={simulationStartedAt}
                  type='secondary'
                  icon='pe-7s-cloud-upload'
                  textPaddingLeft
                >
                  {t(`${T}.uploadMA1File.label`)}
                </Button>
                <FileName title={fileMA1 ?? noFile}>{fileMA1 ?? noFile}</FileName>
              </div>
            )
          }
          {
            uploadType === '3' &&
            (
              <div>
                <Button
                  title={t(`${T}.uploadMA6File.title`)}
                  onClick={this.handleUploadStrandGuideMA6File}
                  disabled={simulationStartedAt}
                  type='secondary'
                  icon='pe-7s-cloud-upload'
                  textPaddingLeft
                >
                  {t(`${T}.uploadMA6File.label`)}
                </Button>
                <FileName title={fileMA6 ?? noFile}>{fileMA6 ?? noFile}</FileName>
              </div>
            )
          }
        </div>
        <Button
          title={isLocked ? t(`${T}.generate.titleLocked`) : t(`${T}.generate.title`)}
          onClick={this.handleGenerate}
          disabled={disabled}
          loading={generateStartedBy}
          noSuccessIcon
          type='secondary'
          bold
        >
          {t(`${T}.generate.label`)}
        </Button>
      </div>
    )
  }
}

export default compose<any>(withTranslation('application'), connector)(StrandGuide)
