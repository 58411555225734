import { filterActions } from 'redux-ignore'

import type { DataState } from '@/types/data'

import { dataActionList, DataActionsEnum, initialState } from './consts'
import additionalDataReducers from './reducers/additionalDataReducers'
import editionReducers from './reducers/editionReducers'

// eslint-disable-next-line @typescript-eslint/ban-types
const actionHandlers: Record<string, Function | undefined> = {
  ...additionalDataReducers,
  ...editionReducers,

  [DataActionsEnum.ACTION_RESET_GAP_WARNINGS]: (state: DataState) => ({
    ...state,
    gapWarnings: initialState.gapWarnings,
  }),
}

function dataReducers (state: DataState = initialState, action: any) {
  const handler = actionHandlers[action.type]

  return handler ? handler(state, action) : state
}

export default filterActions(dataReducers, dataActionList) as typeof dataReducers
