/* eslint-disable camelcase */
/* eslint-disable key-spacing */
import { defaultFields, getHiddenFields } from './defaults'

export const RollerBearing = {
  categories: {
    1: 'geometry',
    2: 'process',
  },
  fields: {
    width: { type: 'number', category: 1, defaultValue: 0, positive: true },
    widthCoord: { type: 'number', category: 1, defaultValue: 0 },
    passlineCoord: { type: 'number', category: 1, defaultValue: 0, disabled: true, positive: true },
    // id:            { type: 'number', category: 2, defaultValue: 0, disabled: true },
    name: { type: 'text', category: 2, defaultValue: '' },
    ...defaultFields,
    ...getHiddenFields([
      'rollerBearingId',
      'rollerMountLogId',
      'thicknessCoord',
    ]),
  },
}
/* eslint-enable key-spacing */
