import type { GridSortItem } from '@mui/x-data-grid'
import isEqual from 'lodash/isEqual'
import { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'redux'

import Button from '@/react/components/Button'
import Input from '@/react/specific/Input'
import { FormWrapper, InputWrapper } from '@/react/visualization/dashboard/Dialogs/DialogStyles'
import VisUtil from '@/react/visualization/VisUtil'
import * as VisualizationActions from '@/store/visualization/actions'
import type { DefaultState } from '@/types/state'
import type { Translation } from '@/types/translation'
import type { PlotConfig, TileConfig } from '@/types/visualization'

import { getAllKeysFromMountLogs } from '../../Plots/TablePlot/TablePlotUtil'

const connector = connect(({ visualization, temporalData }: DefaultState) => ({
  plotConfigs: visualization.plotConfigs,
  currentTileId: visualization.currentTileId,
  tileConfigs: visualization.tileConfigs,
  temporalData,
  data: visualization.data,
  editDialogConfigId: visualization.editDialogConfigId,
}), {
  saveTileConfig: VisualizationActions.saveTileConfig,
  showConfigDialog: VisualizationActions.showConfigDialog,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  fullscreen: boolean
  t: Translation
}

type State = {
  config: any
  tileConfig: TileConfig
  realDataUUID: string
  tableColumns: string[]
}

class TablePreferences extends Component<Props, State> {
  private timeoutRef?: number

  public constructor (props: Props) {
    super(props)

    const { editDialogConfigId, plotConfigs, currentTileId, tileConfigs, temporalData, data } = props
    const { configId } = tileConfigs[currentTileId ?? ''] ?? {}
    const allConfigIds = VisUtil.getConfigSelectors(data, plotConfigs)
    const plotConfig = plotConfigs[configId ?? editDialogConfigId] ?? {} as Partial<PlotConfig>
    const filter = plotConfig.filter ?? ''

    if (!filter) {
      // eslint-disable-next-line no-console
      console.error('No filter in plotConfig')
    }

    const [ , realDataUUID ] = filter.split('#realDataUUID=')

    if (realDataUUID?.length !== 36) {
      // eslint-disable-next-line no-console
      console.error('No realDataUUID in filter')

      return
    }

    const config = allConfigIds.find((config) => config.key === (configId ?? editDialogConfigId))
    const tileConfig = tileConfigs[currentTileId ?? ''] ?? {} as TileConfig
    const realUUIDData = temporalData[realDataUUID]

    if (!realUUIDData) {
      // eslint-disable-next-line no-console
      console.error('No realDataUUID in temporalData')

      return
    }

    const tableColumns = getAllKeysFromMountLogs(realUUIDData)

    this.state = {
      config,
      tileConfig,
      realDataUUID: realDataUUID ?? '',
      tableColumns,
    }
  }

  public override componentDidUpdate = (_pervProps: Props, prevState: State) => {
    const { saveTileConfig } = this.props
    const { tileConfig } = this.state

    if (!isEqual(prevState.tileConfig, tileConfig ?? {})) {
      clearTimeout(this.timeoutRef)

      this.timeoutRef = window.setTimeout(() => {
        saveTileConfig(tileConfig)
        delete this.timeoutRef
      }, 100)
    }
  }

  public override componentWillUnmount () {
    const { saveTileConfig } = this.props
    const { tileConfig } = this.state

    if (this.timeoutRef) {
      clearTimeout(this.timeoutRef)
      saveTileConfig(tileConfig)
    }
  }

  private readonly handleColumnVisibilityChange = (isVisible: boolean, field: string) => {
    const { tileConfig } = this.state
    const { saveTileConfig } = this.props

    const updatedTileConfig = {
      ...tileConfig,
      hiddenColumns: {
        ...tileConfig.hiddenColumns,
        [field]: !isVisible,
      },
    }

    if (isVisible) {
      delete updatedTileConfig.hiddenColumns[field]
    }

    this.setState({ tileConfig: updatedTileConfig })
    saveTileConfig(updatedTileConfig)
  }

  private readonly handleSortingChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { tileConfig } = this.state
    const { saveTileConfig } = this.props
    const { value } = event.target
    const oldSortingDirection = tileConfig.sortingOfColumns?.[0]?.sort ?? 'asc'

    const updatedTileConfig = {
      ...tileConfig,
      sortingOfColumns: [
        {
          field: value,
          sort: oldSortingDirection,
        } as GridSortItem,
      ],
    }

    this.setState({ tileConfig: updatedTileConfig })
    saveTileConfig(updatedTileConfig)
  }

  private readonly handleSortingDirectionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { tileConfig } = this.state
    const { saveTileConfig } = this.props
    const { value } = event.target

    const updatedTileConfig = {
      ...tileConfig,
      sortingOfColumns: [
        {
          field: tileConfig.sortingOfColumns?.[0]?.field ?? 'none',
          sort: value,
        } as GridSortItem,
      ],
    }

    this.setState({ tileConfig: updatedTileConfig })
    saveTileConfig(updatedTileConfig)
  }

  private readonly handleResetSorting = () => {
    const { tileConfig } = this.state
    const { saveTileConfig } = this.props

    const updatedTileConfig = {
      ...tileConfig,
      sortingOfColumns: [],
    }

    this.setState({ tileConfig: updatedTileConfig })
    saveTileConfig(updatedTileConfig)
  }

  public override render () {
    const {
      fullscreen,
      t,
    } = this.props

    const { tileConfig } = this.state

    const {
      hiddenColumns,
      sortingOfColumns,
    } = tileConfig

    const hiddenColumnsArray = Object.keys(hiddenColumns ?? {})

    const columns = this.state.tableColumns.map(key => ({
      key,
      value: key,
    }))

    const amountOfHiddenColumns = hiddenColumnsArray.length

    hiddenColumnsArray.forEach((key) => {
      const index = columns.findIndex(column => column.key === key)

      if (index === -1) {
        columns.push({
          key,
          value: key,
        })
      }
    })

    columns.sort((a, b) => a.key.localeCompare(b.key))

    const shownColumns = columns.filter(column => !(hiddenColumns ?? {})[column.key]).map(column => column.key)
    const sortingDirectionSelectors = [
      {
        key: 'asc',
        value: 'ASC',
      },
      {
        key: 'desc',
        value: 'DESC',
      },
    ]

    const sortBySelectors = [
      { key: 'none', value: 'None' },
      ...columns,
    ]

    return (
      <FormWrapper $fullscreen={fullscreen}>
        <InputWrapper $fullscreen={fullscreen}>
          <Input
            value={[]}
            renderValue={
              () => (
                <>
                  <span
                    style={{ fontSize: '14px', paddingLeft: '2px', position: 'relative', top: '1px' }}
                  >
                    {amountOfHiddenColumns} {t('plotConfig.tablePreferences.hiddenColumns')}
                  </span>
                </>
              ) as any
            }
            noMarginTop
            name='comparisonCaster'
            type='select'
            selectors={columns}
            selectedIds={shownColumns}
            onChange={() => null}
            onCheckboxClick={this.handleColumnVisibilityChange}
            spaceBetween
            checkbox
            multiple
            label='Shown Columns'
            MenuProps={
              {
                getContentAnchorEl: null,
                anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
              }
            }
          />
        </InputWrapper>
        <InputWrapper $fullscreen={fullscreen} $half style={{ alignItems: 'flex-end' }}>
          <Input
            label='Sort by'
            name='sortingOfColumns'
            type='select'
            value={sortingOfColumns?.[0]?.field ?? 'none'}
            selectors={sortBySelectors}
            oneThird
            onChange={this.handleSortingChange}
          />
          <Input
            label='Direction'
            name='sortingDirection'
            type='select'
            value={sortingOfColumns?.[0]?.sort ?? 'asc'}
            selectors={sortingDirectionSelectors}
            oneThird
            onChange={this.handleSortingDirectionChange}
          />
          <Button
            title='Reset Sorting'
            type='primary'
            disabled={false}
            onClick={this.handleResetSorting}
            oneThird
            style={{ alignSelf: 'flex-end', width: '100px' }}
          >
            {t('plotConfig.tablePreferences.resetSorting')}
          </Button>
        </InputWrapper>
      </FormWrapper>
    )
  }
}

export default compose<any>(withTranslation('visualization'), connector)(TablePreferences)
