import cloneDeep from 'lodash/cloneDeep'

import { getCurrentDashboardEntry } from '@/App/util'
import { useConfig } from '@/config'
import Util from '@/logic/Util'
import { ExecutionStepState } from '@/react/dialogs/executables/enums'
import FeatureFlags from '@/react/FeatureFlags'
import { ApplicationMainActionsEnum } from '@/store/application/main/consts'
import { updateElement, updateElementMaps } from '@/store/elements/actions'
import { getElementMapsObject } from '@/store/elements/logic'
import {
  getDifferenceElement,
  handleCopyPaths,
  handleRepeatPaths,
  handleSuccessfullySavedPaths,
} from '@/store/elements/logic/index'
import { setFilterValues } from '@/store/filter/actions'
import FilterHandler from '@/three/logic/FilterHandler'
import ThreeUtil from '@/three/logic/Util'
import type { CasterElementNames, Catalog } from '@/types/data'
import type { CasterDialogElementType } from '@/types/filter'
import type { DefaultState, ElementMaps, TagName, Update } from '@/types/state'
import { ElementMapsUtil } from '@/Util/ElementMapsUtil'
import { Mapping } from '@/Util/mapping/Mapping'

import { DataActionsEnum } from './consts'
import { getElementTypeURL, handleUpdateResponse, prepareNewElements } from './logic'
import { setError } from '../application/error/actions'
import {
  resetLoadingCursor,
  setApplyLoadingCursor,
  setCloneLoadingCursor,
  setCreateLoadingCursor,
  setMirrorLoadingCursor,
  setPatternLoadingCursor,
} from '../LoadingStore'
import { updateModules } from '../modules'

function resetHasChanges () {
  return {
    type: DataActionsEnum.ACTION_RESET_CHANGES,
  }
}

function resetReducer (skipVisualization = false, skipExecutables = false, skipCatalog = false) {
  FilterHandler.filteredElementsByReferencedCase = {}

  return {
    type: DataActionsEnum.ACTION_RESET_ALL,
    skipVisualization,
    skipExecutables,
    skipCatalog,
  }
}

function addPendingDeleteList (path: Array<any> = []) {
  return {
    type: DataActionsEnum.ACTION_ADD_PENDING_DELETE_LIST,
    path,
  }
}

function setEditValues (editValues: any) {
  return {
    type: DataActionsEnum.ACTION_EDIT_VALUES,
    editValues,
  }
}

function getMountLogIdsFromPaths (
  paths: string[],
  elementMaps: ElementMaps,
  elementType: TagName,
) {
  const mountLogTypesAndIds: any = []

  paths.forEach(path => {
    const { type, id: numericId } = ThreeUtil.getElementInfo(path)

    if (type && type === elementType && !Number.isNaN(numericId)) {
      // TODO: TagName is not ElementName so Data-/SensorPoint is not a valid type
      const mountLogType = `${type}MountLog` as keyof ElementMaps

      if (elementMaps[mountLogType]) {
        const mountLogId = Mapping.mountLogIdByTypeAndNumericId[type][numericId]

        if (mountLogId) {
          mountLogTypesAndIds.push(mountLogId)
        }
      }
    }
  })

  return mountLogTypesAndIds
}

function saveElement (
  paths: string[],
  targetArray: any[],
  actionType: 'delete' | 'update',
  elementType: TagName,
  comments?: any,
) {
  return {
    types: [
      DataActionsEnum.ACTION_SAVE_ELEMENT_REQUEST,
      DataActionsEnum.ACTION_SAVE_ELEMENT_SUCCESS,
      DataActionsEnum.ACTION_SAVE_ELEMENT_ERROR,
    ],
    promise: (
      client: any,
      {
        data,
        application: { main: { currentSimulationCase: { id: caseId }, authenticationData } },
        ...remainingState
      }: DefaultState,
      dispatch: any,
    ) => {
      const elementMaps = getElementMapsObject(remainingState as DefaultState)

      // only send the elements that are going to be saved not just the whole state
      const editElements = Object
        .keys(cloneDeep(data.editElements))
        .filter(key => paths.includes(key))
        .reduce((obj, key) => {
          obj[key] = data.editElements[key]

          return obj
        }, {} as Record<string, any>)

      // Object.keys(editElements).forEach(key => {
      //   delete editElements[key]['#parent']
      // })
      if (elementType) {
        dispatch(setApplyLoadingCursor(elementType))
      }

      let mountLogIds: string[] = []

      if (actionType === 'delete') {
        mountLogIds = getMountLogIdsFromPaths(paths, elementMaps, elementType)
      }

      const mountLogEditElements: any = {}

      Object.keys(editElements).forEach(path => {
        const mountLogId = Mapping.mountLogIdByElementPath[path]

        if (!mountLogId) {
          return
        }

        mountLogEditElements[mountLogId] = editElements[path]
      })

      // FIXME: since we are using the new API it is impossible not to be logged in!
      return window.isElectron && (!authenticationData || !authenticationData.featureFlags)
        ? Promise
          .resolve({
            paths,
            editElements,
            targetArray,
            elementType,
          })
          .then(result => {
            dispatch(addPendingDeleteList())
            dispatch(resetLoadingCursor())

            return result
          })
        // dprint-ignore
        : client[actionType === 'delete' ? 'del' : 'patch'](
            `${useConfig().apiBaseURL}/${getElementTypeURL(elementType)}`,
            {
              params: {
                caseId,
                casterId: elementMaps.Caster?.id,
              },
              data: {
                // TODO: some of the changed values are strings instead of numbers so make sure all fields are correct
                // TODO: before sending them, otherwise the history will show them as changes.
                // TODO: e.g. changing "80" to 81 and then to 80 yields 80 but as number not as string
                data: actionType === 'delete' ? mountLogIds : { ...mountLogEditElements },
                comments,
                action: actionType,
                targetPath: (targetArray.length > 0) ? Util.getPathFromPathArray(targetArray) : null,
              },
            },
          )
          .then((response: any) => {
            dispatch(addPendingDeleteList())
            dispatch(resetLoadingCursor())

            const dirtyPaths = [ ...data.dirtyPaths ]
            const dirtyDeletePaths = [ ...data.dirtyDeletePaths ]
            const hidePaths = [ ...data.hidePaths ]
            const editVal = { ...data.editElements, ...editElements }
            const loopCounter = { ...data.loopCounter }
            let selectedPaths = new Set<string>(data.selectedPaths)
            const newElementMaps = cloneDeep(elementMaps)

            const parentPath = targetArray && (targetArray.length > 0) ? Util.getPathFromPathArray(targetArray) : null

            selectedPaths = handleSuccessfullySavedPaths(
              paths,
              actionType,
              parentPath,
              targetArray,
              dirtyPaths,
              dirtyDeletePaths,
              selectedPaths,
              hidePaths,
              editVal,
              newElementMaps,
              loopCounter,
            )

            if (actionType === 'delete') {
              selectedPaths = new Set<string>()
            }
            else if (actionType === 'update' && response) {
              handleUpdateResponse(response, newElementMaps, dirtyPaths)
            }

            dispatch(updateElementMaps(newElementMaps))
            dispatch(updateModules(newElementMaps))

            return ({
              elementMaps: newElementMaps,
              dirtyPaths,
              dirtyDeletePaths,
              hidePaths,
              editElements: editVal,
              selectedPaths,
              loopCounter,
              paths,
              hasChanges: true,
            })
          })
          .catch((error: any) => {
            dispatch(setError('caster/apply', error.status))
            dispatch(resetLoadingCursor())

            throw new Error(error)
          })
    },
  }
}

function createElement (
  elements: any[],
  elementType: TagName,
  parentType: TagName,
) {
  return {
    types: [
      DataActionsEnum.ACTION_CREATE_ELEMENT_REQUEST,
      DataActionsEnum.ACTION_CREATE_ELEMENT_SUCCESS,
      DataActionsEnum.ACTION_CREATE_ELEMENT_ERROR,
    ],
    promise: async (client: any, state: DefaultState, dispatch: any) => {
      const {
        data,
        filter,
        application: { main: { currentSimulationCase: { id: caseId } } },
        ...remainingState
      } = state

      dispatch(setCreateLoadingCursor(elementType))

      const elementMaps = getElementMapsObject(remainingState as DefaultState)
      const elementName = ElementMapsUtil.getElementName(elementType, parentType)
      const newElements = prepareNewElements(elements, elementName, elementMaps)

      let response

      try {
        response = await client.post(
          `${useConfig().apiBaseURL}/${getElementTypeURL(elementType)}`,
          {
            params: { caseId, casterId: elementMaps.Caster?.id },
            data: { elements: newElements },
          },
        )
      }
      catch (error: any) {
        dispatch(setError(`caster/create${elementType}`, error.status))
        dispatch(resetLoadingCursor())

        throw new Error(error)
      }

      dispatch(resetLoadingCursor())

      const newElementMaps = cloneDeep(elementMaps)
      const dirtyPaths = [ ...data.dirtyPaths ]
      const filterElement = cloneDeep(filter.filterElement)

      if (response) {
        handleUpdateResponse(response, newElementMaps, dirtyPaths)

        filterElement[elementType as CasterDialogElementType] = {}

        dispatch(setFilterValues(filterElement))
        dispatch(setEditValues({ [elementType]: {} }))
        // FIXME: reset selected parents
      }

      dispatch(updateElementMaps(newElementMaps))
      dispatch(updateModules(newElementMaps))

      return {
        elementMaps: newElementMaps,
        dirtyPaths,
        hasChanges: true,
        // loopCounter, // FIXME: do we need tu update this?
      }
    },
  }
}

function copyElement (
  copyPath: string[],
  parentMountLogTypeKey: string,
  parentMountLogId: string,
  tagName: TagName,
  assignSegmentByPasslineCoord: boolean,
) {
  return {
    types: [
      DataActionsEnum.ACTION_CREATE_ELEMENT_REQUEST,
      DataActionsEnum.ACTION_CREATE_ELEMENT_SUCCESS,
      DataActionsEnum.ACTION_CREATE_ELEMENT_ERROR,
    ],
    promise: async (client: any, state: DefaultState, dispatch: (action: any) => void) => {
      const { data, application: { main: { currentSimulationCase: { id: caseId } } }, ...remainingState } = state

      dispatch(setCloneLoadingCursor(tagName))

      const elementMaps = getElementMapsObject(remainingState as DefaultState)

      const { passlineCoord: diffPasslnCoord, widthCoord: diffWidthCoord } = getDifferenceElement(
        copyPath,
        data.editElements,
        elementMaps,
      )

      const addedElements = handleCopyPaths(
        copyPath,
        elementMaps,
        tagName,
        data.editElements,
        diffPasslnCoord,
        diffWidthCoord,
        parentMountLogTypeKey,
        parentMountLogId,
        assignSegmentByPasslineCoord,
      )

      if (!copyPath.length || !copyPath[0]) {
        throw new Error('No path to copy')
      }

      const { type: parentType } = ThreeUtil.getParentInfo(copyPath[0])
      const elementName = ElementMapsUtil.getElementName(tagName, parentType)
      const newElements = prepareNewElements(addedElements, elementName, elementMaps)

      let response

      try {
        response = await client.post(
          `${useConfig().apiBaseURL}/${getElementTypeURL(tagName as TagName)}`,
          {
            params: { caseId, casterId: elementMaps.Caster?.id },
            data: { elements: newElements },
          },
        )
      }
      catch (error: any) {
        dispatch(setError(`caster/create${tagName}`, error.status))
        dispatch(resetLoadingCursor())

        throw new Error(error)
      }

      dispatch(resetLoadingCursor())

      const newElementMaps = cloneDeep(elementMaps)
      const dirtyPaths = [ ...data.dirtyPaths ]

      if (response) {
        handleUpdateResponse(response, newElementMaps, dirtyPaths)

        dispatch(setEditValues({ [tagName]: {} }))
        // FIXME: reset selected parents
      }

      dispatch(updateElementMaps(newElementMaps))
      dispatch(updateModules(newElementMaps))

      // TODO: deselect element? and/or select new element?

      return {
        elementMaps: newElementMaps,
        dirtyPaths,
        hasChanges: true,
        // loopCounter, // FIXME: do we need tu update this?
      }
    },
  }
}

function saveCatalog (catalog: Catalog, catalogId?: string) {
  if (catalog) {
    catalog.shift()
  }

  return {
    type: DataActionsEnum.ACTION_SAVE_CATALOG,
    catalog,
    catalogId,
  }
}

function addDirtyPath (path: string[] | string) {
  return {
    type: DataActionsEnum.ACTION_ADD_DIRTY_PATH,
    path,
  }
}

function clearDirtyPaths () {
  return {
    type: DataActionsEnum.ACTION_CLEAR_DIRTY_PATHS,
  }
}

function canSelectElement (type: TagName, permissions: Record<string, boolean>) {
  switch (type) {
    case 'DataLine':
      return FeatureFlags.canSelectDataLine(permissions)
    case 'DataPoint':
      return FeatureFlags.canSelectDataPoint(permissions)
    case 'Nozzle':
      return FeatureFlags.canSelectNozzle(permissions)
    case 'Roller':
      return FeatureFlags.canSelectRoller(permissions)
    case 'RollerBody':
      return FeatureFlags.canSelectRollerBody(permissions)
    case 'RollerBearing':
      return FeatureFlags.canSelectRollerBearing(permissions)
    case 'Segment':
      return FeatureFlags.canSelectSegment(permissions)
    case 'SegmentGroup':
    case 'SupportPoint':
      return FeatureFlags.canSelectSupportPoint(permissions)
    default:
      // eslint-disable-next-line no-console
      console.log(`canSelectElement: ${type} not found`)

      return false
  }
}

function filterSelectedDataWithPermissions (
  selectedPaths: (string | undefined)[],
  permissions: Record<string, boolean>,
) {
  const tempArray: string[] = []
  const sensorPointSelectionPermissions: any = {
    MoldFace: FeatureFlags.canSelectSensorPointInMoldFace(permissions),
    RollerBody: FeatureFlags.canSelectSensorPointInRollerBody(permissions),
    RollerBearing: FeatureFlags.canSelectSensorPointInRollerBearing(permissions),
    Roller: FeatureFlags.canSelectSensorPointInRoller(permissions),
    Segment: FeatureFlags.canSelectSensorPointInSegment(permissions),
    Nozzle: FeatureFlags.canSelectSensorPointInNozzle(permissions),
  }

  for (const path of selectedPaths) {
    if (!path) {
      continue
    }

    const { type } = ThreeUtil.getElementInfo(path)

    if (!type) {
      continue
    }

    if (type === 'SensorPoint') {
      const { type: parentType } = ThreeUtil.getParentInfo(path)

      if (!parentType || !sensorPointSelectionPermissions[parentType]) {
        continue
      }

      tempArray.push(path)

      continue
    }

    if (canSelectElement(type, permissions)) {
      tempArray.push(path)
    }
  }

  return tempArray
}

function setSelectedElementPaths (
  selectedData?: string[] | string,
  multiSelect = false,
  massSelect = false,
  clearFirst = false,
) {
  if (!selectedData) {
    return (dispatch: any, getState: any) => {
      const state: DefaultState = getState()

      // if (!state.data.selectedPaths.size) {
      //   return
      // }

      dispatch({
        type: DataActionsEnum.ACTION_SELECT_EDIT_DATA,
        selectedData: null,
        multiSelect,
        massSelect,
        elementMaps: getElementMapsObject(state),
      })
    }
  }

  const list = selectedData instanceof Array ? selectedData : [ selectedData ]

  return (dispatch: any, getState: any) => {
    const state: DefaultState = getState()
    const { viewsObject, currentDashboard, amountOfComparisonCasterColumns } = state.visualization
    const { viewId } = getCurrentDashboardEntry(currentDashboard, viewsObject)
    const viewObject = viewsObject[viewId as string]
    const selectedComparisonCasters = viewObject?.selectedComparisonCasters ?? []
    const permissions = state.application.main.authenticationData.featureFlags ?? {}

    if (
      (amountOfComparisonCasterColumns && (selectedComparisonCasters.length > 0) && selectedData?.length && (
        selectedData.length > 1 ||
        ((multiSelect || massSelect) && selectedData.length === 1 && state.data.selectedPaths.size)
      )) ||
      !FeatureFlags.canSelectSomeElement(permissions)
    ) {
      return
    }

    const filteredSelectedData = selectedData
      ? filterSelectedDataWithPermissions(list, permissions)
      : []

    const validElements = filteredSelectedData.filter(element => Boolean(element))

    if ((validElements.length > 0) && !state.application.main.openDialogs.includes('CasterDialog')) {
      if (state.application.main.openDialogs.includes('PartsWarehouse')) {
        dispatch({
          type: ApplicationMainActionsEnum.ACTION_SET_OPEN_DIALOGS,
          dialogName: 'PartsWarehouse',
        })
      }

      dispatch({
        type: ApplicationMainActionsEnum.ACTION_SET_OPEN_DIALOGS,
        dialogName: 'CasterDialog',
      })
    }

    dispatch({
      type: DataActionsEnum.ACTION_SELECT_EDIT_DATA,
      selectedData: selectedData ? validElements : null,
      multiSelect,
      massSelect,
      clearFirst,
      elementMaps: getElementMapsObject(state),
    })
  }
}

function setElements (path: string[] | string, element?: any, force = false) {
  return (dispatch: any, getState: any) => {
    const state: DefaultState = getState()
    const elementMaps = getElementMapsObject(state)

    dispatch({
      type: DataActionsEnum.ACTION_SET_ELEMENTS,
      path,
      element,
      elementMaps,
      force,
    })
  }
}

function setParentPath (parentPath = {}) {
  return {
    type: DataActionsEnum.ACTION_SET_PARENT_PATH,
    parentPath,
  }
}

function setNewCopiedElementsToDraw (newCopiedElementsToDraw: boolean) {
  return {
    type: DataActionsEnum.ACTION_SET_NEW_ELEMENTS_TO_DRAW,
    newCopiedElementsToDraw,
  }
}

function addDirtyDeletePath (path: string[] | string) {
  return {
    type: DataActionsEnum.ACTION_ADD_DIRTY_DELETE_PATH,
    path,
  }
}

function clearDirtyDeletePaths () {
  return {
    type: DataActionsEnum.ACTION_CLEAR_DIRTY_DELETE_PATHS,
  }
}

function setActiveEditTab (activeEditTab: any) {
  return {
    type: DataActionsEnum.ACTION_SET_ACTIVE_EDIT_TAB,
    activeEditTab,
  }
}

function setEditChanges (hasEditChanges: boolean, elementType: string) {
  return {
    type: DataActionsEnum.ACTION_EDIT_CHANGES,
    hasEditChanges,
    elementType,
  }
}

function validateInput (elementType: string) {
  return {
    type: DataActionsEnum.ACTION_VALIDATION_INPUT,
    elementType,
  }
}

function setCatalogElement (element?: any) {
  return {
    type: DataActionsEnum.ACTION_SET_CATALOG_ELEMENT,
    element,
  }
}

function undoChanges (key: string | null, elementType: string) {
  return {
    type: DataActionsEnum.ACTION_UNDO_ELEMENT_CHANGES,
    key,
    elementType,
  }
}

function repeatElement (
  repeatPath: string[],
  targetArray: any[],
  elementType: CasterElementNames,
  amount: number,
  offset: number,
  mode: string,
  assignSegmentByPasslineCoord: boolean,
) {
  return {
    types: [
      DataActionsEnum.ACTION_CREATE_ELEMENT_REQUEST,
      DataActionsEnum.ACTION_CREATE_ELEMENT_SUCCESS,
      DataActionsEnum.ACTION_CREATE_ELEMENT_ERROR,
    ],
    promise: async (client: any, state: DefaultState, dispatch: (action: any) => void) => {
      const {
        data,
        filter,
        application: { main: { currentSimulationCase: { id: caseId } } },
        ...remainingState
      } = state

      dispatch(mode === 'mirror' ? setMirrorLoadingCursor(elementType) : setPatternLoadingCursor(elementType))

      const elementMaps = getElementMapsObject(remainingState as DefaultState)

      const addedElements: any[] = []
      const { gapWarnings } = handleRepeatPaths(
        repeatPath,
        targetArray,
        elementMaps,
        mode,
        addedElements,
        data.editElements,
        amount,
        offset,
        assignSegmentByPasslineCoord,
      )

      if (!repeatPath.length || !repeatPath[0]) {
        throw new Error('No path to repeat')
      }

      const { type: parentType } = ThreeUtil.getParentInfo(repeatPath[0])
      const elementName = ElementMapsUtil.getElementName(elementType as TagName, parentType)

      const newElements = prepareNewElements(addedElements, elementName, elementMaps)

      let response

      try {
        response = await client.post(
          `${useConfig().apiBaseURL}/${getElementTypeURL(elementType as TagName)}`,
          {
            params: { caseId, casterId: elementMaps.Caster?.id },
            data: { elements: newElements },
          },
        )
      }
      catch (error: any) {
        dispatch(setError(`caster/create${elementType}`, error.status))
        dispatch(resetLoadingCursor())

        throw new Error(error)
      }

      dispatch(resetLoadingCursor())

      const newElementMaps = cloneDeep(elementMaps)
      const dirtyPaths = [ ...data.dirtyPaths ]
      const filterElement = cloneDeep(filter.filterElement)

      if (response) {
        handleUpdateResponse(response, newElementMaps, dirtyPaths)

        filterElement[elementType as CasterDialogElementType] = {}

        dispatch(setFilterValues(filterElement))
        dispatch(setEditValues({ [elementType]: {} }))
        // FIXME: reset selected parents
      }

      dispatch(updateElementMaps(newElementMaps))
      dispatch(updateModules(newElementMaps))

      return {
        elementMaps: newElementMaps,
        dirtyPaths,
        gapWarnings,
        // loopCounter, // FIXME: do we need tu update this?
      }
    },
  }
}

function resetGapWarnings () {
  return {
    type: DataActionsEnum.ACTION_RESET_GAP_WARNINGS,
  }
}

function setAdditionalData (additionalData: any) {
  return {
    types: [
      DataActionsEnum.ACTION_SET_ADDITIONAL_DATA_REQUEST,
      DataActionsEnum.ACTION_SET_ADDITIONAL_DATA_SUCCESS,
      DataActionsEnum.ACTION_SET_ADDITIONAL_DATA_ERROR,
    ],
    promise: (
      client: any,
      { MoldMountLog, Caster }: DefaultState,
      // eslint-disable-next-line @typescript-eslint/ban-types
      dispatch: Function,
    ) => {
      dispatch(setApplyLoadingCursor('General'))

      const moldMountLog = Object.values(MoldMountLog).find(({ casterId }) => casterId === Caster?.id)

      return client
        .patch(`${useConfig().apiBaseURL}/molds/${moldMountLog?.id}/width`, {
          data: additionalData,
        })
        .then(({ moldMountLogId, width }: { moldMountLogId: string, width: number }) => {
          dispatch(setEditChanges(false, 'General'))
          dispatch(resetLoadingCursor())

          dispatch(updateElement('MoldMountLog', moldMountLogId, { width }))

          return ({
            additionalData,
          })
        })
        .catch((error: any) => {
          dispatch(setError(`caster/create${'general'}`, error.status))
          dispatch(setEditChanges(false, 'General'))
          dispatch(resetLoadingCursor())
        })
        .catch((error: any) => {
          dispatch(setError(`caster/create${'general'}`, error.status))
          dispatch(setEditChanges(false, 'General'))

          throw new Error(error)
        })
    },
  }
}

function setCatalogList (catalogList: CaseFile[]) {
  return {
    type: DataActionsEnum.ACTION_SET_CATALOG_LIST,
    catalogList,
  }
}

function setCurrentCatalogId (currentCatalogId?: string) {
  return {
    type: DataActionsEnum.ACTION_SET_CURRENT_CATALOG_ID,
    currentCatalogId,
  }
}

function updateNotification (updatesCount: number, update: Update, clear = false) {
  return {
    type: DataActionsEnum.ACTION_SET_UPDATE_NOTIFICATIONS,
    updatesCount,
    update,
    clear,
  }
}

function applyUpdates () {
  // FIXME: apply create/update/delete via dispatch ...

  return {
    type: DataActionsEnum.ACTION_APPLY_UPDATES,
  }
}

function setLiveMode (liveMode?: boolean) {
  return {
    type: DataActionsEnum.ACTION_SET_LIVE_MODE,
    liveMode,
  }
}

function removeDeletePaths (paths: Array<string>) {
  return {
    type: DataActionsEnum.ACTION_REMOVE_DELETE_PATHS,
    paths,
  }
}

export function getExecutableDefinitions () {
  return {
    types: [
      DataActionsEnum.ACTION_GET_EXECUTABLE_DEFINITIONS_REQUEST,
      DataActionsEnum.ACTION_GET_EXECUTABLE_DEFINITIONS_SUCCESS,
      DataActionsEnum.ACTION_GET_EXECUTABLE_DEFINITIONS_ERROR,
    ],
    promise: (
      client: any,
      _state: DefaultState,
      // eslint-disable-next-line @typescript-eslint/ban-types
      _dispatch: Function,
    ) =>
      client
        .get(`${useConfig().apiBaseURL}/executable/definitions`)
        .then((definitions: ExecutableDefinition[]) => ({
          executableDefinitions: definitions,
        }))
        .catch((error: any) => {
          throw new Error(error)
        }),
  }
}

export function setExecutionState (
  simulationCaseId: string,
  definitionId: string,
  caseId: string,
  state: ExecutionStepState,
  stepId?: string,
) {
  return {
    type: DataActionsEnum.ACTION_SET_EXECUTION_STATE,
    simulationCaseId,
    definitionId,
    caseId,
    state,
    stepId,
  }
}

export function setManyExecutionStates (newValues: Record<string, string>) {
  return {
    type: DataActionsEnum.ACTION_SET_MANY_EXECUTION_STATES,
    newValues,
  }
}

export function setExecutionData (execution: ExecutionEntity | null) {
  return {
    type: DataActionsEnum.ACTION_SET_EXECUTION_DATA,
    execution,
  }
}

export function executeExecutableDefinition (
  projectId: string,
  simulationCaseId: string,
  definitionId: string,
  caseId: string,
  parameterValues: Record<string, any>,
  uuid: string,
  newCaseData: any,
  lastUUIDsUsedInExecutables: string[],
  executionInputs = {},
  executionId?: string,
  stepId?: string,
  chosenCaseOptionId?: string,
) {
  return {
    types: [
      DataActionsEnum.ACTION_EXECUTE_EXECUTABLE_DEFINITION_REQUEST,
      DataActionsEnum.ACTION_EXECUTE_EXECUTABLE_DEFINITION_SUCCESS,
      DataActionsEnum.ACTION_EXECUTE_EXECUTABLE_DEFINITION_ERROR,
    ],
    promise: (
      client: any,
      _state: DefaultState,
      // eslint-disable-next-line @typescript-eslint/ban-types
      _dispatch: Function,
    ) =>
      client
        .post(
          `${useConfig().apiBaseURL}/executable/execute`,
          {
            data: {
              projectId,
              simulationCaseId,
              definitionId,
              caseId,
              parameterValues,
              uuid,
              newCaseData,
              executionInputs,
              executionId,
              stepId,
              chosenCaseOptionId,
              lastUUIDsUsedInExecutables,
            },
          },
        )
        .then((_result: any) => {
          // console.log(result)

          // return {
          // }
        })
        .catch((error: any) => {
          // set execution state as done
          // dispatch(setExecutionState(simulationCaseId, definitionId, caseId, 'done'))

          throw new Error(error)
        }),
  }
}

export default {
  resetHasChanges,
  resetReducer,
  saveElement,
  createElement,
  saveCatalog,
  setCatalogList,
  addDirtyPath,
  clearDirtyPaths,
  setSelectedElementPaths,
  setElements,
  setParentPath,
  copyElement,
  addDirtyDeletePath,
  addPendingDeleteList,
  clearDirtyDeletePaths,
  setActiveEditTab,
  setEditChanges,
  validateInput,
  setEditValues,
  setCatalogElement,
  undoChanges,
  repeatElement,
  resetGapWarnings,
  setAdditionalData,
  setCurrentCatalogId,
  updateNotification,
  setLiveMode,
  applyUpdates,
  removeDeletePaths,
  setNewCopiedElementsToDraw,
}
