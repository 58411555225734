import cloneDeep from 'lodash/cloneDeep'

import { AppState } from '@/store/application/main/consts'
import type { PlotConfig, VisualizationState } from '@/types/visualization'

import { initialState, VisualizationActionsEnum as VisActionsEnum } from '../consts'
import Util from '../util/Util'

const dataReducers = {
  [VisActionsEnum.ACTION_VISUALIZATION_SET_DATA]: (
    state: VisualizationState,
    { data, meta, clear }: { data: any, meta?: unknown, clear?: boolean },
  ) => {
    if (clear) {
      return {
        ...state,
        data: {},
        plotConfigs: {},
      }
    }

    const plotConfigs = meta ? Util.plotConfigMapping(meta, state.plotConfigs) : (state.plotConfigs ?? {})

    return {
      ...state,
      data,
      plotConfigs,
    }
  },

  [VisActionsEnum.ACTION_VISUALIZATION_SET_DATASOURCE]: (
    state: VisualizationState,
    { plotConfigs: newPlotConfigs }: { plotConfigs: PlotConfig },
  ) => {
    const oldConfigs = cloneDeep(state.plotConfigs)
    const keepKeys = Object.keys(newPlotConfigs)

    // remove dynamicDataSource configs from other configs
    Util.removeDynamicDataSourceFromConfigs(oldConfigs, keepKeys)

    const cleanState = Util.updateConfig({
      plotConfigs: { ...oldConfigs, ...newPlotConfigs },
      tileConfigs: state.tileConfigs,
      viewsObject: state.viewsObject,
    })

    return {
      ...state,
      ...cleanState,
    }
  },

  [VisActionsEnum.ACTION_RESET_VIS_DATA]: (state: VisualizationState) => ({
    ...state,
    data: initialState.data,
  }),

  [VisActionsEnum.ACTION_VISUALIZATION_META_INFORMATION]: (
    state: VisualizationState,
    { name, value, appState }: { name: string, value: string, appState: AppState },
  ) => ({
    ...state,
    visualizationMetaInformation: {
      ...state.visualizationMetaInformation,
      [appState]: {
        ...state.visualizationMetaInformation[appState],
        [name]: value,
      },
    },
  }),

  [VisActionsEnum.ACTION_VISUALIZATION_SET_META]: (state: VisualizationState, { meta }: any) => {
    const plotConfigs = meta ? Util.plotConfigMapping(meta, state.plotConfigs) : {}

    return {
      ...state,
      plotConfigs,
    }
  },
}

export default dataReducers
