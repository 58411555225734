import { Texture } from 'three'

export default class Util {
  public static repeatTexture (texture: Texture, textureRepeat: { u: number, v: number }) {
    texture.repeat.set(textureRepeat.u, textureRepeat.v)
  }

  public static offsetTexture (texture: Texture, textureOffset: { u: number, v: number }) {
    texture.offset.set(textureOffset.u, textureOffset.v)
  }

  public static getJumpDirection (jumpUp: boolean | undefined, jumpDown: boolean | undefined) {
    return (jumpUp ? -1 : (jumpDown ? 1 : 0))
  }
}
