import styled, { css } from 'styled-components'

export const Wrapper = styled.div`${() =>
  css`
  display: flex;
`}`

export const Title = styled.div`${({ theme }) =>
  css`
  font-size: 20px;
  color: ${theme['mainFontColor']};
  user-select: none;
`}`

export const Label = styled.div`${({ theme }) =>
  css`
  color: ${theme['mainFontColor']};
  font-size: 14px;
  margin-top: 17px;
  margin-bottom: 10px;
`}`

export const Hr = styled.div`${({ theme }) =>
  css`
  height: 0;
  border-top: 1px solid ${theme['colors'].swatch6};
`}`

export const FullHR = styled.div`${({ theme }) =>
  css`
  height: 0;
  border-top: 1px solid ${theme['colors'].swatch6};
  position: absolute;
  width: calc(900px - 28px - 28px);
  transform: translate(-422px, 0);
  left: 50%;
`}`

export const Column = styled.div<{ theme?: any, $width?: string }>`${({ $width }) =>
  css`
  display: inline-block;
  width: ${$width};
  padding: 0 28px 17px;
  vertical-align: top;
`}`
