import cloneDeep from 'lodash/cloneDeep'
import moment from 'moment'
import { PureComponent, ReactNode } from 'react'

import type { DatasourceResponse } from '@/api/grafana-api'
import PlotWrapper from '@/react/visualization/PlotWrapper'
import { MountLogToKeyUUIDsMap } from '@/store/mountLogToKeyUUIDs'
import FilterHandler from '@/three/logic/FilterHandler'
import ThreeUtil from '@/three/logic/Util'
import type { ElementMaps, TagName } from '@/types/state'
import type { PlotConfig, PlotConfigMap, TileConfigMap } from '@/types/visualization'
import { ElementMapsUtil } from '@/Util/ElementMapsUtil'
import { ElementsUtil } from '@/Util/ElementsUtil'
import { Mapping } from '@/Util/mapping/Mapping'

import { ViewCompareLogic } from '../ViewCompareLogic'
import { ViewLogic } from '../ViewLogic'

type Props = {
  caseIds: string[]
  /**
   * PlotConfig id
   */
  configId: string
  tileId: string
  plotConfigs: PlotConfigMap
  tileConfigs: TileConfigMap
  viewId: string
  elementMaps: ElementMaps
  forceUpdateHandler: () => void
  filterControlVariables: string[]
  canCompareCasters: boolean
  selectedComparisonCaseIds: string[]
  referenceDate: Date
  plotsCompareCasterInformation: Record<string, Partial<ElementMaps>>
  filteredElementCache: Record<string, string[]>
  setTileWarnings: (tileWarnings: Record<string, Record<string, any>>) => void
  mountLogToKeyUUIDsMap: MountLogToKeyUUIDsMap
  casterDataServer: CasterDataServerState
  temporalData: { [realDataUUID: string]: { x: Date, y: number }[] }
  grafanaData: { [key: string]: DatasourceResponse[] }
}

export class DynamicPlot extends PureComponent<Props> {
  private readonly getRealXDomain = (
    xDomain: Domain,
    xValues: number[],
    tileData: any,
    dynamicData: any[],
    minDefined: boolean,
    maxDefined: boolean,
  ): Domain => {
    const isComparingCasters = this.props.selectedComparisonCaseIds?.length
    const { xAxisPadding = 0 } = tileData

    const valuesAreDates = (xValues[0] as any) instanceof Date

    xDomain.sort((a, b) => a - b)

    const realXDomain = (
      !isComparingCasters
        ? ViewLogic.getXDomain(xDomain, xValues, tileData)
        : ViewCompareLogic.getXDomainForDynamicPlotsWithComparisonCasters(xDomain, tileData, dynamicData)
    ) as Domain

    realXDomain.sort((a, b) => a - b)

    if (realXDomain[0] < 0 && realXDomain[1] < 0) {
      realXDomain.reverse()
    }

    if (!valuesAreDates) {
      realXDomain[0] = realXDomain[0] - (minDefined ? 0 : Number(xAxisPadding))
      realXDomain[1] = realXDomain[1] + (maxDefined ? 0 : Number(xAxisPadding))
    }

    return realXDomain
  }

  private readonly getRealYDomain = (
    yDomain: Domain,
    realXDomain: Domain,
    tileData: any,
    dynamicData: any[],
    minDefined: boolean,
    maxDefined: boolean,
  ): Domain => {
    const isComparingCasters = this.props.selectedComparisonCaseIds?.length
    const { yAxisPadding = 0 } = tileData

    const realYDomain = (
      !isComparingCasters
        ? ViewLogic.getYDomain(realXDomain, yDomain, dynamicData) ?? yDomain[0]
        : ViewCompareLogic.getYDomainForDynamicPlotsWithComparisonCasters(realXDomain, yDomain, tileData, dynamicData)
    ) as Domain

    realYDomain[0] = minDefined ? yDomain[0] : realYDomain[0]
    realYDomain[1] = maxDefined ? yDomain[1] : realYDomain[1]

    realYDomain.sort((a, b) => a - b)

    realYDomain[0] = realYDomain[0] - (minDefined ? 0 : Number(yAxisPadding))
    realYDomain[1] = realYDomain[1] + (maxDefined ? 0 : Number(yAxisPadding))

    return realYDomain
  }

  private readonly getDynamicData = (
    type: TagName,
    elements: string[],
    elementMaps: ElementMaps,
    plotConfig: PlotConfig,
    isRefIdLine: boolean,
    comparisonCasterData: any[],
    tileWarnings: Record<string, Record<string, any>>,
    attrX: string,
    attrY: string,
    plotConfigId: string,
  ) => {
    const { mountLogToKeyUUIDsMap, casterDataServer, temporalData, grafanaData } = this.props

    let dynamicData: any[] = []
    let isMultiLinePlot = false
    let dataLinePlotRanges: any

    if (type === 'DataLine') {
      const filterEmpty = !plotConfig.filter

      if (isRefIdLine && comparisonCasterData.length === 0) {
        dynamicData = ViewLogic.getDynamicDataFromDataLines<DataLineSlot, DataLineMountLog>(
          elements,
          elementMaps,
          type,
          attrX,
          attrY,
          filterEmpty,
          isRefIdLine,
        )
      }
      else {
        dynamicData[0] = ViewLogic.getDynamicDataFromDataLines<DataLineSlot, DataLineMountLog>(
          elements,
          elementMaps,
          type,
          attrX,
          attrY,
          filterEmpty,
          isRefIdLine,
        )
      }

      if (comparisonCasterData.length > 0) {
        for (let i = 0; i < comparisonCasterData.length; i++) {
          const data = comparisonCasterData[i]

          // TODO: this does not work for data coming from CasterDataServer
          dynamicData[i + 1] = ViewCompareLogic.getPointsFromComparisonDataLine(
            data.elementMaps,
            attrX,
            attrY,
            data.caseId,
            plotConfig,
            isRefIdLine,
          )

          if (!dynamicData[i + 1].length) {
            if (!tileWarnings[plotConfigId]) {
              tileWarnings[plotConfigId] = {}
            }

            if (!tileWarnings[plotConfigId][data.casterName]) {
              tileWarnings[plotConfigId][data.casterName] = true
            }
          }

          if (!isRefIdLine) {
            dynamicData[i + 1].forEach?.((line: any) => {
              line.forEach?.((point: any) => {
                point.casterName = data.casterName
              })
            })
          }
          else {
            dynamicData[i + 1].forEach?.((point: any) => {
              point.casterName = data.casterName
            })
          }
        }
      }

      isMultiLinePlot = !(isRefIdLine && comparisonCasterData.length === 0)

      if (isRefIdLine) {
        dataLinePlotRanges = {
          x: [
            Math.min(...dynamicData.map((p: any) => p.x)),
            Math.max(...dynamicData.map((p: any) => p.x)),
          ],
          y: [
            Math.min(...dynamicData.map((p: any) => p.y)),
            Math.max(...dynamicData.map((p: any) => p.y)),
          ],
        }
      }
      else {
        dataLinePlotRanges = {
          x: [
            Math.min(...dynamicData.map(d => ViewLogic.getMultiLineMin(d, 'x'))),
            Math.max(...dynamicData.map(d => ViewLogic.getMultiLineMax(d, 'x'))),
          ],
          y: [
            Math.min(...dynamicData.map(d => ViewLogic.getMultiLineMin(d, 'y'))),
            Math.max(...dynamicData.map(d => ViewLogic.getMultiLineMax(d, 'y'))),
          ],
        }
      }

      const auxDynamicData: any[] = []

      if (!isRefIdLine) {
        dynamicData.forEach((d: any) => {
          auxDynamicData.push(...d)
        })

        dynamicData = auxDynamicData
      }
    }
    else if (comparisonCasterData.length > 0) {
      dynamicData[0] = ViewLogic.getDynamicDataFromElements(
        elements,
        elementMaps,
        type,
        attrX,
        attrY,
      )

      for (let i = 0; i < comparisonCasterData.length; i++) {
        const data = comparisonCasterData[i]

        let points: Array<{ x: number, y: number }> = []

        if (data.timestamp) {
          const timestamp = data.timestamp

          points = ViewCompareLogic.getPointsFromDataServerData(
            type,
            elements,
            elementMaps,
            attrX,
            attrY,
            timestamp,
            mountLogToKeyUUIDsMap,
            casterDataServer,
          )
        }
        else {
          points = ViewCompareLogic.getPointsFromComparisonCaster(
            data.elementMaps,
            type,
            attrX,
            attrY,
            plotConfig,
            data.caseId,
          )
        }

        if (points.length === 0) {
          if (!tileWarnings[plotConfigId]) {
            tileWarnings[plotConfigId] = {}
          }

          if (!tileWarnings[plotConfigId][data.casterName]) {
            tileWarnings[plotConfigId][data.casterName] = true
          }

          continue
        }

        dynamicData[i + 1] = points

        if (!dynamicData[i + 1].length) {
          if (!tileWarnings[plotConfigId]) {
            tileWarnings[plotConfigId] = {}
          }

          if (!tileWarnings[plotConfigId][data.casterName]) {
            tileWarnings[plotConfigId][data.casterName] = true
          }
        }

        // add caster name info to every point of line
        for (const el of dynamicData[i + 1]) {
          el.casterName = data.casterName
        }
      }
      // for every comparison caster, add its elements to dynamic data

      isMultiLinePlot = true
    }
    else if (attrX === 'dataOverTime' && plotConfig.filter) {
      const filteredElements = FilterHandler.getFilteredElements(elementMaps, plotConfig.filter, false)
      const firstElementPath = Object.keys(filteredElements ?? {}).find(path => {
        const { type: elementType } = ThreeUtil.getElementInfo(path)

        return elementType === type
      })

      if (!firstElementPath) {
        return { dynamicData, isMultiLinePlot, dataLinePlotRanges }
      }

      const mountLogId = Mapping.mountLogIdByElementPath[firstElementPath]

      if (!mountLogId) {
        return { dynamicData, isMultiLinePlot, dataLinePlotRanges }
      }

      let element: any

      ElementsUtil.getMountLogMapKeysByTagName(type).forEach((key) => {
        if (elementMaps[key]?.[mountLogId]) {
          element = elementMaps[key]?.[mountLogId]
        }
      })

      const realDataUUID = element?.realDataUUID

      if (!realDataUUID || !temporalData[realDataUUID]) {
        return { dynamicData, isMultiLinePlot, dataLinePlotRanges }
      }

      const { type: parentType } = ThreeUtil.getParentInfo(firstElementPath)

      if (!parentType) {
        return { dynamicData, isMultiLinePlot, dataLinePlotRanges }
      }
      
      const fullMountLogElementName = ElementMapsUtil.getElementName(type, parentType)
      const slot = ElementMapsUtil.getSlotByMountLog(fullMountLogElementName, element, elementMaps) as any

      if (!slot) {
        return { dynamicData, isMultiLinePlot, dataLinePlotRanges }
      }

      dynamicData = temporalData[realDataUUID].map((mountLog: any) => ({
        x: new Date(mountLog.mountedAt),
        y: mountLog[attrY] ?? mountLog?.additionalData?.[attrY] ?? slot?.[attrY] ?? slot?.additionalData?.[attrY],
      })).sort((a: any, b: any) => a.x - b.x)
    }
    else if (plotConfig.type === 'grafana') {
      let i = 0

      const { dashboardId, panelId } = plotConfig.data ?? {}

      if (!dashboardId || !panelId) {
        return { dynamicData, isMultiLinePlot, dataLinePlotRanges }
      }

      const key = `${dashboardId}:${panelId}` // 'UID:ID'
      const data = grafanaData?.[key] ?? []

      let xMin = Infinity
      let xMax = -Infinity
      let yMin = Infinity
      let yMax = -Infinity
      const yTarget = []

      for (const trace of data) {
        const dynamicDataTrace = dynamicData[i++] = [] as { x: Date, y: number }[]

        yTarget.push(trace.target)

        for (const data of trace.datapoints) {
          const x = data[1] ?? null
          const y = data[0] ?? null

          if (x === null || y === null) {
            continue
          }

          if (x < xMin) {
            xMin = x
          }

          if (x > xMax) {
            xMax = x
          }

          if (y < yMin) {
            yMin = y
          }

          if (y > yMax) {
            yMax = y
          }

          dynamicDataTrace.push({ x: new Date(x), y })
        }
      }

      isMultiLinePlot = true // TODO: must be true this does not work: data.length > 1
      dataLinePlotRanges = {
        x: [ xMin === Infinity ? 0 : xMin, xMax === -Infinity ? 0 : xMax ],
        y: [ yMin === Infinity ? 0 : yMin, yMax === -Infinity ? 0 : yMax ],
      }

      return { dynamicData, isMultiLinePlot, dataLinePlotRanges, yTarget: yTarget.join(', ') }
    }
    else {
      dynamicData = ViewLogic.getDynamicDataFromElements(elements, elementMaps, type, attrX, attrY)
    }

    return { dynamicData, isMultiLinePlot, dataLinePlotRanges }
  }

  public override render (): ReactNode | null {
    const {
      configId: plotConfigId,
      tileId,
      plotConfigs,
      tileConfigs,
      viewId,
      forceUpdateHandler,
      elementMaps,
      canCompareCasters,
      selectedComparisonCaseIds,
      plotsCompareCasterInformation,
      filteredElementCache,
      filterControlVariables,
      caseIds,
      casterDataServer,
    } = this.props

    const plotConfig = plotConfigs[plotConfigId]
    const tileData = tileConfigs[tileId]

    if (!plotConfig || !tileData) {
      return null
    }

    const tileWarnings: Record<string, Record<string, any>> = {}
    const [ type, attrY ] = plotConfig.selectedY?.split('|') as [ TagName, string ] ?? []
    const [ , attrX ] = plotConfig.selectedX?.split('|') as [ TagName, string ] ?? []

    const elements = ViewLogic
      .getDynamicElementsFromConfig(elementMaps, plotConfig, filteredElementCache, type)
    const comparisonCasterData: any[] = []

    if (canCompareCasters && selectedComparisonCaseIds?.length) {
      for (const caseId of selectedComparisonCaseIds) {
        const data: any = {}

        if (caseId.includes('cds_timestamp')) {
          const compareEntryId = caseId.split('timestamp_')[1]
          const compareEntry = casterDataServer.compareEntries.find(entry => entry.id === compareEntryId)

          data.timestamp = compareEntry?.timestamp
          data.casterName = moment(new Date(Number(data.timestamp))).format('DD/MM/YYYY HH:mm:ss')
          comparisonCasterData.push(data)

          continue
        }

        data.elementMaps = plotsCompareCasterInformation[caseId]

        if (!data.elementMaps) {
          continue
        }

        const isTimestampCaseId = caseId.includes('_')
        const realCaseId = isTimestampCaseId ? caseId.split('_')[0] : caseId
        const casterIndex = caseIds.indexOf(realCaseId ?? '')
        const timestamp = isTimestampCaseId ? caseId.split('_')[1] : undefined

        data.caseId = caseId
        data.casterName = !isTimestampCaseId
          ? `C${casterIndex + 1}`
          : `C${casterIndex + 1} - ${moment(new Date(Number(timestamp))).format('DD/MM/YYYY HH:mm:ss')}`
        comparisonCasterData.push(data)
      }
    }

    const hasFilter = ViewLogic.isDynamicDataSourceWithCurrentPassLnCoordInFilter(plotConfig) ||
      ViewLogic.isDynamicDataSourceWithFilterControlVariableInFilter(plotConfig, filterControlVariables)

    const isRefIdLine = plotConfig.filter?.includes('#ref=')

    const { dynamicData, isMultiLinePlot, dataLinePlotRanges, yTarget } = this.getDynamicData(
      type,
      elements,
      elementMaps,
      plotConfig,
      isRefIdLine,
      comparisonCasterData,
      tileWarnings,
      attrX,
      attrY,
      plotConfigId,
    )

    // TODO: translate
    const xLabel = tileData.xLabel ?? attrX ?? (yTarget ? 'Time' : '')
    const yLabel = tileData.yLabel ?? attrY ?? yTarget

    let hasNoData = false

    if (dynamicData.length === 0) {
      dynamicData.push([ { x: 0, y: 0 }, { x: 0, y: 0 } ])

      hasNoData = true
    }
    else if (dynamicData.every(data => Array.isArray(data) && (data.length === 0)) && hasFilter) {
      // fallback data in order to show plot with no data but have plotly traces which can be used by FastBase
      dynamicData[0].push(...[ { x: 0, y: 0 }, { x: 0, y: 0 } ])

      hasNoData = true
    }

    const yValues = cloneDeep(dynamicData)
      .filter((el: any) => el) // FIXME: @c.bentele with CDS Data this got empty elements ...
      .map((el: any) => el.y)
      .sort((a: any, b: any) => b - a)

    let shapeIds: string[] = []
    const shapeData: any = []

    if (tileData.type === 'line' || tileData.type === 'area' || tileData.type === 'bar') {
      shapeIds = (tileData.shapeIds ?? [])
        .map(shapeId => shapeId.id)
        .filter(shapeId => shapeId !== undefined && shapeId !== '')

      for (const shape of shapeIds) {
        const plot = plotConfigs[shape]

        if (
          shape === undefined ||
          shape === '' ||
          shape === 'passlineCoord' ||
          filterControlVariables.includes(shape) ||
          !plot
        ) {
          continue
        }

        const { selectedY } = plot
        const pipeIndex = selectedY.indexOf('|')
        const type = selectedY.substring(0, pipeIndex)
        const shapeAttrY = selectedY.substring(pipeIndex + 1)

        if (!type || !shapeAttrY) {
          continue
        }

        const shapeElements = ViewLogic.getDynamicElementsFromConfig(elementMaps, plot, {}, type)

        shapeData.push({
          shapeId: shape,
          data: ViewLogic.getShapeDynamicData(shapeElements, elementMaps, shapeAttrY),
        })
      }
    }

    const {
      xDomainMin: xMin,
      xDomainMax: xMax,
      yDomainMin: yMin,
      yDomainMax: yMax,
      followPasslnCoord,
    } = tileData

    const isDataLine = plotConfig.selectedX?.split('|')?.[0] === 'DataLine'

    const isxMinDefined = xMin !== undefined && String(xMin).length > 0 && !followPasslnCoord
    const isxMaxDefined = xMax !== undefined && String(xMax).length > 0 && !followPasslnCoord
    const isyMinDefined = yMin !== undefined && String(yMin).length > 0
    const isyMaxDefined = yMax !== undefined && String(yMax).length > 0

    const xDomain = [
      isxMinDefined
        ? Number(xMin)
        : !Array.isArray(dynamicData[0])
          ? dynamicData[0]?.x ?? 0
          : !(isDataLine && !isRefIdLine)
            ? ViewLogic.getMultiLineMin(dynamicData, 'x')
            : dataLinePlotRanges.x[0],
      isxMaxDefined
        ? Number(xMax)
        : !Array.isArray(dynamicData[0])
          ? dynamicData[dynamicData.length - 1]?.x ?? 0
          : !(isDataLine && !isRefIdLine)
            ? ViewLogic.getMultiLineMax(dynamicData, 'x')
            : dataLinePlotRanges.x[1],
    ] as Domain

    const yDomain = (!isMultiLinePlot
      ? [
        isyMinDefined ? Number(yMin) : yValues[0],
        isyMaxDefined ? Number(yMax) : yValues[yValues.length - 1],
      ]
      : [
        isyMinDefined ? Number(yMin) : ViewLogic.getMultiLineMin(dynamicData, 'y'),
        isyMaxDefined ? Number(yMax) : ViewLogic.getMultiLineMax(dynamicData, 'y'),
      ]) as Domain

    const isComparingCasters = comparisonCasterData.length

    const xValues = !isComparingCasters
      ? dynamicData.map((el: any) => el.x)
      : dynamicData.map(line => line.map((el: any) => el.x)).flat()

    yDomain.sort((a, b) => a - b)

    const realXDomain = this.getRealXDomain(xDomain, xValues, tileData, dynamicData, isxMinDefined, isxMaxDefined)
    const realYDomain = this.getRealYDomain(yDomain, realXDomain, tileData, dynamicData, isyMinDefined, isyMaxDefined)

    return (
      <PlotWrapper
        tileId={tileId}
        key={tileId}
        configId={plotConfigId}
        dynamicData={dynamicData}
        type={tileData.type}
        shapeIds={shapeIds}
        xRange={[ dynamicData[0]?.x ?? 0, dynamicData[dynamicData.length - 1]?.x ?? 0 ]}
        yValueRange={[ yValues[0], yValues[yValues.length - 1] ]}
        valueRange={[ yValues[0], yValues[yValues.length - 1] ]}
        xValues={dynamicData.map((el: any) => el.x)}
        xDomain={hasNoData ? [ 0, 100 ] : realXDomain}
        yDomain={hasNoData ? [ 0, 100 ] : realYDomain}
        xLabel={xLabel}
        yLabel={yLabel}
        flipAxes={plotConfig.flipAxes}
        isDynamicData
        hasNoData={hasNoData}
        isMultiLinePlot={isMultiLinePlot}
        shapeData={shapeData}
        viewId={viewId}
        forceUpdateHandler={forceUpdateHandler}
      />
    )
  }
}
