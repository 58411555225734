/* eslint-disable camelcase */
/* eslint-disable key-spacing */

import type { ElementMaps } from '@/types/state'

import { defaultFields, getHiddenFields } from './defaults'
import { DefinitionHelper } from './DefinitionHelper'

function segTypeValue (element: any, _elementMaps: ElementMaps) {
  if (Object.keys(element).length === 0 || !element.segType) {
    return ''
  }

  // allowed pattern e.g. Seg-3/6 or Seg-35/100

  const secondPart = element.segType.replace(/Seg-/i, '') as string
  const slashIndex = secondPart.indexOf('/')
  const noOrEndSlash = slashIndex === -1 || slashIndex === secondPart.length - 1

  const first = secondPart.substring(0, noOrEndSlash ? secondPart.length : slashIndex).replace(/[^\d]/, '')
  const second = noOrEndSlash ? '' : secondPart.substring(slashIndex + 1).replace(/[^\d]/, '')

  const a = Number(first === '' ? 0 : first)
  const b = Number(second === '' ? 0 : second)

  // ensure smaller number is first
  const x = noOrEndSlash ? a : Math.min(a, b)
  const y = noOrEndSlash ? 0 : Math.max(a, b)

  return `Seg-${x === 0 || Number.isNaN(x) ? '' : x}/${y === 0 || Number.isNaN(y) ? '' : y}`
}

export const SegmentGroup = {
  categories: {
    1: 'geometry',
    2: 'process',
  },
  fields: {
    id: { type: 'number', category: 2, defaultValue: 0, disabled: true, hidden: true },
    name: { type: 'text', category: 2, defaultValue: '', hidden: true },
    passlineCoord: { type: 'number', category: 1, defaultValue: 0, positive: true, hidden: true },
    endPasslineCoord: { type: 'number', category: 1, defaultValue: 0, positive: true, hidden: true },
    startPasslineCoord: { type: 'number', category: 1, defaultValue: 0, positive: true, hidden: true },
    segmentGroupId: { type: 'text', category: 1, defaultValue: '', hidden: true },
    segmentMountLogs: { type: 'text', category: 1, defaultValue: '', hidden: true },
    shimApplied: { type: 'text', category: 1, defaultValue: '', hidden: true },
    shimMarker: { type: 'text', category: 1, defaultValue: '', hidden: true },
    strandGuideMountLogId: { type: 'text', category: 1, defaultValue: '', hidden: true },
    thicknessCoord: { type: 'text', category: 1, defaultValue: '', hidden: true },
    widthCoord: { type: 'text', category: 1, defaultValue: '', hidden: true },
    historyDetailDialogFilter: { type: 'text', category: 2, defaultValue: '', hidden: true },

    segType: {
      type: 'text',
      category: 1,
      defaultValue: '',
      hidden: DefinitionHelper.isRealDataCase,
      generate: segTypeValue,
    },
    segTypeClone: {
      type: 'text',
      category: 1,
      defaultValue: '',
      disabled: true,
      hidden: DefinitionHelper.isBlueprintCase,
    },

    ...getHiddenFields([
      'supportPointMountLogs',
    ]),

    ...defaultFields,
  },
}
/* eslint-enable key-spacing */
