import { filterActions } from 'redux-ignore'

import type { FilterState } from '@/types/filter'

export enum GrafanaDataActionsEnum {
  ACTION_SET_GRAFANA_DATA = 'grafanaData/ACTION_SET_GRAFANA_DATA',
}

const { ACTION_SET_GRAFANA_DATA } = GrafanaDataActionsEnum
// eslint-disable-next-line @typescript-eslint/ban-types
const actionHandlers: Record<string, Function | undefined> = {
  [ACTION_SET_GRAFANA_DATA]: (state: FilterState, action: { data: any }) => ({
    ...state,
    ...action.data,
  }),
}

function grafanaReducers (state = {}, action: { type: string }) {
  const handler = actionHandlers[action.type]

  return handler ? handler(state, action) : state
}

export default filterActions(grafanaReducers, [
  ACTION_SET_GRAFANA_DATA,
]) as typeof grafanaReducers
