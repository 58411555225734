import hoistStatics from 'hoist-non-react-statics'
import hotkeys from 'hotkeys-js'
import { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'redux'

import Input from '@/react/specific/Input'
import * as VisualizationActions from '@/store/visualization/actions'
import type { DefaultState } from '@/types/state'
import { Identifiable } from '@/Util/decorators/Identifiable'

import { Dialog, DialogBackground, Form, Header, I, Title } from './DialogStyles'

const connector = connect((state: DefaultState) => ({
  editDialogDashboardData: state.visualization.editDialogDashboardData,
  viewsObject: state.visualization.viewsObject,
}), {
  showEditDashboardDialog: VisualizationActions.showEditDashboardDialog,
  setDashboardObject: VisualizationActions.setDashboardObject,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  t(key: string, params?: Record<string, unknown>): string
}

type State = {
  name: string
}

export class EditDashboardDialog extends Component<Props, State> {
  @Identifiable('EditDashboardDialog') public static readonly NAME: string

  public override state: State = {
    name: '',
  }
  
  public override componentDidMount () {
    const { editDialogDashboardData: { viewId, dashboardId }, viewsObject } = this.props

    const { name } = viewsObject[viewId ?? '']?.dashboards?.[dashboardId ?? ''] ?? { name: '' }

    this.setState({
      name,
    })

    hotkeys('Escape, Enter', this.handleHotkeys)
  }
  
  public override componentWillUnmount () {
    hotkeys.deleteScope('other')
    hotkeys.unbind('Escape, Enter', this.handleHotkeys)
  }

  private readonly handleHotkeys = (_event?: any) => this.handleClose()

  private readonly handleInput = (event: any) => {
    const { value } = event.target

    this.setState({
      name: value,
    })
  }

  private readonly handleClose = () => {
    const {
      editDialogDashboardData: { viewId, dashboardId },
      viewsObject,
      setDashboardObject,
      showEditDashboardDialog,
    } = this.props

    if (!viewId || !dashboardId) {
      return
    }

    const { name } = this.state

    setDashboardObject(
      viewId,
      {
        ...viewsObject[viewId]?.dashboards?.[dashboardId],
        name,
      },
      dashboardId,
    )

    showEditDashboardDialog()
  }
  
  public override render () {
    const { t } = this.props
    const { name } = this.state

    return (
      <div>
        <DialogBackground />
        <Dialog $height='200px' $half>
          <Header title={t('editDashboardDialog.title')}>
            <Title>{t('editDashboardDialog.label')}</Title>
            <I
              className='pe-7s-close'
              onClick={this.handleClose}
              title={t('editDashboardDialog.close')}
            />
          </Header>
          <Form>
            <Input
              label={t('editDashboardDialog.name.label')}
              title={t('editDashboardDialog.name.title')}
              name='name'
              type='text'
              value={name ?? ''}
              onChange={this.handleInput}
              autoFocus
            />
          </Form>
        </Dialog>
      </div>
    )
  }
}

const composedComponent = compose<any>(withTranslation('visualization'), connector)(EditDashboardDialog)

export default hoistStatics(composedComponent, EditDashboardDialog)
