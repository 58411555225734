import { request } from '@/api'

const PATH = 'nozzle-catalogs'

export function getNozzleCatalogs (caseId: string) {
  const errorText = 'Failed to load nozzle catalogs' // TODO: translate message

  return request<CaseFile[]>('get', PATH, errorText, { params: { caseId } })
}

export function getNozzleCatalogContents (projectId: string, caseId: string, catalogId: string) {
  const errorText = 'Failed to load nozzle catalog contents' // TODO: translate message

  return request<string>('get', `${PATH}/${catalogId}/contents`, errorText, { params: { projectId, caseId } })
}

export function deleteNozzleCatalog (projectId: string, caseId: string, catalogId: string) {
  const errorText = 'Failed to delete nozzle catalog' // TODO: translate message

  return request<void>('del', `${PATH}/${catalogId}`, errorText, { params: { projectId, caseId } })
}
