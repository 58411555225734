import * as THREE from 'three'
import { Vector2, Vector3 } from 'three'

import Util from '@/three/logic/Util'
import PasslineCurve from '@/three/objects/PasslineCurve'
import { StrandSides } from '@/types/elements/enum'

import { CAMERA_X_ANGLE, CAMERA_Y_ANGLE } from '.'

export default class CalculationUtil {
  public static calcTween (
    pos: Vector3,
    target: Vector3,
    xAngle: number | undefined,
    yAngle: number | undefined,
    center: Vector3,
    position: Vector3,
  ) {
    const currentLength = pos.distanceTo(target)
    const targetLength = position.distanceTo(center)

    const currentPosInCenter = pos.clone().sub(target)
    const currentRotations = Util.getCameraRotations(currentPosInCenter)

    if (xAngle === undefined) {
      xAngle = currentRotations.x
    }

    if (yAngle === undefined) {
      yAngle = currentRotations.y
    }

    const tweenData = {
      xC: target.x,
      yC: target.y,
      zC: target.z,
      len: currentLength,
      xR: 0,
      yR: 0,
    }

    const tweenTargetData = {
      xC: center.x,
      yC: center.y,
      zC: center.z,
      len: targetLength,
      xR: xAngle - currentRotations.x,
      yR: Util.getShortestRotation(yAngle - currentRotations.y),
    }

    return { tweenData, tweenTargetData, currentPosInCenter, currentRotations }
  }

  public static calcResize (sectionViewExpanded: boolean, splitMode: number, height: number, width: number) {
    const x = 0
    let y = 0
    let nWidth = width
    let nHeight = height

    const small = 3 / 8
    const large = 9 / 16

    const size = sectionViewExpanded ? large : small

    if (splitMode === 1) {
      nWidth = width * size
      nHeight = Math.max(height, nWidth / size)

      if (nHeight > height) {
        nHeight = height
        nWidth = nHeight * size
      }

      y = (height - nHeight) / 2
    }

    return { x, y, nHeight, nWidth }
  }

  public static calcJumpToSegments (segments: any[], applyCurve: boolean, plHeight: number) {
    const { min, max, sides } = segments.reduce((obj, { userData }) => ({
      ...obj,
      min: Math.min(obj.min, userData.plMinCoord || obj.min),
      max: Math.max(obj.max, userData.plMaxCoord || obj.max),
      sides: {
        ...obj.sides,
        [userData.side]: userData.side,
      },
    }), { min: plHeight, max: 0, sides: {} })

    const plCoord = min !== plHeight ? min + (max - min) / 2 : max + 1
    const height = max - min

    const { position: cPosition, angleX, normal } = PasslineCurve.getInfoAtPlCoord(plCoord)

    Util.flipXZ(cPosition)
    cPosition.multiply(new THREE.Vector3(-1, 1, 1))

    const center = cPosition
    const position = cPosition.clone()

    const b = height / 2
    const beta = 75 / 2
    const alpha = 90 - beta

    const a = (b * Math.sin(alpha * Util.RAD)) / Math.sin(beta * Util.RAD)
    const side = sides.loose || sides.fixed || sides.left || sides.right
    let yAngle = (Util.sides2Angles(side) + CAMERA_Y_ANGLE) * Util.RAD
    let xAngle = CAMERA_X_ANGLE * Util.RAD

    // TODO: dirty hack => use up vector instead...
    let s = 1

    if (applyCurve) {
      // TODO: use up vector...
      let helper = 1 - (angleX / (Math.PI / 2))

      helper = helper > 0 ? helper : 0

      yAngle = (Util.sides2Angles(side) + CAMERA_Y_ANGLE * helper) * Util.RAD

      if (helper < 0.2 && (side === StrandSides.Right || side === StrandSides.Left)) {
        xAngle += 0.8
      }

      if (side === StrandSides.Loose) {
        s = 2 * helper - 1
      }
    }

    position.sub(normal.setLength(-Math.max(4, a + 2)))
    position.sub(center).applyAxisAngle(Util.xAxis, xAngle - angleX).applyAxisAngle(Util.yAxis, yAngle).add(center)

    return { position, center, xAngle: (xAngle - angleX) * s, yAngle, side }
  }

  public static calcSelection (viewport: ViewPort, start: Vector2, end: Vector2) {
    const min = { x: 0, y: 0 }
    const max = { x: 0, y: 0 }
    const widthHalf = viewport.width / 2
    const heightHalf = viewport.height / 2

    min.x = (Math.min(start.x, end.x) - (viewport.x + widthHalf)) / widthHalf
    min.y = (Math.min(start.y, end.y) - (viewport.y + heightHalf)) / heightHalf
    max.x = (Math.max(start.x, end.x) - (viewport.x + widthHalf)) / widthHalf
    max.y = (Math.max(start.y, end.y) - (viewport.y + heightHalf)) / heightHalf

    return { min, max }
  }

  public static getSectionPlaneGeometry (
    thickness: number,
    width: number,
    largestNarrowNozzle: number,
    largestNozzle: number,
    widestNarrowNozzle: number,
    widestNozzle: number,
    largestNarrowRoller: number,
    largestRoller: number,
    widestNarrowRoller: number,
    widestRoller: number,
  ) {
    const moldThickness = thickness
    const moldWidth = width

    const realWidth = Math.max(
      moldThickness / 1000 + largestNozzle * 2,
      widestNarrowNozzle * 2,
      moldThickness / 1000 + largestRoller * 2,
      widestNarrowRoller * 2,
    )

    const height = Math.max(
      (moldWidth / 1000 + largestNarrowNozzle * 2) + 0.5,
      widestNozzle * 2,
      (moldWidth / 1000 + largestNarrowRoller * 2) + 0.5,
      widestRoller * 2,
    )

    const margin = 0.25

    return new THREE.PlaneGeometry(realWidth + margin, height + margin, 1)
  }

  public static plHeightCalculator (pl?: FullCasterElement<PasslineSectionSlot, PasslineSectionMountLog>[]) {
    if (!pl) {
      return 0
    }

    // return the biggest passlineCoord
    return pl.reduce((max, { passlineCoord }) => Math.max(max, passlineCoord ?? 0), 0)
  }

  public static calcGridHelper (min: Vector3, max: Vector3) {
    const dX = max.x - min.x
    const dZ = max.z - min.z
    const length = Math.ceil(Math.max(dX, dZ)) + 10
    const off = dX + dZ === 0 ? 0 : 5
    const calculatedPosition = { x: dX / 2 + min.x, y: min.y - off, z: dZ / 2 + min.z }

    return { length, calculatedPosition }
  }

  public static calcSetView (side: number, camera: THREE.Camera, controls: any, plHeight: number) {
    const height = controls.target.y || plHeight / 2
    const distance = camera.position.distanceTo(controls.target) || plHeight

    let position = new THREE.Vector3(0, 0, -distance)
    let xAngle = 0
    let yAngle = 0

    switch (side) {
      case 5:
        yAngle = 0 // Right
        break
      case 1:
        yAngle = Util.RAD90 // Loose
        break
      case 4:
        yAngle = Util.RAD180 // Left
        break
      case 0:
        yAngle = Util.RAD270 // Fixed
        break
      case 2:
        xAngle = 89.999 * Util.RAD // TOP
        yAngle = Util.RAD270
        break
      case 3:
        xAngle = -89.999 * Util.RAD // Bottom
        yAngle = Util.RAD270
        break
      default:
        return
    }

    const center = new THREE.Vector3(0, height, 0)

    position.applyAxisAngle(Util.xAxis, xAngle)
    position.applyAxisAngle(Util.yAxis, yAngle)

    position = position.add(center)

    return { position, center, xAngle, yAngle }
  }

  public static calcCurrentRotations (positionRaw: any, targetRaw: any) {
    const pos = positionRaw.clone()
    const target = targetRaw.clone()

    const currentPosInCenter = pos.clone().sub(target)
    const currentRotations = Util.getCameraRotations(currentPosInCenter)

    currentRotations.x *= Util.DEG
    currentRotations.y = currentRotations.y * Util.DEG - 270
  }

  public static orderSnapsByDistanceToMouse (camera: THREE.Camera, mouse: Vector2, snaps: any[]) {
    snaps.sort((a, b) => {
      const aPos = CalculationUtil.getPos(a, camera)
      const bPos = CalculationUtil.getPos(b, camera)
      const aDis = aPos.distanceTo(mouse)
      const bDis = bPos.distanceTo(mouse)

      return aDis - bDis
    })
  }

  private static getPos (object: any, camera: THREE.Camera) {
    object.updateMatrixWorld()

    const vector = new THREE.Vector3()

    vector.setFromMatrixPosition(object.matrixWorld)
    vector.project(camera)

    return new THREE.Vector2(vector.x, vector.y)
  }
}
