import styled from 'styled-components'

export const SectionContainer = styled.div`
  padding: 10px 10px 0 10px;
  user-select: none;
`

export const SectionContent = styled.div`
  padding:   15px 0 0 5px;
  color:     #CCCCCC;
  font-size: 13px;
`
