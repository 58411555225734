import { VerificationType } from '@/store/application/main/consts'

export function isVerified (
  caseId: string,
  verifications: CaseVerification[],
  type: VerificationType,
  payload?: string,
): boolean {
  let latestVerification = null

  for (const verification of verifications) {
    if (
      caseId === verification.caseId &&
      verification.type === type &&
      (!latestVerification || verification.createdAt > latestVerification.createdAt) &&
      (!payload || verification.payload === payload)
    ) {
      latestVerification = verification
    }
  }

  return latestVerification?.isVerified ?? false
}

export function allVerified (caseId: string, verifications: CaseVerification[], payload?: string): boolean {
  const casterVerified = isVerified(caseId, verifications, VerificationType.Caster)
  const catalogVerified = isVerified(caseId, verifications, VerificationType.Catalog, payload)
  const parametersVerified = isVerified(caseId, verifications, VerificationType.Parameters)

  return casterVerified && catalogVerified && parametersVerified
}
