import { request } from '@/api'
import DownloadUtil from '@/logicHandlers/ServerLogic/actions/Util'

const PATH = 'executable'

export function getLastSuccessfulExecution (caseId: string, definitionId: string): Promise<ExecutionEntity | null> {
  const errorText = 'Failed to get last successful execution' // TODO: translate message

  return request('get', `${PATH}/latest-execution`, errorText, { params: { caseId, definitionId } }) as any
}

export async function downloadExecutionFile (executionFileId: string): Promise<any> {
  const errorText = 'Failed to download execution file' // TODO: translate message
  const { fileName, buffer } = await request('get', `execution-file/${executionFileId}`, errorText) as {
    fileName: string
    buffer: Buffer
  }

  try {
    await DownloadUtil.openDownloadFileDialog(buffer, fileName)
  }
  catch (error) {
    // eslint-disable-next-line no-console
    console.log(error)
  }
}
