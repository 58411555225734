import isEqual from 'lodash/isEqual'
import { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'redux'

import Input from '@/react/specific/Input'
import VisUtil from '@/react/visualization/VisUtil'
import ApiClient from '@/store/apiClient'
import * as VisualizationActions from '@/store/visualization/actions'
import type { DefaultState } from '@/types/state'
import type { Translation } from '@/types/translation'

import { FormWrapper, InputWrapper } from '../../Dialogs/DialogStyles'

const connector = connect(({ data, visualization, application }: DefaultState) => ({
  currentSimulationCase: application.main.currentSimulationCase,
  editDialogConfigId: visualization.editDialogConfigId,
  currentTileId: visualization.currentTileId,
  tileConfigs: visualization.tileConfigs,
  catalogList: data.catalogList,
}), {
  saveTileConfig: VisualizationActions.saveTileConfig,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  fullscreen: boolean
  t: Translation
}

type State = {
  typeSelectors: Array<any>
  allConfigIds: Array<any>
  config: any
  tileConfig: any
}

class EditableFilesTab extends Component<Props, State> {
  private timeoutRef?: number

  public override state: State = {
    typeSelectors: [],
    allConfigIds: [],
    config: {},
    tileConfig: {},
  }
  
  public override componentDidMount () {
    const { editDialogConfigId, currentTileId, tileConfigs, currentSimulationCase, catalogList } = this.props
    const { configId } = tileConfigs[currentTileId ?? ''] ?? {}

    ApiClient
      .get(`${'Network.URI(deprecated)'}/simulation_case/editable_files/${currentSimulationCase.id}`)
      .then(({ files }) => {
        const typeSelectors = VisUtil.getTypeSelectors('Editable Files')
        const allEditableFiles = VisUtil.getEditableFileSelectors(files)
        const allViewableFiles = VisUtil.getViewableFileSelectors(currentSimulationCase, catalogList)
        const allConfigIds = [ ...allEditableFiles, ...allViewableFiles ]

        const config = allConfigIds.find((config) => config.key === (configId ?? editDialogConfigId))

        this.setState({
          typeSelectors,
          config,
          allConfigIds,
          tileConfig: currentTileId ? { ...tileConfigs[currentTileId] } : {},
        })
      })
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      .catch(() => {})
  }

  public override componentDidUpdate = (_pervProps: Props, prevState: State) => {
    const { saveTileConfig } = this.props
    const { tileConfig } = this.state

    if (!isEqual(prevState.tileConfig, tileConfig ?? {})) {
      clearTimeout(this.timeoutRef)

      this.timeoutRef = window.setTimeout(() => {
        saveTileConfig(tileConfig)
      }, 100)
    }
  }

  public override componentWillUnmount () {
    const { saveTileConfig } = this.props
    const { tileConfig } = this.state

    clearTimeout(this.timeoutRef)
    saveTileConfig(tileConfig)
  }

  private readonly handleInputDataTile = (event: any) => {
    const { name, value } = event.target
    const { tileConfig } = this.state
    const { saveTileConfig } = this.props

    const val: any = value

    const editTileConfig = {
      ...tileConfig,
      [name]: val,
    }

    if (name === 'configId') {
      saveTileConfig({
        ...editTileConfig,
        type: 'edit_box',
      })
    }

    this.setState({
      tileConfig: editTileConfig,
    })
  }
  
  public override render () {
    const { tileConfig, config, allConfigIds } = this.state
    const { editDialogConfigId, fullscreen, t } = this.props
    const { name, configId } = tileConfig

    const sortedConfigIds = allConfigIds.sort((a, b) => a.value.toLowerCase().localeCompare(b.value.toLowerCase()))

    return (
      <FormWrapper $fullscreen={fullscreen}>
        <InputWrapper>
          <Input
            label={t('plotConfig.name.name')}
            name='name'
            type='text'
            title={name ?? ''}
            value={name ?? ''}
            onChange={this.handleInputDataTile}
          />
          <Input
            label={t('plotConfig.configId.label')}
            name='configId'
            type='select'
            title={config && config.value}
            value={configId ?? editDialogConfigId}
            selectors={sortedConfigIds}
            onChange={this.handleInputDataTile}
          />
        </InputWrapper>
      </FormWrapper>
    )
  }
}

export default compose<any>(withTranslation('visualization'), connector)(EditableFilesTab)
