import { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'redux'
import styled from 'styled-components'

import DataActions from '@/store/data/actions'
import type { DefaultState } from '@/types/state'

const Helper = styled.span`
  background-color: inherit;
  border:           none;
  color:            inherit;
  cursor:           pointer;
  font-size:        11px;
  outline:          0;
  max-width:        65px;
  text-overflow:    ellipsis;
  white-space:      nowrap;
  display:          inline-block;
  overflow:         hidden;
  
  &:hover {
    color: #E00A24;
  }
`

const Label = styled.span`
  display:          flex;
  justify-content:  space-between;
  padding-right:    4px;
`

const connector = connect((state: DefaultState) => ({
  editElements: state.data.editElements,
}), {
  setEditChanges: DataActions.setEditChanges,
})

type PropsFromRedux = ConnectedProps<typeof connector>

export interface Props extends PropsFromRedux {
  name: string
  value: any
  title: string
  type: string
  onHelper?: (type: string, value: any) => void
  display?: string
}

export class LabelWithSuggestion extends Component<Props> {
  private readonly handleClick = () => {
    const { onHelper, name, value, setEditChanges, type } = this.props

    if (onHelper) {
      onHelper(name, value)
      setEditChanges(true, type)
    }
  }
  
  public override render () {
    const { name, value, title, display } = this.props

    return (
      <Label title={title || name}>
        {name}
        {
          value &&
          (
            <Helper title={title ?? 'suggestion'} onClick={this.handleClick}>
              {display ?? value}
            </Helper>
          )
        }
      </Label>
    )
  }
}

export default compose<any>(connector)(LabelWithSuggestion)
