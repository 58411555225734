import { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'redux'

import CommandWrapper from '@/react/visualization/PlotWrapper/CommandWrapper'
import ApiClient from '@/store/apiClient'

const mapStateToProps = () => ({})

const mapDispatchToProps = {}

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  selectedCellData: Array<any>
  simulationCaseId: string
  simulationStartedAt?: Date
  name: string
  id: string
  type: string
  t(key: string, params?: Record<string, unknown>): string
}

type State = {
  selectedCommand: any
  commandFileId: string
}

export class CommandEditor extends Component<Props, State> {
  public override state: State = {
    selectedCommand: {},
    commandFileId: '',
  }

  public override async componentDidMount () {
    const { simulationCaseId, name } = this.props

    const { selectedCommand, commandFileId } = await ApiClient.get(
      `${'Network.URI(deprecated)'}/visualization_command/first_command`,
      { params: { simulationCaseId, command: name } },
    )

    this.setState({ selectedCommand, commandFileId })
  }
  
  public override render () {
    const { simulationCaseId, simulationStartedAt, selectedCellData } = this.props
    const { commandFileId, selectedCommand } = this.state

    if (!commandFileId || !selectedCommand || !selectedCommand._id) {
      return null
    }

    return (
      <div style={{ height: '500px' }}>
        <CommandWrapper
          currentSimulationCase={{ id: simulationCaseId, simulationStartedAt }}
          selectedCellData={selectedCellData}
          commandId={selectedCommand._id}
          currentCommand={selectedCommand}
          fileId={commandFileId}
        />
      </div>
    )
  }
}

export default compose<any>(connector)(CommandEditor)
