import application from './application.json'
import caster from './caster.json'
import login from './login.json'
import titlebar from './titlebar.json'
import visualization from './visualization.json'

export default class en {
  public static readonly translation = {
    ...application,
    ...caster,
    ...visualization,
    ...titlebar,
    ...login,
  }
}
