import { request } from '@/api'

const PATH = 'states'

type StateData = {
  data: Record<string, unknown>
  assigneeEmail: string | null
  expiresAt: string | null
  requiresProjectShare: boolean
}

export type ShareState = BaseEntity & StateData & {
  ownerId: string
}

export type ShareStateForList = ShareState & {
  projectName: string
  caseName: string
  configName: string
}

export function getState (id: string) {
  return request<ShareState>('get', `${PATH}/${id}`, '', {}, true)
}

export function getStates () {
  return request<ShareState[]>('get', PATH, 'Failed to get states') // TODO: translate message
}

export function getStatesForList () {
  return request<ShareStateForList[]>('get', `${PATH}/list`, 'Failed to get states') // TODO: translate message
}

export function createState (data: StateData) {
  return request<ShareState>('post', PATH, 'Failed to create state', { data }) // TODO: translate message
}

export function updateState (id: string, data: StateData) {
  return request<boolean>('patch', `${PATH}/${id}`, 'Failed to update state', { data }) // TODO: translate message
}

export function deleteState (id: string) {
  return request<boolean>('del', `${PATH}/${id}`, 'Failed to delete state') // TODO: translate message
}
