import { request } from '@/api'
import type { DynamicDataSource } from '@/types/visualization'

const PATH = 'dynamic-data-sources'

type UpdateDynamicDataSourceBody = {
  name: string
  selectedSet: string
  selectedX: string
  selectedY: string
  filter: string | null
  isVerticalLine: boolean
}

type CreateDynamicDataSourceBody = UpdateDynamicDataSourceBody & {
  visualizationConfigId: string
}

export function deleteDynamicDataSource (
  dynamicDataSourceId: string,
) {
  const errorText = 'Failed to delete dynamic data source' // TODO: translate message

  return request('del', `${PATH}/${dynamicDataSourceId}`, errorText)
}

export function createDynamicDataSource (data: CreateDynamicDataSourceBody): Promise<DynamicDataSource | null> {
  const errorText = 'Failed to create dynamic data source' // TODO: translate message

  return request('post', PATH, errorText, { data })
}

export function updateDynamicDataSource (
  dynamicDataSourceId: string,
  data: UpdateDynamicDataSourceBody,
): Promise<DynamicDataSource | null> {
  const errorText = 'Failed to update dynamic data source' // TODO: translate message

  return request('patch', `${PATH}/${dynamicDataSourceId}`, errorText, { data })
}
