import Util from '@/logic/Util'
import CalculateHelperFunctions from '@/react/context/form/functions/CalculateHelperFunctions'
import ThreeUtil from '@/three/logic/Util'
import type { CasterElementNames, LoopCounter } from '@/types/data'
import type { ElementMaps, ElementName, TagName } from '@/types/state'
import { ElementMapsUtil } from '@/Util/ElementMapsUtil'
import { Mapping } from '@/Util/mapping/Mapping'

import { deleteElement } from './deleters'

function getElementByDataLineRef (ref: string, elementMaps: ElementMaps) {
  const refInfo = ref.split(':')
  const [ type, refId ] = refInfo as [ElementName, string]
  const mountLogMap = elementMaps[`${type}MountLog`]

  if (!mountLogMap || !type || !refId) {
    return null
  }

  for (const mountLogId in mountLogMap) {
    const mountLog = mountLogMap[mountLogId]

    if (mountLog?.additionalData?.['refId'] === refId) {
      return ElementMapsUtil.getFullCasterElementByMountLog(type, mountLog, elementMaps)
    }
  }

  return null
}

export function linkDataLinesWithElements (
  elementMaps: ElementMaps,
  dataLines: DataLineSlot[],
) {
  Object.values(dataLines).forEach((dataLine: any) => {
    if (!dataLine || !dataLine.ref) {
      return
    }

    const { ref } = dataLine

    const element = getElementByDataLineRef(ref, elementMaps)
    const referencedXCoord = Util.fromSnakeToCamelCase(dataLine.xCoords ?? '') as keyof typeof element
    const referencedYValues = Util.fromSnakeToCamelCase(dataLine.yValues ?? '') as keyof typeof element
    const elementReferencedXCoords = element?.[referencedXCoord] ?? element?.additionalData?.[referencedXCoord]
    const elementReferencedYValues = element?.[referencedYValues] ?? element?.additionalData?.[referencedYValues]

    if (
      !element ||
      typeof referencedXCoord !== 'string' ||
      typeof referencedYValues !== 'string' ||
      !elementReferencedXCoords ||
      !elementReferencedYValues
    ) {
      return
    }

    dataLine[String(referencedXCoord)] = elementReferencedXCoords
    dataLine[String(referencedYValues)] = elementReferencedYValues
  })
}

function checkPositivePropertyAndInKeyList (keys: string[], element: any, properties: string[]) {
  for (let i = 0; i < properties.length; i++) {
    if (!(properties[i] && keys.includes(properties[i]!) && element[properties[i]!] > 0)) {
      return false
    }
  }

  return true
}

export function positivePropertiesAndInKeyList (editValues: any, keys: string[], elementType: CasterElementNames) {
  let createValid = false

  switch (elementType) {
    case 'Nozzle':
      if (
        checkPositivePropertyAndInKeyList(keys, editValues.Nozzle, [ 'height', 'angleWidth', 'angleLength' ])
      ) {
        createValid = true
      }

      break
    case 'Roller':
      if (
        checkPositivePropertyAndInKeyList(keys, editValues.Roller, [ 'diameter', 'rollWidth' ])
      ) {
        createValid = true
      }

      break
    case 'RollerBody':
      if (
        checkPositivePropertyAndInKeyList(keys, editValues.RollerBody, [ 'diameter', 'width' ])
      ) {
        createValid = true
      }

      break
    case 'RollerBearing':
      if (
        checkPositivePropertyAndInKeyList(keys, editValues.RollerBearing, [ 'width' ])
      ) {
        createValid = true
      }

      break
    default:
      createValid = false
  }

  return createValid
}

export function getDifferenceElement<Slot extends BaseSlot, MountLog extends BaseMountLog> (
  paths: string[],
  editElements: any,
  elementMaps: ElementMaps,
) {
  let highestElement: any = {}
  let highestElementOriginal = { passlineCoord: Infinity } as FullCasterElement<Slot, MountLog>

  paths.forEach(path => {
    const element = ElementMapsUtil.getFullCasterElementByPath<Slot, MountLog>(path, elementMaps, true)

    if (element && (highestElementOriginal.passlineCoord ?? 0) > (element.passlineCoord ?? 0)) {
      highestElementOriginal = element
      highestElement = { ...editElements[path] }
    }
  })

  const differenceElement: any = { ...highestElement }

  differenceElement.passlineCoord -= highestElementOriginal.passlineCoord ?? 0
  differenceElement.widthCoord -= highestElementOriginal.widthCoord ?? 0

  return differenceElement
}

function getPathArrayFromPath (path: string) {
  return path.split('/').reduce((list: any, part) =>
    (parts => [
      ...list,
      parts[0],
      Number(parts[1]),
    ])(part.split(':')), []) as (number | string)[]
}

export function getSegmentByPasslnAndSide (
  segmentMountLogMap: SegmentMountLogMap,
  segmentSlotMap: SegmentSlotMap,
  passlineCoord: number,
  side: StrandSide,
) {
  const segmentMountLogMapCopy = { ...segmentMountLogMap }

  return Object
    .values(segmentMountLogMapCopy)
    .filter((segmentMountLog) => (
      (segmentSlotMap[segmentMountLog?.slotId ?? '']?.passlineCoord ?? 0) <= passlineCoord &&
      segmentSlotMap[segmentMountLog?.slotId ?? '']?.side === side
    ))
    .sort((a, b) =>
      Number(segmentSlotMap[b?.slotId ?? '']?.passlineCoord) - Number(segmentSlotMap[a?.slotId ?? '']?.passlineCoord)
    )[0]
}

function getSegmentByRollerPassln (
  elementMaps: ElementMaps,
  rollerMountLogs: RollerMountLog[],
  passlineCoord: number,
) {
  let mountLogs = rollerMountLogs.filter(
    rollerMountLog => (elementMaps.RollerSlot[rollerMountLog?.slotId ?? '']?.passlineCoord ?? 0) > passlineCoord,
  )

  mountLogs = CalculateHelperFunctions.sortedMountLogsBySlot(
    'Roller',
    mountLogs,
    elementMaps,
    CalculateHelperFunctions.byPasslineCoordDesc,
  )

  return mountLogs[mountLogs.length - 1]
}

export function getDefaultElement (element: any, elementType: TagName) {
  let newElement = { ...element }

  if (elementType === 'Roller') {
    newElement = {
      ...newElement,
      passlineCoord: newElement.passlineCoord ?? 0,
      RollerBearing: [], // FIXME: does this work, or do we need to add these via API?
      RollerBody: [], // FIXME: does this work, or do we need to add these via API?
    } as RollerMountLog & RollerSlot

    const { rollWidth, diameter, passlineCoord, side } = newElement
    const RollerBody = {
      width: rollWidth,
      widthCoord: (rollWidth / 2) * -1,
      name: 'DSCRollerBody',
      diameter,
      passlineCoord,
      side,
    } as RollerBodyMountLog & RollerBodySlot

    const RollerBearing = [
      {
        width: 100,
        widthCoord: rollWidth / 2,
        name: '',
        passlineCoord,
        side,
      },
      {
        width: 100,
        widthCoord: -(rollWidth / 2) - 100,
        name: '',
        passlineCoord,
        side,
      },
    ] as (RollerBearingMountLog & RollerBearingSlot)[]

    newElement.RollerBody.push(RollerBody) // TODO: make sure new elements get new ids
    newElement.RollerBearing.push(...RollerBearing) // TODO: make sure new elements get new ids
  }

  return newElement
}

function getGapPositions (
  elementMaps: ElementMaps,
  element: any,
  targetArray: any[],
  offset: number,
  amount: number,
) {
  const gapPasslineCoords: number[] = []
  const segmentMountLogId = Mapping.mountLogIdByTypeAndNumericId.Segment[targetArray[3]]
  const segmentMountLog = elementMaps.SegmentMountLog[segmentMountLogId ?? '']
  const segmentSlot = elementMaps.SegmentSlot[segmentMountLog?.slotId ?? '']
  const segmentSide = segmentSlot?.side ?? ''

  const segmentGroupMountLogId = Mapping.mountLogIdByTypeAndNumericId.SegmentGroup[targetArray[1]]

  let rollerMountLogs = CalculateHelperFunctions.getRollerList(
    elementMaps,
    segmentGroupMountLogId ?? '',
    segmentSide,
    [],
  )

  rollerMountLogs = CalculateHelperFunctions.sortedMountLogsBySlot(
    'Roller',
    rollerMountLogs,
    elementMaps,
    CalculateHelperFunctions.byPasslineCoordAsc,
  )

  const startCoord = Number(element.passlineCoord)

  if (offset > 0) {
    rollerMountLogs = rollerMountLogs.filter(rollerMountLog =>
      (elementMaps.RollerSlot[rollerMountLog?.slotId ?? '']?.passlineCoord ?? 0) > startCoord
    )
  }
  else {
    rollerMountLogs = rollerMountLogs
      .filter(rollerMountLog => (elementMaps.RollerSlot[rollerMountLog?.slotId ?? '']?.passlineCoord ?? 0) < startCoord)
      .reverse()
  }

  for (let i = Math.abs(offset) - 1; i < rollerMountLogs.length - 1; i += Math.abs(offset)) {
    const rollerMountLog = rollerMountLogs[i]
    const rollerMountLog2 = rollerMountLogs[i + 1]
    const rollerSlot = elementMaps.RollerSlot[rollerMountLog?.slotId ?? '']
    const rollerSlot2 = elementMaps.RollerSlot[rollerMountLog2?.slotId ?? '']

    if (offset > 0) {
      const a = Number(rollerSlot?.passlineCoord) + Number(rollerMountLog?.diameter ?? 0) / 2
      const b = Number(rollerSlot2?.passlineCoord) - Number(rollerMountLog2?.diameter ?? 0) / 2

      gapPasslineCoords.push(a + ((b - a) / 2))
    }
    else {
      const a = Number(rollerSlot?.passlineCoord) - Number(rollerMountLog?.diameter ?? 0) / 2
      const b = Number(rollerSlot2?.passlineCoord) + Number(rollerMountLog2?.diameter ?? 0) / 2

      gapPasslineCoords.push(a - ((a - b) / 2))
    }

    if (gapPasslineCoords.length === amount) {
      break
    }
  }

  return { gapPasslineCoords, rollerMountLogs }
}

// <Element extends RollerBodySlot, MountLog extends RollerBodyMountLog>
function saveRollerElements (
  type: string,
  newElement: any, // TODO: maybe use FullCasterElement<Element, MountLog> instead of any?
  path: string,
  _elementMaps: ElementMaps,
  rollerChildrenPath: string[],
) {
  newElement[type].forEach((element: any) => {
    const rollerElementPath = `${path}/${type}:${element._id}`
    // const originalRollerBody = getElementFromPath<Element, MountLog>(rollerElementPath, elementMaps)

    // if (!originalRollerBody) {
    //   return
    // }

    // if (type === 'body') {
    //   originalRollerBody.diameter = newElement.diameter
    // }

    // originalRollerBody.passlineCoord = newElement.passlineCoord

    rollerChildrenPath.push(rollerElementPath)
  })
}

// FIXME: is this even used/called?
function completeTargetPathArray (
  rawPath: string[] | string,
  targetPath: string[] | string,
  elementMaps: ElementMaps,
) {
  const path = rawPath instanceof Array ? Util.getPathFromPathArray(rawPath) : rawPath
  const targetArray = targetPath instanceof Array ? targetPath : getPathArrayFromPath(targetPath)

  const targetArrayComplete = [ ...targetArray ]

  // when only SegmentGroup is selected (e.g. SegmentGroup:1) we need to add the Segment to the path
  // this only works for elements that are direct children of Segment (e.g. Nozzle, Roller)
  if (targetArrayComplete.length === 2) {
    const currentParentPath = path.substring(0, path.lastIndexOf('/'))
    const segment = ElementMapsUtil.getFullCasterElementByPath<SegmentSlot, SegmentMountLog>(
      currentParentPath,
      elementMaps,
    )
    const [ sg, sgId ] = targetArrayComplete
    const segmentGroupPath = `${sg}:${sgId}`
    const segmentGroup = ElementMapsUtil.getFullCasterElementByPath<SegmentGroupSlot, SegmentGroupMountLog>(
      segmentGroupPath,
      elementMaps,
    )

    // TODO: should we throw an error here?
    if (!segment || !segmentGroup) {
      return targetArrayComplete
    }

    const { segmentMountLogs } = segmentGroup
    const { side } = segment

    let numericId = -1

    for (const segmentMountLogId of segmentMountLogs) {
      const segmentMountLog = elementMaps.SegmentMountLog[segmentMountLogId]
      const segmentSlot = elementMaps.SegmentSlot[segmentMountLog?.slotId ?? '']

      if (segmentSlot?.side === side) {
        numericId = Mapping.numericIdByMountLogId[segmentMountLog?.id ?? ''] ?? -1

        break
      }
    }

    // TODO: should we throw an error if the numericId is -1?
    if (numericId !== -1) {
      targetArrayComplete.push('Segment')
      targetArrayComplete.push(numericId)
    }
  }

  return targetArrayComplete
}

// export function setElement (
//   elementMaps: ElementMaps,
//   type: TagName,
//   element: FullCasterElement<BaseSlot, BaseMountLog>,
//   parentType: TagName,
//   parentId: number,
//   pushToList = true,
// ) {
//   const elementName = ElementsUtil.getElementName(type, parentType)
//   const parentElementName = parentType as ElementName // since Data-/SensorPoint cannot be a parents we can cast here

//   if (!elementMaps[type]) {
//     elementMaps[type] = {}
//   }

//   if (!elementMaps[parentType]) {
//     elementMaps[parentType] = {}
//   }

//   element['#parent'] = {
//     type: parentType,
//     id: parentId,
//   }
//   elementMaps[type][element.id] = element
//   // elementMaps[parentType][element._id] = element

//   if (pushToList) {
//     if (!elementMaps[parentType][parentId][`#${type}Ids`]) {
//       elementMaps[parentType][parentId][`#${type}Ids`] = []
//     }

//     elementMaps[parentType][parentId][`#${type}Ids`].push(element.id)
//   }
// }

function save (
  {
    elementMaps,
    dirtyPaths,
    editElements,
    loopCounter,
  }: {
    elementMaps: ElementMaps
    dirtyPaths: string[]
    editElements: Record<string, any>
    loopCounter: LoopCounter
  },
  path: string,
  type: TagName,
  targetArray?: any[],
) {
  const originalElement = ElementMapsUtil.getFullCasterElementByPath(path, elementMaps)

  if (!originalElement) {
    return
  }

  const newElement = editElements[path]

  if (type === 'Nozzle') {
    const oldLoop = (originalElement as FullCasterElement<NozzleSlot, NozzleMountLog>).coolLoopIndex
    const newLoop = newElement.coolLoopIndex

    if (oldLoop && newLoop && oldLoop !== newLoop) {
      if (loopCounter[oldLoop] !== undefined) {
        loopCounter[oldLoop] -= 1
      }

      loopCounter[newLoop] = (loopCounter[newLoop] ?? 0) + 1
    }
  }

  // TODO: is this needed? I could not find any usage of this
  if (type === 'Roller') {
    const rollerChildrenPath: string[] = []

    if (newElement.RollerBearing && newElement.RollerBearing.length) {
      saveRollerElements('RollerBearing', newElement, path, elementMaps, rollerChildrenPath)
    }

    if (newElement.RollerBody && newElement.RollerBody.length) {
      saveRollerElements('RollerBody', newElement, path, elementMaps, rollerChildrenPath)
    }

    dirtyPaths.push(...rollerChildrenPath)
  }

  if (!targetArray) {
    // TODO: this should be outside this if, but is causes the value like passln_coord to jump back
    Object.keys(newElement).forEach(key => {
      ;(originalElement as any)[key] = newElement[key] // FIXME: fix type, maybe rework function
    })

    return
  }

  const targetArrayComplete = completeTargetPathArray(path, targetArray, elementMaps)
  // const [ parentType, parentId ] = targetArrayComplete.slice(-2) as [TagName, number]

  // setElement(elementMaps, type, newElement, parentType, parentId)

  // TODO: is this needed?
  // const { type: oldParentType, id: oldParentId } = ThreeUtil.getParentInfo(path)
  // const oldParentListIds = elementMaps[oldParentType][oldParentId][`#${type}Ids`]

  // oldParentListIds.splice(oldParentListIds.findIndex((oldId: number) => oldId === id), 1)

  const currentParentPath = Util.getPathFromPathArray(targetArrayComplete)

  return `${currentParentPath}/${type}:${newElement.id}`
}

export function handleSuccessfullySavedPaths (
  paths: string[],
  actionType: string,
  parentPath: string | null,
  targetArray: any[],
  dirtyPaths: string[],
  dirtyDeletePaths: string[],
  selectedPaths: Set<string>,
  hidePaths: string[],
  editElements: any,
  elementMaps: ElementMaps,
  loopCounter: LoopCounter,
) {
  const args = {
    elementMaps,
    dirtyPaths,
    editElements,
    loopCounter,
  }

  paths.forEach(path => {
    const { type, id } = ThreeUtil.getElementInfo(path)

    if (actionType !== 'delete') {
      if (!parentPath || path.indexOf(parentPath) === 0) {
        save(args, path, type)
      }
      else {
        const newPath = save(args, path, type, targetArray)

        if (!newPath) {
          return
        }

        dirtyPaths.push(newPath)
        dirtyDeletePaths.push(path)
        selectedPaths.delete(path)
        selectedPaths.add(newPath)
      }

      if (dirtyDeletePaths.includes(path) && !hidePaths.includes(path)) {
        hidePaths.push(path)
        selectedPaths.delete(path)
        delete editElements[path]
      }
    }
    else {
      if (type === 'Nozzle') {
        const element = ElementMapsUtil.getFullCasterElementByPath<NozzleSlot, NozzleMountLog>(path, elementMaps)

        if (element?.coolLoopIndex && loopCounter[element.coolLoopIndex]) {
          loopCounter[element.coolLoopIndex]! -= 1
        }
      }

      const { type: parentType } = ThreeUtil.getParentInfo(path)
      const elementName = ElementMapsUtil.getElementName(type, parentType)

      deleteElement(elementName, id, elementMaps)

      const index = dirtyDeletePaths.indexOf(path)

      if (~index) {
        dirtyDeletePaths.splice(index, 1)
      }
    }

    dirtyPaths.push(path)
  })

  return selectedPaths
}

export function handleRepeatPaths (
  repeatPath: string[],
  targetArray: any[],
  elementMaps: ElementMaps,
  mode: string,
  addedElements: any[],
  editElements: any,
  amount: number,
  offset: number,
  assignSegmentByPasslineCoord: boolean,
) {
  let gapWarnings = 0

  repeatPath.forEach(rawPath => {
    const targetPath = targetArray.length > 3
      ? Util.getPathFromPathArray(targetArray)
      : ThreeUtil.getParentInfo(rawPath).path

    const element = ElementMapsUtil.getFullCasterElementByPath(rawPath, elementMaps, true)

    if (!element) {
      return
    }

    const { type: parentType, id: numericParentId } = ThreeUtil.getElementInfo(targetPath)
    const parentMountLogType = `${parentType}MountLog` as CasterElementNames
    const parentMountLogIdTypeKey = `${parentMountLogType[0]?.toLowerCase() + parentMountLogType.substring(1)}Id`
    const parentMountLogId = Mapping.mountLogIdByTypeAndNumericId[parentType][numericParentId] ?? ''
    const parentSlotId = (elementMaps as any)[parentMountLogType]?.[parentMountLogId]?.slotId
    const parentSide = (elementMaps as any)[`${parentType}Slot`]?.[parentSlotId]?.side

    switch (mode) {
      case 'mirror':
        // here we don't need to find the new parent because it always stays in the same side and passlineCoord
        addedElements.push({
          ...element,
          ...editElements[rawPath],
          widthCoord: -Number(element.widthCoord),
          [parentMountLogIdTypeKey]: parentMountLogId,
        })

        break
      case 'offset':
        for (let i = 1; i <= amount; i++) {
          const newPasslineCoord = Number(element.passlineCoord) + (Number(offset) * i)
          const segmentMountLogId = assignSegmentByPasslineCoord
            ? getSegmentByPasslnAndSide(
              elementMaps.SegmentMountLog,
              elementMaps.SegmentSlot,
              newPasslineCoord,
              parentSide,
            )
              ?.id
            : parentMountLogId

          addedElements.push({
            ...element,
            ...editElements[rawPath],
            passlineCoord: newPasslineCoord,
            [parentMountLogIdTypeKey]: segmentMountLogId ?? parentMountLogId,
          })
        }

        break
      case 'gap offset': // Nozzle only
        {
          const newTargetPath = getPathArrayFromPath(rawPath)
          const { gapPasslineCoords, rollerMountLogs } = getGapPositions(
            elementMaps,
            element,
            newTargetPath,
            offset,
            amount,
          )

          const parentPathArray = getPathArrayFromPath(targetPath)
          const originalSegmentMountLogId = Mapping.mountLogIdByTypeAndNumericId.Segment[Number(parentPathArray[3])]
          const originalSegmentMountLog = elementMaps.SegmentMountLog[originalSegmentMountLogId ?? '']
          const originalSegmentSlot = elementMaps.SegmentSlot[originalSegmentMountLog?.slotId ?? '']

          for (let i = 0; i < amount; i++) {
            if (i >= gapPasslineCoords.length) {
              gapWarnings++

              continue
            }

            const rollerMountLog = getSegmentByRollerPassln(elementMaps, rollerMountLogs, gapPasslineCoords[i]!)
            const rollerSlot = elementMaps.RollerSlot[rollerMountLog?.slotId ?? '']
            const segmentMountLogId = assignSegmentByPasslineCoord
              ? getSegmentByPasslnAndSide(
                elementMaps.SegmentMountLog,
                elementMaps.SegmentSlot,
                rollerSlot?.passlineCoord ?? 0,
                originalSegmentSlot?.side ?? '' as StrandSide,
              )
                ?.id
              : originalSegmentMountLogId

            if (!segmentMountLogId) {
              gapWarnings++

              continue
            }

            addedElements.push({
              ...ElementMapsUtil.getFullCasterElementByPath(rawPath, elementMaps, true),
              ...editElements[rawPath],
              passlineCoord: gapPasslineCoords[i],
              [parentMountLogIdTypeKey]: segmentMountLogId ?? originalSegmentMountLogId,
            })
          }
        }

        break
      default:
        break
    }
  })

  return { gapWarnings }
}

export function handleCopyPaths (
  copyPath: string[],
  elementMaps: ElementMaps,
  tagName: TagName,
  editElements: any,
  diffPasslnCoord: number,
  diffWidthCoord: number,
  parentMountLogTypeKey: string,
  parentMountLogId: string,
  assignSegmentByPasslineCoord: boolean,
) {
  const addedElements: any[] = []

  copyPath.forEach((rawPath) => {
    const origElement = ElementMapsUtil.getFullCasterElementByPath(rawPath, elementMaps, true)

    if (!origElement) {
      return
    }

    const { passlineCoord: orgPasslnCoord, widthCoord: orgWidthCoord } = origElement
    const element = { ...origElement, ...editElements[rawPath] }

    // FIXME: what do we need to do here?
    if (tagName === 'Roller') {
      // deleteChildrenId(elementType, element)
    }

    if (orgPasslnCoord) {
      element.passlineCoord = Number(orgPasslnCoord) + Number(diffPasslnCoord)
    }

    if (orgWidthCoord) {
      element.widthCoord = Number(orgWidthCoord) + Number(diffWidthCoord)
    }

    const { type } = ThreeUtil.getElementInfo(rawPath)

    if (assignSegmentByPasslineCoord && (type === 'Nozzle' || type === 'Roller')) {
      const targetMountLog = elementMaps.SegmentMountLog[parentMountLogId]
      const targetSlot = elementMaps.SegmentSlot[targetMountLog?.slotId ?? '']
      const targetSide = targetSlot?.side ?? ''

      if (!targetSide) {
        return
      }

      const realParentMountLogId = getSegmentByPasslnAndSide(
        elementMaps.SegmentMountLog,
        elementMaps.SegmentSlot,
        element.passlineCoord,
        targetSide,
      )
        ?.id

      element[parentMountLogTypeKey] = realParentMountLogId
    }
    else {
      element[parentMountLogTypeKey] = parentMountLogId
    }

    addedElements.push(element)
  })

  return addedElements
}
