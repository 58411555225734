export default class TabsUtil {
  private static tileConfig: any = {}

  private static plotConfig: any = {}

  public static setConfigs (tileConfig: any, plotConfig: any) {
    TabsUtil.tileConfig = tileConfig
    TabsUtil.plotConfig = plotConfig
  }

  public static internalGetValue (value: any, alternative: any): any {
    return value || value === 0 ? value : alternative
  }

  public static getValue (key: string, alternative?: any): any {
    if (alternative || alternative === 0 || alternative === '') {
      return TabsUtil.internalGetValue(TabsUtil.tileConfig[key], alternative)
    }

    return TabsUtil.internalGetValue(TabsUtil.tileConfig[key], TabsUtil.plotConfig[key])
  }
}
