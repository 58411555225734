import { faTable } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { MenuItem, Select } from '@mui/material'
import { FC, useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect, type ConnectedProps } from 'react-redux'
import { FixedSizeList as List } from 'react-window'
import InfiniteLoader from 'react-window-infinite-loader'
import { compose } from 'redux'
import styled, { ThemeProvider } from 'styled-components'

import { getRealDataPerType, getRealDataMetadata, getFilteredRealDataPerType } from '@/api/real-data'
import { openDialog } from '@/store/application/main/actions'
import { setCountPerType, setElementsPerType, setFilterCache } from '@/store/warehouse'
import type { CasterElementNames } from '@/types/data'
import type { DefaultState } from '@/types/state'

import RealDataElementDetailsDialog from './dialogs/project/RealDataElementDetailsDialog'
import StyleConfig from './visualization/dashboard/config/StyleConfig'

const Top = styled.div`
  display: flex;
  justify-content: space-between;
  height: 50px;
`

const FilterInput = styled.input`
  width: calc(100% - 20px);
  margin: 0;
  height: 32px;
  border: none;
  border-radius: 5px;
  background: #333a44;
  padding: 0 30px 0 10px;
  outline: none;
  color: #FFFFFF;
  margin-top: 9px;
  margin-left: 10px;

  &::placeholder{
    font-style: italic;
    user-select: none;
  }
`

// const CheckboxWrapper = styled.div`
//   margin-top: 4px;
// `

export const IconButton = styled.button`
  display: inline-block;
  float: right;
  text-align: center;
  color: #CCCCCC;
  border: none;
  background-color: inherit;
  cursor: pointer;

  &:disabled {
    color: #333a44;
  }

  &:focus {
    outline: 0;
  }

  &:hover {
    color: #FFFFFF;
  }
`

const Element = styled.div`
  display: flex;
  padding-left: 24px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #333a44;

  ${IconButton} {
    opacity: 0;
  }

  &:hover {
    background: #6b6d71;

    ${IconButton} {
      opacity: 1;
    }
  }
`

const Name = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  font-size: 0.9em;
`

const RealDataUUID = styled.div`
  font-size: 0.75em;
  color: #999999;
`

const StyledOption = styled(MenuItem)`
  display: flex !important;
  justify-content: space-between !important;
`

const elementTypes: CasterElementNames[] = [
  'SegmentGroup',
  'Segment',
  'SupportPoint',
  'RollerBody',
  'RollerBearing',
  'Nozzle',
  'Roller',
]

const elementTypesSelector: Selector[] = elementTypes.map((type) => ({
  value: type,
  key: type,
}))

// default options for the list
elementTypesSelector.unshift({ value: 'Select Type', key: 'Select Type', disabled: true })

const connector = connect((state: DefaultState) => ({
  darkTheme: state.application.main.darkTheme,
  currentProject: state.application.main.currentProject,
  warehouse: state.warehouse,
}), {
  openDialog,
  setCountPerType,
  setElementsPerType,
  setFilterCache,
})

type Props = ConnectedProps<typeof connector> & {
  t: (key: string) => string
}

const take = 80
const T = 'partsWarehouse'

const PartsWarehouse: FC<Props> = (props) => {
  const {
    darkTheme,
    openDialog,
    t,
    currentProject,
    warehouse,
    setCountPerType,
    setElementsPerType,
    setFilterCache,
  } = props
  const { countPerType, elementsPerType } = warehouse

  const [ filter, setFilter ] = useState('')
  const [ selectedElementType, setSelectedElementType ] = useState<CasterElementNames | 'Select Type'>('Select Type')
  const [ currentElementPageIndex, setCurrentElementPageIndex ] = useState(0)
  const [ isLoadingMore, setIsLoadingMore ] = useState(false) // New loading state
  const [ timeoutId, setTimeoutId ] = useState<NodeJS.Timeout | null>(null)

  useEffect(() => {
    // Clear the previous timeout if inputValue changes
    if (timeoutId) {
      clearTimeout(timeoutId)
    }

    // Set a new timeout
    const id = setTimeout(async () => {
      if (!filter || selectedElementType === 'Select Type') {
        if (selectedElementType === 'Select Type') {
          return
        }
  
        const loadedElements = elementsPerType[selectedElementType] ?? []
        const pageIndex = Math.floor(loadedElements.length / take)
  
        setCurrentElementPageIndex(pageIndex)

        return
      }

      // TODO: show loading spinner
      const data = await getFilteredRealDataPerType(
        currentProject.id,
        selectedElementType,
        0,
        take,
        filter,
      )

      if (!data) {
        return
      }

      const { result, count } = data

      setFilterCache({ count, elements: result })
      setCurrentElementPageIndex(1)
    }, 1000) // Timeout duration in milliseconds

    // Save the timeout ID to state
    setTimeoutId(id)

    // Cleanup function to clear the timeout if the component unmounts
    return () => clearTimeout(id)
  }, [ filter ])

  const initialize = async () => {
    try {
      const metadata = await getRealDataMetadata(currentProject.id)

      if (metadata) {
        setCountPerType(metadata.availableAmounts)
      }
    }
    catch (error: any) {
      // eslint-disable-next-line no-console
      console.error(error)
    }
  }

  const handleTypeChange = async (event: any) => {
    const type = event?.target?.value as CasterElementNames | 'Select Type'

    setFilter('')

    setSelectedElementType(type)

    try {
      if (type === 'Select Type') {
        return
      }

      const loadedElements = elementsPerType[type] ?? []
      const count = countPerType[type] ?? 0
      // save in the state the current page index, to know how many to skip
      const pageIndex = Math.floor(loadedElements.length / take)

      setCurrentElementPageIndex(pageIndex)

      if ((loadedElements.length > 0) || loadedElements.length === count) {
        return
      }

      const elements = await getRealDataPerType(currentProject.id, type, pageIndex, take)

      if (elements) {
        setElementsPerType({ ...elementsPerType, [type]: elements })
      }
    }
    catch (error: any) {
      // eslint-disable-next-line no-console
      console.error(error)
    }
  }

  useEffect(() => {
    initialize()
  }, [])

  const getElementCount = () => {
    if (filter) {
      return warehouse.filterCache.count ?? 0
    }

    return selectedElementType === 'Select Type' ? 0 : countPerType[selectedElementType] ?? 0
  }

  const getElements = () => {
    if (filter) {
      return warehouse.filterCache.elements ?? []
    }

    return selectedElementType === 'Select Type' ? [] : elementsPerType[selectedElementType] ?? []
  }

  const elements = getElements()

  const Row = ({ index, style }: { index: number, style: any }) => {
    const itemLoading = index >= elements.length

    if (itemLoading) {
      return (
        <Element style={style}>
          <Name>Loading...</Name>
        </Element>
      )
    }

    const element = elements[index]

    return (
      <Element style={style}>
        <div>
          <Name>{element?.name ? element.name : selectedElementType}</Name>
          <RealDataUUID>{element?.realDataUUID}</RealDataUUID>
        </div>
        <IconButton>
          <FontAwesomeIcon
            icon={faTable}
            title={t(`${T}.showHistoricalDetails`)}
            onClick={
              () =>
                openDialog(
                  RealDataElementDetailsDialog.NAME,
                  { elementType: selectedElementType, realDataUUID: element?.realDataUUID },
                )
            }
          />
        </IconButton>
      </Element>
    )
  }

  const elementCount = getElementCount()
  const hasMoreItems = elementCount > elements.length
  const itemCount = hasMoreItems ? elements.length + 1 : elements.length
  
  const handleLoadMoreItems = async (startIndex: number, _stopIndex: number) => {
    // if startIndex is 0 data fetching should be handled by handleTypeChange
    if (startIndex === 0 || isLoadingMore || startIndex < elements.length) {
      return
    }

    setIsLoadingMore(true)

    if (filter) {
      try {
        const data = await getFilteredRealDataPerType(
          currentProject.id,
          selectedElementType,
          currentElementPageIndex,
          take,
          filter,
        )

        if (data) {
          const { result, count } = data

          setFilterCache({ count, elements: [ ...elements, ...result ] })
          setCurrentElementPageIndex(currentElementPageIndex + 1)
          setIsLoadingMore(false)
        }
      }
      catch (error: any) {
        // eslint-disable-next-line no-console
        console.error(error)
      }

      return
    }

    try {
      const newElements = await getRealDataPerType(
        currentProject.id,
        selectedElementType,
        currentElementPageIndex,
        take,
      )

      if (newElements) {
        setElementsPerType({ ...elementsPerType, [selectedElementType]: [ ...elements, ...newElements ] })
        setCurrentElementPageIndex(currentElementPageIndex + 1)
        setIsLoadingMore(false)
      }
    }
    catch (error: any) {
      // eslint-disable-next-line no-console
      console.error(error)
    }
  }

  return (
    <ThemeProvider theme={darkTheme ? StyleConfig.darkTheme : StyleConfig.lightTheme}>
      <div>
        <Top>
          <FilterInput
            type='text'
            name='filter'
            value={filter}
            onChange={event => setFilter(event.target.value)}
            placeholder='Filter Parts Warehouse'
            disabled={selectedElementType === 'Select Type'}
          />
          {/* <CheckboxWrapper>
            <Checkbox
              onChange={() => setIncludeMounted(!includeMounted)}
              color='default'
              checked={includeMounted}
              name='includeMounted'
              title='Include Mounted Elements'
            />
          </CheckboxWrapper> */}
          {/* TODO: use DropDown -> All, Partially-Mounted, Mounted. Partially: mounted but some parent is not */}
        </Top>
        {/* to debug */}
        {/* <span style={{ color: 'greenyellow', position: 'absolute', zIndex: '10000' }}>
          {elements.length} from {getElementCount()}
        </span> */}
        <Select
          style={{ margin: '0 10px 10px 10px', width: 'calc(100% - 20px)' }}
          name='elementType'
          value={selectedElementType}
          onChange={handleTypeChange}
          renderValue={
            value => ( 
              <div style={
                {
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '95%',
              
                }
              }
              >
                <span>{value}</span>
                <span>{countPerType[value]}</span>
              </div>
            )
          }
        >
          {
            elementTypesSelector.map(({ value, key, disabled }) => (
              <StyledOption key={key} value={value as any} disabled={disabled ?? false}>
                <span>{value}</span>
                <span>{countPerType[key]}</span>
              </StyledOption>
            ))
          }
        </Select>
        <InfiniteLoader
          itemCount={itemCount}
          isItemLoaded={index => index < elements.length}
          loadMoreItems={handleLoadMoreItems}
        >
          {
            ({ onItemsRendered, ref }) => (
              <List
                height={window.innerHeight - 130}
                itemSize={35}
                itemCount={itemCount}
                width={335}
                // itemData={filteredElements}
                // overscanCount={10}
                itemKey={(index) => `element${index}`}
                onItemsRendered={onItemsRendered}
                ref={ref}
              >
                {Row}
              </List>
            )
          }
        </InfiniteLoader>
      </div>
    </ThemeProvider>
  )
}

export default compose<any>(withTranslation('caster'), connector)(PartsWarehouse)
