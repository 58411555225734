import { UtilActionsEnum } from './consts'

export function updateWholeState (data: any) {
  return {
    type: 'UPDATE_WHOLE_STATE',
    data,
  }
}

export function setUserSettings (settings: any) {
  return {
    type: UtilActionsEnum.ACTION_SET_USER_SETTINGS,
    settings,
  }
}

export function setSavePath (savePath: string) {
  return {
    type: UtilActionsEnum.ACTION_SET_SAVE_PATH,
    savePath,
  }
}

export function setRollerVisible (rollerChildren: any) {
  return {
    type: UtilActionsEnum.ACTION_SET_ROLLER_VISIBLE,
    rollerChildren,
  }
}

export function setIsConsistencyCheck () {
  return {
    type: UtilActionsEnum.ACTION_SET_IS_CONSISTENCY_CHECK_CASE,
  }
}
