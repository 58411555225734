import IpcManager from '@/IpcManager'

import LogicInterface from './LogicInterface'

export default class BothLogic extends LogicInterface {
  public override on (...params: any[]) {
    IpcManager.external.on(...params)
    IpcManager.internal.on(...params)
  }

  public override send (...params: any[]) {
    IpcManager.external.send(...params)
    IpcManager.internal.send(...params)
  }

  public override removeListener (...params: any[]) {
    IpcManager.external.removeListener(...params)
    IpcManager.internal.removeListener(...params)
  }
}
