import isEqual from 'lodash/isEqual'
import { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'redux'

import FeatureFlags from '@/react/FeatureFlags'
import DataActions from '@/store/data/actions'
import * as FilterActions from '@/store/filter/actions'
import ThreeManager from '@/three/ThreeManager'
import type { CasterDialogElementType } from '@/types/filter'
import type { DefaultState } from '@/types/state'

import TypeHeader from './TypeHeader'
import AllInOne from '../AllInOne'
import CCElementList from '../CCElementList'
import Modules from '../Modules'
import SegmentGroup from '../SegmentGroup'

const connector = connect((state: DefaultState) => ({
  CCElement: state.CCElement,
  loadingStatus: state.application.main.loadingStatus,
  filterElement: state.filter.filterElement,
  Caster: state.Caster,
  featureFlags: FeatureFlags.getRealFeatureFlags(state),
}), {
  setEditValues: DataActions.setEditValues,
  setFilterValues: FilterActions.setFilterValues,
})

type PropsFromRedux = ConnectedProps<typeof connector>

export interface Props extends PropsFromRedux {
  t(key: string, params?: Record<string, unknown>): string
  key: string
  type: CasterDialogElementType | 'ConsistencyCheck' | 'Elements' | 'Modules'
  paths: string[]
  areElementsShown: boolean
  toggleElements: () => void
}

type State = {
  isExpandedMain: boolean
  isSelected: boolean
}

export class TypeBuilder extends Component<Props, State> {
  public constructor (props: Props) {
    super(props)

    const { paths } = props

    if (!paths || (paths.length === 0)) {
      this.state = {
        isExpandedMain: false,
        isSelected: false,
      }

      return
    }

    this.state = {
      isExpandedMain: true,
      isSelected: true,
    }
  }

  public override componentDidUpdate (prevProps: Props) {
    const { paths, type } = this.props

    if (type === 'ConsistencyCheck' || type === 'Modules') {
      return
    }

    if (!isEqual(prevProps.paths, paths)) {
      let isOpen = true

      if (!paths || (paths.length === 0)) {
        isOpen = false
      }

      this.setState({
        isExpandedMain: isOpen,
        isSelected: isOpen,
      })
    }
  }

  private readonly handleToggleMain = (event: any) => {
    event.preventDefault()

    this.setState({
      isExpandedMain: !this.state.isExpandedMain,
    })

    const { type, setEditValues, setFilterValues, filterElement } = this.props

    if (type === 'Modules') {
      return
    }

    setFilterValues({
      ...filterElement,
      [type]: {},
    })

    setEditValues({ [type]: {} })
  }

  private getCorrespondingTypeComponent (type: string, paths: string[]) {
    switch (type) {
      case 'ConsistencyCheck':
        return <CCElementList paths={paths} />
      case 'Modules':
        return <Modules paths={paths} />
      case 'Elements':
        return null
      case 'SegmentGroup':
        return <SegmentGroup paths={paths} />
      default:
        return <AllInOne paths={paths} type={type} />
    }
  }

  public override render () {
    const { isExpandedMain, isSelected } = this.state
    const {
      CCElement,
      type,
      paths,
      loadingStatus,
      Caster,
      t,
      areElementsShown,
      toggleElements,
      featureFlags,
    } = this.props

    switch (type) {
      case 'ConsistencyCheck':
        if (Object.keys(CCElement ?? {}).length === 0) {
          return null
        }

        break
      case 'Modules':
        if (!FeatureFlags.canViewModules(featureFlags)) {
          return null
        }

        break
      case 'Nozzle':
        if (!FeatureFlags.canViewNozzlesIn3D(featureFlags)) {
          return null
        }

        break
      case 'Roller':
      case 'RollerBearing':
      case 'RollerBody':
        if (!FeatureFlags.canViewRollersIn3D(featureFlags)) {
          return null
        }

        break
      case 'SensorPoint':
        if (!ThreeManager.base.views?.mainView?.elementList?.SensorPoint) {
          return null
        }

        break
      case 'SupportPoint':
      case 'SegmentGroup':
        if (!FeatureFlags.canViewSupportPointsIn3D(featureFlags)) {
          return null
        }

        break
    }

    return (
      <div>
        <TypeHeader
          title={t(`type.${type}.title`)}
          type={t(`type.${type}.label`)}
          isExpanded={
            !loadingStatus &&
            Caster &&
            (type === 'Elements' ? areElementsShown : isExpandedMain)
          }
          isSelected={!loadingStatus && Caster && isSelected}
          onClick={type === 'Elements' ? toggleElements : this.handleToggleMain}
        />
        {
          !loadingStatus &&
          Caster &&
          isExpandedMain &&
          this.getCorrespondingTypeComponent(type, paths)
        }
      </div>
    )
  }
}

export default compose<any>(withTranslation('caster'), connector)(TypeBuilder)
