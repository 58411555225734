/* eslint-env browser */

import { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'redux'

import { changeCatalog, deleteCatalog, loadCatalog } from '@/logic/catalogUtil'
import Button from '@/react/components/Button/index'
import { ConfirmWrapper } from '@/react/components/Button/styles'
import InfoMarker from '@/react/components/InfoMarker/index'
import { DialogID } from '@/react/driver/DriverID'
import FeatureFlags from '@/react/FeatureFlags/index'
import Input from '@/react/specific/Input'
import { Form } from '@/react/visualization/dashboard/Dialogs/DialogStyles'
import * as ErrorActions from '@/store/application/error/actions'
import * as ApplicationActions from '@/store/application/main/actions'
import { AppState } from '@/store/application/main/consts'
import DataActions from '@/store/data/actions'
import * as ElementsActions from '@/store/elements/actions'
import * as LoadingActions from '@/store/LoadingStore'
import * as VisualizationActions from '@/store/visualization/actions'
// TODO: move this file or its contents
import type { DefaultState } from '@/types/state'
import type { ArrayOfTranslations, Translation } from '@/types/translation'

import { CasterColumnLogic } from './CasterColumnLogic'
import Logic from './Logic'
import { Hr, Title } from './Styles'
import { Spacer } from '../OpenProjectDialog/Styles'

const T = 'projectDataDialog.caster'

const connector = connect((state: DefaultState) => ({
  openAppDialogs: state.application.main.openAppDialogs,
  currentSimulationCase: state.application.main.currentSimulationCase,
  currentCatalogId: state.data.currentCatalogId,
  visualizationMetaInformation: state.visualization.visualizationMetaInformation,
  uploadLoading: state.loading.uploadLoading,
  error: state.application.error,
  featureFlags: FeatureFlags.getRealFeatureFlags(state),
  appState: state.application.main.appState,
  currentProject: state.application.main.currentProject,
  authenticationData: state.application.main.authenticationData,
  catalogList: state.data.catalogList,
}), {
  openDialog: ApplicationActions.openDialog,
  closeDialog: ApplicationActions.closeDialog,
  setCurrentSimulationCase: ApplicationActions.setCurrentSimulationCase,
  setAppState: ApplicationActions.setAppState,
  saveCatalog: DataActions.saveCatalog,
  setFileUploadLoadingStatus: LoadingActions.setFileUploadLoadingStatus,
  setError: ErrorActions.setError,
  setConfig: VisualizationActions.setConfig,
  setDataSources: VisualizationActions.setDataSources,
  resetReducer: DataActions.resetReducer,
  resetAllElements: ElementsActions.resetAllElements,
  setCasterDashboardTabIndex: VisualizationActions.setCasterDashboardTabIndex,
  setCatalogList: DataActions.setCatalogList,
  setCurrentCatalogId: DataActions.setCurrentCatalogId,
  setLastLoadedCasterCatalogId: ApplicationActions.setLastLoadedCasterCatalogId,
  setLoadingStatus: ApplicationActions.setLoadingStatus,
})

type PropsFromRedux = ConnectedProps<typeof connector>

export interface Props extends PropsFromRedux {
  onDeleteCatalog?: (type: any, key: any) => null | undefined
  onClose: (isClick?: any) => void
  onChange?: (event: any) => any
  onDeleteConfig?: (type: any, key: any) => null | undefined
  t: ArrayOfTranslations & Translation
  setVisualizationMetaInformation: (name: string, value: string, appState: AppState) => void
  onCatalogChange: (event: any) => any
}

type State = {
  loading: any
  selectedCatalogId: string
}

export class CasterColumn extends Component<Props, State> {
  public constructor (props: Props) {
    super(props)

    this.state = {
      loading: {
        openCaster: false,
      },
      selectedCatalogId: props.currentCatalogId ?? 'default',
    }
  }

  public override componentDidUpdate (): void {
    const newSelectedCatalogId = Logic.getSelectedCatalog(this.state.selectedCatalogId, this.props.catalogList)

    if (newSelectedCatalogId !== this.state.selectedCatalogId) {
      this.setState({ selectedCatalogId: newSelectedCatalogId })
    }
  }

  private readonly handleCatalogChange = (event: any) => {
    const params = {
      ...this.props,
      selectedCatalogId: event.target.value,
      setSelectedCatalogId: (id: string) => this.setState({ selectedCatalogId: id }),
    }

    changeCatalog(event, params)
  }

  private readonly handleDeleteCatalog = async (_type: string, key: string) => {
    const {
      featureFlags,
      currentSimulationCase,
      catalogList,
      setCatalogList,
      currentCatalogId,
      setCurrentCatalogId,
    } = this.props

    await deleteCatalog(
      key,
      {
        featureFlags,
        currentSimulationCase,
        catalogList,
        setCatalogList,
        currentCatalogId,
        setCurrentCatalogId,
      },
    )
  }

  private readonly handleLoadCasterData = () => {
    const { setLoadingStatus } = this.props

    CasterColumnLogic.handleUploadCasterData(undefined, this.props, false, setLoadingStatus)
  }

  private readonly handleOpenCaster = async () => {
    const params = {
      ...this.props,
      selectedCatalogId: this.state.selectedCatalogId,
    }

    await loadCatalog(params)

    CasterColumnLogic.openCaster(this.props)
  }

  public override render () {
    const { loading } = this.state
    const {
      t,
      currentSimulationCase,
      featureFlags,
      uploadLoading,
      error,
      catalogList,
      authenticationData,
    } = this.props

    const { selectedCatalogId } = this.state

    const {
      simulationStartedAt,
      isCasterGenerated,
      currentCaster,
    } = currentSimulationCase

    // FIXME: this should be the name of the caster file, not the caster itself
    const casterName = currentCaster?.name ?? 'n/a'

    const catalogSelectors = Logic.getSelectors<CaseFile>(
      catalogList,
      'id',
      'fileName',
      { value: t(`${T}.catalog.default`), disabled: true },
    )

    // const inputDataIsOpen = openAppDialogs && openAppDialogs.includes(InputDataDialog.NAME)

    const isCaseOwner = currentSimulationCase && currentSimulationCase?.userId === authenticationData?.id

    return (
      <Form $maxWidth='300px' $padding='18px 28px 20px'>
        <Title>
          {t(`${T}.title`)}
          <InfoMarker
            message={t(`${T}.info`, { returnObjects: true }).join('\n')}
            x={224}
            y={-23}
          />
        </Title>
        <ConfirmWrapper>
          <Button
            id={DialogID.ProjectData.UploadButton}
            onClick={this.handleLoadCasterData}
            loading={uploadLoading.CasterDataIsLoading}
            error={error['CasterData'] && t([ `error.${error['CasterData']}`, 'error.default' ])}
            title={
              casterName
                ? t(`${T}.uploaded`, { name: casterName })
                : t(`${T}.data.title`)
            }
            icon={casterName && !isCasterGenerated ? 'pe-7s-check' : 'pe-7s-up-arrow'}
            disabled={!isCaseOwner || Boolean(simulationStartedAt) || Boolean(currentSimulationCase.blueprintId)}
          >
            {t(`${T}.data.label`)}
          </Button>
        </ConfirmWrapper>
        <Input
          name='catalog'
          type='select'
          label={t(`${T}.catalog.label`)}
          title={
            error['catalog']
              ? t([ `error.${error['catalog']}`, 'error.default' ])
              : t(`${T}.catalog.title`)
          }
          error={error['catalog'] && t([ `error.${error['catalog']}`, 'error.default' ])}
          value={selectedCatalogId}
          selectors={
            FeatureFlags.canUploadNozzleCatalog(featureFlags)
              ? [
                ...catalogSelectors,
              {
                key: 'add',
                notRemovable: true,
                value: t(`${T}.catalog.add`),
                disabled: Boolean(simulationStartedAt),
              } as Selector,
              ]
              : [ ...catalogSelectors ]
          }
          onChange={this.handleCatalogChange}
          onDelete={FeatureFlags.canDeleteNozzleCatalog(featureFlags) && this.handleDeleteCatalog}
          disabled={Boolean(simulationStartedAt)}
        />
        <Spacer $h={103} $br />
        <Hr />
        <Spacer $h={55} $br />
        <Button
          id={DialogID.ProjectData.OpenCasterButton}
          onClick={this.handleOpenCaster}
          disabled={!currentSimulationCase.currentCasterId || uploadLoading.CasterDataIsLoading}
          loading={loading.openCaster}
          title={
            error['openCaster']
              ? t([ `error.${error['openCaster']}`, 'error.default' ])
              : t(`${T}.${simulationStartedAt ? 'view' : 'open'}.title`)
          }
          error={error['openCaster'] && t([ `error.${error['openCaster']}`, 'error.default' ])}
          hideError
        >
          {t(`${T}.${simulationStartedAt ? 'view' : 'open'}.label`)}
        </Button>
      </Form>
    )
  }
}

export default compose<any>(withTranslation('application'), connector)(CasterColumn)
