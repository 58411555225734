import superagent from 'superagent'

import { ensureValidAccessToken } from '@/api/auth'
import { AuthDataStore } from '@/logic/auth-data-store'

export type HttpMethods = 'del' | 'get' | 'patch' | 'post' | 'put'

export type Options = {
  params?: any
  data?: any
  form?: any
  files?: any[]
  getHeadersOnly?: boolean
  headers?: any
  skipAuth?: boolean
}

export default class ApiClient {
  public static async request (method: HttpMethods, path: string, options: Options = {}): Promise<any> {
    const { params, data, form, files, getHeadersOnly, headers, skipAuth } = { ...options }

    if (!skipAuth) {
      await ensureValidAccessToken()
    }

    return new Promise((resolve, reject) => {
      const request = superagent[method](path)

      request
        .set('Accept', 'application/json, text/javascript, */*; q=0.01')
        .set('X-Requested-With', 'XMLHttpRequest')
        .withCredentials()

      const tokenData = AuthDataStore.get()

      if (tokenData?.accessToken && !skipAuth) {
        request.set('Authorization', `Bearer ${tokenData.accessToken}`)
      }

      if (params) {
        request.query(params)
      }

      if (form) {
        request.type('form')
      }

      if (data) {
        request.send(data)
      }

      if (files) {
        request.set('Content-Type', 'multipart/form-data')
        files.map(file => request.attach(file.field, file.chunk))
      }

      if (headers) {
        for (const header in headers) {
          request.set(header, headers[header])
        }
      }

      request.end((error, response) => {
        const { body = {}, text, type, status } = response ?? {}

        if (error) {
          // eslint-disable-next-line prefer-promise-reject-errors
          reject({ error, body, status })

          return
        }

        if (getHeadersOnly) {
          resolve(response.headers ?? {})

          return
        }

        if (type.startsWith('text/')) {
          resolve(text)

          return
        }

        resolve(body)
      })
    })
  }

  /** @deprecated use `import { request } from '@/api'` instead */
  public static get (path: string, options?: Options): Promise<any> {
    return ApiClient.request('get', path, options)
  }

  /** @deprecated use `import { request } from '@/api'` instead */
  public static post (path: string, options?: Options): Promise<any> {
    return ApiClient.request('post', path, options)
  }

  /** @deprecated use `import { request } from '@/api'` instead */
  public static put (path: string, options?: Options): Promise<any> {
    return ApiClient.request('put', path, options)
  }

  /** @deprecated use `import { request } from '@/api'` instead */
  public static patch (path: string, options?: Options): Promise<any> {
    return ApiClient.request('patch', path, options)
  }

  /** @deprecated use `import { request } from '@/api'` instead */
  public static del (path: string, options?: Options): Promise<any> {
    return ApiClient.request('del', path, options)
  }
}
