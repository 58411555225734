import * as THREE from 'three'

export default class ConditionUtil {
  public static scrollValueNotUpdatedOr0 (lastDrawnScrollValue: number, scrollValue: number) {
    return (lastDrawnScrollValue === scrollValue || scrollValue === 0)
  }

  public static noListOrNozzleOrRoller (elementList: any) {
    return (!elementList ||
      (!elementList.Nozzle && !elementList.Roller))
  }

  public static mouseIsOutsideOfSectionView (
    raycaster: THREE.Raycaster,
    sectionPlaneFolded?: THREE.Mesh,
    sectionPlaneHeader?: THREE.Mesh,
  ) {
    // intersects the raycaster, which has the mouse's position with the section plane's elements
    // if intersects any of them, that means the mouse is in the section view
    if (sectionPlaneFolded && sectionPlaneHeader) {
      const intersects = raycaster.intersectObjects([ sectionPlaneFolded, sectionPlaneHeader ], false)

      return intersects.length === 0
    }

    return true
  }
}
