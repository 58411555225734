import hoistStatics from 'hoist-non-react-statics'
import cloneDeep from 'lodash/cloneDeep'
import { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'redux'

import { setVisualizationConfig } from '@/api/visualization-config'
import { AppState } from '@/store/application/main/consts'
import * as VisualizationActions from '@/store/visualization/actions'
import type { DefaultState } from '@/types/state'
import { Identifiable } from '@/Util/decorators/Identifiable'

import { Button, Dialog, DialogBackground, Form, Header, I, Text, Title } from './DialogStyles'

const connector = connect(({ visualization, application }: DefaultState) => ({
  viewsObject: visualization.viewsObject,
  plotConfigs: visualization.plotConfigs,
  tileConfigs: visualization.tileConfigs,
  visualizationMetaInformation: visualization.visualizationMetaInformation,
  dashboardToDelete: visualization.dashboardToDelete,
  appState: application.main.appState,
  casterDashboardTabIndex: visualization.casterDashboardTabIndex,
}), {
  deleteDashboard: VisualizationActions.deleteDashboard,
  deleteSplitView: VisualizationActions.deleteSplitView,
  showDeleteDashboardDialog: VisualizationActions.showDeleteDashboardDialog,
  setCasterDashboardTabIndex: VisualizationActions.setCasterDashboardTabIndex,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  t(key: string, params?: Record<string, unknown>): string
}

export class DeleteDashboardDialog extends Component<Props> {
  @Identifiable('DeleteDashboardDialog') public static readonly NAME: string

  private readonly handleSubmit = async () => {
    const {
      deleteDashboard,
      dashboardToDelete,
      showDeleteDashboardDialog,
      appState,
      casterDashboardTabIndex,
      setCasterDashboardTabIndex,
      viewsObject,
      plotConfigs,
      tileConfigs,
      visualizationMetaInformation,
    } = this.props

    if (appState === AppState.Caster) {
      setCasterDashboardTabIndex(casterDashboardTabIndex - 1)
    }

    const viewsObjectCopy = cloneDeep(viewsObject)

    delete viewsObjectCopy[dashboardToDelete.key]?.dashboards?.[dashboardToDelete.dashboardId]

    const dashboardConfigId = visualizationMetaInformation?.[appState]?.config
    const data = { viewsObject: viewsObjectCopy, plotConfigs, tileConfigs }

    await setVisualizationConfig(dashboardConfigId, data)

    deleteDashboard(dashboardToDelete.key, dashboardToDelete.dashboardId)
    showDeleteDashboardDialog()
  }

  private readonly handleDeleteSplitAndDashboard = () => {
    const { deleteSplitView, dashboardToDelete, showDeleteDashboardDialog, deleteDashboard } = this.props

    deleteDashboard(dashboardToDelete.key, dashboardToDelete.dashboardId)
    deleteSplitView(dashboardToDelete.key)
    showDeleteDashboardDialog()
  }

  private readonly handleDeleteSplit = () => {
    const { deleteSplitView, dashboardToDelete, showDeleteDashboardDialog } = this.props

    deleteSplitView(dashboardToDelete.key)
    showDeleteDashboardDialog()
  }

  private readonly setFocus = (ref: any) => {
    if (ref) {
      ref.focus()
    }
  }
  
  public override render () {
    const { showDeleteDashboardDialog, viewsObject, dashboardToDelete, t } = this.props

    if (Object.keys(viewsObject[dashboardToDelete.key]?.dashboards ?? {}).length < 2) {
      const hasSplit = dashboardToDelete.key.includes('split')

      return (
        <div>
          <DialogBackground />
          <Dialog $height={hasSplit ? '320px' : '155px'} $half>
            <Header title={t('deleteDashboardDialog.title')}>
              <Title>{t('deleteDashboardDialog.label')}</Title>
              <I
                className='pe-7s-close'
                onClick={(_e: any) => showDeleteDashboardDialog()}
                title={t('deleteDashboardDialog.close')}
              />
            </Header>
            <Form>
              {
                t('deleteDashboardDialog.mergeMessage', {
                  split: hasSplit ? t('deleteDashboardDialog.split') : '',
                })
                  .split('\n')
                  .map((val, i) => <Text key={i}>{val}</Text>)
              }
              <br />
              {
                hasSplit &&
                (
                  <div>
                    <Button
                      onClick={this.handleDeleteSplit}
                      title={t('deleteDashboardDialog.apply.title')}
                    >
                      <span>{t('deleteDashboardDialog.apply.label')}</span>
                    </Button>
                    <Button
                      onClick={this.handleDeleteSplitAndDashboard}
                      $type='error'
                      title={t('deleteDashboardDialog.abort.title')}
                    >
                      <span>{t('deleteDashboardDialog.abort.label')}</span>
                    </Button>
                  </div>
                )
              }
            </Form>
          </Dialog>
        </div>
      )
    }

    return (
      <div>
        <DialogBackground />
        <Dialog $height='220px' $half>
          <Header title={t('deleteDashboardDialog.title')}>
            <Title>{t('deleteDashboardDialog.label')}</Title>
            <I className='pe-7s-close' onClick={(_e: any) => showDeleteDashboardDialog()} />
          </Header>
          <Form>
            <Text>{t('deleteDashboardDialog.message')}</Text>
            <br />
            <Button onClick={this.handleSubmit} ref={this.setFocus} title={t('deleteDashboardDialog.delete.title')}>
              <span>{t('deleteDashboardDialog.delete.label')}</span>
            </Button>
          </Form>
        </Dialog>
      </div>
    )
  }
}

const composedComponent = compose<any>(withTranslation('visualization'), connector)(DeleteDashboardDialog)

export default hoistStatics(composedComponent, DeleteDashboardDialog)
