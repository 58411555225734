import hoistStatics from 'hoist-non-react-statics'
import hotkeys from 'hotkeys-js'
import { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'redux'

import ApiClient from '@/store/apiClient'
import * as VisualizationActions from '@/store/visualization/actions'
import type { DefaultState } from '@/types/state'
import { Identifiable } from '@/Util/decorators/Identifiable'

import { Button, Dialog, DialogBackground, Form, Header, I, Text, Title } from './DialogStyles'

const connector = connect((state: DefaultState) => ({
  currentSimulationCase: state.application.main.currentSimulationCase,
  deleteDialogPlot: state.visualization.deleteDialogPlot,
  tileConfigs: state.visualization.tileConfigs,
}), {
  showDeleteDialog: VisualizationActions.showDeleteDialog,
  deletePlotTile: VisualizationActions.deletePlotTile,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  t(key: string, params?: Record<string, unknown>): string
}

export class DeletePlotDialog extends Component<Props> {
  @Identifiable('DeletePlotDialog') public static readonly NAME: string

  public override componentDidMount () {
    hotkeys('Escape', this.handleClose as any)
    hotkeys('Enter', this.handleSubmit as any)
  }
  
  public override componentWillUnmount () {
    hotkeys.deleteScope('other')
    hotkeys.unbind('Escape', this.handleClose as any)
    hotkeys.unbind('Enter', this.handleSubmit as any)
  }

  private readonly handleSubmit = async () => {
    const { currentSimulationCase, showDeleteDialog, deletePlotTile, deleteDialogPlot, tileConfigs } = this.props

    if (!deleteDialogPlot.tileId) {
      return
    }

    const currentTile = tileConfigs[deleteDialogPlot.tileId]

    if (currentTile?.type === 'command') {
      await ApiClient.del(
        `${'Network.URI(deprecated)'}/visualization_command/${currentTile.fileId}/${currentTile.commandId}`,
        { data: { simulationCaseId: currentSimulationCase.id } },
      )
    }

    deletePlotTile(deleteDialogPlot.tileId)
    showDeleteDialog()
  }

  private readonly handleClose = () => {
    const { showDeleteDialog } = this.props

    showDeleteDialog()
  }
  
  public override render () {
    const { t } = this.props

    return (
      <div>
        <DialogBackground />
        <Dialog $height='200px'>
          <Header title={t('deletePlotDialog.title')}>
            <Title>{t('deletePlotDialog.label')}</Title>
            <I
              className='pe-7s-close'
              onClick={this.handleClose}
              title={t('deletePlotDialog.close')}
            />
          </Header>
          <Form>
            <Text>{t('deletePlotDialog.message')}</Text>
            <br />
            <Button
              onClick={this.handleSubmit}
              $type='error'
              $half
              title={t('deletePlotDialog.delete.title')}
            >
              {t('deletePlotDialog.delete.label')}
            </Button>
            <Button
              onClick={this.handleClose}
              $half
              title={t('deletePlotDialog.abort.label')}
            >
              {t('deletePlotDialog.abort.title')}
            </Button>
          </Form>
        </Dialog>
      </div>
    )
  }
}

const composedComponent = compose<any>(withTranslation('visualization'), connector)(DeletePlotDialog)

export default hoistStatics(composedComponent, DeletePlotDialog)
