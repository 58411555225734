import { faDotCircle, faEye, faEyeSlash, faTimesCircle } from '@fortawesome/free-regular-svg-icons'
import { faAdjust, faFilter, faLayerGroup } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CSSProperties, PureComponent } from 'react'

import ThreeManager from '@/three/ThreeManager'

import { FilterControl } from './CasterFilter'
import { UI3DID } from './driver/DriverID'
import FeatureFlags from './FeatureFlags'

type Props = {
  setTermDisabled: (termDisabled: boolean) => void
  termDisabled: boolean
  setSelectedElementPaths: (elementPath?: string, ctrlKey?: boolean, shiftKey?: boolean) => void
  featureFlags: Record<string, boolean>
  rollersVisible: boolean
}

const containerStyle: CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  gap: '5px',
}

export default class UIControls extends PureComponent<Props> {
  private readonly handleToggleFilter = () => {
    const { setTermDisabled, termDisabled } = this.props

    setTermDisabled(!termDisabled)
  }

  private readonly handleToggleBending = () => {
    ThreeManager.toggleStrandBending()
  }

  private readonly handleToggleRollerVisibility = () => {
    ThreeManager.toggleRollerVisibility()
  }

  private readonly handleToggleSectionView = () => {
    ThreeManager.toggleSectionView()
  }

  private readonly handleDeselectElements = () => {
    const { setSelectedElementPaths } = this.props

    setSelectedElementPaths()
  }

  private readonly handleCenterCamera = (event: any) => {
    const shiftClick = event.shiftKey

    ThreeManager.centerCamera(shiftClick)
  }

  public override render () {
    const { featureFlags, termDisabled, rollersVisible } = this.props
    const canToggleBending = FeatureFlags.canToggleBending(featureFlags)
    const canToggleRollerVisibility = FeatureFlags.canToggleRollerDetails(featureFlags)
    const canToggleSectionView = FeatureFlags.canToggleSectionView(featureFlags)
    const canDeselectElements = FeatureFlags.canDeselectAll(featureFlags)
    const canToggleFilter = FeatureFlags.canDeleteFilterWith3DButton(featureFlags)
    const canCenterCamera = FeatureFlags.canCenter3DPosition(featureFlags)

    const toggleSectionViewTooltip = (
      FeatureFlags.canJumpOverSomeElement(featureFlags) &&
      ThreeManager.getAmountOfJumpableElements() > 0
    )
      ? 'Toggle section view'
      : 'Toggle section view (disabled)'

    return (
      <div style={containerStyle}>
        {
          canToggleFilter &&
            <FilterControl
              id={UI3DID.ToggleFilter}
              $backgroundColor='#505457'
              key='toggle-filter'
              onClick={this.handleToggleFilter}
              $active={false}
              title={termDisabled ? 'Enable filter' : 'Disable filter'}
              $color={termDisabled ? '#FF0000' : '#B0B1B2'}
            >
              <FontAwesomeIcon icon={faFilter} />
            </FilterControl>
        }
        {
          canToggleBending &&
            <FilterControl
              id={UI3DID.ToggleBending}
              $backgroundColor='#505457'
              key='toggle-bending'
              onClick={this.handleToggleBending}
              $active={false}
              title='Toggle bending'
              $color='#B0B1B2'
            >
              <FontAwesomeIcon icon={faAdjust} />
            </FilterControl>
        }
        {
          canToggleRollerVisibility &&
            <FilterControl
              id={UI3DID.ToggleRollerVisibility}
              $backgroundColor='#505457'
              key='roller-toggle'
              onClick={this.handleToggleRollerVisibility}
              $active={false}
              title='Toggle roller visibility'
              $color='#B0B1B2'
            >
              <FontAwesomeIcon icon={rollersVisible ? faEye : faEyeSlash} />
            </FilterControl>
        }
        {
          canToggleSectionView &&
            <FilterControl
              id={UI3DID.ToggleSectionView}
              $backgroundColor='#505457'
              key='section-view-toggle'
              onClick={this.handleToggleSectionView}
              $active={false}
              title={toggleSectionViewTooltip}
              $color='#B0B1B2'
            >
              <FontAwesomeIcon icon={faLayerGroup} />
            </FilterControl>
        }
        {
          canDeselectElements &&
            <FilterControl
              id={UI3DID.DeselectElements}
              $backgroundColor='#505457'
              key='deselect-button'
              onClick={this.handleDeselectElements}
              $active={false}
              title='Deselect all'
              $color='#B0B1B2'
            >
              <FontAwesomeIcon icon={faTimesCircle} />
            </FilterControl>
        }
        {
          canCenterCamera &&
            <FilterControl
              id={UI3DID.CenterView}
              $backgroundColor='#505457'
              key='center-view-button'
              onClick={this.handleCenterCamera}
              $active={false}
              title='Center camera'
              $color='#B0B1B2'
            >
              <FontAwesomeIcon icon={faDotCircle} />
            </FilterControl>
        }
      </div>
    )
  }
}
